import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
const qs = require("qs");

// keys
export const GET_CONFIGS = "GET_CONFIGS";

//functions
async function getConfigs() {
    try {
        const response = await API({ }).get<any, any>(
            `configs`
        );

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetConfigs = () => useQuery([GET_CONFIGS], () => getConfigs());
