import { Group, Button, Modal, Stack, Text, Title, Image } from '@mantine/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmCancelSubModal = (props: any) => {

    return (
        <Modal
            centered
            opened={props.opened}
            onClose={() => props.onClose()}
            withCloseButton={false}
            closeOnClickOutside={false}
        >
            <Stack spacing={16}>
                <Stack spacing={4}>
                    <Text align='center' color="red" fz="lg" fw={700}>Cancel Subscription?</Text>
                    <Text align='center' fz="md" >Your subscription will end immediately and your will no longer have access to paid features </Text>
                </Stack>
                <Group sx={{ width: '100%' }} grow>
                    <Button onClick={() => props.onClose()}>
                        <Text>NO</Text>
                    </Button>
                    <Button
                        variant='outline'
                        onClick={() => props.onConfirm()}
                    >
                        <Text>YES</Text>
                    </Button>
                    {/* <Button onClick={() => props.onConfirm()} color="red">
                        OK
                    </Button> */}
                </Group>
            </Stack>
        </Modal>
    );
};

export default ConfirmCancelSubModal;