import {
    Box,
    Container,
    Grid,
    Text,
    Card,
    Image,
    UnstyledButton,
    Button,
    Center,
    Stack,
} from "@mantine/core";
import { RegisterFooter } from "components/Footer/RegisterFooter";
import MaintainenceModal from "components/Modals/MaintainenceModal";
import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { accountTypes } from "types/accountTypes";

type Props = {
    src: string;
    title: string;
    content: string;
    onClick: () => void;
    isSelected: boolean;
};
const AccountTypeCard: React.FC<Props> = ({
    src,
    title,
    content,
    onClick,
    isSelected,
}) => {
    return (
        <UnstyledButton
            onClick={onClick}
            sx={{
                flexGrow: 1,
            }}
        >
            <Card
                sx={(theme) => ({
                    border: isSelected
                        ? `2px solid ${theme.colors.primaryGreen[7]}`
                        : "2px solid lightgrey",
                })}
                p={24}
            >
                <Image
                    src={src}
                    width={32}
                    height={32}
                    fit="contain"
                    radius={5}
                />
                <Text size={20} weight={600}>
                    {title}
                </Text>
                <Text color="primaryGreen">{content}</Text>
            </Card>
        </UnstyledButton>
    );
};

export const AccountSelect: React.FC<any> = ({}) => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState<accountTypes | null>(null);

    const handleContinue = () => {
        if (!!selected) {
            switch (selected) {
                case accountTypes.AGENT: {
                    navigate("agent");
                    break;
                }
                case accountTypes.LISTER: {
                    navigate("/requests/create");
                    break;
                }
                default:
                    break;
            }
        }
    };

    return (
        <Box
            sx={theme => ({
                backgroundColor: theme.colors.bgYellow,
                height: '100%'
            })}
        >
            {/* <MaintainenceModal /> */}
            <Container size="800px"
                sx={{
                    height: '100%',
                }}
            >
                <Stack justify="center" sx={{ height: 'inherit' }}>
                    <Text
                        size={32}
                        weight={600}
                        my={16}
                        sx={{ textAlign: "center" }}
                    >
                        Sign up as a requestor or agent
                    </Text>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 16,
                        }}
                    >
                        <AccountTypeCard
                            src="/lister-icon.svg"
                            title="Requestor"
                            content="Sign up to get the best offers"
                            onClick={() => setSelected(accountTypes.LISTER)}
                            isSelected={selected === accountTypes.LISTER}
                        />
                        <AccountTypeCard
                            src="/agent-icon.svg"
                            title="Agent"
                            content="Sign up to offer properties"
                            onClick={() => setSelected(accountTypes.AGENT)}
                            isSelected={selected == accountTypes.AGENT}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                        mt={16}
                        mb={16}
                    >
                        <Button
                            onClick={handleContinue}
                            disabled={!selected}
                            color="primaryGreen.7"
                            radius={8}
                            sx={theme => ({
                                height: "45px",
                                width: '100%',
                                [theme.fn.largerThan('md')]: {
                                    width: '50%'
                                }
                            })}
                        >
                            Continue
                        </Button>
                    </Box>
                    <RegisterFooter />
                </Stack>
            </Container>
        </Box>
    );
};
