import { Box, Grid, Group, Image, Stack, Text } from '@mantine/core';
import React from 'react';

const TransparencyAboveAllElse = () => {
    const stats = [
        {
            title: "100+",
            content: (
                <Text color="#fff" component="p">
                    Verified participating agencies that work hard to make sure
                    you find the perfect place
                </Text>
            ),
        },
        {
            title: "8",
            content: (
                <Group spacing={4}>
                    <Text color="#fff" component="p" m={0}>
                        Developers partnered with
                    </Text>
                    <Image
                        src={"/logo-white.svg"}
                        alt="propmoth logo"
                        width={95}
                        height={32}
                    />
                    <Text color="#fff" component="p" m={0}>
                        which gives you direct access to their sales departments
                    </Text>
                </Group>
            ),
        },
        {
            title: "300+",
            content: (
                <Text color="#fff" component="p">
                    Genuine REN Agents registered and are ready to fight over
                    your business with their best offers.
                </Text>
            ),
        },
    ];

    return (
        <>
            <Stack sx={{ maxWidth: "450px" }} spacing={16} px={16} py={32}>
                <Text
                    component="h1"
                    sx={{
                        fontSize: "1.5em",
                        lineHeight: 1,
                        color: "#B4E562",
                    }}
                >
                    Transparency above all else
                </Text>
                <Text
                    component="h2"
                    color="#fff"
                    sx={{
                        fontSize: "3em",
                        margin: "0px",
                        lineHeight: 1,
                    }}
                >
                    Genuine properties for genuine people
                </Text>
                <Text component="p" color="#fff">
                    Be part of the growing force fighting back
                    against the predatory industry of property. Get
                    what you want and nothing else. Never be afraid
                    of fake listings ever again.
                </Text>
            </Stack>
            <Grid>
                {stats.map((s) => (
                    <Grid.Col md={4}>
                        <Stack spacing={8} align="flex-start">
                            <Box
                                sx={{
                                    borderLeft: "1px solid #FFF",
                                }}
                                px={16}
                            >
                                <Text
                                    component="h2"
                                    sx={{ margin: 0 }}
                                    color="#fff"
                                >
                                    {s.title}
                                </Text>
                            </Box>
                            <Box px={16}>{s.content}</Box>
                        </Stack>
                    </Grid.Col>
                ))}
            </Grid>

        </>
    );
};

export default TransparencyAboveAllElse;