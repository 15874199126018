import React, { useEffect, useMemo, useState } from "react";
import {
    Box,
    Modal,
    Text,
    Button,
    ActionIcon,
    Textarea,
    Grid,
    Group,
    Select,
    Stack,
} from "@mantine/core";
import { useGetConfigs } from "api/configAPI";
import { CONFIG_KEYS } from "helpers/constants";
import { useForm } from "@mantine/form";
import { selectFilter } from "helpers/utility";
import { useUserAPI } from "api/userAPI";
import { useProfileController } from "hooks/useProfile";


export const AgentDetailModal: React.FC<any> = ({ opened, onClose, onSkip }) => {
    const {
        profileData,
    } = useProfileController();
    const { useAgentUpdateLocality } = useUserAPI()
    const updateLocality = useAgentUpdateLocality()
    const [states, setStates] = useState([]);
    const form = useForm({
        initialValues: {
            state: "",
            area: ""
        },
        validate: (values) => ({
            state: values.state.length === 0 ? "State is required" : null,
        })
    })
    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);

    useEffect(() => {
        let statesData = statesAndAreas
            .map((s: any) => ({
                value: s.state,
                label: s.state,
            }))
            .filter((e: any) => !!e);

        setStates(statesData);
    }, [configData]);

    const areas = useMemo(() => {
        if (!!form.values.state) {
            const stateObj = statesAndAreas.find(
                (s: any) => s.state === form.values.state
            );
            // console.log(stateObj);
            if (!!stateObj) {
                return stateObj.areas
                    .map((a: any) => ({
                        value: a,
                        label: a,
                    }))
                    .filter((e: any) => !!e);
            }
            return [];
        }

        return [
            {
                value: "",
                label: "Please select a state first",
            },
        ];
    }, [form.values]);

    const handleClose = async () => {
        const { hasErrors } = form.validate()
        if (hasErrors) {
            return
        }

        await updateLocality.mutateAsync({
            id: profileData.id,
            localityState: form.values.state,
            localityArea: form.values.area,
        })

        onClose()
    }

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            closeOnClickOutside={false}
            title="Let us know where you operate!"
            centered
        >
            <Stack>
                <Select
                    label="State"
                    placeholder="Type to search..."
                    data={states}
                    searchable
                    nothingFound="No options"
                    filter={selectFilter}
                    {...form.getInputProps(
                        "state"
                    )}
                    onChange={(e) => {
                        form.setFieldValue(
                            "state",
                            e || ""
                        );
                        form.setFieldValue(
                            "area",
                            ""
                        );
                    }}
                    maxDropdownHeight={80}
                />
                <Select
                    label="Area"
                    placeholder={!form.values.state ? "Select state first" : "Type to search..."}
                    data={
                        !form.values.state
                            ? []
                            : areas
                    }
                    disabled={
                        !form.values.state
                    }
                    searchable
                    nothingFound="No options"
                    filter={selectFilter}
                    {...form.getInputProps(
                        "area"
                    )}
                    maxDropdownHeight={80}
                />
                <Stack spacing={4}>
                    <Button onClick={() => handleClose()}>Submit</Button>
                    <Button onClick={() => onSkip()} variant="subtle">Skip for now</Button>
                </Stack>
            </Stack>
        </Modal>
    );
};
