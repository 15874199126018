import { Badge, Box, Button, Card, Center, Grid, Group, Image, Indicator, Loader, Stack, Switch, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Check, X } from '@phosphor-icons/react';
import { IconCross } from '@tabler/icons-react';
import { useSubscribePlan } from 'api/tokenAPI';
import { useInsightsAPI } from 'api/useInsightsAPI';
import { useUserAPI } from 'api/userAPI';
import { SubscriptionBadge } from 'components/Badges';
import ConfirmCancelSubModal from 'components/Modals/ConfirmCancelSubModal';
import ConfirmeCancelSubModal from 'components/Modals/ConfirmCancelSubModal';
import { PAYMENT_TYPE, basicPackage, proPackage } from 'helpers/constants';
import { useProfileController } from 'hooks/useProfile';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const PackageSelect = () => {
    const subscribePlan = useSubscribePlan()
    const { currentActiveSubscription, isPro, isBasic, isUnsubscribed, refetchProfile } =
        useProfileController();
    const { useCancelSubscription } = useUserAPI()
    const cancelSubcription = useCancelSubscription()
    const [cancelSubOpen, setCancelSubOpen] = useState(false)
    const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.MONTHLY)
    const [isLoading, setIsLoading] = useState<boolean | string>(false)
    const { useGetUserInsights } = useInsightsAPI()

    const {
        data: insightsData,
        isLoading: insightsIsLoading,
        error: insightsError,
    } = useGetUserInsights();

    const handleSubscribe = async (priceId?: string, couponId?: string, oneOff?: boolean) => {
        if (!!priceId) {
            setIsLoading(priceId)
            const redirect = await subscribePlan.mutateAsync({
                priceId,
                coupon: couponId ?? null,
                oneOff
            })

            setIsLoading(false)
            if (!!redirect.url) {
                window.location.href = redirect.url;
            } else {
                notifications.show({
                    title: "Oops, something went wrong",
                    message:
                        "Please try again, if this issue persists please contact our customer service for help",
                });
            }
        }
    }

    const CheckOrCross = ({ status }: { status: boolean }) => {
        return !!status ? <Image src="/green-check.png" width={24} height={24} /> : <Image src="/red-cross.png" width={24} height={24} />
    }

    const VerticalCard = ({ data, paymentType, isLoading }: any) => {
        return (
            <>
                <Box
                    sx={{
                        width: '240px',
                        position: 'relative',
                        paddingTop: 120,
                    }}
                >
                    <Group
                        position='center'
                        sx={{
                            background: "#fff",
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            // width: '100%',
                            padding: 32,
                            borderRadius: '50%',
                            borderTopRightRadius: '50%',
                            width: '240px'
                        }}
                    >
                        {/* <Indicator
                            inline
                            label={<Text fw={700} fz={16}>{`RM${data.annualSavings}/month!`}</Text>}
                            size={32}
                            disabled={paymentType === PAYMENT_TYPE.MONTHLY}
                        >
                        </Indicator> */}
                        <Box
                            sx={{ background: data.bg, borderRadius: '50%', height: '176px', width: '176px' }}
                        >
                            <Center sx={{ height: '100%', width: '100%', color: "#fff" }}>
                                <Stack spacing={4}>
                                    <Text fw={700} fz={24}>Moth<Text component='span' lh={1} fw={500}>{data.tag}</Text></Text>
                                    <Stack spacing={2}>
                                        <Text lh={1} fz={24} align='right'>RM<Text component='span' lh={1} fw={700} fz={32}>{paymentType === PAYMENT_TYPE.MONTHLY ? data.price : data.priceAnnually}</Text></Text>
                                        <Text lh={1} fz='xs' align='right'>{paymentType === PAYMENT_TYPE.MONTHLY ? "/month" : "/year"}</Text>
                                    </Stack>
                                </Stack>
                            </Center>
                        </Box>
                    </Group>
                    <Stack
                        sx={{
                            backgroundColor: "#fff",
                            padding: 16,
                            paddingTop: paymentType === PAYMENT_TYPE.ANUALLY ? 112 : 120,
                        }}
                        spacing={16}
                    >
                        {paymentType === PAYMENT_TYPE.ANUALLY && (
                            <Stack p={8} spacing={4}>
                                <Text align='center' color="red" fz={16}>{`Save RM${data.annualSavings}`}</Text>
                                <Text align='center' color="red" fw={700} fz={16}>{`RM${data.annualPerMonth}/month!`}</Text>
                            </Stack>
                        )}
                        <Stack spacing={8}>
                            <Group spacing={8}>
                                <CheckOrCross status={data.functionList.agentProfile} />
                                <Text fz={16}>Agent Profile</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={data.functionList.localityInsights} />
                                <Text fz={16}>Locality insights</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={true} />
                                <Text fz={16}>Up to {data.functionList.listings} listings!</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={data.functionList.whatsappNotifications} />
                                <Text fz={16}>Whatsapp Notifications</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={data.functionList.leadBrowsing} />
                                <Text fz={16}>Lead Browsing</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={data.functionList.advancedLeadFilter} />
                                <Text fz={16}>Advanced Lead Filters</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={data.functionList.cobrokeFeatures} />
                                <Text fz={16}>Co-Agency Features</Text>
                            </Group>
                        </Stack>
                        <Button
                            onClick={
                                () => handleSubscribe(
                                    paymentType === PAYMENT_TYPE.ANUALLY ? data.annualPriceId : data.priceId,
                                    paymentType === PAYMENT_TYPE.ANUALLY ? data.annualCouponId : process.env.REACT_APP_COUPON_ID,
                                )
                            }
                        >
                            {isLoading === data.priceId ? (
                                <Loader color='white' size={'sm'} />
                            ) : (
                                <>Subscribe Now</>
                            )}
                        </Button>
                    </Stack>
                </Box>
            </>
        )
    }

    const PromoVerticalCard = ({ data, isLoading }: any) => {
        return (
            <Group>
                <Box
                    sx={{
                        width: '240px',
                        position: 'relative',
                        paddingTop: 120,
                    }}
                >
                    <Group
                        position='center'
                        sx={{
                            background: "#fff",
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            // width: '100%',
                            padding: 32,
                            borderRadius: '50%',
                            borderTopRightRadius: '50%',
                            width: '240px'
                        }}
                    >
                        {/* <Indicator
                            inline
                            label={<Text fw={700} fz={16}>{`RM${data.annualSavings}/month!`}</Text>}
                            size={32}
                            disabled={paymentType === PAYMENT_TYPE.MONTHLY}
                        >
                        </Indicator> */}
                        <Box
                            sx={{ background: "#1F8150", borderRadius: '50%', height: '176px', width: '176px' }}
                        >
                            <Center sx={{ height: '100%', width: '100%', color: "#fff" }}>
                                <Stack spacing={4}>
                                    <Text align='center' fw={700} fz={24}>Moth</Text>
                                    <Text align='center' lh={1} fz={24} fw={700}>PIONEER</Text>
                                    <Stack spacing={2}>
                                        <Text lh={1} fz={24} align='right'>RM<Text component='span' lh={1} fw={700} fz={32}>300</Text></Text>
                                        <Text lh={1} fz='xs' align='right'>Anually for life!*</Text>
                                    </Stack>
                                </Stack>
                            </Center>
                        </Box>
                    </Group>
                    <Stack
                        sx={{
                            backgroundColor: "#fff",
                            padding: 16,
                            paddingTop: 112,
                        }}
                        spacing={16}
                    >
                        <Stack p={8} spacing={4}>
                            <Text align='center' color="red" fz={16}>{`Save RM 700 a year!`}</Text>
                            <Text align='center' color="red" fw={700} fz={16}>{`RM 25/month!`}</Text>
                        </Stack>
                        <Stack spacing={8}>
                            <Group spacing={8}>
                                <CheckOrCross status={true} />
                                <Text fz={16}>Agent Profile</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={true} />
                                <Text fz={16}>Locality insights</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={true} />
                                <Text fz={16}>Up to {100} listings!</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={true} />
                                <Text fz={16}>Whatsapp Notifications</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={true} />
                                <Text fz={16}>Lead Browsing</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={true} />
                                <Text fz={16}>Advanced Lead Filters</Text>
                            </Group>
                            <Group spacing={8}>
                                <CheckOrCross status={true} />
                                <Text fz={16}>Co-Agency Features</Text>
                            </Group>
                        </Stack>
                        <Button
                            onClick={
                                () => handleSubscribe(process.env.REACT_APP_PRO_ANNUAL_PRICE_ID, process.env.REACT_APP_PROMO_PIONEER_COUPON, true)
                            }
                        >
                            {isLoading === process.env.REACT_APP_PRO_ANNUAL_PRICE_ID ? (
                                <Loader color='white' size={'sm'} />
                            ) : (
                                <>Subscribe Now</>
                            )}
                        </Button>
                    </Stack>
                </Box>
                <Card sx={{
                    border: "3px solid #417256",
                    borderRadius: 8,
                    width: '240px',
                    padding: 16
                }}>
                    <Stack>
                        <Text>Only Available For The First 1000 Subscribers!</Text>
                        <Text>There are currently {insightsData?.totalAgents ?? 0} that have claimed this limited promotion</Text>
                    </Stack>
                </Card>
            </Group>
        )
    }

    return (
        <Stack sx={{ alignItems: 'center' }}>
            <Group position='center' spacing={64} sx={{ width: '100%' }}>
                {!!isUnsubscribed && (
                    <Indicator
                        label={"Save 75%!"}
                        size={24}
                        color='red'
                        offset={-3}
                    >
                        <Button
                            variant={paymentType === PAYMENT_TYPE.PROMO ? "filled" : 'outline'}
                            onClick={() => setPaymentType(PAYMENT_TYPE.PROMO)}
                        >
                            Promo
                        </Button>
                    </Indicator>

                )}
                <Button
                    variant={paymentType === PAYMENT_TYPE.MONTHLY ? "filled" : 'outline'}
                    onClick={() => setPaymentType(PAYMENT_TYPE.MONTHLY)}
                >
                    Monthly
                </Button>
                <Indicator
                    label={"Save up to 20%!"}
                    size={24}
                    color='red'
                    offset={-3}
                >
                    <Button
                        variant={paymentType === PAYMENT_TYPE.ANUALLY ? "filled" : 'outline'}
                        onClick={() => setPaymentType(PAYMENT_TYPE.ANUALLY)}
                    >
                        <Stack>
                            <Text>Anually</Text>
                        </Stack>
                    </Button>
                </Indicator>
            </Group>
            <Group position='center' sx={{ flex: 1 }}>
                {paymentType === PAYMENT_TYPE.PROMO ? (
                    <>
                        {!!isUnsubscribed && (
                            <PromoVerticalCard paymentType={paymentType} isLoading={isLoading} />
                        )}
                    </>
                ) : (
                    <>
                        {!!isUnsubscribed && (
                            <VerticalCard data={basicPackage} paymentType={paymentType} isLoading={isLoading} />
                        )}
                        {(!!isBasic || !!isUnsubscribed) && (
                            <VerticalCard data={proPackage} paymentType={paymentType} isLoading={isLoading} />
                        )}
                    </>
                )}
                {/* <>
                    {!!isUnsubscribed && (
                        <VerticalCard data={basicPackage} paymentType={paymentType} isLoading={isLoading} />
                    )}
                    {(!!isBasic || !!isUnsubscribed) && (
                        <VerticalCard data={proPackage} paymentType={paymentType} isLoading={isLoading} />
                    )}
                </> */}
            </Group>
        </Stack>
    );
};

export default PackageSelect;