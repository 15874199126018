// @ts-nocheck
import Konva from "konva";
import { dataUrlToFile } from "./utility";

async function createImage(imgSrc: string) {
    return new Promise((resolve, reject) => {
        const newImg = new Image();

        newImg.onload = () => {
            resolve(newImg);
        };

        newImg.onerror = (error) => {
            reject(error);
        };

        newImg.src = imgSrc;
    });
}

export const handleWatermarkImage = async (obj: any, user: any) => {
    try {
        const {
            base64,
            name,
            size: { width, height },
        } = obj;

        

        let markHeight;
        let markWidth = width / 2;
        let x;
        let y;
        let logoHeight;
        let logoWidth;
        let logoX;
        let logoY;
        let nameTextX;
        let nameTextY;
        let phoneTextX;
        let phoneTextY;
        let fontSize;
        let paddingSize;

        const opacity = 0.9;

        markHeight = (height / 100) * 15;

        x = width - markWidth;
        y = height / 2 - markHeight / 2;

        logoHeight = (markHeight / 100) * 80;
        logoWidth = (markHeight / 100) * 80;

        logoX = x + 8;
        logoY = height / 2 - logoWidth / 2;

        fontSize = (markWidth / 100) * 5;

        nameTextX = logoX + logoWidth + 8;
        nameTextY = y + markHeight / 2 - fontSize;

        phoneTextX = nameTextX;
        phoneTextY = nameTextY + fontSize + 4;
        // if (height < 1024) {
        // } else {
        //     markHeight = 100;

        //     x = width - markWidth;
        //     y = height / 2 - markHeight / 2;

        //     logoHeight = markHeight - 30;
        //     logoWidth = markHeight - 30;

        //     logoX = x + 16;
        //     logoY = height / 2 - logoWidth / 2;

        //     fontSize = 24;

        //     nameTextX = logoX + logoWidth + 16;
        //     nameTextY = y + 24;

        //     phoneTextX = nameTextX;
        //     phoneTextY = nameTextY + 24 + 4;
        // }
        // console.log({
        //     opacity,
        //     markHeight,
        //     markWidth,
        //     x,
        //     y,
        //     logoHeight,
        //     logoWidth,
        //     logoX,
        //     logoY,
        //     nameTextX,
        //     nameTextY,
        //     phoneTextX,
        //     phoneTextY,
        //     width,
        //     height,
        // });

        const stage = new Konva.Stage({
            container: "containerId",
            width,
            height,
        });

        // add canvas element
        var layer = new Konva.Layer();
        stage.add(layer);

        let baseImage = new Konva.Image({
            width,
            height,
        });
        layer.add(baseImage);

        const baseImage1 = await createImage(base64);
        console.log({ baseImage1 });
        baseImage.image(baseImage1);
        layer.draw();

        var rect1 = new Konva.Rect({
            x: x,
            y: y,
            width: markWidth,
            height: markHeight,
            fill: "rgb(0,0,0,0.7)",
            // opacity: opacity,
        });
        layer.add(rect1);

        var nameText = new Konva.Text({
            x: nameTextX,
            y: nameTextY,
            text: user.fullname,
            fontSize: fontSize,
            fill: "rgb(255,255,255)",
        });
        layer.add(nameText);

        var phoneText = new Konva.Text({
            x: phoneTextX,
            y: phoneTextY,
            text: user.username,
            fontSize: fontSize,
            fill: "rgb(255,255,255)",
        });
        layer.add(phoneText);

        layer.draw();

        let logoImage = new Konva.Image({
            x: logoX,
            y: logoY,
            width: logoWidth,
            height: logoHeight,
        });
        layer.add(logoImage);
        // logoImage.moveUp()

        const logo1 = await createImage("/propmoth-white-circle.png");
        logoImage.image(logo1);
        layer.draw();

        const base64Return = stage.toDataURL({ pixelRatio: 1 });
        const watermarkedFile = await dataUrlToFile(
            base64Return,
            `watermarked-${new Date().getTime()}`
        );


        return {
            base64: base64Return,
            file: watermarkedFile,
        };
    } catch (e) {
        console.log("watermark err", e);
    }
};

export const getMeta = async (url: string) => {
    const img = new Image();
    img.src = url;
    await img.decode();
    return img;
};
