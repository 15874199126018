import { Box, Overlay, Text } from '@mantine/core';
import React, { useState } from 'react';

const ClickBox = ({ text, textProps, coverText, activated, onReveal }: any) => {
    const [visible, setVisible] = useState(true);

    return (
        <Box
            sx={{ position: 'relative', cursor: 'pointer', borderRadius: 8, width: '100%' }}
            onClick={(e) => {
                e.stopPropagation()
                setVisible(!visible)
                onReveal && !!activated && onReveal()
            }}
        >
            {visible && (
                <Overlay color="#000" opacity={0.8} blur={1} center sx={{ borderRadius: 8 }}>
                    <Text size="xs" color="whitesmoke" fw={700}>{coverText ?? 'View'}</Text>
                </Overlay>
            )}

            <Text fz='sm' {...textProps}>
                {!!activated ? text : 'for subscribers only!'}
            </Text>
        </Box>
    );
};

export default ClickBox;