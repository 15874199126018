import { Button, Modal, Select, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useUserAPI } from 'api/userAPI';
import { selectFilter } from 'helpers/utility';
import { useConfigData } from 'hooks/useConfigData';
import React, { useEffect, useState } from 'react';

const MissingLocality = () => {
    const [opened, { open, close }] = useDisclosure(false);
    const [states, setStates] = useState([])
    const { useCreateFeedback } = useUserAPI()
    const createFeedback = useCreateFeedback()
    const { statesAndAreas } = useConfigData()

    const form = useForm({
        initialValues: {
            locality: "",
            state: ""
        },
        validate: {
            locality: (value) => {
                if (!value) {
                    return "Please fill in this field"
                }
                return null
            },
            state: (value) => {
                if (!value) {
                    return "Please fill in this field"
                }
                return null
            },
        }
    })

    useEffect(() => {
        let statesData = statesAndAreas
            .map((s: any) => ({
                value: s.state,
                label: s.state,
            }))
            .filter((e: any) => !!e);

        setStates(statesData);
    }, [statesAndAreas]);

    const onSubmit = (e: any) => {
        e.preventDefault()

        const { hasErrors } = form.validate()
        if (!hasErrors) {

            createFeedback.mutate({
                content: `missing locality: ${form.values.locality} in ${form.values.state}`
            }, {
                onSuccess: () => {
                    form.reset()
                    close()
                    notifications.show({
                        title: "Thank You!",
                        message: "Your feedback has been submitted",
                    });
                },
                onError: (e) => {
                    console.log(e)
                    // setFeedbackFail(true)
                },
            })
        }

    }

    return (
        <>
            <Button color='orange' onClick={() => open()}>Report Missing Locality</Button>
            <Modal centered opened={opened} onClose={close} title="Report Missing Locality">
                <form onSubmit={(e) => onSubmit(e)}>
                    <Stack>
                        <TextInput
                            label="Locality"
                            {...form.getInputProps("locality")}
                        />
                        <Select
                            sx={{
                                flex: 1,
                            }}
                            label="State"
                            placeholder="Pick one"
                            data={states}
                            searchable
                            nothingFound="No options"
                            filter={selectFilter}
                            {...form.getInputProps("state")}
                        />
                        <Button
                            disabled={createFeedback.isLoading}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </Stack>
                </form>
            </Modal>
        </>
    );
};

export default MissingLocality;