export const promoTnc = `
**Find Your Home with Propmoth! Giveaway Contest TERMS & CONDITIONS**

The “Find Your Home with Propmoth! Giveaway Contest’’ is a contest organised by Propmoth Capital Sdn Bhd. (“the Organiser”).

• For the purposes of these Terms and Conditions, "The Organiser" refers to Propmoth Capital Sdn Bhd whose Instagram handle is @propmoth. The "Prize" refers to 2. Prizes i,ii,iii,iv within the context of this document.

This contest is in no way sponsored, endorsed, administered by, or associated with Facebook, and all participants release Facebook from any liability in connection with this contest. By entering, participants hereby agree to look solely to Organiser with any comments or issues. It is further understood that participant is providing personal information to the Organiser, and not to Facebook. To participate in this Contest, each participant will be subject to the Organiser's Terms & Conditions and Privacy Policy where applicable. However, your use of the Facebook Platform may also subject you to the Facebook Terms and Conditions (http://www.facebook.com/terms.php) and Privacy Policy (http://www.facebook.com/privacy/explanation.php). Please read these terms before participating. If you do not accept these Terms and Conditions, please do not enter the Contest.

1. The Contest begins on 3 September 2023 at 12:00:00PM Malaysian Time (GMT +8) and ends on 3 September 2023 at 11:59:00PM (GMT +8) ("Contest Period").

2. ELIGIBILITY:

2.1 Participation in this Contest is only open to citizens of Malaysia with valid Malaysian NRIC or permanent legal residents of Malaysia, who are aged 18 years and above, as of the beginning of the Contest.

2.2 Employees of the Organiser, and its parent company, affiliates, subsidiaries, officers, directors, contractors, representatives, agents, and advertising/PR agencies of Organiser, and each of their respective immediate families and household members (collectively the "Contest Entities") are not eligible to enter this Contest.

**HOW TO PARTICIPATE**

Step 1: Click on the promotional AD or visit propmoth.com.

Step 2: Click “Start Now” Or “Sign Up” as a “Requestor” and create an account with Propmoth.

Step 3: Create your “Property Request”.

Step 4: Wait for agents to “Offer” you property and choose one to “Accept”.
Step 5: Once accepted, the agent will have to confirm that a transaction has occurred and your entry will be in! (transaction = Participant/Requester has made a monetary transaction with regards to purchasing/renting property offered by the Agent) 

*Entry is only confirmed once the transaction has been verified and is recorded on an agent’s “dashboard” as Closed Requests.                                         

1. Participants are only allowed to submit one entry. Each Participant will only win ONCE throughout the Contest Period.

2. Incomplete registrations/entries will be disqualified from the Contest.

3. Entries that do not adhere to the rules will be automatically disqualified.

**WINNERS & PRIZES**

1. How to Win:

i. Top three (3) participants chosen at random from the pool of “Closed requests” 

ii. The random winners chosen via a random number generator is final. No further correspondence or appeal will be entertained. By participating in this Contest, the participants agree not to challenge and/ or object to any decisions made by the Organizer in connection with the Contest.
iii. The Organizer shall make every attempt possible to contact the winning contestant(s) and if the contestant(s) do not accept or is unresponsive within 1 week, another winner will be selected via a random number generator.

iv. Every eligible entry will be entered into the random number generator provided by google with the Minimum set at 1 and the Maximum set at the total amount of eligible entries. 

2. Prizes:

i. **Grand Prize x 1 : Selected “Dream Property” of up to RM500,000**

ii. **2nd Prize x 1 : Dyson V8 Slim Fluffy+**

iii. **3rd Prize x 1 : iPhone 14 Pro**
iv. **4th Prize x 1 : RM10,000 Cash**

3. The Organizer reserves the rights to feature winners’ photos on all Propmoth websites and social media pages.

4. The winners’ announcement will be made on the Propmoth Facebook page.

5. The prize winners will be messaged by Propmoth’s Facebook page through messenger inbox.

6. All prizes must be claimed within one (1) week after the date of notification of winnings. All unclaimed prizes will be forfeited by the Organiser one (1) week after the date of notification of winnings.

7. The participant is required to produce a proof of identity during or prior to prize redemption for purposes of verification.

8. In the event of the Organiser is requested to post/courier a prize to a winner, the Organiser will not be held liable of non-receipt of prize or damages caused during the delivery process. No replacement and/or exchange of prize will be entertained.

9. In the event that the Prize is posted/couriered to the Winner, it is compulsory for the Winner to inform the Organiser on the receipt of the Prize. Winner should attach a photo taken with the prize for advertising, marketing and communication purposes.

10. The Organiser reserves the absolute right to substitute any prize with that of similar value at any time without prior notice. All prizes are not transferable, refundable or exchangeable in any other form for whatever reason. The value of the prize is correct at the time of printing. All prizes are given on an “as is” basis.

11. Prizes are not exchangeable for cash, in part or in full. The Organiser reserves the right to substitute the prize with that of a similar value at any time.

**USE OF PERSONAL DATA**

All Participants to the Contest shall be deemed to have given consent to the Organiser to disclose, share or collect their Personal Data to the Organiser’s business partner and associates. The Organiser shall always put it as a priority to secure the Personal Data of the Participants in relation to their participation in the Contest. The Participants also acknowledge that they have read, understood and accepted to all the terms and conditions as stipulated under the Organiser’s Privacy Policy.

**OWNERSHIP / USE RIGHTS**

1. The Participants hereby grant to the Organiser the right to use on any photos, information and/or any other material received by the Organiser from the Participants during the Contest (including but not limited to the Participants’ name, email addresses, contact numbers, photo and etc.) for advertising, marketing and communication purposes without compensation to the Participant, his or her successors or assigns, or any other entity.

2. The Organiser reserves all their exclusive right whether to reject, amend, vary or correct on any entries on which the Organiser deemed incorrect, incomplete, suspicious, invalid or where the Organiser has reasonable ground to believe it is against the law, public policy or involved fraud.

3. The Participants agrees and consent to comply with all the policy, rules and regulation as which may be prescribed by the Organiser from time to time and shall not knowingly or negligently damage or cause any form of interruption to the Contest and/or prevent others from entering the Contest, failing which the Organiser shall be allowed at their absolute discretion to disqualify or bar the Participant from participating in the Contest or any contest in the future as which may be launched or announced by the Organiser.

4. The Organiser and its respective parent companies, affiliates, subsidiaries, licensees, directors, officers, agents, independent contractors, advertising, promotion, and fulfilment agencies, and legal advisors are not responsible for and shall not be liable for:-

any disruption, network congestion, malicious virus attacks, unauthorized data hacking, data corruption and server hardware failure or otherwise; any technical errors, whether due to inaccessibility of internet network

4.1 any telephone, electronic, hardware or software program, network, internet, server or computer malfunctions, failures, interruptions, miscommunications or difficulties of any kind, whether human, mechanical or electrical, including, without limitation, the incorrect or inaccurate capture of entry information online;

4.2 any late, lost, delayed, misdirected, incomplete, illegible or unintelligible communication including but not limited to e-mails;

4.3 any failure, incomplete, lost, garbled, jumbled, interrupted, unavailable or delayed on the computer transmissions;

4.4 any condition caused by events beyond the control of the Organiser that may cause the Contest to be disrupted or corrupted;

4.5 any injuries, losses, or damages of any kind arising in connection with or as a result of the gift, or acceptance, possession, or use of the Prize, or from participation in the Contest;

4.6 any printing or typographical errors in any materials associated with the Contest.

5. The Organiser and its respective parent companies, subsidiaries, affiliates, licensees, directors, officers, employees, agents, independent contractors and advertising/promotion agencies make no warranties and representatives, whether expressly or impliedly, in fact or in law, relative to the use or enjoyment of the Prize, including but without limitation to their quality, merchantability or fitness for a particular purpose.

6. Winners will be required to sign and return a release of liability (if any), declaration of eligibility (if any), and where lawful, publicity consent agreement (if any), from the Organiser. By participating in the Contest, winners agree to grant the Organiser and their respective parent companies, subsidiaries, affiliates, licensees, directors, officers, agents, independent contractors and advertising/ promotion agencies the use of data collected through the Contest website, likeness, biographical data and statements for purposes, including, without limitation, advertising, trade, or promotion, in perpetuity, in any and all media now known or hereafter devised, without compensation, unless prohibited by law.

7. The Organiser reserves the right to end, terminate or postpone the Contest from time to time or even to vary, amend or extend the Contest Period at its own and absolute discretion.

8. All costs, fees and/or expenses incurred and/or to be incurred by the Winners in relation to the Contest and/or to claim the Prize(s), which shall include but not limited to the costs for transportation, postage/courier, personal costs and/or any other costs shall be at sole responsibility of the Winners.

**Intellectual Property**

Unless otherwise stated, the Organiser retains all proprietary rights to the intellectual property (including but not limited to trademarks and copyrights) used for this Contest and owns the copyright to all contents within.
`