import { Box, Overlay, Text } from '@mantine/core';
import React, { useState } from 'react';

const HoverBox = ({ text, textProps }: any) => {
    const [visible, setVisible] = useState(true);

    return (
        <Box
            sx={{ position: 'relative', cursor: 'pointer', borderRadius: 8 }}
            onMouseEnter={() => setVisible(false)}
            onMouseLeave={() => setVisible(true)}
        >
            {visible && (
                <Overlay color="#000" opacity={0.8} blur={1} center sx={{ borderRadius: 8 }}>
                    <Text size="xs" color="whitesmoke" fw={700}>View</Text>
                </Overlay>
            )}
            <Text fz='sm' {...textProps}>
                {text}
            </Text>
        </Box>
    );
};

export default HoverBox;