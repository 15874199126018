import { Button, Center, Container, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate()

    return (
        <Container
            size="1200px"
            py={32}
            px={16}
        >
            <Center sx={{ minHeight: "calc(100vh - 88px)" }}>
                <Stack>
                    <Stack spacing={4}>
                        <Text fz={70} fw={700} color="primaryGreen.7" lh={1} align="center">404</Text>
                        <Text fz={70} fw={700} color="primaryGreen.7" lh={1} align="center">NOT FOUND</Text>
                    </Stack>
                    <Text fz={30} align="center">Sorry this content could not be found</Text>
                    <Group position='center'>
                        <Button onClick={() => navigate("/")}>Go back to main page</Button>
                    </Group>
                </Stack>
            </Center>
        </Container>
    );
};

export default NotFound;