import {
    Badge,
    Box,
    Button,
    Card,
    Center,
    Chip,
    Container,
    Divider,
    Flex,
    Grid,
    Group,
    LoadingOverlay,
    Modal,
    Pagination,
    ScrollArea,
    Stack,
    Sx,
    Tabs,
    Text,
    TextInput,
    Loader
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useListingAPI } from "api/useListingAPI";
import FeedbackBanner from "components/Profile/FeedbackBanner";
import OfferCard from "components/PropRequest/OfferCard";
import PropRequestCard from "components/PropRequest/PropRequestCard";
import {
    AGENT_REQUEST_STATUS,
    PROPERTY_REQUEST_COLOR_SETTINGS,
    PROPERTY_REQUEST_TYPE,
    USER_TYPE,
} from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bath, Bed, BorderRadius, Edit, Home2, Search } from "tabler-icons-react";
import ListingCard from "./PropRequest/ListingsCard";
import useApiPagination from "hooks/useApiPagination";
import { APIHookResponse, ApiResponse } from "types/systemTypes";
import { useDebouncedValue } from "@mantine/hooks";
import { useAgentRequestAPI } from "api/useAgentRequestApi";
import AgentRequestCard from "./AgentRequestCard";
import { notifications } from "@mantine/notifications";

export default function AgentRequestsList(props: any) {
    const { pagination } = useApiPagination()
    // const [debouncedKeyword] = useDebouncedValue(keyword, 200);
    const { user } = useAuth(Permission.USER_ONLY);
    const { useGetMyAgentRequests, useUpdateAgentRequest } = useAgentRequestAPI()
    const updateAgentRequest = useUpdateAgentRequest()
    const {
        data,
        error,
        isLoading,
        refetch
    } = useGetMyAgentRequests({
        id: user.id.toString(),
        pagination: pagination.paginationObj,
        // filters: {
        //     title: {
        //         $containsi: debouncedKeyword
        //     }
        // }
    }) as APIHookResponse
    const navigate = useNavigate();
    
    const [openDelete, setOpenDelete] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    // const [keyword, setKeyword] = useState("")

    useEffect(() => {
        if (!!data?.meta) {
            pagination.setTotalPages(data?.meta.pagination.pageCount)
            pagination.setActivePage(data?.meta.pagination.page)
        }
    }, [data])

    useEffect(() => {
        setOpenDelete(!!deleteId)
    }, [deleteId]);


    const handleDelete = () => {
        updateAgentRequest.mutate({
            status: AGENT_REQUEST_STATUS.DELETED,
            id: deleteId
        }, {
            onSuccess: () => {
                notifications.show({
                    title: "Success!",
                    message: "Successfully deleted agent request",
                    autoClose: 2000,
                });
                setDeleteId('')
                refetch()
                props?.callback && props.callback()
            }
        })
    }

    return (
        <>
            <Modal
                centered
                opened={openDelete}
                onClose={() => setDeleteId("")}
                title="Are you sure?"
            >
                <Stack p={8}>
                    <Group sx={{ width: "100%" }}>
                        <Button
                            onClick={() => setDeleteId("")}
                            sx={{ flexGrow: 1 }}
                            variant="outline"
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => handleDelete()}
                            sx={{ flexGrow: 1 }}
                            color="red"
                        >
                            Yes
                        </Button>
                    </Group>
                </Stack>
            </Modal>
            {/* <LoadingOverlay visible={isLoading} overlayBlur={2} /> */}
            <Stack spacing={24}>
                {!!data && data?.data?.length > 0 && (
                    <Flex sx={{ width: '100%', justifyContent: 'flex-end' }} pt={24}>
                        <Button onClick={() => navigate("/agent-requests/create")}>Sell Property</Button>
                    </Flex>
                )}
                {/* <TextInput
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    sx={{ width: '100%' }}
                    rightSection={<Search color="#aaa" />}
                    placeholder="Search agent request title"
                /> */}
                {!!isLoading && (
                    <Center p={32}> <Loader /> </Center>
                )}
                <Grid>
                    {!!data &&
                        data?.data?.length > 0 &&
                        data?.data?.map((agentRequest: any, i: number) => (
                            <Grid.Col md={6}>
                                <Card
                                    sx={{
                                        height: "100%",
                                        border: "1px solid #BFC8C3",
                                    }}
                                    shadow="sm"
                                    key={`agent-request-${i}`}
                                >
                                    <Stack sx={{ height: '100%' }}>
                                        <AgentRequestCard data={agentRequest} />
                                        {user.userType === USER_TYPE.LISTER && (
                                            <Button onClick={() => navigate(`/agent-requests/view/${agentRequest.id}`)}>
                                                {`View Replies (${agentRequest.replies.length})`}
                                            </Button>
                                        )}
                                        <Group grow>
                                            <Button onClick={() => navigate(`/agent-requests/edit/${agentRequest.id}`)}>Edit</Button>
                                            <Button onClick={() => setDeleteId(agentRequest.id)}>Delete</Button>
                                        </Group>
                                    </Stack>
                                </Card>
                            </Grid.Col>
                        ))}
                </Grid>
                {!!data && data?.data?.length > 0 && (
                    <Group sx={{ width: '100%', justifyContent: 'center' }}>
                        <Pagination total={pagination.totalPages} onChange={pagination.setActivePage} value={pagination.activePage} />
                    </Group>
                )}
                {!!data && data?.data?.length === 0 && (
                    <Center>
                        <Stack align="center" spacing={8} py={32}>
                            <Home2 size={32} />
                            <Text>You have no agent requests currently</Text>
                            <Button onClick={() => navigate("/agent-requests/create")}>Sell Property</Button>
                        </Stack>
                    </Center>
                )}
            </Stack>
        </>
    );
}
