import { Carousel } from '@mantine/carousel';
import { BackgroundImage, Box, Button, Card, Container, Group, Image, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useInsightsAPI } from 'api/useInsightsAPI';
import CustomHeader from 'components/Header/Header';
import React, { useMemo } from 'react';
import { ArrowNarrowRight } from 'tabler-icons-react';

const AgentPromoHero = ({ handleSchedule }: any) => {
    const sidePadding = 24;
    const theme = useMantineTheme();
    const minimumTablet = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

    const { useGetRequestInsights } = useInsightsAPI()
    const {
        data: requestInsightData,
        isLoading: requestInsightIsLoading,
        error: requestInsightError,
    } = useGetRequestInsights();

    const mobileSlides = [
        "/agent-promo-slider-1.png",
        "/agent-promo-slider-2.png",
        "/agent-promo-slider-3.png",
    ]


    const parsedTotalRequest = useMemo(() => {
        if (!requestInsightIsLoading && !!requestInsightData) {
            const dataString = `${requestInsightData.totalPropertyRequests}`
            let returnArr = [...dataString.split("")]

            while (returnArr.length < 5) {
                returnArr.unshift('0')
            }
            return returnArr

        }

        return [0, 0, 0, 0, 0]
    }, [requestInsightData, requestInsightIsLoading])

    if (minimumTablet) {
        return (
            <>
                <BackgroundImage
                    src="https://propmoth-images.s3.ap-southeast-1.amazonaws.com/cropped_hero_c3d5553ce2.png?updated_at=2023-12-13T15:20:17.495Z"
                    sx={{
                        minHeight: '100vh',
                        width: '100vw',
                        backgroundPosition: 'left-center',
                        paddingBottom: 50,
                        position: 'relative'
                    }}
                >
                    <Container
                        pl={sidePadding}
                        pt={24}
                    >
                        <Group pb={40}>
                            <Image
                                src={"/logo-white.svg"}
                                alt={"propmoth logo"}
                                width={148}
                                height={48}
                            />
                        </Group>
                        <Stack sx={{ maxWidth: '70%', height: '100%' }} spacing={36}>
                            <Stack spacing={16}>
                                <Text sx={{ margin: 0 }} component="h1" color="white" fz={48} fw={700}>We’ve Collected Leads For You</Text>
                                <Text sx={{ margin: 0 }} component="h2" color="white" fz={24} fw={500}>Access our leads today! We’ve gathered and verified them <br /> for you to acquire in our <Text fw={700} component='span'>pay per lead</Text> system.</Text>
                                <Text sx={{ margin: 0 }} component="h2" color="white" fz={24} fw={500}>Find one that you can close and reach out today! </Text>
                            </Stack>
                            <Stack spacing={4}>
                                <Group position='left'>
                                    <Button
                                        radius={'lg'}
                                        sx={{
                                            backgroundColor: 'rgba(33,33,33, 0.8)'
                                        }}
                                        onClick={() => handleSchedule()}
                                    >
                                        <Group spacing={4}>
                                            <Text>Claim Your Free Leads Now </Text>
                                            <ArrowNarrowRight />
                                        </Group>
                                    </Button>
                                </Group>
                                <Text fz={14} color="white">Book a consultation with us to create an account for <Text fw={700} component='span'>FREE!</Text></Text>
                            </Stack>
                            <Group position='left'>
                                <Stack spacing={4}>
                                    <Text align='center' fz={24} fw={600} color="white">Total Leads Generated </Text>
                                    <Group spacing={2} position='center'>
                                        {parsedTotalRequest.map((i, index) => (
                                            <Card py={4} px={8} sx={{ background: "#D9D9D9" }} key={`card-${index}`}>
                                                <Text fz={64} fw={700}>{i}</Text>
                                            </Card>
                                        ))}
                                    </Group>
                                </Stack>
                            </Group>
                        </Stack>
                    </Container>
                </BackgroundImage>
            </>
        );
    }

    return (
        <>
            <Box
                sx={{
                    zIndex: 100,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                }}
            >

                <Group position='center'>
                    <Image
                        src={"/logo-white.svg"}
                        alt={"propmoth logo"}
                        width={148}
                        height={48}
                    />
                </Group>
            </Box>
            <BackgroundImage
                src="/agent-promo-backdrop-img.png"
                sx={{
                    minHeight: '100vh',
                    width: '100vw',
                    paddingTop: 104,
                    paddingBottom: 50,
                    position: 'relative'
                }}
            >
                <Stack spacing={32}>
                    <Stack>
                        <Stack spacing={16}>
                            <Text align="center" sx={{ margin: 0 }} component="h1" color="white" fz={32} fw={700}>We’ve Collected <br /> Leads For You</Text>
                            <Text align="center" sx={{ margin: 0 }} component="h2" color="white" fz={16} fw={400}>Access our leads today! We’ve gathered and verified them for you to acquire in our <Text fw={700} component='span'>pay per lead</Text> system.</Text>
                            <Text align="center" sx={{ margin: 0 }} component="h2" color="white" fz={16} fw={400}>Find one that you can close and reach out today! </Text>
                        </Stack>
                    </Stack>
                    <Carousel
                        slideSize="70%"
                        // height={200}
                        slideGap="sm"
                        withControls={false}
                        loop
                    >
                        {mobileSlides.map((h, i) => (
                            <Carousel.Slide key={`carousel-${i}`}>
                                <Image src={h} fit="contain" />
                            </Carousel.Slide>
                        ))}
                    </Carousel>
                    <Stack spacing={8}>
                        <Group position="center">
                            <Button
                                radius={'lg'}
                                sx={{
                                    backgroundColor: 'rgba(33,33,33, 0.8)'
                                }}
                                onClick={() => { window.open("https://calendly.com/propmothadmin/30min") }}
                            >
                                <Group spacing={4}>
                                    <Text>Claim Your Free Leads Now </Text>
                                    <ArrowNarrowRight />
                                </Group>
                            </Button>
                        </Group>
                        <Text align="center" fz={14} color="white">Book a consultation with us to create an account for <Text fw={700} component='span'>FREE!</Text></Text>
                    </Stack>
                    <Stack spacing={4}>
                        <Text align='center' fz={24} fw={600} color="white">Total Leads Generated </Text>
                        <Group spacing={2} position='center'>
                            {parsedTotalRequest.map((i, index) => (
                                <Card py={4} px={8} sx={{ background: "#D9D9D9" }} key={`timer-${index}`}>
                                    <Text fz={64} fw={700}>{i}</Text>
                                </Card>
                            ))}
                        </Group>
                    </Stack>
                </Stack>

            </BackgroundImage>
        </>
    )
};

export default AgentPromoHero;