import React from "react";
import {
    Avatar,
    BackgroundImage,
    Box,
    Button,
    Card,
    Divider,
    Grid,
    Group,
    Stack,
    Text,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useGetLatestArticles } from "api/blogAPI";

export const LatestPosts: React.FC<any> = ({ ...props }) => {
    const navigate = useNavigate();
    const { data, isLoading, error } = useGetLatestArticles();

    return (
        <Card shadow="md" sx={{ border: "1px solid #ced4da" }}>
            <Stack spacing={16}>
                <Text component="h1" m={0} my={8}>
                    Latest Posts
                </Text>
                <Divider />
                {!!data && data?.length === 0 && (
                    <Text align="center">Coming Soon</Text>
                )}
                {!!data && data?.length > 0 && (
                    <>
                        {data.map((d: any, i: number) => (
                            <>
                                <Group
                                    onClick={() =>
                                        navigate(`/articles/${d.slug}`)
                                    }
                                    sx={{ cursor: "pointer" }}
                                >
                                    {!!d.banner && (
                                        <Avatar
                                            size="md"
                                            radius="50%"
                                            src={d.banner.url}
                                        />
                                    )}
                                    <Box sx={{ flex: 1, width: "100%" }}>
                                        <Text
                                            component="h2"
                                            my={0}
                                            fz={16}
                                            fw={700}
                                            sx={{ width: "100%" }}
                                        >
                                            {d.title}
                                        </Text>
                                    </Box>
                                </Group>
                                {i + 1 !== data.length && <Divider />}
                            </>
                            // <Card
                            //     shadow="md"
                            //     sx={{ cursor: "pointer" }}
                            //     onClick={() => navigate(`/articles/${d.slug}`)}
                            // >

                            //     <Grid align="center">
                            //     <Grid.Col span={3}></Grid.Col>
                            //     <Grid.Col span={9}></Grid.Col>
                            // </Grid>
                            // </Card>
                        ))}
                    </>
                )}
            </Stack>
        </Card>
    );
};
