import validator from "validator";

export const strictNumberInput = (evt: any) => {
    return (
        (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
        evt.preventDefault()
    );
};

export const validateEmail = (value: string) => {
    if (!value || value.length === 0) {
        return "Please fill in this field";
    }
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!regex.test(value)) {
        return "Please enter a valid email";
    }

    return null;
};

export const validateNotEmpty = (value: string) => {
    return !value || value.length === 0 ? "Please fill in this field" : null;
};

export const validatePassword = (value: string) => {
    const isEmpty = validateNotEmpty(value);

    if (!!isEmpty) {
        return isEmpty;
    }

    if (
        !validator.isStrongPassword(value, {
            minLength: 8,
            // minLowercase: 1,
            // minUppercase: 1,
            // minNumbers: 1,
        })
    ) {
        return "Your password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
    }

    return null;
};

export const validateConfirmPassword = (value: string, password: string) => {
    const isEmpty = validateNotEmpty(value);

    if (!!isEmpty) {
        return isEmpty;
    }

    if (value !== password) {
        return "Your passwords do not match";
    }

    return null;
};
