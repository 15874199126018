import { notifications } from "@mantine/notifications";
import { useAgentRequestAPI } from "api/useAgentRequestApi";
import { useCreateRequest } from "pages/PropRequestForm/hooks/useCreateRequest";
import { useMemo } from "react";

const usePostLoginFunctions = () => {
    const { createRequest, createRequestIsLoading } = useCreateRequest();
    const { useCreateAgentRequest } = useAgentRequestAPI();
    const createAgentRequestMutation = useCreateAgentRequest();

    const createPropRequest = async (user: any, data: any) => {
        try {
            console.log("createRequest", data);
            await createRequest(data.form, data.tags, user.id);
            sessionStorage.removeItem("pre_login_draft");
        } catch (e) {
            notifications.show({
                title: "Error creating property request",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    };

    const createAgentRequest = async (user: any, data: any) => {
        try {
            createAgentRequestMutation.mutate(
                {
                    ...data.form,
                    lister: user.id,
                },
                {
                    onSuccess: () => {
                        notifications.show({
                            title: "Success!",
                            message: "Successfully created agent request",
                            autoClose: 2000,
                        });
                        sessionStorage.removeItem("pre_login_draft");
                    },
                    onError: () => {
                        notifications.show({
                            title: "Error creating agent request",
                            message:
                                "If this issue persist, please contact our support",
                            autoClose: 2000,
                        });
                    },
                }
            );
        } catch (e) {
            notifications.show({
                title: "Error creating property request",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    };

    return {
        createPropRequest,
        createAgentRequest,
        createRequestIsLoading,
        createAgentRequestIsLoading: createAgentRequestMutation.isLoading,
    };
};

export default usePostLoginFunctions;
