import { useGetConfigs } from "api/configAPI";
import { CONFIG_KEYS } from "helpers/constants";
import { mergeArraysAndRemoveDuplicates } from "helpers/utility";
import { useMemo } from "react";

export const useConfigData = () => {
    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    const tokenPackages = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.TOKEN_PACKAGES
            ).value;
        }

        return [];
    }, [configData]);

    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);

    const propertyTypes = useMemo(() => {
        if (!!configData && !configIsLoading) {
            const data = configData.find(
                (p: any) => p.key === CONFIG_KEYS.PROPERTY_TYPES
            ).value;

            let proptypeArr = [] as [][];
            data.forEach((d: any) => proptypeArr.push(d.values));
            return mergeArraysAndRemoveDuplicates(proptypeArr);
            
            // if (state.transactionType.length === 0) {
            // }

            // return (
            //     data.find((d: any) => state.transactionType.includes(d.type))
            //         ?.values ?? []
            // );
        }

        return [];
    }, [configData]);

    return {
        tokenPackages,
        statesAndAreas,
        propertyTypes,
    };
};
