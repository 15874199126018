import {
    ActionIcon,
    AspectRatio,
    Badge,
    Box,
    Button,
    Card,
    Center,
    Container,
    Group,
    Image,
    Loader,
    Modal,
    Stack,
    Tabs,
    Text,
    Tooltip,
} from "@mantine/core";
import { PROPERTY_REQUEST_COLOR_SETTINGS } from "helpers/constants";
import {
    Bath,
    Bed,
    Dots,
    DotsVertical,
    Edit,
    TrashX,
} from "tabler-icons-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { notifications } from "@mantine/notifications";
import { useListingAPI } from "api/useListingAPI";
import { censorString, formatSeriousness } from "helpers/utility";
import { Permission, useAuth } from "hooks/useAuth";
import { useCreateChat, useGetChat } from "api/chatAPI";
import { TokensModal } from "components/Modals/TokensModal";
import ConfirmeStartChatModal from "components/Modals/ConfirmStartChatModal";
import HoverBox from "components/HoverBox";
import ClickBox from "components/HoverBox/ClickBox";

export default function PropRequestCard({ ...props }) {
    const { useDeletePropRequest, useUpdatePropRequest } = useListingAPI()
    const updatePropRequest = useUpdatePropRequest();

    const navigate = useNavigate();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { user } = useAuth(Permission.ANYONE);
    const createChatMutation = useCreateChat();
    const data = props.data as any;

    const [deleteLoading, setDeleteLoading] = useState(false);
    const deletePropRequestMutation = useDeletePropRequest();
    const [purchaseTokensPopup, setPurchaseTokensPopup] = useState(false);
    const [startChatIsOpen, setStartChatIsOpen] = useState(false);

    const transactionType = props.data.transactionType as
        | "BUY"
        | "RENT"
        | "INVEST";

    const tagArr =
        !!data.tags && data.tags.length > 0
            ? data.tags.map((t: any) => (
                <Badge
                    sx={{
                        background: t.tag?.badgeBackground ?? "#00A550",
                        color: t.tag?.badgeColor ?? "#FFFFFF",
                    }}
                    key={`badge-${t.value}`}
                >
                    {t.value}
                </Badge>
            ))
            : [];

    const onDelete = useCallback(async () => {
        try {
            await updatePropRequest.mutateAsync({
                status: "DELETED",
                id: data.id
            })

            setDeleteLoading(false);
            setDeleteOpen(false);
            props.deleteCallback && props.deleteCallback();
            notifications.show({
                title: "Success!",
                message: "Successfully deleted property request, you may create another now!",
                autoClose: 2000,
            });
        } catch (e) {
            setDeleteLoading(false);
            setDeleteOpen(false);
            notifications.show({
                title: "Error deleting",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    }, [props.deleteCallback, data])

    const formatDisplay = (value: string) => {
        if (value === "not-sure") {
            return "Not Sure"
        }

        return value
    }

    const hasChat = useMemo(() => {
        if (!!data) {
            return !!data?.chat?.id
        }

        return false
    }, [data])

    const handleCreateChat = async (data: any) => {
        try {
            const chat = await createChatMutation.mutateAsync({
                lister: data.lister.id,
                agent: user.id,
                propertyRequest: data.id
            });

            navigate(`/profile?tab=chats`, { state: { id: chat.data.id } });

            console.log(data)
        } catch (e: any) {
            if (e?.response?.data?.error?.message === "Insufficient tokens") {
                setStartChatIsOpen(false)
                setPurchaseTokensPopup(true);
            }
        }
    }

    const price = useMemo(() => {
        if (data.transactionType !== "RENT" && !!data.incomeRange) {
            return (
                <Stack spacing={0}>
                    <Text fz={16} align="right">Monthly Income Range</Text>
                    <Text fw={700} fz={20} align="right">
                        {data.incomeRange}
                    </Text>
                </Stack>
            )
        } else if (!!data.budgetMin && !!data.budgetMax) {
            return (
                <Stack spacing={0}>
                    <Text fz={16} align="right">Budget</Text>
                    <Text fw={700} fz={20} align="right">
                        RM{data.budgetMin.toLocaleString()} - RM
                        {data.budgetMax.toLocaleString()}
                    </Text>
                </Stack>
            )
        }
    }, [data])

    const handleReveal = useCallback(async () => {
        await updatePropRequest.mutateAsync({
            contactRevealedTo: { connect: [user.id] },
            id: data.id
        })
    }, [data, user])

    return (
        <>
            <Card
                sx={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid #BFC8C3",
                    cursor: "pointer",
                }}
                onClick={(e: any) => {
                    navigate(`/requests/${data.id}`);
                }}
            >
                <Stack spacing={8} h="100%">
                    <Group position="apart">
                        <Group spacing={4}>
                            {!!data.isUrgent && (
                                <Tooltip label={'User has agreed to reveal phone number and email'}>
                                    <Badge color="red">⚠️ Urgent</Badge>
                                </Tooltip>
                            )}
                            {!!data.isFirstTime && (
                                <Badge color="yellow">First Time</Badge>
                            )}
                            {!!data.showPhoneNumber && (
                                <Badge color="red">Contact available now</Badge>
                            )}
                        </Group>
                        {!!props.controls && (
                            <Group spacing={8}>
                                <Tooltip label="Edit">
                                    <ActionIcon
                                        variant="transparent"
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            navigate(
                                                `/requests/edit/${data.id}`
                                            );
                                        }}
                                    >
                                        <Edit />
                                    </ActionIcon>
                                </Tooltip>
                                <Tooltip label="Delete">
                                    <ActionIcon
                                        variant="transparent"
                                        color="red"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteOpen(true);
                                        }}
                                    >
                                        <TrashX />
                                    </ActionIcon>
                                </Tooltip>
                            </Group>
                        )}
                    </Group>
                    <Group>
                        {!!data?.subSalesOk && (
                            <Badge color="dark" size={'lg'}>Subsales OK</Badge>
                        )}
                        {!!data?.newProjectsOnly && (
                            <Badge color="blue" size={'lg'}>NEW PROJECTS OK</Badge>
                        )}
                    </Group>
                    <Group position="apart">
                        <Stack spacing={4}>
                            <Group spacing={4}>
                                <Text size={'md'}>I'm looking to </Text>
                                <Badge
                                    sx={{
                                        color: PROPERTY_REQUEST_COLOR_SETTINGS[
                                            transactionType
                                        ].color,
                                        backgroundColor:
                                            PROPERTY_REQUEST_COLOR_SETTINGS[
                                                transactionType
                                            ].background,
                                    }}
                                    size="lg"
                                >
                                    {transactionType}
                                </Badge>
                                <Text size={'md'}>
                                    at
                                </Text>
                                <Text size={'lg'} fw="bold">
                                    {data.postcode ?? ""} {data.area}, {data.state}
                                </Text>
                            </Group>
                            {!!data?.seriousnessRating && (
                                <Text size={'md'}>{formatSeriousness(data?.seriousnessRating)?.label}</Text>
                            )}
                        </Stack>
                        <Text fz={12}>
                            Active {moment(data.updatedAt).toNow(true)} ago
                        </Text>
                    </Group>
                    <Stack spacing={16} sx={{ flex: 1 }} justify="flex-end">
                        <Group position="apart">
                            <Stack spacing={4} sx={{ flexGrow: 1 }}>
                                <Group spacing={8}>
                                    <Stack spacing={0}>
                                        <Group spacing={8}>
                                            <Text size="lg" fw={'bold'}>
                                                {data.minSquareFt} sqft -{" "}
                                                {data.maxSquareFt} sqft
                                            </Text>

                                        </Group>
                                        <Group spacing={4}>
                                            <Text>Property Type:</Text>
                                            <Text size="lg" fw={'bold'}>{data.propertyType}</Text>
                                        </Group>
                                        <Group spacing={4}>
                                            <Text>Tenure:</Text>
                                            <Text size="lg" fw={'bold'}>{formatDisplay(data.tenure)}</Text>
                                        </Group>
                                        <Group spacing={4}>
                                            <Text>Property Title Type:</Text>
                                            <Text size="lg" fw={'bold'}>{formatDisplay(data.propertyTitleType)}</Text>
                                        </Group>
                                    </Stack>
                                </Group>
                                <Group spacing={8}>
                                    {parseInt(data.noOfBedrooms) !== 0 && (
                                        <Group spacing={4}>
                                            <Text>{data.noOfBedrooms}</Text>
                                            <Bed />
                                        </Group>
                                    )}
                                    <Group spacing={4}>
                                        <Text>{data.noOfBathrooms}</Text>
                                        <Bath />
                                    </Group>
                                </Group>
                            </Stack>
                            <Stack spacing={4} sx={{ flexGrow: 1 }}>
                                {price}
                                {data.lister && (
                                    <>
                                        <Text fz={12} align="right">
                                            Requested by{" "}
                                            <Text
                                                fz={14}
                                                fw={700}
                                                span
                                                sx={(theme) => ({
                                                    color: theme.colors
                                                        .primaryGreen["6"],
                                                })}
                                            // td="underline"
                                            // onClick={() => }
                                            >
                                                {data.lister.fullname}
                                            </Text>
                                        </Text>
                                        {!props.isLanding && (
                                            <>

                                                {!!data.showPhoneNumber || !!data.isUrgent ? (
                                                    <Group position="right">
                                                        <ClickBox
                                                            text={`+${data.lister.phoneNumber}`}
                                                            textProps={{
                                                                sx: {
                                                                    fontSize: 14,
                                                                    textAlign: 'right',
                                                                    fontWeight: 700,
                                                                    color: "#417256"
                                                                }
                                                            }}
                                                            coverText="View phone no."
                                                            activated={props.hasPermissions}
                                                            onReveal={() => handleReveal()}
                                                        />
                                                    </Group>
                                                ) : (
                                                    <Text
                                                        align="right"
                                                        fz={14}
                                                        fw={700}
                                                        span
                                                        sx={(theme) => ({
                                                            color: theme.colors
                                                                .primaryGreen["6"],
                                                        })}
                                                    >
                                                        +{censorString(data.lister.phoneNumber)}
                                                    </Text>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                            </Stack>
                        </Group>
                        {data.additionalNotes.length > 0 && (
                            <Group position="apart" sx={{ backgroundColor: "whitesmoke" }} p={8}>
                                <Text lineClamp={2}>
                                    {data.additionalNotes}
                                </Text>
                            </Group>
                        )}
                        <Group>
                            <Box sx={{ flexGrow: 1 }}>
                                <Group>
                                    {tagArr}
                                </Group>
                            </Box>
                            {!props.hideButtons && (
                                <Group sx={{ flexGrow: 1 }} position="right">
                                    {props.forAgents ? (
                                        <Stack>
                                            <Group>
                                                <Button

                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate(
                                                            `/requests/${data.id}`
                                                        );
                                                    }}
                                                >
                                                    View Details
                                                </Button>
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate(
                                                            `/requests/${data.id}/offer`
                                                        );
                                                    }}
                                                    variant="outline"
                                                >
                                                    <Group spacing={8}>
                                                        Make Offer
                                                        {/* <Group spacing={4} align="center">
                                                            <Text>1</Text>
                                                            <Image
                                                                src={"/token.png"}
                                                                height={16}
                                                                width={16}
                                                                style={{ marginRight: "4px" }}
                                                            />
                                                        </Group> */}
                                                    </Group>
                                                </Button>
                                            </Group>
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (hasChat) {
                                                        handleCreateChat(data)
                                                    } else {
                                                        setStartChatIsOpen(true)
                                                    }
                                                }}
                                                variant="outline"
                                            >
                                                <Group spacing={8}>
                                                    {!!hasChat ? "Chat" : "Start Chat"}
                                                    {/* {!hasChat && (
                                                        <Group spacing={4} align="center">
                                                            <Text>5</Text>
                                                            <Image
                                                                src={"/token.png"}
                                                                height={16}
                                                                width={16}
                                                                style={{ marginRight: "4px" }}
                                                            />
                                                        </Group>
                                                    )} */}
                                                </Group>
                                            </Button>
                                        </Stack>
                                    ) : (
                                        <Button>View Offers{data.offers.length ? ` (${data.offers.length})` : ''}</Button>
                                    )}
                                </Group>
                            )}
                        </Group>
                    </Stack>
                </Stack >
            </Card >
            <TokensModal
                opened={purchaseTokensPopup}
                onCancel={() => setPurchaseTokensPopup(false)}
                tokenAmount={5}
            />
            <ConfirmeStartChatModal
                opened={startChatIsOpen}
                onClose={() => setStartChatIsOpen(false)}
                onConfirm={() => handleCreateChat(data)}
            />
            <Modal
                opened={deleteOpen}
                onClose={() => {
                    setDeleteOpen(false);
                }}
                title={`Are you sure you want to delete this request?`}
                centered
            >
                <Stack sx={{ width: "100%" }}>
                    <Text align="center" color="red">
                        This action cannot be undone
                    </Text>
                    <Group sx={{ width: "100%" }}>
                        <Button
                            sx={{ flex: 1 }}
                            variant="outline"
                            onClick={() => setDeleteOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{ flex: 1 }}
                            onClick={() => onDelete()}
                            loading={deleteLoading}
                        >
                            Yes
                        </Button>
                    </Group>
                </Stack>
            </Modal>
        </>
    );
}
