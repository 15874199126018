import { Button, Center, Container, Divider, Group, LoadingOverlay, Modal, Stack, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useUserAPI } from 'api/userAPI';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Feedback = () => {
    const navigate = useNavigate()
    const { useCreateFeedback } = useUserAPI()
    const createFeedback = useCreateFeedback()
    const [feedbackSuccess, setFeedbackSuccess] = useState(false)
    const [feedbackFail, setFeedbackFail] = useState(false)



    const form = useForm({
        initialValues: {
            content: ""
        },
        validate: {
            content: (value) => {
                if (!value) {
                    return "Please fill in this field"
                }

                if (value.length < 50) {
                    return "At least 50 characters are required"
                }
                return null
            },
        }
    })

    const onSubmit = () => {
        const { hasErrors } = form.validate()
        if (!hasErrors) {

            createFeedback.mutate(form.values, {
                onSuccess: () => {
                    form.reset()
                    setFeedbackSuccess(true)
                },
                onError: () => {
                    setFeedbackFail(true)
                },
            })
        }

    }

    const handleClose = () => {
        setFeedbackSuccess(false)
        setFeedbackFail(false)
    }

    return (
        <Container
            size="1200px"
            py={32}
            px={16}
        >
            <LoadingOverlay visible={createFeedback.isLoading} overlayBlur={2} />
            <Modal
                centered
                opened={!!feedbackSuccess}
                onClose={() => handleClose()}
                title={'Feedback successfully submited!'}
            >
                <Group sx={{ width: '100%' }}>
                    <Button sx={{ width: '100%' }} onClick={() => handleClose()}>OK</Button>
                </Group>
            </Modal>
            <Modal
                centered
                opened={!!feedbackFail}
                onClose={() => handleClose()}
                title={'Something went wrong, please try again'}
            >
                <Group sx={{ width: '100%' }}>
                    <Button sx={{ width: '100%' }} onClick={() => handleClose()}>OK</Button>
                </Group>
            </Modal>
            <Stack spacing={24}>
                <Group position="apart">
                    <Text fz={32} fw={600}>
                        Feedback
                    </Text>
                </Group>
                <Divider my={8} />
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Stack>
                        <Text>Your feedback will help us improve propmoth's experience.</Text>
                        <Textarea
                            minRows={5}
                            {...form.getInputProps("content")}
                            error={form.errors.content}
                        />
                        <Group>
                            <Button onClick={() => onSubmit()}>Submit</Button>
                        </Group>
                    </Stack>
                </form>
            </Stack>
        </Container>
    );
};

export default Feedback;