import { Flex, Group, Select, Text } from '@mantine/core';
import { buyOptions, incomeOptions, rentOptions } from 'helpers/constants';
import React, { useCallback, useEffect } from 'react';

const BudgetInput = ({ form }: any) => {
    

    const decideOptions = useCallback(() => {
        switch (form.values.transactionType) {
            case "BUY":
                return buyOptions;
            case "RENT":
                return rentOptions;
            case "INVEST":
                return buyOptions;
        }
    }, [form.values])

    useEffect(() => {
        if (!!form.values.budgetMax && !form.values.budgetMin) {
            form.setFieldValue('budgetMin', form.values.budgetMax)
        }

        if (!form.values.budgetMax && !!form.values.budgetMin) {
            form.setFieldValue('budgetMax', form.values.budgetMin)
        }

        if (!!form.values.budgetMax && !!form.values.budgetMin && form.values.budgetMin > form.values.budgetMax) {
            form.setFieldValue('budgetMax', form.values.budgetMin)
        }

        if (!!form.values.budgetMax && !!form.values.budgetMin && form.values.budgetMax < form.values.budgetMin) {
            form.setFieldValue('budgetMin', form.values.budgetMax)
        }

    }, [form.values])

    return (
        <Flex
            sx={{
                flexDirection: "column",
            }}
        >
            <Text
                sx={{
                    display:
                        "inline-block",
                    fontSize:
                        "0.875rem",
                    fontWeight: 500,
                    color: "#212529",
                    wordBreak:
                        "break-word",
                    cursor: "default",
                }}
            >
                {form.values.transactionType === "RENT" ? ("Budget") : ("Income Range per month")}
            </Text>
            {form.values.transactionType === "RENT" ? (
                <Flex
                    sx={{
                        alignItems:
                            "center",
                        gap: 16,
                    }}
                    pt={2}
                >
                    <Select
                        data={decideOptions()}
                        {...form.getInputProps(
                            "budgetMin"
                        )}
                        sx={{
                            flex: 1,
                        }}
                    />
                    <Select
                        data={decideOptions()}
                        {...form.getInputProps(
                            "budgetMax"
                        )}
                        sx={{
                            flex: 1,
                        }}
                    />
                </Flex>
            ) : (
                <Flex>
                    <Select
                        data={incomeOptions}
                        {...form.getInputProps("incomeRange")}
                        sx={{
                            flex: 1,
                        }}
                    />
                </Flex>
            )}
        </Flex>
    );
};

export default BudgetInput;