import { BackgroundImage, Box, Card, Center, Grid, Image, Stack, Text } from '@mantine/core';
import React from 'react';

const FeaturedOn = () => {
    const featuredOn = [
        {
            title: "Digital News Asia",
            img: "/digital-news-asia.png",
            preview: "/dna-preview.png",
            text: "MYStartup selects 26 startups for their pre-accelerator cohort 3",
            credit: "By Digital News Asia March 16, 2024",
            link: "https://www.digitalnewsasia.com/startups/mystartup-selects-26-startups-their-pre-accelerator-cohort-3"

        },
        {
            title: "Tech Node Global",
            img: "/tnglobal.png",
            preview: "/tnglobal-preview.png",
            text: "MALAYSIA'S MYSTARTUP SELECTS 26 STARTUPS FOR PRE-ACCELERATOR COHORT 3 PROGRAM",
            credit: "By Technode Global Staff March 15, 2024",
            link: "https://technode.global/2024/03/15/malaysias-mystartup-selects-26-startups-for-pre-accelerator-cohort-3-program/"
        },
        {
            title: "Vulcan Post",
            img: "/vulcan.png",
            preview: "/vulcan-preview.png",
            text: "26 out of 136 startups were chosen for MYStartup's 3rd pre-accelerator, here's what they do",
            credit: "By Claudia Khaw, Vulcan Post March 19, 2024",
            link: "https://vulcanpost.com/854831/mystartup-pre-accelerator-cohort-3-startups-malaysia/"
        },
    ]

    return (
        <>
            <Text
                component="h1"
                sx={(theme) => ({
                    fontSize: "1.5em",
                    lineHeight: 1,
                    [theme.fn.smallerThan("xs")]: {
                        textAlign: "center",
                    },
                })}
                align="center"
            >
                Featured On
            </Text>
            <Grid gutter={24}>
                {featuredOn.map((logo) => (
                    <Grid.Col xs={12} sm={6}>
                        <Center
                            sx={{
                                height: "100%",
                                background: 'rgba(78,122,77,0.6)',
                                borderRadius: 16,
                                cursor: 'pointer'
                            }}
                            p={16}
                            onClick={() => window.open(logo.link, "_newtab")}
                        >
                            <Stack spacing={8} align="center">
                                <Image
                                    src={logo.img}
                                    alt={logo.title}
                                    fit="contain"
                                    height={38}
                                />
                                <Text color="#fff" align="center" component="h1">{logo.title}</Text>
                                <Card sx={{ borderRadius: 8 }}>
                                    <Grid>
                                        <Grid.Col md={5} xs={12}>
                                            <Card withBorder shadow="md" p={0} >
                                                <BackgroundImage
                                                    src={logo.preview}
                                                    sx={{ height: 312, width: '100%', borderRadius: 8, backgroundPosition: 'top' }}
                                                >
                                                    <img src={logo.preview} alt={`${logo.title}-preview`} className='sr-only' />
                                                </BackgroundImage>
                                            </Card>
                                        </Grid.Col>
                                        <Grid.Col md={7} xs={12}>
                                            <Box component="article" sx={{ flex: 1 }}>
                                                <Stack spacing={2}>
                                                    <Text component="h1">{logo.text}</Text>
                                                    <Text size="xs" align="right">{logo.credit}</Text>
                                                </Stack>
                                            </Box>
                                        </Grid.Col>
                                    </Grid>
                                </Card>
                            </Stack>
                        </Center>
                    </Grid.Col>
                ))}
            </Grid>
        </>
    );
};

export default FeaturedOn;