import { Group, NumberInput, Stack, Text } from '@mantine/core';
import React from 'react';
import BedroomsInput from '../components/BedroomsInput';
import BathroomsInput from '../components/BathroomsInput';
import FloorSizeInput from '../components/FloorSizeInput';

const SizeSection = ({ showBedrooms, form }: any) => {
    return (
        <Stack>
            {!!showBedrooms && (
                <BedroomsInput form={form} />
            )}
            <BathroomsInput form={form} />
            <FloorSizeInput form={form} />
        </Stack>
    );
};

export default SizeSection;