import { AspectRatio, Box, Button, Card, Grid, Group, Image, Text, TextInput, Stack } from '@mantine/core';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { Plus } from 'tabler-icons-react';

interface VideoEmbedProps {
    urlArr: any[]
    removedUrl?: any[]
    existingUrlArr?: any[],
    setUrlArr: any
    setRemovedUrl?: any
}


const VideoEmbedInput = ({
    urlArr,
    setUrlArr,
    removedUrl,
    setRemovedUrl,
    existingUrlArr
}: VideoEmbedProps) => {
    const [urlInput, setUrlInput] = useState("");
    const [urlIsLoading, setUrlIsloading] = useState(false);
    const [urlErr, setUrlErr] = useState("");


    const getTiktokData = async (tiktokUrl: string) => {
        setUrlIsloading(true);
        await axios
            .get(`https://www.tiktok.com/oembed?url=${tiktokUrl}`)
            .then((e) => {
                if (e.data?.thumbnail_url) {
                    setUrlArr([
                        ...urlArr,
                        {
                            link: tiktokUrl,
                            thumbnail: e.data?.thumbnail_url,
                            type: "TIKTOK",
                        },
                    ]);
                }
                setUrlIsloading(false);
            });
    };

    const onAddVideo = (e: any) => {
        e.preventDefault();
        const regex = /^https:\/\/www\.tiktok\.com\/@[\w.-]+\/video\/\d+(?:\?[\w=&.-]+)?$/;

        if (!regex.test(urlInput)) {
            setUrlErr("This link is not supported yet");
            return;
        }

        setUrlErr("");
        setUrlInput("");
        getTiktokData(urlInput);
    };

    const handleRemoveUrl = (urlObj: any, index: number) => {
        if (urlObj.id && !!removedUrl) {
            setRemovedUrl([...removedUrl, urlObj])
        } else {
            const newUrlArr = urlArr.filter((e, i) => i !== index);
            setUrlArr(newUrlArr);
        }
    };

    const formatUrls = useCallback(() => {
        if (!!removedUrl) {
            return urlArr.filter((e) => {
                return !removedUrl.find(r => r.link === e.link)
            })
        }

        return urlArr
    }, [urlArr, removedUrl])

    return (
        <>
            <Stack>
                <form
                    onSubmit={(
                        e
                    ) =>
                        onAddVideo(
                            e
                        )
                    }
                >
                    <Group
                        sx={{
                            alignItems:
                                "flex-end",
                        }}
                    >
                        <TextInput
                            label="Video Links (optional)"
                            radius="md"
                            sx={{
                                flex: 1,
                                width: "100%",
                            }}
                            onChange={(
                                e
                            ) =>
                                setUrlInput(
                                    e
                                        .target
                                        .value
                                )
                            }
                            value={
                                urlInput
                            }
                            error={
                                urlErr
                            }
                        />
                        <Button
                            leftIcon={
                                <Plus />
                            }
                            type="submit"
                            mb={
                                !!urlErr
                                    ? 19
                                    : 0
                            }
                            loading={
                                urlIsLoading
                            }
                        >
                            Add
                        </Button>
                    </Group>
                </form>
                <Grid>
                    {formatUrls().map(
                        (
                            u,
                            i
                        ) => (
                            <>
                                <Grid.Col
                                    sm={
                                        3
                                    }
                                >
                                    <Card>
                                        <Group
                                            spacing={
                                                8
                                            }
                                            align="center"
                                            sx={{
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Stack
                                                spacing={
                                                    4
                                                }
                                            >
                                                <AspectRatio
                                                    ratio={
                                                        9 /
                                                        16
                                                    }
                                                    w={
                                                        150
                                                    }
                                                >
                                                    <Image
                                                        src={
                                                            u.thumbnail
                                                        }
                                                    />
                                                </AspectRatio>
                                                <Button
                                                    color="red"
                                                    onClick={() =>
                                                        handleRemoveUrl(
                                                            u,
                                                            i
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </Stack>
                                        </Group>
                                    </Card>
                                </Grid.Col>
                            </>
                        )
                    )}
                </Grid>
            </Stack>
        </>
    );
};

export default VideoEmbedInput;