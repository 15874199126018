import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
const qs = require("qs");

// keys
export const GET_CHATS = "GET_CHATS";
export const GET_SINGLE_CHAT = "GET_SINGLE_CHAT";
export const GET_OFFER_CHAT = "GET_OFFER_CHAT";
export const GET_LISTING_CHAT = "GET_LISTING_CHAT";
export const GET_PROPERTY_REQUEST_CHAT = "GET_PROPERTY_REQUEST_CHAT";
export const GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES";
export const GET_CHAT_MESSAGES_AFTER_ID = "GET_CHAT_MESSAGES_AFTER_ID";
export const SEND_MESSAGE = "SEND_MESSAGE";

//functions
const createChat = async (payload: {
    lister: number;
    agent: number;
    offer?: number;
    listing?: number;
    propertyRequest?: number;
}) => {
    const resp = await API({ requireAuth: true }).post<any, any>(`chats`, {
        data: { ...payload },
    });

    return flattenObj(resp);
};

export const useCreateChat = () => useMutation(createChat);

const sendMessage = async (payload: {
    sender: number;
    data: any;
    chat: number;
}) => {
    const resp = await API({ requireAuth: true }).post<any, any>(`messages`, {
        data: { ...payload },
    });

    return flattenObj(resp);
};

export const useCreateMessage = () => useMutation(sendMessage);

async function getChats(userId: number, userType: string) {
    try {
        const filters = {
            // $or: [
            //     {
            //         offer: {
            //             propertyRequest: {
            //                 id: {
            //                     $not: null,
            //                 },
            //             },
            //         },
            //     },
            //     {
            //         offer: {
            //             listing: {
            //                 id: {
            //                     $not: null,
            //                 },
            //             },
            //         },
            //     },
            //     {
            //         propertyRequest: {
            //             id: {
            //                 $not: null,
            //             },
            //         },
            //     },
            // ],
            [`${userType.toLowerCase()}`]: {
                id: {
                    $eq: userId,
                },
            },
            // offer: {
            //     id: {
            //         $not: null,
            //     },
            // },
        };
        const query = qs.stringify(
            {
                populate: {
                    agent: true,
                    lister: true,
                    offer: {
                        populate: {
                            listing: {
                                populate: ["images"],
                            },
                            propertyRequest: true,
                        },
                    },
                    propertyRequest: true,
                    listing: {
                        populate: ["images"],
                    },
                    messages: {
                        populate: ["sender"],
                    },
                },
                filters,
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );

        const response = await API({ requireAuth: true }).get<any, any>(
            `chats?${query}`
        );

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetChats = (params: { userId: number; userType: string }) =>
    useQuery(
        [GET_CHATS, params],
        () => getChats(params.userId, params.userType),
        {
            enabled: !!params.userId && !!params.userType,
        }
    );

async function getListingChat(
    userId: number,
    userType: string,
    listingId: number
) {
    try {
        const filters = {
            // [`${userType.toLowerCase()}`]: {
            //     id: {
            //         $eq: userId,
            //     },
            // },
            listing: {
                id: {
                    $eq: listingId,
                },
            },
        };
        const query = qs.stringify(
            {
                populate: "*",
                filters,
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );

        const response = await API({ requireAuth: true }).get<any, any>(
            `chats?${query}`
        );

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetListingChat = (
    userId: number,
    userType: string,
    listingId: number
) =>
    useQuery([GET_LISTING_CHAT, listingId], () =>
        getListingChat(userId, userType, listingId)
    );

async function getPropertyRequestChat(propertyRequestId: number) {
    try {
        const filters = {
            // [`${userType.toLowerCase()}`]: {
            //     id: {
            //         $eq: userId,
            //     },
            // },
            propertyRequest: {
                id: {
                    $eq: propertyRequestId,
                },
            },
        };
        const query = qs.stringify(
            {
                populate: "*",
                filters,
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );

        const response = await API({ requireAuth: true }).get<any, any>(
            `chats?${query}`
        );

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetPropertyRequestChat = (propertyRequestId: number) =>
    useQuery(
        [GET_PROPERTY_REQUEST_CHAT, propertyRequestId],
        () => getPropertyRequestChat(propertyRequestId),
        {
            enabled: !!propertyRequestId,
        }
    );

async function getOfferChat(userId: number, userType: string, offerId: number) {
    try {
        const filters = {
            // [`${userType.toLowerCase()}`]: {
            //     id: {
            //         $eq: userId,
            //     },
            // },
            offer: {
                id: {
                    $eq: offerId,
                },
            },
        };
        const query = qs.stringify(
            {
                populate: "*",
                filters,
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );

        const response = await API({ requireAuth: true }).get<any, any>(
            `chats?${query}`
        );

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetOfferChat = (
    userId: number,
    userType: string,
    offerId: number
) =>
    useQuery([GET_OFFER_CHAT, offerId], () =>
        getOfferChat(userId, userType, offerId)
    );

async function getChat(chatId: number | null) {
    try {
        const query = qs.stringify(
            {
                populate: {
                    agent: true,
                    lister: true,
                    propertyRequest: true,
                    offer: {
                        populate: {
                            listing: {
                                populate: ["images"],
                            },
                            propertyRequest: true,
                        },
                    },
                    listing: {
                        populate: ["images"],
                    },
                    messages: {
                        populate: ["sender"],
                    },
                },
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );

        const response = await API({ requireAuth: true }).get<any, any>(
            `chats/${chatId}?${query}`
        );

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetChat = (chatId: number | null) =>
    useQuery([GET_SINGLE_CHAT, chatId], () => getChat(chatId), {
        enabled: !!chatId,
    });

async function getChatMessages(chatId: number) {
    try {
        const query = qs.stringify(
            {
                populate: "*",
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );

        const response = await API({ requireAuth: true }).get<any, any>(
            `chats/${chatId}?${query}`
        );

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetChatMessages = (chatId: number) =>
    useQuery([GET_CHAT_MESSAGES, chatId], () => getChatMessages(chatId));

async function getChatMessagesAfterId(
    chatId: number | null,
    lastId: number | null
) {
    try {
        const query = qs.stringify(
            {
                populate: "*",
                filters: {
                    id: {
                        $gt: lastId,
                    },
                    chat: {
                        id: {
                            $eq: chatId,
                        },
                    },
                },
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );

        const response = await API({ requireAuth: true }).get<any, any>(
            `messages?${query}`
        );

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetChatMessagesAfterId = (
    chatId: number | null,
    lastId: number | null
) =>
    useQuery(
        [GET_CHAT_MESSAGES_AFTER_ID, chatId],
        () => getChatMessagesAfterId(chatId, lastId),
        {
            enabled: !!chatId && !!lastId,
        }
    );
