import { AspectRatio, BackgroundImage, Badge, Box, Button, Card, Center, Chip, Container, Flex, Grid, Group, Image, Modal, Overlay, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import ProjectContactForm from './ProjectContactForm';
import { Carousel } from '@mantine/carousel';
import { LightBox } from 'components/LightBox';

const ContentSpan = ({ content }: { content: string }) => {
    return (
        <Text component="span" size="sm" color="#15BC69">{content}</Text>
    )
}

const ProjectCard = ({ project }: { project: any }) => {
    const [opened, setOpened] = useState(false)
    const [showLightBox, setShowLightBox] = useState(false);
    const [clickedImage, setClickedImage] = useState(0);

    const handleOpenLightBox = (index: number) => {
        setClickedImage(index)
        setShowLightBox(true)
    }

    return (
        <>
            <Card
                withBorder
                radius={16}
                sx={{ cursor: 'pointer' }}
            >
                <Grid
                    gutter={16}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("sm")]: {
                            flexDirection: 'column'
                        }
                    })}
                >
                    <Grid.Col sm={12} md={6}>
                        <Grid>
                            <Grid.Col sm={12} md={6}>
                                <Stack spacing={16}>
                                    <BackgroundImage
                                        src={project.bannerImageUrl}
                                        sx={{ height: '150px', width: '100%', borderRadius: 8 }}
                                    >
                                        <img src={project.bannerImageUrl} alt={project.title} className='sr-only' />
                                    </BackgroundImage>
                                    <AspectRatio ratio={693 / 878}>
                                        <BackgroundImage
                                            src={project.mainImageUrl}
                                            sx={(theme) => (
                                                {
                                                    height: '100%',
                                                    width: '100%',
                                                    borderRadius: 8,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundColor: "rgba(0,0,0,0.1)",
                                                    // [theme.fn.smallerThan("md")]: {
                                                    //     height: '50vh',
                                                    // }
                                                }
                                            )}
                                        >
                                            <img src={project.mainImageUrl} alt={project.title} className='sr-only' />
                                        </BackgroundImage>
                                    </AspectRatio>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col sm={12} md={6}>
                                <Flex
                                    sx={(theme) => ({
                                        height: '100%',
                                        gap: 16,
                                        flexDirection: 'column',
                                        // [theme.fn.smallerThan("md")]: {
                                        // }
                                    })}
                                >
                                    <Stack spacing={16} sx={{ border: '1px solid #ddd', borderRadius: 8, padding: 16, flex: 1 }}>
                                        {!project.comingSoon ? (
                                            <>
                                                <Stack spacing={4}>
                                                    <Text align='center' fz={36} component='h1' sx={{ lineHeight: 1, margin: 0, color: "#1F8150" }}>{project.title}</Text>
                                                    <Text align='center' fz={24}><ContentSpan content="MYR" />{project.priceMin} - <ContentSpan content="MYR" />{project.priceMax}</Text>
                                                    <Text align='center' fz={24}>{project.sqftMin}<ContentSpan content="SQFT" /> - {project.sqftMax}<ContentSpan content="SQFT" /></Text>
                                                </Stack>
                                                <Group position='center'>
                                                    {project.tags?.map((t: any) => (
                                                        <Badge leftSection={t.icon} color='blue'>
                                                            {t.label}
                                                        </Badge>
                                                    ))}
                                                </Group>
                                                <Group position='center'>
                                                    <Image src="/expected-completion.png" fit="contain" height={24} width={24} />
                                                    <Stack spacing={0}>
                                                        <Text>Expected</Text>
                                                        <Text>Completion</Text>
                                                    </Stack>
                                                    <Badge size='lg'>
                                                        {project.expectedCompletion}
                                                    </Badge>
                                                </Group>
                                                <article>
                                                    <Text component='p' m={0}>{project.description}</Text>
                                                </article>
                                            </>
                                        ) : (
                                            <>
                                                <Text align='center' fz={36} component='h1' sx={{ lineHeight: 1, margin: 0, color: "#1F8150" }}>{project.title}</Text>
                                                <Text align='center' fz={24}>Starting From <ContentSpan content="MYR" />{project.priceMax}</Text>
                                                <Text align='center'>This project is extremely new and we can't tell you too much information about it. </Text>
                                                <Text align='center'>Here are some of the things we can tell you</Text>
                                                <Stack spacing={4}>
                                                    {project.shareData.map((d: string, i: number) => (
                                                        <Text fz={14}>{i + 1}. {d}</Text>
                                                    ))}
                                                </Stack>
                                            </>
                                        )}
                                    </Stack>
                                    <Stack spacing={8}>
                                        {project.brochureLink && (
                                            <Button
                                                variant='outline'
                                                onClick={() => window.open(project.brochureLink, '_blank')}
                                            >
                                                Download Brochure
                                            </Button>
                                        )}
                                        <Button onClick={() => setOpened(true)}>Talk to an Expert</Button>
                                    </Stack>
                                </Flex>
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>
                    <Grid.Col sm={12} md={6}>
                        <Box style={{ height: '100%', display: 'flex', position: 'relative', minHeight: 200 }}>
                            {project.carousel.length > 0 ? (

                                <Carousel
                                    loop
                                    withIndicators
                                    slideSize="70%"
                                    slideGap='sm'
                                    height="100%"
                                    styles={{
                                        'indicator': {
                                            backgroundColor: '#417256'
                                        }
                                    }}
                                    sx={{ flex: 1 }}
                                >
                                    {project.carousel.map((c: any, i: number) => (
                                        <Carousel.Slide>
                                            <Center sx={{ height: '100%' }}>
                                                <Image
                                                    src={c.url}
                                                    alt={c.url}
                                                    height={'100%'}
                                                    fit="contain"
                                                    onClick={() => handleOpenLightBox(i)}
                                                />
                                            </Center>
                                        </Carousel.Slide>
                                    ))}
                                </Carousel>
                            ) : (
                                <Overlay color="#000" opacity={0.8} blur={1} center sx={{ borderRadius: 8 }}>
                                    <Center sx={{ width: '100%', height: '100%' }}>
                                        <Text color="#fff" fz={32} fw={700}>Coming Soon</Text>
                                    </Center>
                                </Overlay>
                            )}
                        </Box>
                    </Grid.Col>
                </Grid>
            </Card>
            <Modal
                centered
                opened={opened}
                onClose={() => setOpened(false)}
                title={project.title}
            >
                <ProjectContactForm submitCallback={() => setOpened(false)} projectName={project.title} />
            </Modal>
            <LightBox
                showLightBox={showLightBox}
                onClose={() => setShowLightBox(false)}
                // image={formattedData.offerImages[0]?.src || ""}
                images={project.carousel}
                clickedImage={clickedImage}
            />
        </>
    );
};

export default ProjectCard;