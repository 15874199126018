import { Box, Button, Flex, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const FeedbackBanner = () => {
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                // backgroundColor: '#417256',
                border: '1px solid #417256',
                borderRadius: 8,
                cursor: 'pointer'
            }}
            p={16}
            onClick={() => navigate("/feedback")}
        >
            <Flex
                sx={(theme) => ({
                    flexDirection: 'row',
                    gap: 16,
                    [theme.fn.smallerThan('sm')]: {
                        flexDirection: 'column',
                    }
                })}
            >
                <Stack sx={{ flex: 1, flexGrow: 1 }} spacing={0}>
                    <Text sx={{ color: '#417256' }} fw={700} fz="lg">Your feedback would be appreciated!</Text>
                    <Text>Let us know how we can make the propmoth experience better for you</Text>
                </Stack>
                <Button onClick={() => navigate("/feedback")}>Let's Go!</Button>
            </Flex>
        </Box>
    );
};

export default FeedbackBanner;