import {
    Avatar,
    BackgroundImage,
    Badge,
    Box,
    Button,
    Card,
    Center,
    Chip,
    Container,
    Divider,
    Grid,
    Group,
    LoadingOverlay,
    ScrollArea,
    Stack,
    Sx,
    Tabs,
    Text,
} from "@mantine/core";
import { useGetArticles } from "api/blogAPI";
import { BlogCard } from "components/Blog/BlogCard";
import { LatestPosts } from "components/Blog/LatestPostColumn";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InPartnershipWith from "./MainLanding/InPartnershipWith";
import FeaturedOn from "./MainLanding/FeaturedOn";

export default function BlogList() {
    const navigate = useNavigate();
    const { data, error, isLoading } = useGetArticles();

    // useEffect(() => {
    //     console.log(data);
    // }, [data]);

    return (
        <Container size="1200px" py={32} px={16}>
            <Stack spacing={24}>
                <Group position="apart">
                    <Text fz={32} fw={600}>
                        News & Articles
                    </Text>
                </Group>
                <Divider my={8} />
                <Grid>
                    <Grid.Col md={!!data && data?.length > 0 ? 8 : 12} sm={12}>
                        <Grid>
                            <Grid.Col md={12}>
                                <Stack>
                                    {!!data && data?.length > 0 && (
                                        <>
                                            {data?.map((article: any) => (
                                                <BlogCard data={article} />
                                            ))}
                                        </>
                                    )}

                                    {!!data && data?.length === 0 && (
                                        <Card
                                            shadow="md"
                                            sx={{
                                                border: "1px solid #ced4da",
                                            }}
                                        >
                                            <Text align="center">
                                                There are no blog posts
                                                currently, check back soon!
                                            </Text>
                                        </Card>
                                    )}
                                </Stack>
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>
                    {!!data && data?.length > 0 && (
                        <Grid.Col md={4} sm={12}>
                            <LatestPosts />
                        </Grid.Col>
                    )}
                    {/* <Grid.Col>
                        <Container size="1200px">
                            <Stack
                                // sx={{
                                //     borderLeft: ".5px solid rgba(0,0,0,0.3)",
                                //     borderRight: ".5px solid rgba(0,0,0,0.3)",
                                // }}
                                // px={24}
                                pb={24}
                                pt={24}
                            >
                                <InPartnershipWith />
                            </Stack>
                            <Stack
                                // sx={{
                                //     borderLeft: ".5px solid rgba(0,0,0,0.3)",
                                //     borderRight: ".5px solid rgba(0,0,0,0.3)",
                                // }}
                                // px={24}
                                pb={24}
                            >
                                <FeaturedOn />
                            </Stack>
                        </Container>
                    </Grid.Col> */}
                </Grid>
            </Stack>
        </Container>
    );
}
