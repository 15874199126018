import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useState } from 'react';

const SaveAsDraft = ({ draftValidation, onSave, loading }: any) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [draftError, setDraftError] = useState(false)

    const saveAsDraftTrigger = () => {
        const isValid = draftValidation()

        setDraftError(false)
        if (!isValid) {
            setDraftError(true)
        }
        open()
    }

    const handleSaveAsDraft = async () => {
        close()
        await onSave()
    }

    return (
        <>
            <Button
                // disabled={!isReadyForPreview}
                onClick={() => saveAsDraftTrigger()}
                variant="outline"
            >
                Save As Draft
            </Button>

            <Modal opened={opened} onClose={close} centered title="Save As Draft?">
                {!!draftError ? (
                    <Stack>
                        <Text color="red" ta="center">You must at least fill in title, transaction type and property type</Text>
                        <Button onClick={() => close()}>OK</Button>
                    </Stack>
                ) : (
                    <Stack>
                        <Text ta="center" fw={700} fz={24}>Confirm save listing as draft?</Text>
                        <Text ta="center">No one will be able to see this listing except you</Text>
                        <Text ta="center">You can still edit the listing in your <br /> "My Listings" tab</Text>
                        <Group w="100%">
                            <Button sx={{ flex: 1 }} loading={loading} disabled={loading} bg='red' onClick={() => close()}>Cancel</Button>
                            <Button sx={{ flex: 1 }} loading={loading} disabled={loading} onClick={() => handleSaveAsDraft()}>Confirm</Button>
                        </Group>
                    </Stack>
                )}
            </Modal >
        </>
    );
};

export default SaveAsDraft;