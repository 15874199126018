import { Box, Grid, Group, Stack, Text } from "@mantine/core";
import { useInsightsAPI } from "api/useInsightsAPI";
import { useListerFormController } from "pages/RegisterLister";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import RegisterForm from "./components/RegisterForm";
import RegisterFeedback from "components/RegisterFeedback";

export const ListerForm: React.FC<any> = ({
    setView,
    setVerifyData,
    verifyData,
    ReactPixel
}) => {
    const navigate = useNavigate();
    const { useGetUserInsights } = useInsightsAPI()
    const {
        data: insightsData,
        isLoading: insightsIsLoading,
        error: insightsError,
    } = useGetUserInsights();

    const { form, onSubmit, registerMutation, allowSubmit, submitIsLoading } =
        useListerFormController({
            setView,
            setVerifyData,
            verifyData,
            ReactPixel
        });
    const [checkboxes, setCheckboxes] = useState<any>({
        tnc: false,
        privacy: false,
    });
    const handleChange = (key: string) => {
        setCheckboxes((prevState: any) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };
    const allowProceed = useMemo(
        () => Object.values(checkboxes).every((value) => !!value === true),
        [checkboxes]
    );

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid m={0}>
                <Grid.Col
                    p={0}
                    md={4}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("md")]: {
                            display: "none",
                        },
                    })}
                >
                    <Box
                        sx={{
                            backgroundImage: `url('/section-img.png')`,
                            width: "100%",
                            height: "100%",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            minHeight: "calc(100vh - 60px)",
                            position: 'relative'
                        }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,0.6)'
                        }}>
                            <Stack sx={{ height: '100%' }} align="center" justify="center" p={8}>
                                <Text color="white" fz={'3vw'} align="center">
                                    Join <Text color="white" component="span" fw={700}>{100 + insightsData?.totalListers}</Text> others <br /> looking for property today!
                                </Text>
                            </Stack>
                        </Box>
                    </Box>
                </Grid.Col>
                <Grid.Col
                    md={8}
                    xs={12}
                    p={0}
                    sx={(theme) => ({
                        backgroundColor: theme.colors.bgYellow,
                        [theme.fn.largerThan("md")]: {
                            paddingTop: "96px",
                            paddingBottom: "96px",
                        },
                        [theme.fn.smallerThan("md")]: {
                            paddingTop: "24px",
                            paddingBottom: "32px",
                        },
                    })}
                >

                    <Group sx={{ justifyContent: 'center' }}>
                        <Stack spacing={32} sx={{ maxWidth: "450px" }}>
                            <RegisterForm
                                form={form}
                                totalListers={insightsData?.totalListers || null}
                                checkboxes={checkboxes}
                                handleCheckboxChange={handleChange}
                                onSubmit={onSubmit}
                                submitIsLoading={submitIsLoading}
                                isSubmitDisabled={submitIsLoading || !allowSubmit || !allowProceed}
                            />
                            <RegisterFeedback />
                        </Stack>
                    </Group>
                </Grid.Col>
            </Grid>
        </form>
    );
};