import axios from "axios";
var sha256 = require("js-sha256");

export const catchUserData = async () => {
    try {
        const res = await axios.get("http://ip-api.com/json/");

        const { countryCode, city, query, zip } = res.data;
        const client_ip_address = query;
        const client_user_agent = navigator.userAgent;
        const ct = [sha256(city)];
        const country = [sha256(countryCode)];
        const zp = [sha256(zip)];

        return {
            countryCode,
            city,
            query,
            zip,
            client_ip_address,
            client_user_agent,
            ct,
            country,
            zp,
        };
    } catch (err) {
        throw err;
    }
};

export const sendScheduleEvent = async () => {
    const API_VERSION = "v18.0"; // Replace with your API version
    const DATASET_ID = "1566927573844693"; // Replace with your Dataset ID
    const TOKEN =
        "EAAUZAIh0PIyIBOZB5sMz048A6xx1nVdoRx1bsAhlY7jedubbGLKPapwZBqxQvTgNp7h5BVOZBTAdkS8AYy1mAczpZByFnYAMjHxA726CbtiAcbdNnRv9S6l4VHyPRQXvgheKrWFL9lEr3C5RKTVsZCVfR4zrcRagwtFgTO6xzTh1AI4Uk5HUZCxkZAZC6RpBaQewCDgZDZD"; // Replace with your Access Token

    const url = `https://graph.facebook.com/${API_VERSION}/${DATASET_ID}/events?access_token=${TOKEN}`;
    try {
        const { client_ip_address, client_user_agent, ct, country, zp } =
            await catchUserData();

        const data = {
            data: [
                {
                    event_name: "Schedule",
                    event_time: Math.floor(Date.now() / 1000),
                    action_source: "website",
                    user_data: {
                        client_user_agent,
                        client_ip_address,
                        ct,
                        country,
                        zp,
                    },
                },
            ],
        };

        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error("Error posting event:", error);
        throw error;
    }
};

export const sendAgentRegisterClickEvent = async () => {
    const API_VERSION = "v18.0"; // Replace with your API version
    const DATASET_ID = "879417273653069"; // Replace with your Dataset ID
    const TOKEN =
        "EAAUZAIh0PIyIBO9lf0jtWsbBRWO3MubDHoYLy2YEUa8OftUTCMpniXukH9UY1VRLkg1eTtVKlL4VPOFDntbdRISzK6d5xi2Ku1yCTZB0gFLfGeUaYcP3X2OqzDZCzEXwzZAUhiOOA9rP4e7s0YmQvOz3yGquPrH3qWUwACcBBV8N8wZBxJkuSQ6mbGVgYBcZCCNAZDZD"; // Replace with your Access Token

    const url = `https://graph.facebook.com/${API_VERSION}/${DATASET_ID}/events?access_token=${TOKEN}`;
    try {
        const { client_ip_address, client_user_agent, ct, country, zp } =
            await catchUserData();

        const data = {
            data: [
                {
                    event_name: "CompleteRegistration",
                    event_time: Math.floor(Date.now() / 1000),
                    action_source: "website",
                    user_data: {
                        client_user_agent,
                        client_ip_address,
                        ct,
                        country,
                        zp,
                    },
                },
            ],
        };

        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error("Error posting event:", error);
        throw error;
    }
};
