import {
    Badge,
    Box,
    Button,
    Card,
    Center,
    Chip,
    Container,
    Divider,
    Group,
    LoadingOverlay,
    ScrollArea,
    Stack,
    Sx,
    Tabs,
    Text,
} from "@mantine/core";
import PropRequestCard from "components/PropRequest/PropRequestCard";
import {
    PROPERTY_REQUEST_COLOR_SETTINGS,
    PROPERTY_REQUEST_TYPE,
} from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bath, Bed, Edit, Home2 } from "tabler-icons-react";
import { useProfileController } from "hooks/useProfile";
import { UserDetailModal } from "components/Modals/UserDetailModal";
import { useListingAPI } from "api/useListingAPI";

export default function MyPropRequest() {
    const { useGetMyPropRequests } = useListingAPI();
    const navigate = useNavigate();
    const activeTabs = [
        { title: "All", key: "all" },
        { title: "Ongoing", key: "ongoing" },
        { title: "Offered", key: "offered" },
        { title: "Completed", key: "completed" },
    ];
    const { user } = useAuth(Permission.USER_ONLY);
    const { profileData, refetchProfile } = useProfileController();
    const { data, isLoading, error, refetch } = useGetMyPropRequests(
        user.id.toString()
    );
    const [filteredData, setFilteredData] = useState([]);
    const [activeTab, setActiveTab] = useState(activeTabs[0].key);
    const [detailModalOpened, setDetailModalOpened] = useState(false);

    const [requestType, setRequestType] = useState([
        PROPERTY_REQUEST_TYPE.BUY,
        PROPERTY_REQUEST_TYPE.RENT,
        PROPERTY_REQUEST_TYPE.INVEST,
    ]);

    const hasFinishedDetails = useMemo(() => {
        // console.log(profileData);
        // console.log('!profileData?.dob',!profileData?.dob);
        // console.log('!profileData?.maritalStatus‰',!profileData?.maritalStatus);
        // console.log('!profileData?.nationality‰',!profileData?.nationality);
        // console.log('!profileData?.noOfChildren‰',!profileData?.noOfChildren);
        // console.log('!profileData?.occupation‰',!profileData?.occupation);
        // console.log('!profileData?.race‰',!profileData?.race);
        if (!!profileData) {
            return (
                !!profileData?.dob &&
                !!profileData?.maritalStatus &&
                !!profileData?.nationality &&
                !!profileData?.noOfChildren &&
                !!profileData?.occupation &&
                !!profileData?.race
            );
        }

        return false;
    }, [profileData, data]);

    const handleAddRequest = () => {
        if (!hasFinishedDetails && data.length === 1) {
            setDetailModalOpened(true);
        } else {
            navigate("/requests/create");
        }
    };

    const tabStyles = {
        minWidth: "80px",
        textAlign: "center",
        // borderBottom: "2px solid green",
        zIndex: 10,
        cursor: "pointer",
        paddingBottom: 8,
    } as Sx;

    // useEffect(() => {
    //     console.log(data);
    //     if(!!data && !isLoading){
    //         setFilteredData(data)
    //     }
    // }, [data]);

    useEffect(() => {
        console.log('fetched', data);
        if (!!data && !isLoading && data.length > 0) {
            setFilteredData(
                data.filter((d: any) => requestType.includes(d.transactionType))
            );
        }else{
            setFilteredData([])
        }
    }, [data, requestType]);

    return (
        <>
            <Container size="1200px" py={32} px={16}>
                <LoadingOverlay visible={isLoading} overlayBlur={2} />
                <Stack spacing={24}>
                    <Stack>
                        <Group>
                            <Chip.Group
                                multiple
                                value={requestType}
                                onChange={(e: PROPERTY_REQUEST_TYPE[]) => {
                                    setRequestType(e);
                                }}
                            >
                                <Group position="center" mt="md">
                                    <Chip
                                        variant="light"
                                        value={PROPERTY_REQUEST_TYPE.BUY}
                                        sx={{
                                            backgroundColor: `${
                                                PROPERTY_REQUEST_COLOR_SETTINGS[
                                                    PROPERTY_REQUEST_TYPE.BUY
                                                ].background
                                            } !important`,
                                            color: `${
                                                PROPERTY_REQUEST_COLOR_SETTINGS[
                                                    PROPERTY_REQUEST_TYPE.BUY
                                                ].color
                                            } !important`,
                                        }}
                                    >
                                        Buy
                                    </Chip>
                                    <Chip
                                        variant="light"
                                        value={PROPERTY_REQUEST_TYPE.RENT}
                                        sx={{
                                            backgroundColor: `${
                                                PROPERTY_REQUEST_COLOR_SETTINGS[
                                                    PROPERTY_REQUEST_TYPE.RENT
                                                ].background
                                            } !important`,
                                            color: `${
                                                PROPERTY_REQUEST_COLOR_SETTINGS[
                                                    PROPERTY_REQUEST_TYPE.RENT
                                                ].color
                                            } !important`,
                                        }}
                                    >
                                        Rent
                                    </Chip>
                                    <Chip
                                        variant="light"
                                        value={PROPERTY_REQUEST_TYPE.INVEST}
                                        sx={{
                                            backgroundColor: `${
                                                PROPERTY_REQUEST_COLOR_SETTINGS[
                                                    PROPERTY_REQUEST_TYPE.INVEST
                                                ].background
                                            } !important`,
                                            color: `${
                                                PROPERTY_REQUEST_COLOR_SETTINGS[
                                                    PROPERTY_REQUEST_TYPE.INVEST
                                                ].color
                                            } !important`,
                                        }}
                                    >
                                        Invest
                                    </Chip>
                                </Group>
                            </Chip.Group>
                        </Group>
                    </Stack>
                    <Stack spacing={8}>
                        {!!data &&
                            filteredData.length > 0 &&
                            filteredData.map((propReq: any) => (
                                <React.Fragment key={`req-${propReq.id}`}>
                                    <PropRequestCard
                                        data={propReq}
                                        controls
                                        deleteCallback={() => refetch()}
                                    />
                                </React.Fragment>
                            ))}
                        {!!data && filteredData.length === 0 && (
                            <Center>
                                <Stack align="center" spacing={8} py={32}>
                                    <Home2 size={32} />
                                    <Text>
                                        You have no Co-Agency requests currently
                                    </Text>
                                    <Button
                                        onClick={() =>
                                            navigate("/requests/create")
                                        }
                                    >
                                        Create Property Request
                                    </Button>
                                </Stack>
                            </Center>
                        )}
                    </Stack>
                </Stack>
            </Container>
            <UserDetailModal
                opened={detailModalOpened}
                onCancel={() => {
                    setDetailModalOpened((state) => !state);
                    refetchProfile();
                }}
            />
        </>
    );
}
