import { Box, Text } from '@mantine/core';
import React from 'react';

const FAQ = () => {
    const faq = [
        {
            title: "Do I need to pay?",
            content:
                "No, not at all. Other than telling us your budget, we will never ask you for any financial information. We will never email you asking for your financial information either.",
        },
        {
            title: "Is it safe?",
            content:
                "Yes, aside from certain pieces of information that you input to help you get the optimal property offers, you control all your personal data on our platform. Until you say so, your info is not available to anyone, even Propmoth. However, Agent data is always publicly available for you to check before you engage with them.",
        },
        {
            title: "I signed up, now what?",
            content:
                "First off, thank you for signing up! You can now create a property request according to your perfect requirements which states your budget, type and even style of property! Agents will then offer you property according to your details and you can then look through and select the ones you like and start talking to them to find out more!",
        },
    ];
    
    return (
        <>
            <Text
                component="h1"
                sx={{
                    fontSize: "1.5em",
                    lineHeight: 1,
                    color: "#B4E562",
                }}
            >
                Frequently asked questions
            </Text>
            {faq.map((f) => (
                <Box>
                    <Text
                        color="white"
                        fw={700}
                        sx={{ fontSize: 24, margin: 0 }}
                        component="h2"
                    >
                        {f.title}
                    </Text>
                    <Text color="white" fz="sm">
                        {f.content}
                    </Text>
                </Box>
            ))}
        </>
    );
};

export default FAQ;