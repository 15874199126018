import React, { useState } from "react";
import { Permission, useAuth } from "../../hooks/useAuth";
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Divider,
    Group,
    Stack,
    Text,
    TextInput,
    Container,
    Card,
    Avatar,
    Modal,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { USER_TYPE } from "helpers/constants";
import ChatHeaderButtons from "./ChatHeaderButtons";

export const ChatHeader: React.FC<any> = ({ ...props }) => {
    const { activeChatData, userType } = props;
    const navigate = useNavigate();
    const [acceptPopup, setAcceptPopup] = useState(false);

    const isAgent = userType === USER_TYPE.AGENT;

    const onCloseReq = () => {
        setAcceptPopup(false);
        props.onAccept();
    };

    return (
        <>
            <Group sx={{ width: "100%" }} p={16} position="apart" spacing={8}>
                <Stack spacing={0}>
                    {!!activeChatData?.offer && (
                        <Text>{activeChatData?.offer?.listing?.title}</Text>
                    )}
                    {!!activeChatData?.listing && (
                        <Text>{activeChatData?.listing?.title}</Text>
                    )}
                    {!!activeChatData?.propertyRequest && (
                        <Text>{activeChatData?.propertyRequest?.transactionType} @ {activeChatData?.propertyRequest?.area},{activeChatData?.propertyRequest?.state}</Text>
                    )}
                    <Group spacing={4}>
                        {!isAgent && <Avatar radius={"50%"} />}
                        {!isAgent ? (
                            <Text fz={12}>
                                {" "}
                                {activeChatData?.agent?.fullname}
                            </Text>
                        ) : (
                            <Text fz={12}>
                                {" "}
                                {activeChatData?.lister?.fullname}
                            </Text>
                        )}
                    </Group>
                </Stack>
                <ChatHeaderButtons
                    isAgent={isAgent}
                    activeChatData={activeChatData}
                    setAcceptPopup={setAcceptPopup}
                />
            </Group>
            <Modal
                opened={acceptPopup}
                onClose={() => {
                    setAcceptPopup(false);
                }}
                title={`Are you sure you want to accept this offer?`}
                centered
            >
                <Stack sx={{ width: "100%" }}>
                    <Text align="center" color="red">
                        This will mark your request as fulfilled, <br />
                        agents will no longer be able to see <br /> this request
                        and make offers
                    </Text>
                    <Group sx={{ width: "100%" }}>
                        <Button
                            sx={{ flex: 1 }}
                            variant="outline"
                            onClick={() => setAcceptPopup(false)}
                        >
                            Cancel
                        </Button>
                        <Button sx={{ flex: 1 }} onClick={onCloseReq}>
                            Accept
                        </Button>
                    </Group>
                </Stack>
            </Modal>
        </>
    );
};
