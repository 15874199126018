import { flattenObj } from "helpers/strapi";
import API from "./API";

export const defaultQueryFn = async ({ queryKey }: any) => {
    let requireAuth = false;

    const response = await API({
        requireAuth: !!queryKey?.[1],
        token: queryKey?.[1] || null,
    }).get<any, any>(queryKey[0]);

    return flattenObj(response.data);
};
