import API from "./API";
import { accountTypes } from "types/accountTypes";

type UserPayload = {
    email: string;
    avatar?: File | string | null;
    fullname: string;
    username: string;
    phoneNumber: string;
    password?: string;
};

type AgentDetailPayload = {
    renId: string;
    agencyName: string;
    renImg?: File | string | null;
    preferredName: string;
    localityState: string;
    localityArea: string;
};

type ListerDetailPayload = {
    dob?: Date;
    occupation?: string;
    nationality?: string;
    race?: string;
    maritalStatus?: string;
    noOfChildren?: number | undefined;
    remark?: string;
};

interface UserData extends UserPayload {
    id: number;
    userType: string;
}

export interface AgentDetailData extends UserData, AgentDetailPayload {
    detailId: number;
}

export interface UserDetailData extends UserData, ListerDetailPayload {
    detailId: number;
}

export interface updateUserDetailPayload extends ListerDetailPayload {
    detailId: number;
}

export type updateUserPayload = AgentDetailData | UserDetailData;

//functions
export const updateUser = async (data: updateUserPayload) => {
    let userPayload: UserPayload = {
        fullname: data?.fullname,
        email: data?.email,
        phoneNumber: data?.phoneNumber,
        username: data?.phoneNumber,
    };

    if(!!data.password){
        userPayload = {
            ...userPayload,
            password: data.password
        }
    }

    const userUrl = `/users/${data.id}`;

    let avatarId = null;
    if (data?.avatar && typeof data.avatar === "object") {
        const uploadFormData = new FormData();
        uploadFormData.append("files", data.avatar, (data.avatar as File).name);
        const uploadRes = await API({
            requireAuth: true,
            contentType: "multipart/form-data",
        }).post("/upload", uploadFormData);
        avatarId = uploadRes.data[0].id;
    } else if (data.avatar === null) {
        userPayload.avatar = null;
    }
    if (data?.avatar && typeof data.avatar === "object") {
        userPayload.avatar = avatarId;
    }
    const userRes = await API({ requireAuth: true }).put(userUrl, userPayload);

    if (data.userType == accountTypes.AGENT) {
        const formValues = data as AgentDetailData;
        const agentPayload: AgentDetailPayload = {
            agencyName: formValues.agencyName,
            renId: formValues.renId,
            preferredName: formValues.preferredName,
            localityState: formValues.localityState,
            localityArea: formValues.localityArea,
        };
        const agentUrl = `/agent-details/${formValues.detailId}`;
        const agentFormData = new FormData();
        if (formValues?.renImg && typeof formValues.renImg === "object") {
            agentFormData.append(
                "files.renImg",
                formValues.renImg,
                (formValues.renImg as File).name
            );
        } else if (formValues.renImg === null) {
            agentPayload.renImg = null;
        }
        agentFormData.append("data", JSON.stringify(agentPayload));
        const agentRes = await API({
            requireAuth: true,
            contentType: "multipart/form-data",
        }).put(agentUrl, agentFormData);

        return {
            ...userRes.data,
            ...agentRes.data,
        };
    } else {
        const formValues = data as UserDetailData;
        const listerPayload: ListerDetailPayload = {
            occupation: formValues?.occupation,
            nationality: formValues?.nationality,
            race: formValues?.race,
            maritalStatus: formValues?.maritalStatus,
            noOfChildren: formValues?.noOfChildren,
            dob: formValues?.dob,
            remark: formValues?.remark,
        };
        const listerUrl = `/user-details/${formValues.detailId}`;
        const listerRes = await API({ requireAuth: true }).put(listerUrl, {
            data: listerPayload,
        });
        return {
            ...userRes.data,
            ...listerRes.data,
        };
    }
};