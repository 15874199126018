import { Anchor, Avatar, Badge, Box, Button, Card, Center, CopyButton, Grid, Group, Image, Stack, Text, Title } from '@mantine/core';
import { useInsightsAPI } from 'api/useInsightsAPI';
import { useGetAgentReferral } from 'api/userAPI';
import { useProfileController } from 'hooks/useProfile';
import React, { useEffect, useMemo } from 'react';
import QRCode from "react-qr-code";
import { AgentDetail } from 'types/userTypes';

const DashboardReferral = () => {
    const { profileData } = useProfileController();
    const { useGetReferralInsights } = useInsightsAPI()
    const profile = profileData as AgentDetail;
    console.log(profile)
    const {
        data: referral,
    } = useGetAgentReferral(profileData?.agent?.id ?? null);

    const {
        data: referralInsights,
        isLoading,
        error,
    } = useGetReferralInsights();

    const referralLink = useMemo(() => {
        if (!!referral && referral.length > 0) {
            const [{ customCode, code }] = referral;
            return `${window.location.origin}/register/agent?code=${customCode ?? code}`;
        }

        return "";
    }, [referral]);

    useEffect(() => {
        console.log("referralInsights", referralInsights)
    }, [referralInsights])

    return (
        <Stack>
            <Card shadow='md' radius={16}>
                <Grid>
                    <Grid.Col md={12}>
                        <Group spacing={8} align='center' position="center">
                            <Text size={24} fw={700} lh={1}>
                                Invite your friends to join
                            </Text>
                            <Image
                                src={"/logo.svg"}
                                alt={"propmoth logo"}
                                width={148}
                                height={48}
                            />
                        </Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                        <Center sx={{ width: '100%', height: '100%' }}>
                            <QRCode value={referralLink} size={224} fgColor='#417256' />
                        </Center>
                    </Grid.Col>
                    <Grid.Col md={8}>
                        <Stack>
                            <Group>
                                <Group
                                    spacing={8}
                                    // sx={{ border: '2px solid #ddd', borderRadius: 8, alignItems: "center" }}
                                    p={4}

                                >
                                    <Avatar
                                        w={64}
                                        h={64}
                                        src={profile?.renImg?.url}
                                        styles={{
                                            image: {
                                                objectFit: 'contain'
                                            }
                                        }}
                                    />
                                    <Stack spacing={4}>
                                        <Text align='left' fw={700}>{profile?.agent?.fullname}</Text>
                                        <Text align='left'>{profile?.renId}</Text>
                                    </Stack>
                                </Group>
                                <Box
                                    sx={{
                                        // background: "#fff",
                                        border: '2px solid #ddd',
                                        borderRadius: 8,
                                        flexGrow: 1,
                                    }}
                                    p={8}
                                >
                                    <Text size="sm">{referralLink}</Text>
                                </Box>
                                <Group position='right' sx={{ width: '100%' }}>
                                    <CopyButton value={referralLink}>
                                        {({ copied, copy }) => (
                                            <Button onClick={copy}>{copied ? 'Copied url' : 'Copy url'}</Button>
                                        )}
                                    </CopyButton>
                                    <Anchor
                                        target="_blank"
                                        href={`whatsapp://send?text=Here is my referral link to propmoth! ${referralLink}`}
                                    >
                                        <Button>Share to WhatsApp</Button>
                                    </Anchor>
                                </Group>
                            </Group>
                        </Stack>
                    </Grid.Col>
                </Grid>
            </Card>
            {!!referralInsights && !!referralInsights?.referredBy && (
                <Stack>
                    <Title order={4}>Referred By</Title>
                    <Card shadow='md'>
                        <Group position='apart'>
                            <Group
                                spacing={8}
                                // sx={{ border: '2px solid #ddd', borderRadius: 8, alignItems: "center" }}
                                p={4}

                            >
                                <Avatar
                                    w={64}
                                    h={64}
                                    src={referralInsights.referredBy.user.agentDetail?.renImg?.url}
                                    styles={{
                                        image: {
                                            objectFit: 'contain'
                                        }
                                    }}
                                />
                                <Stack spacing={4}>
                                    <Text align='left' fw={700}>{referralInsights.referredBy.user.fullname}</Text>
                                    <Text align='left'>{referralInsights.referredBy?.agentDetail?.renId}</Text>
                                </Stack>
                            </Group>
                        </Group>
                    </Card>
                </Stack>
            )}
            {!!referralInsights && referralInsights?.referralCode?.length > 0 && (
                <Stack>
                    <Title order={4}>Referred Users</Title>
                    {referralInsights.referralCode.map((referral: any) => (
                        <Card shadow='md'>
                            <Group position='apart'>
                                <Group
                                    spacing={8}
                                    // sx={{ border: '2px solid #ddd', borderRadius: 8, alignItems: "center" }}
                                    p={4}

                                >
                                    <Avatar
                                        w={64}
                                        h={64}
                                        src={referral.agentDetail?.renImg?.url}
                                        styles={{
                                            image: {
                                                objectFit: 'contain'
                                            }
                                        }}
                                    />
                                    <Stack spacing={4}>
                                        <Text align='left' fw={700}>{referral.fullname}</Text>
                                        <Text align='left'>{referral.agentDetail?.renId}</Text>
                                    </Stack>
                                </Group>

                                {referral.currentPackage !== "UNSUBBED" ? (
                                    <Badge>SUBSCRIBED</Badge>
                                ) : (
                                    <Badge color="red">UNSUBSCRIBED</Badge>
                                )}
                            </Group>
                        </Card>
                    ))}
                </Stack>
            )}
        </Stack>
    );
};

export default DashboardReferral;