// @ts-nocheck
import {
    Avatar,
    BackgroundImage,
    Badge,
    Box,
    Button,
    Card,
    Center,
    Chip,
    Container,
    Divider,
    Grid,
    Group,
    LoadingOverlay,
    ScrollArea,
    Stack,
    Sx,
    Tabs,
    Text,
} from "@mantine/core";
import { useGetArticleBySlug } from "api/blogAPI";
import { BlogCard } from "components/Blog/BlogCard";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { LatestPosts } from "components/Blog/LatestPostColumn";
import { ArrowLeft } from "tabler-icons-react";
import remarkGfm from 'remark-gfm'
import { Helmet } from "react-helmet";


export default function BlogPost() {
    const navigate = useNavigate();
    const { slug } = useParams();
    const { data, error, isLoading } = useGetArticleBySlug(slug as string);

    const blogData = useMemo(() => {
        if (!!data && !isLoading) {
            return data[0];
        }

        return {
            content: "",
            title: "",
            banner: {
                url: "",
            },
            metaDescription: ""
        };
    }, [data, isLoading, slug]);

    return (
        <Container size="1200px" py={32} px={16}>
            {!!blogData.metaDescription && (
                <Helmet>
                    <meta name="description" content={blogData.metaDescription} />
                </Helmet>
            )}
            <Grid>
                <Grid.Col span={12}>
                    <Button
                        variant="subtle"
                        leftIcon={<ArrowLeft />}
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </Button>
                </Grid.Col>
                <Grid.Col md={8} sm={12}>
                    <Grid>
                        <Grid.Col md={12}>
                            <Card
                                shadow="md"
                                sx={{ border: "1px solid #ced4da" }}
                            >
                                <Stack spacing={8}>
                                    {!!blogData?.banner && (

                                        <BackgroundImage
                                            src={blogData?.banner?.url}
                                            sx={{ height: 300 }}
                                        />
                                    )}
                                    <Stack spacing={16}>
                                        <Text
                                            component="h1"
                                            my={0}
                                            lineClamp={1}
                                            fz={24}
                                        >
                                            {blogData?.title}
                                        </Text>
                                        <Box className="markdown-wrap" id={blogData.slug}>
                                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                {blogData?.content}
                                            </ReactMarkdown>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
                <Grid.Col md={4} sm={12}>
                    <LatestPosts />
                </Grid.Col>
                <Grid.Col>
                    <Divider my={16} />
                </Grid.Col>
                {/* <Grid.Col md={12}>
                    <Stack>
                        <Text component="h2" m={0}>
                            Similar Posts
                        </Text>
                        <Grid>
                            <Grid.Col md={4}>
                                <BlogCard />
                            </Grid.Col>
                            <Grid.Col md={4}>
                                <BlogCard />
                            </Grid.Col>
                            <Grid.Col md={4}>
                                <BlogCard />
                            </Grid.Col>
                        </Grid>
                    </Stack>
                </Grid.Col> */}
            </Grid>
        </Container>
    );
}
