import { Button, Card, Checkbox, Grid, Group, MultiSelect, Select, Stack, Text } from '@mantine/core';
import { NewProjectBadge, PropRequestBadge, SubsalesOkBadge, UrgentBadge } from 'components/Badges';
import LocationFilter from 'components/LocationFilter';
import { buyOptions, rentOptions } from 'helpers/constants';
import { useConfigData } from 'hooks/useConfigData';
import React, { useEffect, useState } from 'react';
import { ArrowDown, ChevronDown } from 'tabler-icons-react';

const LeadFilter = ({ dispatch, state, onFilter, filterError }: any) => {
    const [open, setOpen] = useState(true)
    const { propertyTypes } = useConfigData()

    const budgetOptions = [...rentOptions, ...buyOptions]


    useEffect(() => {
        console.log("inside state", state)
    }, [state])

    const transactionTypeIsChecked = (title: string) => {
        return state.transactionType.find(
            (transactionType: any) => transactionType === title
        );
    };

    return (
        <Card shadow='md' withBorder>
            <Stack sx={{ width: '100%', flex: 1 }}>
                <Group sx={{ flex: 1, cursor: 'pointer', width: '100%' }} onClick={() => setOpen(!open)}>
                    <Stack spacing={4} sx={{ flexGrow: 1 }}>
                        <Text fz="lg" color="#417256" fw="700">Automatic Lead Filter</Text>
                        <Text fz="sm">You will only see leads that fit what you can provide</Text>
                    </Stack>
                    <ChevronDown />
                </Group>
                {open && (
                    <Grid sx={{ width: '100%' }}>
                        <Grid.Col md={12}>
                            <LocationFilter dispatch={dispatch} state={state} />
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <Stack>
                                <MultiSelect
                                    sx={{ flex: 1 }}
                                    data={propertyTypes}
                                    label="Property Type"
                                    value={state.propertyType}
                                    onChange={(event) =>
                                        dispatch({
                                            type: "PROPERTY_TYPE_CHANGE",
                                            payload: event,
                                        })
                                    }
                                    searchable
                                />
                                <Stack spacing={2}>
                                    <Text>Budget</Text>
                                    <Group>
                                        <Select
                                            sx={{ flex: 1 }}
                                            data={budgetOptions as any}
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "BUDGET_MIN_CHANGE",
                                                    payload: event
                                                })
                                            }
                                            value={(state.minPrice)}
                                        />
                                        <Text>-</Text>
                                        <Select
                                            sx={{ flex: 1 }}
                                            data={budgetOptions as any}
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "BUDGET_MAX_CHANGE",
                                                    payload: event
                                                })
                                            }
                                            value={(state.maxPrice)}
                                        />
                                    </Group>
                                </Stack>
                            </Stack>
                        </Grid.Col>
                        {/* <Grid.Col md={4}>
                            <Stack>
                                <Stack spacing={2}>
                                    <Text>Budget</Text>
                                    <Group>
                                        <Select
                                            sx={{ flex: 1 }}
                                            data={budgetOptions as any}
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "BUDGET_MIN_CHANGE",
                                                    payload: event
                                                })
                                            }
                                            value={(state.minPrice)}
                                        />
                                        <Text>-</Text>
                                        <Select
                                            sx={{ flex: 1 }}
                                            data={budgetOptions as any}
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "BUDGET_MAX_CHANGE",
                                                    payload: event
                                                })
                                            }
                                            value={(state.maxPrice)}
                                        />
                                    </Group>
                                </Stack>
                                <Stack spacing={2}>
                                    <Text>Income Range</Text>
                                    <Group>
                                        <Select sx={{ flex: 1 }} data={[]} />
                                        <Text>-</Text>
                                        <Select sx={{ flex: 1 }} data={[]} />
                                    </Group>
                                </Stack>
                            </Stack>
                        </Grid.Col> */}
                        <Grid.Col md={6}>
                            <Stack spacing={2}>
                                <Text>Tags</Text>
                                <Group >
                                    <Stack>
                                        <Checkbox
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "NEW_PROJECTS_ONLY_CHANGE",
                                                    payload:
                                                    {
                                                        value: event.currentTarget
                                                            .checked
                                                    },
                                                })
                                            }
                                            label={<NewProjectBadge />}
                                            checked={state.newProjectsOnly}
                                        />
                                        <Checkbox
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "IS_URGENT_CHANGE",
                                                    payload:
                                                    {
                                                        value: event.currentTarget
                                                            .checked
                                                    },
                                                })
                                            }
                                            label={<UrgentBadge />}
                                            checked={state.isUrgent}
                                        />
                                        <Checkbox
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "SUBSALES_OK_CHANGE",
                                                    payload:
                                                    {
                                                        value: event.currentTarget
                                                            .checked
                                                    },
                                                })
                                            }
                                            label={<SubsalesOkBadge />}
                                            checked={state.subSalesOk}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Checkbox
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "PROPERTY_TRANSACTION_TYPE_CHANGE",
                                                    payload:
                                                        "BUY",
                                                    include:
                                                        event.currentTarget
                                                            .checked,
                                                })
                                            }
                                            label={<PropRequestBadge propRequestType='BUY' />}
                                            checked={transactionTypeIsChecked('BUY')}
                                        />
                                        <Checkbox
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "PROPERTY_TRANSACTION_TYPE_CHANGE",
                                                    payload:
                                                        "RENT",
                                                    include:
                                                        event.currentTarget
                                                            .checked,
                                                })
                                            }
                                            label={<PropRequestBadge propRequestType='RENT' />}
                                            checked={transactionTypeIsChecked('RENT')}
                                        />
                                        <Checkbox
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "PROPERTY_TRANSACTION_TYPE_CHANGE",
                                                    payload:
                                                        "INVEST",
                                                    include:
                                                        event.currentTarget
                                                            .checked,
                                                })
                                            }
                                            label={<PropRequestBadge propRequestType='INVEST' />}
                                            checked={transactionTypeIsChecked('INVEST')}
                                        />
                                    </Stack>
                                </Group>
                            </Stack>
                        </Grid.Col>
                        <Grid.Col md={12}>
                            <Group position='right' sx={{ width: '100%' }}>
                                <Stack spacing={8} align='flex-end'>
                                    <Button onClick={() => onFilter()}>Save and filter</Button>
                                    {!!filterError && (
                                        <Text color="red">{filterError}</Text>
                                    )}
                                </Stack>
                            </Group>
                        </Grid.Col>
                    </Grid>
                )}
            </Stack>
        </Card>
    );
};

export default LeadFilter;