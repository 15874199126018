import { Avatar, BackgroundImage, Box, Button, Card, Container, Grid, Group, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useListingAPI } from 'api/useListingAPI';
import PageLoader from 'components/LoadingStates/PageLoader';
import TransactionTypeBadge from 'components/TransactionTypeBadge';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const AgentPublicProfile = () => {
    const { slug } = useParams();
    const navigate = useNavigate()
    const theme = useMantineTheme();
    const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const { useGetAgentPublicProile } = useListingAPI()
    const { data, isLoading, error } = useGetAgentPublicProile({ slug })


    const formattedListings = useMemo(() => {
        if (!data?.listings || data?.listings.length === 0) {
            return []
        }
        console.log("data.listings", data.listings)



        const listingsArr = data.listings.map((r: any) => {
            let thumbnail

            if (r?.imageLinks.length > 0) {
                thumbnail = {
                    url: r?.imageLinks[0]
                }
            }

            if (!!r?.images && r?.images.length > 0) {
                thumbnail = r?.images[0]
            }

            // const imageArr = r?.images[0] || {
            //     url: r?.imageLinks[0]
            // }

            return {
                id: r?.id,
                title: r?.title,
                image: thumbnail,
                price: r?.price,
                area: r?.area,
                state: r?.state,
                propertyType: r?.propertyType,
                transactionType: r.transactionType
            }
        })
        console.log("listingsArr", listingsArr)

        return listingsArr.filter((d: any) => !!d.image)
    }, [data])

    const agentDetail = useMemo(() => {
        if (!data?.agentDetail) {
            return {}
        }

        return data.agentDetail
    }, [data])

    if (!!isLoading) {
        return <>
            <PageLoader title="Loading Profile" />
        </>
    }


    return (
        <Box>
            <Container size={'1200px'} py={32}>
                <Stack spacing={32}>
                    <Card
                        sx={{
                            border: '1px solid #417256'
                        }}
                        shadow='md'
                        radius={16}
                    >
                        <Group sx={{ alignItems: 'flex-start' }}>
                            <Avatar
                                w={isTablet ? 60 : 120}
                                h={isTablet ? 60 : 120}
                                src={agentDetail.renImg?.url}
                                styles={{
                                    image: {
                                        objectFit: 'contain'
                                    }
                                }}
                            />
                            <Stack spacing={4} sx={{ flex: 1 }}>
                                <Text size={24} fw="bold">{agentDetail.preferredName} <Text component='span' size={'md'} fw="600">({agentDetail.renId})</Text></Text>
                                <Text>{agentDetail.agencyName}</Text>
                            </Stack>
                            <Text size={14}>joined since {moment(agentDetail?.joinedAt).format("YYYY")}</Text>
                        </Group>
                    </Card>
                    <Grid>
                        {formattedListings.length === 0 && (
                            <Grid.Col span={12}>
                                <Text fz={18} fw='bold' align='center'>No public listings yet</Text>
                            </Grid.Col>
                        )}
                        {formattedListings.map((d: any, i: number) => (
                            <Grid.Col sm={6} md={4}>
                                <Card
                                    withBorder
                                    shadow='lg'
                                    radius={16}
                                    w="100%"
                                    key={`landing-propreq-${i}`}
                                >
                                    <Stack>
                                        <BackgroundImage
                                            src={d.image.url}
                                            w={'100%'}
                                            h={250}
                                            radius={8}
                                        />
                                        <Stack spacing={4} align='flex-start'>
                                            <TransactionTypeBadge transactionType={d.transactionType} />
                                            <Title order={4} lineClamp={1}>{d.title}</Title>
                                            <Text>{d.area}, {d.state}</Text>
                                            <Title order={2}>RM{d.price}</Title>
                                        </Stack>
                                        <Button onClick={() => navigate(`/listings/${d.id}`)} size='lg'>See More</Button>
                                    </Stack>
                                </Card>
                            </Grid.Col>
                        ))}
                    </Grid>
                </Stack>
            </Container>
        </Box>
    );
};

export default AgentPublicProfile;