import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
import { Permission, useAuth } from "hooks/useAuth";
const qs = require("qs");

export const JOIN_PROMO = "JOIN_PROMO";
export const GET_PROMO = "GET_PROMO";

export const usePromoAPI = () => {
    const { user } = useAuth(Permission.USER_ONLY);

    const useGetCampaign = (payload: any) =>
        useQuery([GET_PROMO, payload], async (payload: any) => {
            const query = qs.stringify(
                {
                    filters: {
                        uid: payload.slug,
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            try {
                const response = await API().get<any, any>(
                    `campaigns?${query}`
                );

                if (response.data.data.length === 1) {
                    return flattenObj(response.data.data[0]);
                }

                return false;
            } catch (e) {
                return e;
            }
        });

    const useJoinPromo = () =>
        useMutation(async (payload: any) => {
            let apiSetting = {};

            if (!!user.jwt) {
                apiSetting = {
                    requireAuth: true,
                    token: user.jwt,
                };
            }

            const resp = await API(apiSetting).put<any, any>(`campaigns/${payload.id}`, {
                data: {
                    participants: {
                        connect: [payload.userId],
                    },
                },
            });

            return flattenObj(resp);
        });
    return {
        useGetCampaign,
        useJoinPromo,
    };
};
