import React, { useState, useEffect, useMemo } from "react";
import { Box, Container, UnstyledButton, Avatar, Text } from "@mantine/core";
import moment from "moment";
import { DATE_DISPLAYS } from "helpers/constants";
import { Notification } from "./Notification";
import { Permission, useAuth } from "hooks/useAuth";
import { useGetNotificationCount, useGetNotifications } from "api/userAPI";

export const NotificationPage: React.FC<any> = ({ }) => {
    const { user, logout } = useAuth(Permission.ANYONE);
    const { data: notificationCountData, refetch: refetchCount } =
        useGetNotificationCount({
            id: user.id ?? null,
            jwt: user.jwt ?? null
        });

    const notificationCount = useMemo(() => {
        return notificationCountData?.length ?? 0;
    }, [notificationCountData]);
    const {
        data: notificationData,
        error: notificationError,
        isLoading: notificationIsLoading,
        refetch: refetchNoti,
    } = useGetNotifications({
        id: user.id ?? null,
        jwt: user.jwt ?? null
    });

    return (
        <Box>
            <Container size="1200px" py={16}>
                <Notification
                    notificationData={notificationData}
                    newCount={notificationCount}
                    refetch={() => {
                        refetchCount();
                        refetchNoti();
                    }}
                />
            </Container>
        </Box>
    );
};
