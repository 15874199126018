import {
    Container,
    Box,
    Grid,
    Flex,
    Text,
    Divider,
    Button,
    useMantineTheme,
    Image,
    Stack,
    Anchor,
    Group,
    Card
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { IconCoin } from "@tabler/icons-react";
import { useState } from "react";
import { usePurchaseToken } from "../api/tokenAPI";
import { Permission, useAuth } from "hooks/useAuth";
import { notifications } from "@mantine/notifications";
import { tokenData, USER_TYPE } from "helpers/constants";
import { useWallet } from "hooks/useWallet";
import TokensBanner from "components/Banners/TokensBanner";
import { useGetConfigs } from "api/configAPI";
import { useConfigData } from "hooks/useConfigData";

export default function PurchaseToken() {

    const navigate = useNavigate();
    const { user } = useAuth(Permission.ANYONE);
    const { walletData, walletIsLoading, walletError } = useWallet(
        !!user.jwt && user.userType === USER_TYPE.AGENT
            ? user.id.toString()
            : null
    );

    const [selectedAmount, setSelectedAmount] = useState({
        amount: 0,
        tokenAmount: 0,
    });
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const purchaseToken = usePurchaseToken();
    const { tokenPackages } = useConfigData()

    const onClickTile = (tokenAmount: number, amount: number) => {
        setSelectedAmount({ tokenAmount, amount });
    };

    const oneClickBuy = async (payload: any) => {
        const redirect = await purchaseToken.mutateAsync({
            user: user.id,
            ...payload
        });

        if (!!redirect.url) {
            window.location.href = redirect.url;
        } else {
            notifications.show({
                title: "Oops, something went wrong",
                message:
                    "Please try again, if this issue persists please contact our customer service for help",
            });
        }
    }

    const buyToken = async () => {
        const redirect = await purchaseToken.mutateAsync({
            user: user.id,
            ...selectedAmount,
        });
        if (!!redirect.url) {
            window.location.href = redirect.url;
        } else {
            notifications.show({
                title: "Oops, something went wrong",
                message:
                    "Please try again, if this issue persists please contact our customer service for help",
            });
        }
    };

    // const buyTokenPayex = async () => {
    //     const redirect = await purchaseToken.mutateAsync({
    //         ...selectedAmount
    //     });
    //     if (!!redirect.url) {
    //         window.location.href = redirect.url;
    //         console.log(redirect);
    //     } else {
    //         notifications.show({
    //             title: "Oops, something went wrong",
    //             message:
    //                 "Please try again, if this issue persists please contact our customer service for help",
    //         });
    //     }
    // };

    return (
        <Box>
            <Container size="1200px">
                <Stack>
                    {/* <TokensBanner /> */}
                    <Box py={32}>
                        <Grid m={0} p={0} pb={32}>
                            <Grid.Col span={6} p={0}>
                                <Text
                                    weight={600}
                                    size={isMobile ? 20 : 36}
                                    sx={{
                                        lineHeight: "44px",
                                        ...(isMobile && { LineHeight: "30px" }),
                                    }}
                                >
                                    Buy tokens
                                </Text>
                            </Grid.Col>
                            <Grid.Col my={"auto"} span={6} p={0}>
                                {!!user.jwt &&
                                    user.userType === USER_TYPE.AGENT && (
                                        <Flex align={"center"} justify={"flex-end"}>
                                            <Text
                                                mr={4}
                                                size={20}
                                                weight={500}
                                                sx={{ lineHeight: "30px" }}
                                            >
                                                Your tokens:
                                            </Text>
                                            {/* <IconCoin
                                            width={18}
                                            height={18}
                                            style={{ marginRight: "4px" }}
                                        /> */}
                                            <Image
                                                src={"/token.png"}
                                                height={18}
                                                width={18}
                                                style={{ marginRight: "4px" }}
                                            />
                                            <Text
                                                mr={4}
                                                size={20}
                                                weight={500}
                                                sx={{ lineHeight: "30px" }}
                                            >
                                                {walletData?.amount ?? 0}
                                            </Text>
                                        </Flex>
                                    )}
                            </Grid.Col>
                        </Grid>
                        <Divider my="sm" pb={32} m={"0 !important"} w={"100%"} />
                        <Stack>
                            <Card
                                sx={(theme) => ({
                                    backgroundColor: "#5A9D66",
                                    padding: 24,
                                    position: 'relative',
                                    borderRadius: 16,
                                    // [theme.fn.smallerThan("md")]: {
                                    //     paddingBottom: 200,
                                    // }
                                })}
                                shadow="lg"
                            >
                                <Grid
                                    sx={(theme) => ({
                                        zIndex: 10,
                                        position: 'relative',

                                    })}
                                >
                                    <Grid.Col md={4} sm={12}>
                                        <Group sx={{ width: '100%' }} position="center">
                                            <Image
                                                sx={(theme) => ({
                                                    zIndex: 10,
                                                    [theme.fn.smallerThan("md")]: {
                                                        width: "80% !important"
                                                    },
                                                    cursor: 'pointer'
                                                })}
                                                src="/package-card.png"
                                                onClick={() => oneClickBuy({
                                                    amount: 49.99,
                                                    tokenAmount: 400,
                                                    packageKey: "WELCOME_PACKAGE"
                                                })}
                                            />
                                        </Group>
                                    </Grid.Col>
                                    <Grid.Col md={5}>
                                        <Stack
                                            justify="center"
                                            sx={{
                                                height: '100%',
                                                background: "rgba(255,255,255,0.7)",
                                                padding: 24,
                                                borderRadius: 24,
                                                border: "1px solid #000"
                                            }}
                                        >
                                            <Group position="center">
                                                <Image
                                                    sx={{
                                                        cursor: "pointer"
                                                    }}
                                                    src={"/logo.svg"}
                                                    alt={"propmoth logo"}
                                                    width={148}
                                                    height={48}
                                                />
                                            </Group>
                                            <Text align="center" fw="700" fz={32}>Welcome Package</Text>
                                            <Text align="center" fw="700">With 2 Tokens, You can make an offer to a requester and wait for them to respond to it. </Text>
                                            <Text align="center" fw="700">With 5 Tokens, You can immediately start a chat with the requester and offer them multiple properties upfront.</Text>
                                            <Text sx={{ color: "#C70000" }} align="center">If you don’t get a response, your token will be fully refunded!</Text>
                                            <Stack
                                                sx={{
                                                    border: "1px solid #000",
                                                    borderRadius: 16,
                                                    padding: 8
                                                }}
                                            >
                                                <Text align="center" fw="700">Tokens are used for:</Text>
                                                <Flex
                                                    sx={(theme) => ({
                                                        // flexDirection: 'column',
                                                        flexDirection: 'row',
                                                        // [theme.fn.smallerThan("md")]: {
                                                        //     flexDirection: 'row'
                                                        // },
                                                        [theme.fn.smallerThan("sm")]: {
                                                            flexDirection: 'column',
                                                            alignItems: 'center'
                                                        },
                                                        gap: 8
                                                    })}
                                                >
                                                    <Button
                                                        variant="outline"
                                                        sx={(theme) => ({
                                                            background: "#fff",
                                                            flex: 1,
                                                            [theme.fn.smallerThan("sm")]: {
                                                                flex: "auto",
                                                            },
                                                        })}
                                                    >
                                                        <Group spacing={8}>
                                                            Start Chat
                                                            <Group spacing={4} align="center">
                                                                <Text>5</Text>
                                                                <Image
                                                                    src={"/token.png"}
                                                                    height={16}
                                                                    width={16}
                                                                    style={{ marginRight: "4px" }}
                                                                />
                                                            </Group>
                                                        </Group>
                                                    </Button>
                                                    <Button
                                                        variant="outline"
                                                        sx={(theme) => ({
                                                            background: "#fff",
                                                            flex: 1,
                                                            [theme.fn.smallerThan("sm")]: {
                                                                flex: "auto",
                                                            },
                                                        })}
                                                    >
                                                        <Group spacing={8}>
                                                            Make Offer
                                                            <Group spacing={4} align="center">
                                                                <Text>2</Text>
                                                                <Image
                                                                    src={"/token.png"}
                                                                    height={16}
                                                                    width={16}
                                                                    style={{ marginRight: "4px" }}
                                                                />
                                                            </Group>
                                                        </Group>
                                                    </Button>
                                                </Flex>
                                            </Stack>
                                        </Stack>
                                    </Grid.Col>

                                </Grid>
                                <Image
                                    sx={(theme) => ({
                                        position: 'absolute',
                                        right: 0,
                                        bottom: 0,
                                        maxWidth: '45%',
                                        zIndex: 1,
                                        [theme.fn.smallerThan("md")]: {
                                            maxWidth: '60%',
                                        }
                                    })}
                                    src="/height-map-guy.png"
                                />
                            </Card>
                            <Grid m={0} p={0}>
                                {tokenPackages.map((data: any) => (
                                    <Grid.Col md={3} xs={12}>
                                        <Stack
                                            sx={{
                                                padding: 24,
                                                borderRadius: 24,
                                                backgroundColor: "#F8F9FA",
                                                border: "1px solid #BFC8C3",
                                                cursor: "pointer",
                                                ...(selectedAmount.tokenAmount ===
                                                    data.tokenAmount && {
                                                    border: "2px solid #212922",
                                                    backgroundColor: "#EAEDEB",
                                                }),
                                                ":hover": {
                                                    border: "2px solid #212922",
                                                    backgroundColor: "#EAEDEB",
                                                },
                                                height: '100%'
                                            }}
                                            onClick={() => {
                                                onClickTile(
                                                    data.tokenAmount,
                                                    parseFloat(data.price)
                                                )
                                            }}
                                            justify="space-between"
                                        >
                                            <Stack align="center">
                                                <Image
                                                    src={"/token.png"}
                                                    height={64}
                                                    width={64}
                                                    style={{ marginRight: "4px" }}
                                                />

                                                <Text
                                                    weight={500}
                                                    size={24}
                                                    fw="700"
                                                    sx={{ lineHeight: "20px" }}
                                                >
                                                    {`${data.tokenAmount} ${data.tokenAmount > 1
                                                        ? "Tokens"
                                                        : "Token"
                                                        }`}
                                                </Text>
                                            </Stack>
                                            <Stack align="center" spacing={4}>
                                                {data.saveAmount !== "" && (
                                                    <Text
                                                        weight={500}
                                                        size={16}
                                                        color="#F14950"
                                                        sx={{ lineHeight: "20px" }}
                                                    >
                                                        Save {data.saveAmount}
                                                    </Text>
                                                )}
                                                <Text
                                                    weight={500}
                                                    size={24}
                                                    sx={{ lineHeight: "20px" }}
                                                >
                                                    RM {data.price}
                                                </Text>
                                            </Stack>
                                        </Stack>
                                    </Grid.Col>))}
                                {/* {tokenPackages.map((data: any, index: number) => (
                                    <Grid.Col
                                        key={index}
                                        m={0}
                                        px={24}
                                        py={16}
                                        mb={16}
                                        onClick={() => {
                                            onClickTile(
                                                data.tokenAmount,
                                                parseFloat(data.price)
                                            )
                                            // onClickTile(
                                            //     data.tokenAmount,
                                            //     data.apiPrice
                                            // )
                                        }}
                                        sx={{
                                            border: "1px solid #BFC8C3",
                                            backgroundColor: "#FFFFFF",
                                            // backgroundColor: "#DDD",
                                            borderRadius: "12px",
                                            cursor: "pointer",
                                            ...(selectedAmount.tokenAmount ===
                                                data.tokenAmount && {
                                                border: "2px solid #212922",
                                                backgroundColor: "#EAEDEB",
                                            }),
                                            ":hover": {
                                                border: "2px solid #212922",
                                                backgroundColor: "#EAEDEB",
                                            }
                                        }}
                                    >
                                        <Grid
                                            m={0}
                                            p={0}
                                            align={"center"}
                                            justify={"center"}
                                        >
                                            <Grid.Col span={6} p={0}>
                                                <Flex align={"center"}>
                                                    <Image
                                                        src={"/token.png"}
                                                        height={32}
                                                        width={32}
                                                        mr={8}
                                                    />
                                                    <Flex direction={"column"}>
                                                        <Text
                                                            weight={500}
                                                            size={14}
                                                            sx={{ lineHeight: "20px" }}
                                                        >
                                                            {`${data.tokenAmount} ${data.tokenAmount > 1
                                                                ? "Tokens"
                                                                : "Token"
                                                                }`}
                                                        </Text>
                                                        <Text
                                                            size={14}
                                                            sx={{ lineHeight: "20px" }}
                                                        >
                                                            RM {data.pricePerLead} /
                                                            lead
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            </Grid.Col>
                                            <Grid.Col span={6} p={0}>
                                                <Flex
                                                    direction={"column"}
                                                    align={"flex-end"}
                                                    justify={"center"}
                                                >
                                                    <Text
                                                        weight={500}
                                                        size={14}
                                                        sx={{ lineHeight: "20px" }}
                                                    >
                                                        RM {data.price}
                                                    </Text>
                                                    {data.saveAmount !== "" && (
                                                        <Text
                                                            weight={500}
                                                            size={14}
                                                            color="#F14950"
                                                            sx={{ lineHeight: "20px" }}
                                                        >
                                                            Save RM {data.saveAmount}
                                                        </Text>
                                                    )}
                                                </Flex>
                                            </Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                ))} */}
                            </Grid>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
            <Divider my="sm" m={"0 !important"} w={"100%"} />
            <Box
                sx={{
                    backgroundColor: "#FFFFFF",
                    position: "sticky",
                    bottom: 0,
                }}
            >
                <Container size="1200px">
                    <Grid justify="space-between" align="center" py={32} m={0} sx={{ gap: 8 }}>
                        <Box>
                            <Anchor href="/refundpolicy">
                                <Text size={'xs'}>
                                    Refund Policy
                                </Text>
                            </Anchor>
                        </Box>
                        <Button
                            onClick={() => buyToken()}
                            // disabled={true}
                            disabled={
                                !selectedAmount.amount ||
                                !selectedAmount.tokenAmount ||
                                user.userType !== USER_TYPE.AGENT
                            }
                            h={44}
                            radius={"8px"}
                            w={isMobile ? "100%" : "auto"}
                            sx={{
                                padding: "10px 32px",
                                backgroundColor: "#294936",
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        >
                            Buy Token
                        </Button>
                    </Grid>
                </Container>
            </Box>
        </Box >
    );
}
