export const defaultFilters = {
    propertyType: [],
    transactionType: [],
    tags: [],
    state: [],
    area: [],
    minSquareFt: "",
    maxSquareFt: "",
    minPrice: "",
    maxPrice: "",
    isUrgent: false,
    newProjectsOnly: false,
    subSalesOk: false,
};

export const addUrl = (state: any) => {
    if (state.url) {
        delete state.url;
    }
    let andArr = [
        {
            status: {
                $eq: "ACTIVE",
            },
        },
    ] as any[];

    let orArr = [] as any[];
    let priceAndArr = [] as any[];
    let incomeAndArr = [] as any[];

    Object.entries(state).forEach(([key, value]) => {
        if (!!value) {
            switch (key) {
                case "subSalesOk": {
                    andArr.push({
                        subSalesOk: true,
                    });
                    break;
                }
                case "newProjectsOnly": {
                    andArr.push({
                        newProjectsOnly: true,
                    });
                    break;
                }
                case "isUrgent": {
                    andArr.push({
                        isUrgent: true,
                    });
                    break;
                }
                case "noOfBedrooms": {
                    // let price = urlObj[0].$and.
                    if (parseInt(value as string) === 1) {
                        andArr.push({
                            [`noOfBedrooms`]: {
                                $gte: value,
                            },
                        });
                    } else {
                        andArr.push({
                            [`noOfBedrooms`]: {
                                $lte: value,
                            },
                        });
                    }
                    break;
                }
                case "noOfBathrooms": {
                    if (value === 1) {
                        andArr.push({
                            [`noOfBathrooms`]: {
                                $gte: value,
                            },
                        });
                    } else {
                        andArr.push({
                            [`noOfBathrooms`]: {
                                $lte: value,
                            },
                        });
                    }
                    break;
                }
                case "maxSquareFt": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        [`maxSquareFt`]: {
                            $lte: value,
                        },
                    });
                    break;
                }
                case "minSquareFt": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        [`minSquareFt`]: {
                            $gte: value,
                        },
                    });
                    break;
                }
                case "maxPrice": {
                    // let price = urlObj[0].$and.

                    andArr.push({
                        $or: [
                            {
                                [`budgetMax`]: {
                                    $lte: value,
                                },
                            },
                            {
                                [`maxIncomeRange`]: {
                                    $lte: value,
                                },
                            },
                        ],
                    });
                    break;
                }
                case "minPrice": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        $or: [
                            {
                                [`budgetMin`]: {
                                    $gte: value,
                                },
                            },
                            {
                                [`minIncomeRange`]: {
                                    $gte: value,
                                },
                            },
                        ],
                    });
                    break;
                }
                case "propertyType": {
                    let _value = value as any[];
                    // let price = urlObj[0].$and.
                    if (_value.length > 0) {
                        andArr.push({
                            [`${key}`]: {
                                $in: value,
                            },
                        });
                    }
                    break;
                }
                case "transactionType": {
                    let _value = value as any[];
                    // let price = urlObj[0].$and.
                    if (_value.length > 0) {
                        orArr.push({
                            [`${key}`]: {
                                $in: value,
                            },
                        });
                    }
                    break;
                }
                case "tags": {
                    let tagArr = value as any[];
                    // console.log('tags value',value)
                    tagArr.forEach((tag: any) => {
                        andArr.push({
                            tags: tag,
                        });
                    });
                    break;
                }
                default:
                    andArr.push({
                        [`${key}`]: {
                            $in: value,
                        },
                    });
                    break;
            }
        }
    });

    return {
        $and: {
            ...andArr,
            // $or: [
            //     {
            //         $and: priceAndArr,
            //     },
            //     {
            //         $and: incomeAndArr,
            //     },
            // ],
        },
        $or: orArr,
    };
};

export function reducer(state: any, action: any) {
    try {
        let newState = {} as any;
        if (!!state.url) {
            delete state.url;
        }

        switch (action.type) {
            case "NEW_PROJECTS_ONLY_CHANGE": {
                newState = {
                    ...state,
                    newProjectsOnly: action.payload.value,
                };
                break;
            }
            case "SUBSALES_OK_CHANGE": {
                newState = {
                    ...state,
                    subSalesOk: action.payload.value,
                };
                break;
            }
            case "IS_URGENT_CHANGE": {
                newState = {
                    ...state,
                    isUrgent: action.payload.value,
                };
                break;
            }
            case "LOCATION_SELECT_CHANGE": {
                newState = {
                    ...state,
                    ["state"]: action.payload.state,
                    area: action.payload.area,
                };
                break;
            }
            case "STATE_SELECT_CHANGE": {
                newState = {
                    ...state,
                    ["state"]: action.payload,
                };
                break;
            }
            case "AREA_SELECT_CHANGE": {
                newState = {
                    ...state,
                    area: action.payload,
                };
                break;
            }
            case "BUDGET_MIN_CHANGE": {
                if (parseInt(action.payload) > parseInt(state.maxPrice)) {
                    newState = {
                        ...state,
                        minPrice: action.payload,
                        maxPrice: state.minPrice,
                    };
                } else {
                    newState = {
                        ...state,
                        minPrice: action.payload,
                    };
                }

                break;
            }
            case "BUDGET_MAX_CHANGE": {
                if (parseInt(action.payload) < parseInt(state.minPrice)) {
                    newState = {
                        ...state,
                        minPrice: action.payload,
                        maxPrice: state.minPrice,
                    };
                } else {
                    newState = {
                        ...state,
                        maxPrice: action.payload,
                    };
                }
                break;
            }
            case "PROPERTY_TYPE_CHANGE": {
                // let statePropertyType = state.propertyType ?? [];
                // if (!action.include) {
                //     statePropertyType = statePropertyType.filter(
                //         (f: any) => f !== action.payload
                //     );
                // } else {
                //     statePropertyType = [...statePropertyType, action.payload];
                // }

                newState = {
                    ...state,
                    propertyType: action.payload,
                };
                break;
            }
            case "PROPERTY_TRANSACTION_TYPE_CHANGE": {
                let stateTransactionType = state.transactionType ?? [];
                if (!action.include) {
                    stateTransactionType = stateTransactionType.filter(
                        (f: any) => f !== action.payload
                    );
                } else {
                    stateTransactionType = [
                        ...stateTransactionType,
                        action.payload,
                    ];
                }

                newState = {
                    ...state,
                    transactionType: stateTransactionType,
                };
                break;
            }
            case "BATHROOM_CHANGE": {
                newState = {
                    ...state,
                    noOfBathrooms: action.payload,
                };
                break;
            }
            case "BEDROOM_CHANGE": {
                newState = {
                    ...state,
                    noOfBedrooms: action.payload,
                };
                break;
            }
            case "MIN_SQFT_CHANGE": {
                newState = {
                    ...state,
                    minSquareFt: action.payload,
                };
                break;
            }
            case "MAX_SQFT_CHANGE": {
                newState = {
                    ...state,
                    maxSquareFt: action.payload,
                };
                break;
            }
            case "TAG_CHANGE": {
                let selectArr = state.tags ?? [];
                if (!action.include) {
                    selectArr = state.tags.filter(
                        (f: any) => f.value !== action.payload
                    );

                    newState = {
                        ...state,
                        tags: selectArr,
                    };
                    break;
                }

                newState = {
                    ...state,
                    tags: [
                        ...state.tags,
                        {
                            value: action.payload,
                        },
                    ],
                };
                break;
            }
            case "RESET": {
                newState = defaultFilters;
                break;
            }
            case "SET_CUSTOM_FILTERS": {
                newState = {
                    ...state,
                    ...action.payload,
                };
                break;
            }
        }

        return newState;
    } catch (e) {
        throw Error("Unknown action: " + action.type);
    }
}
