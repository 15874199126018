import { 
    Box 
} from "@mantine/core"

type SectionContainerProps = {
    children?: React.ReactNode
}

export const SectionContainer: React.FC<SectionContainerProps> = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                borderBottom: `1px solid #BFC8C3`,
            }}
            py={24}
        >
            {children}
        </Box>
    )
}