import { ActionType } from 'types/systemTypes';
import { UserType } from 'types/userTypes';
import { createContext, Dispatch } from 'react';
import { USER_ACTIONS } from './userActions';
import { userInitialState } from './userReducer';

export const UserContext = createContext<UserType>(userInitialState);

export const UserDispatchContext = createContext<Dispatch<ActionType<USER_ACTIONS, UserType | null>>>(
  {} as Dispatch<ActionType<USER_ACTIONS, UserType | null>>
);
