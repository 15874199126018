import { Card, Group, Skeleton, Stack } from '@mantine/core';
import React from 'react';

const PropRequestCardSkeleton = () => {
    return (
        <Card
            sx={{
                border: "1px solid #BFC8C3",
            }}
        >
            <Stack>
                <Skeleton height={24} w={"80%"} radius="xl" />
                <Skeleton height={8} w={"25%"} radius="xl" />
                <Stack spacing={8}>
                    <Skeleton height={16} w={"100%"} radius="xl" />
                    <Skeleton height={16} w={"100%"} radius="xl" />
                    <Skeleton height={16} w={"100%"} radius="xl" />
                    <Skeleton height={16} w={"100%"} radius="xl" />
                </Stack>

                <Skeleton height={32} w={"100%"} radius="md" />
                <Group position="right" w={'100%'}>
                    <Stack w="50%">
                        <Group>
                            <Skeleton height={32} sx={{ flex: 1 }} />
                            <Skeleton height={32} sx={{ flex: 1 }} />
                        </Group>
                        <Skeleton height={32} sx={{ width: '100%' }} />
                    </Stack>
                </Group>
            </Stack>
        </Card>
    );
};

export default PropRequestCardSkeleton;