// @ts-nocheck

import { ActionIcon, Button, Center, Divider, Group, Image as MantineImage, Stack, Text, TextInput } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { convertFileToPreview } from 'helpers/utility';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Files, Plus, Trash } from 'tabler-icons-react';
import { Stage, Layer, Star, Text as KonvaText, Image as KonvaImage, Rect } from 'react-konva';
import { dataUrlToFile } from 'helpers/utility';
import { Permission, useAuth } from 'hooks/useAuth';
import Konva from 'konva';
import { handleWatermarkImage } from 'helpers/generateWatermark';

const URLImage = (props) => {
    const [image, setImage] = useState(null);
    const imageRef = useRef(null);

    const loadImage = () => {
        const newImage = new window.Image();
        newImage.src = props.src;
        newImage.addEventListener('load', handleLoad);
        setImage(newImage);
    };

    const handleLoad = () => {
        setImage(imageRef.current);
    };

    useEffect(() => {
        if (!!image) {
            props.handleLoaded(true);
        }
    }, [image])

    useEffect(() => {
        loadImage();
        return () => {
            if (imageRef.current) {
                imageRef.current.removeEventListener('load', handleLoad);
            }
        };
    }, [props.src]);

    return (
        <KonvaImage
            x={props.x}
            y={props.y}
            image={image}
            ref={imageRef}
            height={props.height ?? null}
            width={props.width ?? null}
            opacity={props.opacity ?? null}
        />
    );
}

async function createImage(imgSrc) {
    return new Promise((resolve, reject) => {
        const newImg = new Image();

        newImg.onload = () => {
            resolve(newImg);
        };

        newImg.onerror = (error) => {
            reject(error);
        };

        newImg.src = imgSrc;
    });
}


const Watermark = ({ obj, url, setWatermarked }: any) => {
    const {
        name,
        size: { width, height }
    } = obj
    const { user } = useAuth(Permission.USER_ONLY)
    const stageRef = React.useRef(null);
    const [imageLoaded, setImageLoaded] = useState(false)
    const [logoLoaded, setLogoLoaded] = useState(false)


    const opacity = 0.9

    const markHeight = 100
    const markWidth = width / 2

    const x = width - markWidth
    const y = height / 2 - (markHeight / 2)

    const logoHeight = markHeight - 30
    const logoWidth = markHeight - 30

    const logoX = x + 16
    const logoY = height / 2 - (logoWidth / 2)

    const nameTextX = logoX + logoWidth + 16
    const nameTextY = y + 24

    const phoneTextX = nameTextX
    const phoneTextY = nameTextY + 24 + 4

    const handleExport = async () => {
        const uri = stageRef.current.toDataURL();
        const watermarkedFile = await dataUrlToFile(uri, `watermarked-${new Date().getTime()}`)
        setWatermarked(
            {
                base64: uri,
                size: {
                    height,
                    width
                }
            },
            watermarkedFile,
            name
        )
    };

    useEffect(() => {
        if (!!stageRef.current && !!imageLoaded && !!logoLoaded) {
            handleExport()
        }


    }, [stageRef, imageLoaded, logoLoaded])


    return (
        <Stage width={width} height={height} ref={stageRef}>
            <Layer>
                <URLImage src={url} x={0} y={0} handleLoaded={setImageLoaded} />
                <URLImage
                    src={"./propmoth-black-circle.png"}
                    x={logoX}
                    y={logoY}
                    width={logoWidth}
                    height={logoHeight}
                    opacity={opacity}
                    handleLoaded={setLogoLoaded}
                />
                <KonvaText fill={"rgb(255,255,255)"} text={user.fullname} fontSize={24} x={nameTextX} y={nameTextY} />
                <KonvaText fill={"rgb(255,255,255)"} text={user.username} fontSize={24} x={phoneTextX} y={phoneTextY} />

                <Rect
                    x={x}
                    y={y}
                    width={markWidth}
                    height={markHeight}
                    fill="rgb(0,0,0,0.7)"
                    opacity={opacity}
                />
            </Layer>
        </Stage>
    )
}

interface ImageInputProps {
    importImages: any[],
    files: any[],
    base64Preview: any[],
    existingImages?: any[],
    removedImages?: any[],
    setImportImages: any,
    setFiles: any,
    setBase64Preview: any,
    setRemovedImages?: any
    imagesError: boolean
}

const ImageInput = ({
    importImages,
    files,
    base64Preview,
    existingImages,
    removedImages,
    setImportImages,
    setFiles,
    setBase64Preview,
    setRemovedImages,
    imagesError
}: ImageInputProps) => {
    // const [importImages, setImportImages] = useState<string[]>([]);
    // const [files, setFiles] = useState<File[]>([]);
    // const [base64Preview, setBase64Preview] = useState<any[]>([]);
    const { user } = useAuth(Permission.USER_ONLY)
    const [imageUrlInput, setImageUrlInput] = useState("");
    const [imageUrlError, setImageUrlErr] = useState("")
    const [uploadIsLoading, setUploadIsLoading] = useState(false);

    const setWatermarked = (preview, file, name) => {
        setBase64Preview([...base64Preview, preview]);
        setFiles([...files, file]);
        // setPreWatermarkArr((preWatermarkArr) => preWatermarkArr.filter(pre => pre.name !== name))
    }

    const handleUpload = async (e: any) => {
        // setValue(e);
        setUploadIsLoading(true);

        const { previewArr, fileArr } = await convertFileToPreview(e);
        try {
            let newFiles = []
            let newPreviews = []
            for (const preview of previewArr) {
                const newwatermarked = await handleWatermarkImage(preview, user)
                console.log("newwatermarked here", newwatermarked)

                if (!!newwatermarked) {
                    newFiles.push(newwatermarked.file)
                    newPreviews.push({ base64: newwatermarked.base64 })
                }
            }

            setFiles([...files, ...newFiles])
            setBase64Preview([...base64Preview, ...newPreviews])
            setUploadIsLoading(false);
        } catch (e) {
            console.log("error", e)
            setUploadIsLoading(false);
        }
    };

    const onAddImage = (e: any) => {
        e.preventDefault();

        const regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:[\w.-]+)(?:[\/?#][^\s]*)?$/;

        if (!!imageUrlInput && !regex.test(imageUrlInput)) {
            setImageUrlErr("This link is not supported yet");
            return;
        }

        setImportImages([...importImages, imageUrlInput])
        setImageUrlErr("");
        setImageUrlInput("");
    }


    const mergePreviews = useCallback(() => {
        let formattedExisting = [] as any[]
        console.log("base64Preview", base64Preview)
        if (!!existingImages && !!removedImages) {
            formattedExisting = existingImages.filter((e) => {
                return !removedImages.find(r => parseInt(r) === e.id)
            }).map((e, i) => ({
                url: e.url,
                id: `exsistingImages-${e.id}`,
            }))
        }

        const formattedImport = importImages.map((e, i) => ({
            url: e,
            id: `importImages-${i}`
        }))

        const formattedBase64 = base64Preview.map((b, i) => ({
            url: b.base64,
            id: `base64Preview-${i}`
        }))

        return [...formattedExisting, ...formattedBase64, ...formattedImport]
    }, [base64Preview, importImages, removedImages, existingImages])

    const clearFileUpload = (image: any) => {
        let [type, index] = image.id.split("-")

        if (type === "base64Preview") {
            const filtered = base64Preview.filter((p, i) => i !== parseInt(index));
            const filteredFile = files.filter((p, i) => i !== parseInt(index));
            setBase64Preview(filtered);
            setFiles(filteredFile);
            return
        }

        if (type === "importImages") {
            const filtered = importImages.filter((e, i) => i !== parseInt(index))
            setImportImages(filtered)
            return
        }

        if (type === "exsistingImages" && !!removedImages) {
            setRemovedImages([...removedImages, index])
        }

    };

    return (
        <>
            <div id="containerId"
                style={{ position: 'absolute', left: -10000, top: -10000 }}
            />
            <Stack
                spacing={0}
            >
                <Text mb={8}>
                    Images
                    <Text component="span" color="red"> *</Text>
                </Text>
                <Stack spacing={8}>
                    <form onSubmit={(e) => onAddImage(e)}>
                        <Group sx={{ alignItems: "flex-end", }} >
                            <TextInput
                                label="Image Links"
                                radius="md"
                                sx={{
                                    flex: 1,
                                    width: "100%",
                                }}
                                onChange={(e) => setImageUrlInput(e.target.value)}
                                value={imageUrlInput}
                                error={imageUrlError}
                                placeholder={'Enter image url here '}
                            />
                            <Button
                                leftIcon={
                                    <Plus />
                                }
                                type="submit"
                                mb={
                                    !!imageUrlError
                                        ? 19
                                        : 0
                                }
                                disabled={!imageUrlInput}
                            >
                                Add
                            </Button>
                        </Group>
                    </form>
                    <Divider label="OR" labelPosition="center" />
                    <Dropzone
                        loading={uploadIsLoading}
                        mt={2}
                        maxSize={10 * 1024 ** 2}
                        onDrop={(files) => handleUpload(files)}
                        onReject={(files) => console.log("rejected files", files)}
                        styles={{ inner: { pointerEvents: "all", }, }}
                        accept={{
                            'image/*': [], // All images
                        }}
                    >
                        <Center>
                            <Files />
                            <Text> Drop your images here </Text>
                        </Center>
                    </Dropzone>
                    <Text size={'sm'}>max size per image is 10mb. Rejected images will not be previewed or saved</Text>
                    {(mergePreviews().length > 0) && (
                        <Group>
                            {mergePreviews().map((image, i) => (
                                <Center
                                    sx={{
                                        position:
                                            "relative",
                                    }}
                                >
                                    <MantineImage
                                        width={120}
                                        height={120}
                                        fit="contain"
                                        src={image.url}
                                    />

                                    <ActionIcon
                                        sx={{
                                            borderRadius: "50%",
                                            backgroundColor: "white",
                                            border: "1px solid #BFC8C3",
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            clearFileUpload(image);
                                        }}
                                    >
                                        <Trash
                                            size={16}
                                            color="red"
                                        />
                                    </ActionIcon>
                                </Center>
                            )
                            )}
                        </Group>
                    )}
                    {!!imagesError && (
                        <Text color="red" mt={4} fz="sm">Please upload or link at least 1 image</Text>
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default ImageInput;