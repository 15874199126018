import React, { useEffect } from 'react';
import { RichTextEditor as MantineRichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { Spoiler } from '@mantine/core';

const heightPerLine = 32

const RichTextBox = ({ content }: any) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content,
        editable: false,
    });

    return (
        <MantineRichTextEditor 
            editor={editor} 
            sx={theme => ({
                borderRadius: '0.5rem',
                '.mantine-RichTextEditor-content': {
                    backgroundColor: 'white',
                    borderRadius: '0.5rem',
                    fontSize: theme.fontSizes.md
                },
                '.ProseMirror': {
                    padding: '0 !important',
                }
            })}
        >
            <Spoiler 
                maxHeight={heightPerLine * 3}
                hideLabel='Hide' 
                showLabel='Show more...'
                p={16}
            >
                <MantineRichTextEditor.Content />
            </Spoiler>
        </MantineRichTextEditor>
    );
};

export default RichTextBox;