import {
    Flex,
    Container,
    Grid,
    Text,
    Button,
    useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { IconCircleCheck } from "@tabler/icons-react";

export default function PurchaseSuccess() {
    const navigate = useNavigate();
    //Add get purchaseAmount from session storage
    // const purchaseAmount = 1000;
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

    const onClickReturn = () => {
        navigate("/tokens/purchase");
    };

    return (
        <Container size={"1200px"}>
            <Grid p={0} m={0} py={32} align="center" justify="center">
                <Grid.Col
                    p={0}
                    display={"flex"}
                    sx={{ alignContent: "center", justifyContent: "center" }}
                >
                    <Text
                        weight={600}
                        size={isMobile ? 20 : 36}
                        pb={64}
                        sx={{ lineHeight: "44px", ...(isMobile && {lineHeight: '30px'}) }}
                    >
                        Purchase successful!
                    </Text>
                </Grid.Col>
                <Grid.Col p={0}>
                    <Flex
                        direction="column"
                        align={"center"}
                        justify={"center"}
                    >
                        <IconCircleCheck
                            width={40}
                            height={40}
                            style={{ marginBottom: "16px" }}
                        />
                        <Text
                            weight={500}
                            size={16}
                            pb={24}
                            align="center"
                            color="#294936"
                            sx={{ lineHeight: "24px" }}
                        >
                            Thank you for purchase with us. You have purchased tokens successfully!
                        </Text>
                        <Button
                            h={44}
                            mb={8}
                            radius={"8px"}
                            onClick={() => onClickReturn()}
                            sx={{
                                padding: "10px 32px",
                                backgroundColor: "#294936",
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        >
                            Return
                        </Button>
                    </Flex>
                </Grid.Col>
            </Grid>
        </Container>
    );
}
