import {
    AspectRatio,
    Box,
    Center,
    Container,
    Grid,
    Image,
    Stack,
    Text,
    useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { DirectionSign, Home } from "tabler-icons-react";

export default function OurStory() {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

    const aboutUsCopyWriting = [
        {
            title: "Who We Are",
            content: `
                Founded in 2023, Propmoth is a platform created to tackle the current issues that the property industry faces today.<br /><br />
                These problems include, but are not limited to:
                Unqualified agents, fishing, fake listings and spam. With the worst one being data brokering.<br /><br />
                We are a service that connects serious clients to the best agents in Malaysia.

                We plan to be the best property browsing platform for all types of clients from B2B and B2C
            `,
            image: "/aboutUs1.jpeg",
            icon: <Home size={32} />,
        },
        {
            title: "Our Mission",
            content:
                `Our mission is to create the best environment for both professional real estate agents and genuine buyers and tenants. To educate users that they should take a closer look at what properties are truly available, subsale and and auction included, before making their choice. <br/><br/> You're the one paying, why shouldn't you get the best choices?`,
            image: "/aboutUs1.jpeg",
            icon: <DirectionSign size={32} />,
        },
    ];
    return (
        <Container size="1200px" p={0}>
            <Grid>
                <Grid.Col span={12}>
                    <Box sx={{ background: "#F2EFE7" }} p={32} py={64}>
                        <Center>
                            <Stack align="center" maw={600} w="100%">
                                <Text align="center" fz={32} fw={700}>
                                    Our Story
                                </Text>
                                <Text align="center">
                                    Propmoth was founded by local Malaysian Benjamin Lee and Muhammad Illyas
                                </Text>
                            </Stack>
                        </Center>
                    </Box>
                </Grid.Col>
                {!isMobile ? (
                    <>
                        <Grid.Col md={6} sm={12}>
                            <Stack
                                justify="center"
                                align="center"
                                sx={{ height: "100%" }}
                                px={32}
                            >
                                <Home size={32} />
                                <Text
                                    fz={18}
                                    fw={700}
                                    sx={{ width: "100%" }}
                                    align="left"
                                >
                                    Who We Are
                                </Text>
                                <Text sx={{ width: "100%" }} align="left">
                                    Founded in 2023, Propmoth is a platform created to tackle the current issues that the property industry faces today.<br /><br />
                                    These problems include, but are not limited to:
                                    Unqualified agents, fishing, fake listings and spam. With the worst one being data brokering.<br /><br />
                                    We are a service that connects serious clients to the best agents in Malaysia.

                                    We plan to be the best property browsing platform for all types of clients from B2B and B2C
                                </Text>
                            </Stack>
                        </Grid.Col>
                        <Grid.Col md={6} sm={12}>
                            <AspectRatio ratio={1 / 1} mah={450}>
                                <Image src="/aboutus1.jpeg" />
                            </AspectRatio>
                        </Grid.Col>
                        <Grid.Col md={6} sm={12}>
                            <AspectRatio ratio={1 / 1} mah={450}>
                                <Image src="/aboutus2.jpeg" />
                            </AspectRatio>
                        </Grid.Col>
                        <Grid.Col md={6} sm={12}>
                            <Stack
                                justify="center"
                                align="center"
                                sx={{ height: "100%" }}
                                px={32}
                            >
                                <DirectionSign size={32} />
                                <Text
                                    fz={18}
                                    fw={700}
                                    sx={{ width: "100%" }}
                                    align="left"
                                >
                                    Our Mission
                                </Text>
                                <Text sx={{ width: "100%" }} align="left">
                                    Our mission is to create the best environment for both professional real estate agents and genuine buyers and tenants. To educate users that they should take a closer look at what properties are truly available, subsale and and auction included, before making their choice. <br /><br /> 
                                    You're the one paying, why shouldn't you get the best choices?
                                </Text>
                            </Stack>
                        </Grid.Col>
                    </>
                ) : (
                    <>
                        {aboutUsCopyWriting.map((a) => (
                            <>
                                <Grid.Col sm={12}>
                                    <AspectRatio ratio={2 / 1} mah={450}>
                                        <Image src={a.image} />
                                    </AspectRatio>
                                </Grid.Col>
                                <Grid.Col sm={12}>
                                    <Grid.Col md={6} sm={12}>
                                        <Stack
                                            justify="center"
                                            align="center"
                                            sx={{ height: "100%" }}
                                            p={32}
                                        >
                                            {a.icon}
                                            <Text
                                                fz={18}
                                                fw={700}
                                                sx={{ width: "100%" }}
                                                align="left"
                                            >
                                                {a.title}
                                            </Text>
                                            <Text
                                                sx={{ width: "100%" }}
                                                align="left"
                                            >
                                                <div dangerouslySetInnerHTML={{ __html: a.content }} />
                                            </Text>
                                        </Stack>
                                    </Grid.Col>
                                </Grid.Col>

                            </>
                        ))}
                    </>
                )}
            </Grid>
        </Container>
    );
}
