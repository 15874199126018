import API from "api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj, flattenWithMeta } from "helpers/strapi";
import { Permission, useAuth } from "hooks/useAuth";
import { AGENT_REQUEST_STATUS } from "helpers/constants";
const qs = require("qs");

// keys
export const GET_AGENT_REQUESTS_BY_LISTER = "GET_AGENT_REQUESTS_BY_LISTER";
export const GET_ONE_AGENT_REQUEST = "GET_ONE_AGENT_REQUEST";
export const GET_ALL_AGENT_REQUESTS = "GET_ALL_AGENT_REQUESTS";

export const useAgentRequestAPI = () => {
    const { user } = useAuth(Permission.USER_ONLY);

    const createAgentRequestReply = async (payload: any) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).post<any,any>(`agent-request-replies`, {
            data: {
                ...payload,
                agent: user.id
            }
        })
        const response = flattenObj(resp)
        const res = await API({ requireAuth: true, token: user.jwt }).put<any, any>(`agent-requests/${payload.agentRequest}`, {
            data: {
                replies: {
                    connect: [response.data.id],
                },
            },
        });
        return response
    }
    const useCreateAgentRequestReply = () => useMutation(createAgentRequestReply)

    const createAgentRequest = async (payload: any) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).post<
            any,
            any
        >(`agent-requests`, {
            data: {
                ...payload,
            },
        });

        return flattenObj(resp);
    };

    const useCreateAgentRequest = () => useMutation(createAgentRequest)

    const updateAgentRequest = async (payload: any) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).put<
            any,
            any
        >(`agent-requests/${payload.id}`, {
            data: { ...payload },
        });
        return flattenObj(resp)
    }

    const useUpdateAgentRequest = () => useMutation(updateAgentRequest)

    async function getOneAgentRequest(id: any) {
        try {
            const query = qs.stringify(
                {
                    populate: {
                        lister: {
                            populate: {
                                avatar: true
                            }
                        },
                        replies: {
                            populate: {
                                agent: {
                                    populate: {
                                        avatar: true
                                    }
                                },
                                agentRequest: true,
                            }
                        },
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            )
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`agent-requests/${id}?${query}`);

            return flattenObj(response.data);
        } catch (e) {
            return e
        }
    }

    async function getMyAgentRequests(payload: any) {
        const { id, pagination } = payload;
        try {
            const query = qs.stringify(
                {
                    populate: {
                        lister: true,
                        replies: {
                            populate: {
                                agent: true,
                                agentRequest: true,
                            }
                        },
                    },
                    filters: {
                        lister: {
                            id: {
                                $eq: id,
                            },
                        },
                        status: {
                            $eq: AGENT_REQUEST_STATUS.ACTIVE
                        }
                    },
                    pagination,
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`agent-requests?${query}`);

            return flattenWithMeta(response);
        } catch (e) {
            return e;
        }
    }

    const getMasterList = async (payload: any) => {
        const { pagination } = payload;
        try {
            const query = qs.stringify(
                {
                    populate: {
                        lister: true,
                        replies: {
                            populate: {
                                agent: true,
                                agentRequest: true,
                            }
                        },
                    },
                    filters: {
                        status: {
                            $eq: AGENT_REQUEST_STATUS.ACTIVE
                        }
                    },
                    pagination,
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`agent-requests?${query}`);

            return flattenWithMeta(response);
        } catch (e) {
            return e;
        }
    }

    const useGetMyAgentRequests = (payload: any) =>
        useQuery([GET_AGENT_REQUESTS_BY_LISTER, payload], () =>
            getMyAgentRequests(payload)
        );

    const useGetOneAgentRequest = (id: any) =>
        useQuery([GET_ONE_AGENT_REQUEST, id], () =>
            getOneAgentRequest(id),
            {
                enabled: !!id
            }
        )
    const useGetMasterList = (payload: any) =>
        useQuery([GET_ALL_AGENT_REQUESTS, payload], () => getMasterList(payload))

    return {
        useCreateAgentRequest,
        useGetMyAgentRequests,
        useGetOneAgentRequest,
        useUpdateAgentRequest,
        useGetMasterList,
        useCreateAgentRequestReply,
    };
};
