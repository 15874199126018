import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    Text,
    Textarea,
    Select,
    NumberInput,
} from "@mantine/core";
import { ActionButton } from "components/Buttons/ActionButton";
import { useProfileController } from "hooks/useProfile";
import { ListerDetail } from "types/userTypes";
import { EditInput } from "components/Inputs/EditInput";
import { useForm } from "@mantine/form";
import {
    updateUserDetailPayload,
} from "api/updateUserAPI";
import { notifications } from "@mantine/notifications";
import { allCountries } from "country-region-data";
import { useGetConfigs } from "api/configAPI";
import { CONFIG_KEYS } from "helpers/constants";
import { DateInput } from "@mantine/dates";
import { BaseFormInput } from "components/Inputs/BaseFormInput";
import { strictNumberInput } from "helpers/validations";

type FormValues = {
    nationality: string;
    race: string;
    maritalStatus: string;
    noOfChildren: number | string;
    occupation: string;
    dob: Date | undefined;
    remark: string;
    companyName: string;
};

export const UpdateUserDetail: React.FC<any> = ({ onClose, plainForm }) => {
    const { profileData, profileIsLoading, updateUserDetailMutation } =
        useProfileController();
    const [raceInput, setRaceInput] = useState("");
    const profile = profileData as ListerDetail;
    const form = useForm<FormValues>({
        initialValues: {
            nationality: "",
            race: "",
            maritalStatus: "",
            noOfChildren: 0,
            occupation: "",
            dob: undefined,
            remark: "",
            companyName: "",
        },
        validateInputOnChange: true,
        validate: (values) => ({}),
    });
    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    const allCountriesFormatted = useMemo(() => {
        if (!!allCountries) {
            return allCountries.map((c) => c[0]);
        }

        return [];
    }, [allCountries]);

    const allOccupations = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find((p: any) => p.key === CONFIG_KEYS.OCCUPATION)
                .value;
        }

        return [];
    }, [configData]);

    useEffect(() => {
        if (profile) {
            // if (!!profile?.race && profile?.race.includes("|")) {

            // }
            form.setValues({
                nationality: profile?.nationality || "",
                race: profile?.race || "",
                dob: profile?.dob ? new Date(profile?.dob) : undefined,
                maritalStatus: profile?.maritalStatus || "",
                companyName: profile?.companyName || "",
                noOfChildren: !Number.isNaN(profile?.noOfChildren)
                    ? profile?.noOfChildren
                    : "",
                occupation: profile?.occupation || "",
                remark: profile?.remark || "",
            });
        }
    }, [profile]);

    const onSubmit = useCallback((values: FormValues) => {
        // console.log('in onsubmit', values)
        if (profile?.user?.id) {
            const payload: updateUserDetailPayload = {
                ...values,
                noOfChildren: parseInt(values?.noOfChildren as string) ?? 0,
                detailId: profile.id,
            };
            updateUserDetailMutation.mutate(payload, {
                onSuccess: (data) => {
                    notifications.show({
                        title: "",
                        message: "Additional info saved",
                    });
                    onClose && onClose();
                },
                onError: (error: any) => {
                    const errorMessage = error?.response?.data?.error?.message;
                    const errorDetails = error?.response?.data?.error?.details;
                    if (
                        errorDetails &&
                        Object.keys(errorDetails).length === 0
                    ) {
                        notifications.show({
                            title: "",
                            message: errorMessage || "",
                        });
                    }
                },
            });
        }
    }, [form.values])

    useEffect(() => {
        if (updateUserDetailMutation.error) {
            // const error: axios
            // console.log('type', typeof updateUserDetailMutation.error, updateUserDetailMutation.error)
            const errors = (updateUserDetailMutation.error as any)?.response
                ?.data?.error?.details?.errors;
            if (errors) {
                errors.forEach(({ path, message }: any) => {
                    // console.log(path[0], message);
                    if (path[0] === "username") {
                        form.setFieldError(
                            "phoneNumber",
                            message.replace("username", "phone number")
                        );
                    }
                    form.setFieldError(path[0], message);
                });
            }
        }
    }, [updateUserDetailMutation.error]);

    return (
        <>
            <Box
                mt={16}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    position: "relative",
                }}
                component="form"
            >
                {/* <EditInput
                    label="Nationality"
                    placeholder="Enter nationality"
                    inputSx={{ width: "100% !important" }}
                    {...form.getInputProps("nationality")}
                /> */}
                {/* <EditInput
                    label="Race"
                    placeholder="Enter race"
                    inputSx={{ width: "100% !important" }}
                    {...form.getInputProps("race")}
                /> */}
                <Select
                    label="Nationality"
                    searchable
                    nothingFound="No options"
                    placeholder="Pick one"
                    radius="md"
                    data={allCountriesFormatted}
                    {...form.getInputProps("nationality")}
                />
                <Select
                    label="Race"
                    placeholder="Pick one"
                    radius="md"
                    data={["Chinese", "Malay", "Indian", "Other"]}
                    {...form.getInputProps("race")}
                />
                {/* {form.values.race === "Other" && (
                    <EditInput
                        placeholder="Please fill this in"
                        inputSx={{ width: "100% !important" }}
                        onChange={(e: any) => {
                            setRaceInput(e.target.value);
                        }}
                        value={raceInput}
                        // {...form.getInputProps("race")}
                    />
                )} */}
                <Select
                    label="Marital Status"
                    placeholder="Pick one"
                    radius="md"
                    data={["Single", "Married", "Widowed"]}
                    {...form.getInputProps("maritalStatus")}
                />
                {/* <EditInput
                    label="Marital Status"
                    placeholder="Enter marital status"
                    inputSx={{ width: "100% !important" }}
                    {...form.getInputProps("maritalStatus")}
                /> */}
                <BaseFormInput
                    label="No. of Children"
                    onKeyDown={(evt: any) => strictNumberInput(evt)}
                    {...form.getInputProps("noOfChildren")}
                    onChange={(e: any) => form.setFieldValue("noOfChildren", e.target.value)}
                    required
                />
                <BaseFormInput
                    label="Current Company Name"
                    {...form.getInputProps("companyName")}
                    required
                />
                {/* <EditInput
                    label="Occupation"
                    placeholder="Enter occupation"
                    inputSx={{ width: "100% !important" }}
                    {...form.getInputProps("occupation")}
                /> */}
                <Select
                    label="Occupation"
                    placeholder="Pick one"
                    radius="md"
                    searchable
                    nothingFound="No options"
                    data={allOccupations}
                    {...form.getInputProps("occupation")}
                />
                <DateInput
                    label="Date of Birth"
                    placeholder="choose a date"
                    // onChange={(e) => console.log(e)}
                    {...form.getInputProps("dob")}
                />

                <Box>
                    <Textarea
                        label="Personal Remark"
                        placeholder="Personal remark about yourself ..."
                        sx={(theme: any) => ({
                            textarea: {
                                width: "100%",
                            },
                        })}
                        size="md"
                        radius={8}
                        {...form.getInputProps("remark")}
                    />
                    <Text size={14} color="primaryGreen" mt={2}>
                        This info will be available to agents regardless of
                        whether they choose your request.
                    </Text>
                </Box>

                <Box
                    mt={24}
                    sx={{
                        display: "flex",
                        gap: 8,
                    }}
                >
                    {!plainForm && (
                        <ActionButton
                            variant="outline"
                            sx={{ flex: 1 }}
                            onClick={onClose}
                        >
                            Not Now
                        </ActionButton>
                    )}

                    <ActionButton
                        sx={{ flex: 1 }}
                        onClick={() => onSubmit(form.values)}
                        disabled={
                            updateUserDetailMutation.isLoading ||
                            !form.isValid()
                        }
                        loading={updateUserDetailMutation.isLoading}
                    >
                        Confirm & Submit
                    </ActionButton>
                </Box>
            </Box>
        </>
    );
};
