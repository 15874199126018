import { Center, Grid, Image, Text } from '@mantine/core';
import React from 'react';

const InPartnershipWith = () => {
    const logos = [
        {
            title: "La Galerie Du Monde",
            img: "/la-galerie-du-monde.png",
        },
        {
            title: "Paperballed",
            img: "/paperballad.png",
        },
        {
            title: "Wl Framing",
            img: "/wl-framing.png",
        },
        {
            title: "Mycar",
            img: "/mycar.png",
        },
    ];
    return (
        <>
            <Text
                component="h1"
                sx={(theme) => ({
                    fontSize: "1.5em",
                    lineHeight: 1,
                    [theme.fn.smallerThan("xs")]: {
                        textAlign: "center",
                    },
                })}
                align="center"
            >
                In Partnership with
            </Text>
            <Grid gutter={24} justify="center">
                {logos.map((logo) => (
                    <Grid.Col xs={12} sm={6} md={3}>
                        <Center sx={{ height: "100%" }}>
                            <Image
                                src={logo.img}
                                alt={logo.title}
                                fit="contain"
                                width={150}
                            />
                        </Center>
                    </Grid.Col>
                ))}
            </Grid>
        </>
    );
};

export default InPartnershipWith;