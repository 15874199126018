import React, { useCallback, useMemo } from "react";
import { Permission, useAuth } from "../../hooks/useAuth";
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Divider,
    Group,
    Stack,
    Text,
    TextInput,
    Container,
    Card,
    Avatar,
    Badge,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { PROPERTY_REQUEST_COLOR_SETTINGS, USER_TYPE } from "helpers/constants";

export const ChatSelector: React.FC<any> = ({ ...props }) => {
    const { chat, userType, activeChatId, onClick } = props;

    const styles = useMemo(() => {
        const sharedStyles = {
            width: "100%",
            cursor: "pointer",
        };
        if (activeChatId === chat.id) {
            return {
                ...sharedStyles,
                color: "white",
                backgroundColor: "#417256",
                border: "1px solid #417256",
            };
        } else {
            return {
                ...sharedStyles,
                color: "initial",
                backgroundColor: "initial",
                border: "1px solid #BFC8C3",
            };
        }
    }, [activeChatId]);

    const chatType = useMemo(() => {
        if (chat?.offer) {
            return {
                title: "Offer",
                color: "orange"
            }
        }

        if (chat?.listing) {
            return {
                title: "Listing",
                color: "black"
            }
        }

        if (chat?.propertyRequest) {
            return {
                title: "Property Request",
                color: "blue"
            }
        }

        return null
    }, [chat])

    const getTitle = useCallback(() => {
        if (chat?.offer) {
            return chat?.offer?.listing?.title
        }

        if (chat?.listing) {
            return chat?.listing?.title
        }

        if (chat?.propertyRequest) {
            return `${chat?.propertyRequest.transactionType} @ ${chat?.propertyRequest.area},${chat?.propertyRequest.state}`
        }

        return false
    }, [chat])

    const transactionType = useMemo(() => {
        if (!!chat?.propertyRequest) {
            return chat?.propertyRequest.transactionType as
                | "BUY"
                | "RENT"
                | "INVEST";
        }

        return false
    }, [chat])

    const isAgent = userType === USER_TYPE.AGENT;

    return (
        <Card radius="md" sx={styles} p={8} onClick={() => onClick(chat.id)}>
            <Stack>
                <Group>
                    <Stack spacing={0}>
                        <Group mb={4} spacing={8}>
                            {!!transactionType && (
                                <Badge
                                    sx={{
                                        color: PROPERTY_REQUEST_COLOR_SETTINGS[
                                            transactionType
                                        ].color,
                                        backgroundColor:
                                            PROPERTY_REQUEST_COLOR_SETTINGS[
                                                transactionType
                                            ].background,
                                    }}
                                    size="lg"
                                >
                                    {transactionType}
                                </Badge>
                            )}
                            {!!chatType && (
                                <Badge color={chatType.color}>{chatType.title}</Badge>
                            )}
                        </Group>
                        {getTitle() ? (
                            <Text sx={{ color: styles.color }}>
                                {getTitle()}
                            </Text>
                        ) : (
                            <Text sx={{ color: activeChatId === chat.id ? "#ddd" : "#000", fontStyle: 'italic' }} fz="xs">
                                This offer does not exist anymore
                            </Text>
                        )}
                        <Group spacing={4}>
                            {!isAgent && <Avatar radius={"50%"} size="sm" />}
                            <Text fz={12} sx={{ color: styles.color }}>
                                {isAgent
                                    ? chat?.lister?.fullname
                                    : chat?.agent?.fullname}
                            </Text>
                        </Group>
                    </Stack>
                </Group>
            </Stack>
        </Card>
    );
};
