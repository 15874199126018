import { notifications } from "@mantine/notifications";
import { useListingAPI } from "api/useListingAPI";
import { useState } from "react";

export const useCreateRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { useCreateRequestTag, useCreatePropRequest, useUpdatePropRequest } =
        useListingAPI();

    const createPropRequest = useCreatePropRequest();
    const updatePropRequest = useUpdatePropRequest();
    const createRequestTag = useCreateRequestTag();

    const getTagDisplayText = (tag: any, value: string) => {
        const displayText = tag.values.options.find(
            (o: any) => o.value === value
        );

        if (!displayText) {
            return "";
        }

        return displayText.label;
    };

    const createRequest = async (
        formValues: any,
        tagForm: any,
        userId: any
    ) => {
        setIsLoading(true);
        let payload = {
            ...formValues,
        };

        if (!!formValues.incomeRange && formValues.transactionType !== "RENT") {
            payload = {
                ...payload,
                budgetMax: "0",
                budgetMin: "0",
            };
        }
        console.log("payload", payload);
        const request = await createPropRequest.mutateAsync({
            ...payload,
            lister: userId,
        });
        let idArr = [];

        for (const tag of Object.values(tagForm)) {
            const tagValue = tag as any;
            let tagObj = {
                tag: tagValue.id,
                propertyRequest: request.data.id,
            } as any;

            if (tagValue.values.type === "boolean" && !!tagValue.value) {
                tagObj["value"] = tagValue.title;
            }

            if (tagValue.values.type === "select") {
                tagObj["value"] = getTagDisplayText(tag, tagValue.value);
            }

            if (!!tagObj.value) {
                const tagCreate = await createRequestTag.mutateAsync(tagObj);

                idArr.push({ id: tagCreate.data.id });
            }
        }

        const update = updatePropRequest.mutateAsync({
            tags: {
                connect: idArr,
            },
            id: request.data.id,
        });

        sessionStorage.removeItem("request_draft");
        notifications.show({
            title: "Success!",
            message: "Successfully created property request",
            autoClose: 2000,
        });
        setIsLoading(false);
    };

    return {
        createRequest,
        createRequestIsLoading: isLoading,
    };
};
