import { Carousel } from '@mantine/carousel';
import { BackgroundImage, Box, Card, Center, Flex, Grid, Image, Overlay, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { LightBox } from 'components/LightBox';
import React, { useCallback, useMemo, useState } from 'react';

const ImageGrid = ({ images, mainImage }: any) => {
    const showCarousel = useMediaQuery('(max-width: 993px)');
    const hasSideGrid = images.length > 0
    const [showLightBox, setShowLightBox] = useState(false);
    const [clickedImage, setClickedImage] = useState(0);


    const gridImages = useCallback(() => {
        const returnImages = images.filter((e: any, i: number) => i < 4)
        if (returnImages.length === 4) {
            return returnImages
        } else {
            let withDummies = [...returnImages]
            let counter = 4 - returnImages.length
            while (counter > 0) {
                withDummies.push({
                    url: 'blank'
                })
                counter -= 1
            }

            return withDummies
        }


    }, [images])

    const totalHidden = useCallback(() => {
        return images.length - 4
    }, [images])

    const lightboxImages = useMemo(() => {
        return [mainImage, ...images,]
    }, [images, mainImage])

    const handleOpenLightBox = (index: number) => {
        setClickedImage(index)
        setShowLightBox(true)
    }

    if (!!showCarousel) {
        return (
            <Box sx={{ height: '70vh' }}>
                <Carousel w={'100%'} h={'100%'} slideSize="70%" loop>
                    {lightboxImages.map((i) => (
                        <Carousel.Slide w={'100%'} h={'100%'}>
                            <Flex
                                sx={{
                                    flexDirection: 'row',
                                    height: '100%',
                                    width: '100%',
                                    alignItems: 'center',
                                    padding: 8
                                }}
                            >
                                <Image
                                    src={i?.url ?? "/img-placeholder.jpeg"}
                                    height={"100%"}
                                    width={"100%"}
                                    fit="contain"
                                    imageProps={{
                                        style: {
                                            maxHeight: "70vh",
                                        },
                                    }}
                                    sx={{ backgroundColor: '#ddd' }}
                                />
                            </Flex>
                        </Carousel.Slide>
                    ))}
                </Carousel>
            </Box>
        )
    }

    return (
        <>
            {!!showLightBox && (
                <LightBox
                    showLightBox={showLightBox}
                    onClose={() => setShowLightBox(false)}
                    // image={formattedData.offerImages[0]?.src || ""}
                    images={lightboxImages}
                    clickedImage={clickedImage}
                />
            )}
            <Grid sx={{ height: '70vh' }}>
                <Grid.Col md={hasSideGrid ? 8 : 12} sm={12} sx={{ height: '100%', backgroundColor: 'whitesmoke' }}>
                    <BackgroundImage
                        src={mainImage.url}
                        radius="sm"
                        sx={{
                            height: '100%',
                            width: '100%',
                            backgroundRepeat: 'no-repeat'
                        }}
                        onClick={() => handleOpenLightBox(0)}
                    />
                </Grid.Col>
                {!!hasSideGrid && (
                    <Grid.Col md={4} sm={12} sx={{ height: '100%' }}>
                        <Grid sx={{ height: '100%', paddingTop: 0, paddingBottom: 0 }}>
                            {gridImages().map((i: any, index: number) => (
                                <Grid.Col
                                    key={`grid-img-${index}`}
                                    md={6}
                                    sm={6}
                                    xs={3}
                                    sx={(theme) => ({
                                        height: '50%',
                                        backgroundColor: 'whitesmoke',
                                        [theme.fn.smallerThan("xs")]: {
                                            height: '25%',
                                        }
                                    })}
                                >
                                    {i.url === "blank" ? (
                                        <Box
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                backgroundColor: '#ddd',
                                                borderRadius: '0.25rem'
                                            }}
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                position: 'relative'
                                            }}
                                            onClick={() => handleOpenLightBox(index + 1)}
                                        >
                                            {totalHidden() > 0 && index === 3 && (
                                                <Overlay center>
                                                    <Center>
                                                        <Text color="white" fw={700} fz="lg">+{totalHidden()} photos</Text>
                                                    </Center>
                                                </Overlay>
                                            )}
                                            <BackgroundImage
                                                src={i.url}
                                                radius="sm"
                                                sx={{
                                                    height: '100%',
                                                    width: '100%',
                                                    backgroundRepeat: 'no-repeat'
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Grid.Col>
                            ))}
                        </Grid>
                    </Grid.Col>
                )}
            </Grid>
        </>
    );
};

export default ImageGrid;   