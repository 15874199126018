import { Flex, NumberInput, Select, Stack, Text, TextInput } from '@mantine/core';
import { selectFilter } from 'helpers/utility';
import React, { useEffect, useState } from 'react';

const AgentTagInput = ({ form, tagType, tagNumber, setTagType, setTagNumber }: any) => {


    useEffect(() => {
        if (tagType === "Probation" || tagType === "Referral" || tagType === "Vendor") {
            form.setFieldValue("renId", `${tagType}`)
            setTagNumber(tagType)
        } else {
            form.setFieldValue("renId", `${tagType ?? ""}${tagNumber ?? ""}`)
        }
    }, [tagType, tagNumber])

    return (
        <Stack spacing={2}>
            <Text>REN/PEA/REA Tag </Text>
            <Flex sx={{ width: '100%' }}>
                <Select
                    data={['REN', 'PEA', 'REA', 'Probation', 'Referral']}
                    searchable
                    filter={selectFilter}
                    sx={{
                        maxWidth: tagType === "Probation" ? '100%' : 100,
                        width: tagType === "Probation" ? '100%' : ''
                    }}
                    onChange={(e) => setTagType(e)}
                />
                {(tagType !== "Probation" && tagType !== "Referral") && (
                    <NumberInput
                        sx={{ flex: 1 }}
                        onChange={(e) => setTagNumber(e)}
                        required
                        hideControls
                        onWheel={(evt) => evt.currentTarget.blur()}
                    />
                )}
            </Flex>
            {!!form?.errors?.renId && (
                <Text size="sm" color="red">{form?.errors?.renId}</Text>
            )}
        </Stack>
    );
};

export default AgentTagInput;