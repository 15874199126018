import React, {
    useState,
    useEffect,
    useMemo
} from "react";
import { Trash } from "tabler-icons-react";
import { 
    Box,
    Image,
    ActionIcon,
    Text,
    FileInput,
    Mark
} from "@mantine/core";

interface EditFormImageInputProps {

}
export const EditFormImageInput: React.FC<any> = ({
    value,
    onChange,
    label,
    required,
    ...props
}) => {
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState<string|null>(null);

    const handleUpload = async (file: File) => {
        setLoading(state => !state)
        onChange(file)
        setPreview(URL.createObjectURL(file))
        setLoading(state => !state)
    };

    const handleClear = () => {
        onChange(null)
        setPreview(null)
    }

    useEffect(() => {
        if (value && typeof value === 'string') {
            setPreview(value)
        }
        if (value && typeof value === 'object' && preview === null) {
            setPreview(URL.createObjectURL(value))
        }
    },[value])

    const imgPreview = useMemo(() => {
        if (preview) {
            return (
                <Box
                    sx={{
                        position: 'relative'
                    }}    
                >
                    <Image
                        width={120}
                        height={120}
                        fit='contain'
                        src={preview}
                    />
                    <ActionIcon
                        sx={{
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            border: '1px solid #BFC8C3',
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                        onClick={handleClear}
                    >
                        <Trash
                            size={24}
                            strokeWidth={1.5}
                            color='black'
                        />
                    </ActionIcon>
                </Box>
            )
        }
        return null
    },[preview])

    return (
        <Box>
            <Text
                weight={500}
                color='#212529'
            >
                {`${label}`}
                {required && (
                    <Text color='red' component="span">
                        {' *'}
                    </Text>
                )}
            </Text>
            <Box 
                mt={1}
                sx={{
                    display: 'flex',
                }}
            >
                {imgPreview}
                {!preview && (
                    <FileInput
                        sx={{
                            button: {
                                height: 120,
                                width: 120,
                                textAlign: 'center',
                            },
                            '.mantine-FileInput-placeholder': {
                            },
                        }}
                        accept='image/png,image/jpeg'
                        onChange={handleUpload}
                        placeholder='Add Photo'
                        {...props}
                    />
                )}
            </Box>
        </Box>
    )
}