import { Badge } from "@mantine/core"
import { PROPERTY_REQUEST_COLOR_SETTINGS } from "helpers/constants"

export const NewProjectBadge = () => {
    return <Badge color="blue" size={'lg'}>NEW PROJECTS OK</Badge>
}

export const UrgentBadge = () => {
    return <Badge color="red">⚠️ Urgent</Badge>
}

export const SubsalesOkBadge = () => {
    return <Badge
        color="dark"
        size={'lg'}
    >
        Subsales OK
    </Badge>
}

export const PropRequestBadge = ({ propRequestType }: { propRequestType: "BUY" | "RENT" | "INVEST" }) => {
    return <Badge
        sx={{
            color: PROPERTY_REQUEST_COLOR_SETTINGS[
                propRequestType
            ].color,
            backgroundColor:
                PROPERTY_REQUEST_COLOR_SETTINGS[
                    propRequestType
                ].background,
        }}
    >
        {propRequestType}
    </Badge>
}

export const SubscriptionBadge = ({ type }: any) => {
    switch (type) {
        case "BASIC":
            return (<Badge
                sx={{
                    backgroundColor: "white",
                    borderColor: "#417256"
                }}
            >Moth Basic</Badge>);
        case "PRO":
            return (
                <Badge>Moth Pro</Badge>
            )
        case "UNSUBBED":
            return <Badge
                color="dark"
            >Not Subscribed</Badge>
        default:
            return <Badge
                color="dark"
            >Not Subscribed</Badge>
    }

}