import React, { useEffect, useMemo } from "react";
import {
    Box,
    UnstyledButton,
    Text,
    Avatar,
    ScrollArea,
    Center,
    Indicator,
    Badge,
    Group,
    Stack,
    Divider,
} from "@mantine/core";
import moment from "moment";
import { DATE_DISPLAYS } from "helpers/constants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { notificationType } from "helpers/constants";
import { useNavigate } from "react-router-dom";
import API from "api/API";
import { Permission, useAuth } from "hooks/useAuth";
import { GET_NOTIFICATIONS, GET_NOTIFICATION_COUNT } from "api/userAPI";

const NotificationItem: React.FC<any> = ({ data, onClick }) => {
    const navigate = useNavigate();
    const { description, refId, read, type, receiver, createdAt } = data;

    // const content =
    //     type === notificationType.OFFER ? (
    //         <Box
    //             onClick={() => navigate(`/offer/${refId}`)}
    //             sx={{ cursor: "pointer" }}
    //         >
    //             <Text mb={8}>
    //                 <UnstyledButton>
    //                     <Text weight={500} component="span">
    //                         {receiver.fullname}
    //                     </Text>
    //                 </UnstyledButton>
    //                 &nbsp;has made an offer to your&nbsp;request
    //                 {/* <UnstyledButton
    //                     onClick={(e) => {
    //                         e.stopPropagation()
    //                         navigate(`/offer/${refId}`)
    //                     }}
    //                 >
    //                     <Text underline weight={500} color='primaryGreen' component='span'>
    //                         request
    //                     </Text>
    //                 </UnstyledButton> */}
    //             </Text>
    //             <Box
    //                 sx={{
    //                     display: "flex",
    //                     gap: 8,
    //                     alignItems: "center",
    //                 }}
    //             >
    //                 <Text color="primaryGreen">
    //                     {moment(createdAt).fromNow()}
    //                 </Text>
    //                 {!read && (
    //                     <Badge>
    //                         <Text>New</Text>
    //                     </Badge>
    //                 )}
    //             </Box>
    //         </Box>
    //     ) : (
    //         <Box
    //             onClick={() => navigate(`/requests/${refId}`)}
    //             sx={{ cursor: "pointer" }}
    //         >
    //             <Text mb={8}>
    //                 {/* <UnstyledButton>
    //                     <Text weight={500} component='span'>
    //                         {receiver.fullname}
    //                     </Text>
    //                 </UnstyledButton> */}
    //                 {/* &nbsp;has made an offer to your&nbsp; */}
    //                 Request notification
    //                 {/* <UnstyledButton
    //                 >
    //                     <Text underline weight={500} color='primaryGreen' component='span'>
    //                         request
    //                     </Text>
    //                 </UnstyledButton> */}
    //             </Text>
    //             <Box
    //                 sx={{
    //                     display: "flex",
    //                     gap: 8,
    //                     alignItems: "center",
    //                 }}
    //             >
    //                 <Text color="primaryGreen">
    //                     {moment(createdAt).fromNow()}
    //                 </Text>
    //                 {!read && (
    //                     <Badge>
    //                         <Text>New</Text>
    //                     </Badge>
    //                 )}
    //             </Box>
    //         </Box>
    //     );
    const onNotiClick = () => {
        !!onClick && onClick();
        switch (type) {
            case notificationType.OFFER:
                navigate(`/offer/${refId}`);
                break;
            case notificationType.REQUEST:
                navigate(`/requests/${refId}`);
                break;
            case notificationType.CHAT:
                navigate(`/chats`, { state: { id: refId } });
                break;
        }
    };
    return (
        <Box>
            <Box
                mt={16}
                sx={{
                    display: "flex",
                    gap: 16,
                    width: "100%",
                }}
            >
                {/* <Avatar src={receiver?.avatar} size={40} radius={4} /> */}
                <Box
                    onClick={onNotiClick}
                    sx={{ cursor: "pointer", width: "100%" }}
                >
                    <Text mb={8}>
                        {/* <UnstyledButton>
                            <Text weight={500} component="span">
                                {receiver.fullname}
                            </Text>
                        </UnstyledButton> */}
                        {description}
                    </Text>
                    <Group spacing={4} position="apart">
                        <Group spacing={4}>
                            <Text color="primaryGreen" size={12}>
                                {moment().format(DATE_DISPLAYS.NOTIFICATION)}
                            </Text>
                            {!read && (
                                <Badge>
                                    <Text>New</Text>
                                </Badge>
                            )}
                        </Group>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 8,
                                alignItems: "center",
                            }}
                        >
                            <Text color="primaryGreen" size={12}>
                                {moment(createdAt).fromNow()}
                            </Text>
                        </Box>
                    </Group>
                </Box>
            </Box>
        </Box>
    );
};

export const Notification: React.FC<any> = ({
    notificationData,
    refetch,
    newCount,
}) => {
    const queryClient = useQueryClient();
    const { user } = useAuth(Permission.USER_ONLY);

    const qs = require("qs");
    const query = qs.stringify(
        {
            populate: ["receiver", "receiver.avatar"],
            filters: {
                receiver: {
                    id: { $eq: user.id },
                },
            },
        },
        { encodeValuesOnly: true }
    );

    useEffect(() => {
        console.log("notif", notificationData);
    }, [notificationData]);

    const readMutation = useMutation({
        mutationFn: async (data: any) =>
            API({ requireAuth: true }).put(`/notifications/${data.id}`, {
                data: { read: true },
            }),
    });

    const markAllRead = () => {
        if (notificationData && notificationData.length > 0) {
            // const unreadRefIds = []
            notificationData.forEach((x: any, i: number) => {
                if (!x.read) {
                    // unreadRefIds.push(x.refId)
                    readMutation.mutate(
                        { id: x.id },
                        {
                            onSuccess: () => {
                                if (i === notificationData.length - 1) {
                                    // console.log(i);
                                    // console.log(notificationData.length - 1);
                                    refetch();
                                }
                            },
                        }
                    );
                }
            });
        }
    };

    const readSingle = (id: number) => {
        readMutation.mutate(
            { id },
            {
                onSuccess: () => {
                    // if (i === notificationData.length - 1) {
                    //     console.log(i);
                    //     console.log(notificationData.length - 1);
                    // }
                    refetch();
                },
            }
        );
    };

    const notifications = useMemo(() => {
        if (!!notificationData && notificationData.length > 0) {
            // let grouped = [] as any[];

            // notificationData.forEach((noti: any) => {
            //     const date = moment(noti.createdAt).format("DD MMM YYYY");
            //     const recordIndex = grouped.findIndex((g) => g.date === date);
            //     console.log(recordIndex);
            //     if (!recordIndex) {
            //         console.log({
            //             date,
            //             notifications: [noti],
            //         });
            //         grouped.push({
            //             date,
            //             notifications: [noti],
            //         });
            //     } else {
            //         grouped[recordIndex]?.notifications?.push(noti);
            //     }
            // });
            // console.log("grouped", grouped);
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                    }}
                >
                    {notificationData.map((x: any) => (
                        <>
                            <NotificationItem
                                key={x.id}
                                data={x}
                                onClick={() => readSingle(x.id)}
                            />
                            <Divider m={0} />
                        </>
                    ))}
                </Box>
            );
        }
        return null;
    }, [notificationData]);

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 16,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Text size={18} weight={600}>
                    {`Notifications (${newCount})`}
                </Text>
                <UnstyledButton onClick={markAllRead}>
                    <Text underline color="primaryGreen" size={14} weight={500}>
                        Mark All As Read
                    </Text>
                </UnstyledButton>
            </Box>
            {notificationData && notificationData.length === 0 && (
                <Center>
                    <Text color="grey.1">No notifications to show</Text>
                </Center>
            )}
            {/* <ScrollArea> */}
            {notifications}
            {/* </ScrollArea> */}
        </Box>
    );
};
