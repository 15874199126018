// @ts-nocheck

import API from "../api/API";
// import { USER_ACTIONS } from "../context/user/userActions";
import { UserContext, UserDispatchContext } from "../context/user/userContext";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
// import jwtDecode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
// import { UserType } from "../types/userTypes";
import { useNavigate } from "react-router-dom";
import { flattenObj } from "helpers/strapi";

// export enum Permission {
//     ANYONE,
//     GUEST_ONLY,
//     USER_ONLY,
// }

// export type UploadProps = {
// };

export const useUpload = () => {
    const navigate = useNavigate();
    // const queryClient = useQueryClient();
    // get an entire user object
    const user = useContext(UserContext);

    // operations for user
    const userDispatch = useContext(UserDispatchContext);

    // auto redirect based on conditions
    // useEffect(() => {
    //     if (!user.loaded) return;
    //     checkAuth().then((result) => {
    //         // check permission
    //         switch (permission) {
    //             case Permission.GUEST_ONLY:
    //                 if (!!result) {
    //                     navigate("/");
    //                     return;
    //                 }
    //                 break;
    //             case Permission.USER_ONLY:
    //                 if (!result) {
    //                     navigate("/login");
    //                     return;
    //                 }
    //         }
    //     });
    // }, [user]);

    // checkAuth validates user and refresh when needed
    // const checkAuth = async () => {
    //     if (!user?.jwt) return false;
    //     const { exp }: { exp: number } = jwtDecode(user.jwt);
    //     // if access token has expired
    //     if (new Date() >= new Date(exp * 1000)) {
    //         logout();
    //         return false;
    //     }
    //     return true;
    // };

    // logout the users
    // const logout = async () => {
    //     console.log('logout')
    //     userDispatch({ type: USER_ACTIONS.LOGOUT, payload: null });
    //     navigate("/");
    // };

    // login user
    // const loginMutation = useMutation({
    //     mutationFn: (data: LoginProps) => {
    //         return API({}).post(`auth/local`, {
    //             identifier: data.identifier,
    //             password: data.password,
    //         });
    //     },
    // });

    // async function codeLogin(code: string) {
    //     const response = await API({}).get<UserType, any>(
    //         `passwordless/login?loginToken=${code}`
    //     );

    //     return response.data;
    // }

    // const useCodeLogin = (code: string) =>
    //     useQuery(["CODE_LOGIN", code], () => codeLogin(code), {
    //         enabled: false,
    //     });
    // register user
    const uploadMutation = useMutation({
        mutationFn: async (data: any, name? : string) => {
            const formData = new FormData();
            formData.append(`files`, data.file, name ?? data.file.name);
            if (!!data.path) {
                formData.append(`path`, data.path);
            }
            if (!!data.ref) {
                formData.append(`ref`, data.ref);
            }
            if (!!data.refId) {
                formData.append(`refId`, data.refId);
            }
            if (!!data.field) {
                formData.append(`field`, data.field);
            }
            const resp = await API({ contentType: "multipart/form-data" }).post(
                "/upload",
                formData
            );
            return flattenObj(resp.data);
        },
    });

    return {
        uploadMutation,
    };
};
