import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import { Box, Image, PasswordInput, Text } from "@mantine/core";
import { BasePasswordInput } from "./BasePasswordInput";
import { UseFormReturnType } from "@mantine/form";
import { Check, ChevronsDownLeft, CircleX, X } from "tabler-icons-react";
import validator from "validator";
import { useFocusWithin } from "@mantine/hooks";

const validatePassword = (x: string) => {
    const minChar = validator.isStrongPassword(x, {
        minLength: 8,
        minSymbols: 0,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 0,
    });
    // const specialChar = validator.isStrongPassword(x, {
    //     minLength: 2,
    //     minSymbols: 1,
    //     minLowercase: 0,
    //     minUppercase: 0,
    //     minNumbers: 1,
    // });
    // const caseChar = validator.isStrongPassword(x, {
    //     minLength: 1,
    //     minSymbols: 0,
    //     minLowercase: 0,
    //     minUppercase: 1,
    //     minNumbers: 0,
    // });

    return {
        minChar,
        // specialChar,
        // caseChar,
    };
};

type Props = {
    form: any;
};

const ErrorText: React.FC<any> = ({ isValid, text }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 8,
            }}
        >
            {isValid ? (
                <Check width={24} strokeWidth={1} color={"green"} />
            ) : (
                <X width={24} strokeWidth={1} color={"red"} />
            )}
            <Text size={14} color={isValid ? "green" : "red"}>
                {text}
            </Text>
        </Box>
    );
};

export const FormPasswordInput: React.FC<any> = ({ form, value, ...props }) => {
    const { ref, focused } = useFocusWithin();
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState(validatePassword(value));
    useEffect(() => {
        setErrors(validatePassword(value));
    }, [value]);
    useEffect(() => {
        if (focused) {
            setShow(true);
        }
    }, [focused]);
    useLayoutEffect(() => {
        if (value.length > 0) {
            // console.log('all passed', Object.values(errors).every(v => !!v))
            if (Object.values(errors).every((v) => !!v)) {
                form.setFieldError("password", null);
            } else {
                form.setFieldError("password", true);
            }
        }
    }, [errors, value]);
    return (
        <Box ref={ref}>
            <BasePasswordInput
                label="Password"
                placeholder="Enter password"
                mb={2}
                value={value}
                {...props}
            />
            {show && (
                <>
                    <ErrorText
                        isValid={errors.minChar}
                        text="At least 8 characters"
                    />
                    {/* <ErrorText
                        isValid={errors.specialChar}
                        text="Contains a number & special character"
                    />
                    <ErrorText
                        isValid={errors.caseChar}
                        text="At least 1 uppercase"
                    /> */}
                </>
            )}
        </Box>
    );
};
