import { Box, Stack, Tabs } from '@mantine/core';
import { useProfileController } from 'hooks/useProfile';
import SearchRequestsBrowse from 'pages/SearchRequestsBrowse';
import SearchRequestsModified from 'pages/SearchRequestsModified';
import React from 'react';
import Paywall from './Paywall';

const DashboardLeads = () => {
    const { isPro, isBasic, isUnsubscribed } =
        useProfileController();

    return (
        <Stack sx={{ position: 'relative' }}>
            <Tabs defaultValue="browse">
                <Tabs.List>
                    <Tabs.Tab value="browse">Browse</Tabs.Tab>
                    <Tabs.Tab value="advanced">Advanced Filter</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="browse" pt="xs">
                    <Box sx={{ position: 'relative' }}>
                        {!!isUnsubscribed && (
                            <Paywall type="BASIC" />
                        )}
                        <SearchRequestsBrowse />
                    </Box>
                </Tabs.Panel>
                <Tabs.Panel value="advanced" pt="xs">
                    <SearchRequestsModified />
                </Tabs.Panel>
            </Tabs>

        </Stack>
    );
};

export default DashboardLeads;