import React from "react";
import { Permission, useAuth } from "../../hooks/useAuth";
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Divider,
    Group,
    Stack,
    Text,
    TextInput,
    Container,
    Flex,
    useMantineTheme,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import {
    BrandFacebook,
    BrandInstagram,
    BrandLinkedin,
    BrandTiktok,
    BrandTwitter,
    BrandWhatsapp,
    Home,
    Mail,
    Phone,
} from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";

export default function CustomFooter() {
    const navigate = useNavigate();
    const { user, logout } = useAuth(Permission.ANYONE);
    const theme = useMantineTheme();
    const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

    const socialLinks = [
        // { icons: <BrandTwitter color="white" />, link: "" },
        {
            icons: <BrandFacebook color="white" />,
            link: "https://www.facebook.com/profile.php?id=100091936744354",
        },
        {
            icons: <BrandLinkedin color="white" />,
            link: "https://www.linkedin.com/company/propmoth/about/",
        },
        // { icons: <BrandLinkedin color="white" />, link: "" },
        // { icons: <BrandTiktok color="white" />, link: "" },
        {
            icons: <BrandWhatsapp color="white" />,
            link: "https://wa.link/xha2lb",
        },
        {
            icons: <BrandInstagram color="white" />,
            link: "https://instagram.com/propmoth?igshid=MzRlODBiNWFlZA==",
        },
    ];

    const policies = [
        {
            text: "Terms & Conditions",
            link: "/termsandconditions",
        },
        {
            text: "Privacy Policy",
            link: "/privacypolicy",
        },
        // {
        //     text: "Refund Policy",
        //     link: "/refundypolicy",
        // },
    ]

    const usefullLinks = [
        {
            link: "/feedback",
            text: "Feedback",
        },
        {
            text: "Our Story",
            link: "/our-story",
        },
        {
            text: "News & Articles",
            link: "/articles",
        },
    ];

    const contactUs = [
        {
            icon: <Home />,
        },
    ];

    return (
        <Box
            sx={(theme) => ({
                backgroundColor: "#212922",
                padding: 32,
                [theme.fn.smallerThan("md")]: {
                    padding: `32px 8px 16px`,
                },
            })}
        >
            <Container size="1200px">
                <Stack>
                    <Flex
                        sx={{
                            justifyContent: "apart",
                            flexDirection: isTablet ? "column" : "row",
                            gap: isTablet ? 32 : 16,
                        }}
                    >
                        <Group
                            align="flex-start"
                            sx={{ flexGrow: 1 }}
                            spacing={32}
                        >
                            <Stack spacing={16}>
                                <Text fw={700} color="white">
                                    Useful Links
                                </Text>
                                <Stack spacing={8}>
                                    {/* <Anchor
                                        onClick={() => navigate("/about")}
                                    ></Anchor> */}
                                    {usefullLinks.map((link, i) => (
                                        <Text
                                            key={`${link.link}-${i}`}
                                            fz={14}
                                            color="white"
                                            sx={{
                                                ":hover": {
                                                    color: "#417256",
                                                },
                                                cursor: " pointer",
                                            }}
                                            onClick={() => navigate(link.link)}
                                        >
                                            {link.text}
                                        </Text>
                                    ))}
                                </Stack>
                            </Stack>
                            <Stack spacing={16}>
                                <Text fw={700} color="white">
                                    Policies
                                </Text>
                                <Stack spacing={8}>
                                    {/* <Anchor
                                        onClick={() => navigate("/about")}
                                    ></Anchor> */}
                                    {policies.map((link, i) => (
                                        <Text
                                            key={`${link.link}-${i}`}
                                            fz={14}
                                            color="white"
                                            sx={{
                                                ":hover": {
                                                    color: "#417256",
                                                },
                                                cursor: " pointer",
                                            }}
                                            onClick={() => navigate(link.link)}
                                        >
                                            {link.text}
                                        </Text>
                                    ))}
                                </Stack>
                            </Stack>
                            <Stack align="flex-end">
                                <Stack spacing={16}>
                                    <Text fw={700} color="white">
                                        Contact Us
                                    </Text>
                                    <Stack spacing={8}>
                                        <Group spacing={8}>
                                            <Home
                                                width={16}
                                                height={16}
                                                color="white"
                                            />
                                            <Text fz={14} color="white">
                                                No. 3, 5, Jalan Chantek 5/13,
                                                Bukit Gasing
                                            </Text>
                                        </Group>
                                        <Group spacing={8}>
                                            <Mail
                                                width={16}
                                                height={16}
                                                color="white"
                                            />
                                            <Text
                                                fz={14}
                                                color="white"
                                                onClick={() => { }}
                                            >
                                                admin@propmoth.com
                                            </Text>
                                        </Group>
                                        <Group spacing={8}>
                                            <Phone
                                                width={16}
                                                height={16}
                                                color="white"
                                            />
                                            <Text
                                                fz={14}
                                                color="white"
                                                // onClick={() => {
                                                //     window.location.href =
                                                //         "wa.link/j8wlcz";
                                                // }}
                                            >
                                                012-3384374
                                            </Text>
                                        </Group>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Group>
                        <Stack
                            align={isTablet ? "flex-start" : "flex-end"}
                            sx={{ height: "100%" }}
                        >
                            <Text color="white" fz={12}>
                                Stay informed with our newsletter - no spam,
                                ever.
                            </Text>
                            <Group sx={{ width: "100%" }}>
                                <TextInput sx={{ flexGrow: 1 }} />
                                <Button>Subscribe</Button>
                            </Group>
                        </Stack>
                    </Flex>
                    <Divider my="sm" />
                    <Group position="apart" sx={{ width: "100%" }}>
                        <Text color="white" fz={12}>
                            2023 Propmoth. All rights reserved.
                        </Text>
                        <Group>
                            {socialLinks.map((s, i) => (
                                <ActionIcon
                                    variant="transparent"
                                    key={`${i}-icon`}
                                    onClick={() =>
                                        window.open(s.link, "_newtab")
                                    }
                                >
                                    {s.icons}
                                </ActionIcon>
                            ))}
                        </Group>
                    </Group>
                </Stack>
            </Container>
        </Box>
    );
}
