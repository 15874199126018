import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    Container,
    Stack,
    Group,
    Text,
    Select,
    Button,
    Modal,
    TextInput,
    Textarea,
    Divider,
    Overlay,
    ScrollArea,
    Flex,
} from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useAgentRequestAPI } from "api/useAgentRequestApi";
import { CONFIG_KEYS, PROPERTY_TITLE_TYPE_OPTIONS, TENURE_OPTIONS, TRANSACTION_TYPE_OPTIONS } from "helpers/constants";
import { useGetConfigs } from "api/configAPI";
import { selectFilter } from "helpers/utility";
import { notifications } from "@mantine/notifications";
import { Permission, useAuth } from "hooks/useAuth";
import { AgentRequestTnc } from "./components/AgentRequestTnc";
import BathroomsInput from "pages/PropRequestForm/components/BathroomsInput";
import BedroomsInput from "pages/PropRequestForm/components/BedroomsInput";
import { useProfileController } from "hooks/useProfile";

const AgentRequestForm = () => {
    let { id } = useParams();
    const { user, agentRequestTncMutation } = useAuth(Permission.USER_ONLY);
    const { profileData } = useProfileController();
    const navigate = useNavigate();
    const [submitConfirm, setSubmitConfirm] = useState(false)
    const [backConfirm, setBackConfirm] = useState(false)
    const [showTnc, setShowTnc] = useState(false)
    const [states, setStates] = useState([]);
    const { useCreateAgentRequest, useGetOneAgentRequest, useUpdateAgentRequest } = useAgentRequestAPI();
    const {
        data: agentRequestData,
        error: agentRequestError,
        isLoading: agentRequestIsLoading,
    } = useGetOneAgentRequest(id ?? '')
    const createAgentRequest = useCreateAgentRequest()
    const updateAgentRequest = useUpdateAgentRequest()
    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();
    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);
    const form = useForm<any>({
        initialValues: {
            transactionType: "",
            propertyTitleType: "",
            state: '',
            area: '',
            address: '',
            floorSize: '',
            tenure: '',
            askingPrice: '',
            description: '',
            noOfBedrooms: '',
            noOfBathrooms: '',
        },
        validate: {
            transactionType: (value) => (!!value ? null : "Please fill in this field"),
            propertyTitleType: (value) => (!!value ? null : "Please fill in this field"),
            state: (value) => (!!value ? null : "Please fill in this field"),
            area: (value) => (!!value ? null : "Please fill in this field"),
            floorSize: (value) =>
                !!value ? null : "Please fill in this field",
            tenure: (value) => (!!value ? null : "Please fill in this field"),
            address: (value) => (!!value ? null : "Please fill in this field"),
            description: (value) => (!!value ? null : "Please fill in this field"),
            askingPrice: (value) => (!!value ? null : "Please fill in this field"),
            noOfBedrooms: value => !Number.isNaN(value) ? null : 'Please fill in this field',
            noOfBathrooms: value => !Number.isNaN(value) ? null : 'Please fill in this field',
        },
    });
    const areas = useMemo(() => {
        if (!!form.values.state) {
            const stateObj = statesAndAreas.find(
                (s: any) => s.state === form.values.state
            );
            if (!!stateObj) {
                return stateObj.areas
                    .map((a: any) => ({
                        value: a,
                        label: a,
                    }))
                    .filter((e: any) => !!e);
            }
            return [];
        }

        return [
            {
                value: "",
                label: "Please select a state first",
            },
        ];
    }, [form.values]);

    const handleAcceptTnc = () => {
        agentRequestTncMutation.mutate({
            id: profileData.id,
            payload: {
                agentRequestTnc: true,
            }
        }, {
            onSuccess: () => {
                setShowTnc(false)
            },
            onError: () => {
                notifications.show({
                    title: "Error accepting terms and conditions",
                    message: "If this issue persist, please contact our support",
                    autoClose: 2000,
                })
            }
        })
    }

    const onCreateAgentRequest = () => {
        createAgentRequest.mutate({
            ...form.values,
            lister: user.id,
        }, {
            onSuccess: () => {
                navigate("/profile");
                notifications.show({
                    title: "Success!",
                    message: "Successfully created agent request",
                    autoClose: 2000,
                });
            },
            onError: () => {
                notifications.show({
                    title: "Error creating agent request",
                    message: "If this issue persist, please contact our support",
                    autoClose: 2000,
                });
            }
        })
    }


    const handleCreateAgentRequest = useCallback(() => {
        if (!!user.jwt) {
            return onCreateAgentRequest()
        }

        const cache = {
            action: "CREATE_AGENT_REQUEST",
            data: {
                form: form.values,
            }
        };

        sessionStorage.setItem("pre_login_draft", JSON.stringify(cache));
        return navigate("/register/lister")
    }, [user, form.values])
    const handleUpdateAgentRequest = () => {
        updateAgentRequest.mutate({
            ...form.values,
            id
        }, {
            onSuccess: () => {
                navigate("/profile");
                notifications.show({
                    title: "Success!",
                    message: "Successfully updated agent request",
                    autoClose: 2000,
                });
            },
            onError: () => {
                notifications.show({
                    title: "Error updating agent request",
                    message: "If this issue persist, please contact our support",
                    autoClose: 2000,
                });
            }
        })
    }

    const handleBack = () => {
        setBackConfirm(true)
    }

    const validateForm = () => {
        form.validate()
        const isValid = form.isValid();
        if (isValid) {
            setSubmitConfirm(true)
            return
        }
    }

    const handleSubmit = () => {
        if (!!id) {
            handleUpdateAgentRequest()
        } else {
            handleCreateAgentRequest()
        }
    }

    useEffect(() => {
        let statesData = statesAndAreas
            .map((s: any) => ({
                value: s.state,
                label: s.state,
            }))
            .filter((e: any) => !!e);

        setStates(statesData);
    }, [configData]);

    useEffect(() => {
        document.body.style.overflow = showTnc ? 'hidden' : 'auto'
        return () => { document.body.style.overflow = 'auto' }
    }, [showTnc])

    useEffect(() => {
        if (profileData) {
            if (!profileData.agentRequestTnc) {
                setShowTnc(true)
            }
        }
    }, [profileData])

    useEffect(() => {
        if (agentRequestData) {
            form.setValues({
                transactionType: agentRequestData?.transactionType,
                propertyTitleType: agentRequestData?.propertyTitleType,
                state: agentRequestData?.state,
                area: agentRequestData?.area,
                address: agentRequestData?.address,
                floorSize: agentRequestData?.floorSize,
                tenure: agentRequestData?.tenure,
                askingPrice: agentRequestData?.askingPrice,
                description: agentRequestData?.description,
                noOfBedrooms: `${agentRequestData?.noOfBedrooms}`,
                noOfBathrooms: `${agentRequestData?.noOfBathrooms}`,
            })
        }
    }, [agentRequestData])

    return (
        <Box
            sx={{
                position: "relative",
            }}
        >
            <Modal
                fullScreen
                opened={showTnc}
                onClose={() => setBackConfirm(true)}
                closeOnClickOutside={false}
                closeOnEscape={false}
                withCloseButton={false}
                styles={{
                    'body': {
                        backgroundColor: 'whitesmoke',
                        padding: 0,
                        minHeight: '100svh'
                    }
                }}
            >
                <Stack spacing={8}>
                    <AgentRequestTnc />
                    <Divider />
                    <Group position="right" sx={{ width: '100%', padding: '16px' }}>
                        <Button onClick={() => setBackConfirm(true)} variant="outline">
                            Back
                        </Button>
                        <Button onClick={handleAcceptTnc}>
                            Accept
                        </Button>
                    </Group>
                </Stack>
            </Modal>
            <Modal
                centered
                opened={submitConfirm}
                onClose={() => setSubmitConfirm(false)}
                title={id ? "Confirm Update Agent Request?" : "Confirm Create Agent Request?"}
            >
                <Stack>
                    <Group sx={{ width: "100%" }}>
                        <Button
                            onClick={() => setSubmitConfirm(false)}
                            sx={{ flexGrow: 1 }}
                            variant="outline"
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => handleSubmit()}
                            sx={{ flexGrow: 1 }}
                        >
                            Yes
                        </Button>
                    </Group>
                </Stack>
            </Modal>
            <Modal
                centered
                opened={backConfirm}
                onClose={() => setBackConfirm(false)}
                title="Are you sure you want to leave this page?"
            >
                <Group sx={{ width: "100%" }}>
                    <Button
                        onClick={() => setBackConfirm(false)}
                        sx={{ flexGrow: 1 }}
                        variant="outline"
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => navigate(-1)}
                        sx={{ flexGrow: 1 }}
                    >
                        Yes
                    </Button>
                </Group>
            </Modal>
            <Container
                size="1200px"
                pt={32}
                px={16}
            >
                <Stack spacing={24}>
                    <Group>
                        <Button
                            variant="subtle"
                            leftIcon={<ArrowLeft />}
                            onClick={() => handleBack()}
                        >
                            Back
                        </Button>
                    </Group>
                    <Group
                        position="apart"
                        sx={{ flexWrap: "nowrap" }}
                    >
                        <Text
                            fz={32}
                            fw={600}
                            sx={{ flexGrow: 1 }}
                        >
                            {!!id ? "Edit property listing" : "List your property"}
                        </Text>
                    </Group>
                    <Divider my={8} />
                </Stack>
            </Container>
            <Container
                size="1200px"
                pb={32}
                px={16}
            >
                <Stack pos="relative">
                    <Select
                        withAsterisk
                        label="Transaction Type"
                        placeholder="Pick one"
                        data={TRANSACTION_TYPE_OPTIONS}
                        {...form.getInputProps(
                            "transactionType"
                        )}
                        onChange={(e) => {
                            form.setFieldValue("transactionType", e as string)
                        }}
                        disabled={!!id}
                    />
                    <Flex
                        sx={(theme) => ({
                            justifyContent: 'center',
                            gap: 8,
                            [theme.fn.smallerThan("sm")]: {
                                flexDirection: 'column'
                            }
                        })}
                    >
                        <Select
                            withAsterisk
                            label="Property Title Type"
                            placeholder="Pick one"
                            radius="md"
                            data={PROPERTY_TITLE_TYPE_OPTIONS}
                            {...form.getInputProps(
                                "propertyTitleType"
                            )}
                            sx={{ flex: 1 }}
                        />
                        <Select
                            withAsterisk
                            label="Tenure"
                            placeholder="Pick one"
                            radius="md"
                            data={TENURE_OPTIONS}
                            {...form.getInputProps(
                                "tenure"
                            )}
                            sx={{ flex: 1 }}
                        />
                    </Flex>
                    <TextInput
                        withAsterisk
                        label="Floor size"
                        type="number"
                        radius="md"
                        {...form.getInputProps(
                            "floorSize"
                        )}
                        rightSection={
                            <Text
                                sx={{
                                    color: "#adb5bd",
                                }}
                            >
                                Sqft
                            </Text>
                        }
                        rightSectionWidth={
                            45
                        }
                    />
                    <Flex
                        sx={(theme) => ({
                            justifyContent: 'center',
                            gap: 8,
                            [theme.fn.smallerThan("sm")]: {
                                flexDirection: 'column'
                            }
                        })}
                    >
                        <BathroomsInput form={form} />
                        <BedroomsInput form={form} />
                    </Flex>
                    <TextInput
                        withAsterisk
                        label="Asking price"
                        type="number"
                        onWheel={(evt) => evt.currentTarget.blur()}
                        radius="md"
                        {...form.getInputProps(
                            "askingPrice"
                        )}
                        icon={
                            <Text sx={{ lineHeight: 1, }}>
                                RM
                            </Text>
                        }
                    />
                    <Flex
                        sx={(theme) => ({
                            justifyContent: 'center',
                            gap: 8,
                            [theme.fn.smallerThan("sm")]: {
                                flexDirection: 'column'
                            }
                        })}
                    >

                        <Select
                            sx={{
                                flex: 1,
                            }}
                            withAsterisk
                            label="State"
                            placeholder="Pick one"
                            data={
                                states
                            }
                            searchable
                            nothingFound="No options"
                            filter={
                                selectFilter
                            }
                            {...form.getInputProps(
                                "state"
                            )}
                            onChange={(
                                e
                            ) => {
                                form.setFieldValue(
                                    "state",
                                    e ||
                                    ""
                                );
                                form.setFieldValue(
                                    "area",
                                    ""
                                );
                            }}
                        />
                        <Select
                            sx={{
                                flex: 1,
                            }}
                            withAsterisk
                            label="Area"
                            placeholder="Pick one"
                            data={
                                !form
                                    .values
                                    .state
                                    ? []
                                    : areas
                            }
                            disabled={
                                !form
                                    .values
                                    .state
                            }
                            searchable
                            nothingFound="No options"
                            filter={
                                selectFilter
                            }
                            {...form.getInputProps(
                                "area"
                            )}
                        />
                    </Flex>
                    <Textarea
                        withAsterisk
                        label="Address"
                        {...form.getInputProps(
                            "address"
                        )}
                    />
                    <Textarea
                        withAsterisk
                        label="Description"
                        {...form.getInputProps(
                            "description"
                        )}
                    />
                    <Group position="right">
                        <Button
                            onClick={() => validateForm()}
                            loading={createAgentRequest.isLoading || updateAgentRequest.isLoading}
                        >
                            {!!id ? 'Update' : 'Create'}
                        </Button>
                    </Group>
                </Stack>
            </Container>
        </Box>
    )
}

export default AgentRequestForm