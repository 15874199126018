import { Box, Button, Flex, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ExclamationMark } from 'tabler-icons-react';

const OfferWhatsappBanner = () => {
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                // backgroundColor: '#417256',
                border: '1px solid #417256',
                borderRadius: 8,
                cursor: 'pointer'
            }}
            p={16}
        >
            <Flex
                sx={(theme) => ({
                    flexDirection: 'row',
                    gap: 16,
                    [theme.fn.smallerThan('sm')]: {
                        flexDirection: 'column',
                    }
                })}
            >
                <Stack sx={{ flex: 1, flexGrow: 1 }} spacing={0}>
                    <Group spacing={0}>
                        <Text sx={{ color: '#417256' }} fw={700} fz="lg">🚨Your property request is now active❗</Text>
                    </Group>
                    <Text>We're a new platform so it might take some time for you to receive requests. Thank you for supporting us! We'll notify you via Whatsapp on your phone when you receive new offers!</Text>
                </Stack>
                <Button onClick={() => navigate("/listings")}>Browse Listings</Button>
            </Flex>
        </Box>
    );
};

export default OfferWhatsappBanner;