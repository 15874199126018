import { Modal, Image, Center } from "@mantine/core";
import { Carousel, Embla, useAnimationOffsetEffect } from "@mantine/carousel";
import { useState } from "react";

interface LightBoxProps {
    showLightBox: boolean;
    onClose: Function;
    images: any;
    clickedImage?: number;
}

export const LightBox: React.FC<LightBoxProps> = (props: LightBoxProps) => {
    const [embla, setEmbla] = useState<Embla | null>(null);

    const TRANSITION_DURATION = 200;
    useAnimationOffsetEffect(embla, TRANSITION_DURATION);

    return (
        <Modal
            opened={props.showLightBox}
            size={"100%"}
            padding={0}
            withCloseButton={false}
            transitionProps={{ duration: TRANSITION_DURATION }}
            onClose={() => props.onClose()}
            sx={{
                "& .mantine-Modal-content": {
                    backgroundColor: "transparent !important",
                },
            }}
            centered
        >
            {/* <Carousel
                w="90%"
                h="70%"
                slideGap="xs"
                mx={"auto"}
                withIndicators
                initialSlide={props.clickedImage}
            >
                {props.images.map((data: any, index: number) => (
                    <Carousel.Slide key={index}>
                        <Image
                            src={data.src}
                            height={"100%"}
                            width={"100%"}
                            fit="contain"
                        />
                    </Carousel.Slide>
                ))}
            </Carousel> */}

            <Carousel
                w="90%"
                h="70%"
                slideGap="xs"
                mx={"auto"}
                withIndicators
                slideSize="100%"
                withControls={!!props.images && props.images.length > 1}
                getEmblaApi={setEmbla}
                initialSlide={props.clickedImage ?? 0}
                loop
            >
                {!!props.images &&
                    props.images?.map((img: any, i: number) => (
                        <Carousel.Slide key={`carousel-${i}`}>
                            <Center sx={{ height: '100%' }}>
                                {/* <Box>{i}</Box> */}
                                <Image
                                    src={img.url}
                                    height={"100%"}
                                    width={"100%"}
                                    fit="contain"
                                    imageProps={{
                                        style: {
                                            maxHeight: "70vh",
                                        },
                                    }}
                                />
                            </Center>
                        </Carousel.Slide>
                    ))}
            </Carousel>
        </Modal>
    );
};
