import { Anchor, BackgroundImage, Badge, Box, Button, Card, Center, Chip, Container, Grid, Group, Image, Modal, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import ProjectCard from './ProjectCard';
import { House, Tag, Buildings } from '@phosphor-icons/react';
import ProjectContactForm from './ProjectContactForm';



export const BUKIT_JALIL_PROJECTS = [
    {
        title: "Ren Residences",
        priceMin: "537,000",
        priceMax: "1,148,000",
        sqftMin: "920",
        sqftMax: "1680",
        expectedCompletion: "31 DEC 2027",
        description: "This property is perfect for first time home buyers and young couples as it provides an incredible amount of amenities and is so close to Pavilion Bukit Jalil, Propmoth staff was able to drive there within 10 minutes from the site. Even the layout of the smallest unit provides more than enough space to raise children (or pets)",
        bannerImageUrl: "/projects/ren-residences-banner.png",
        mainImageUrl: "/projects/ren-residences-main.png",
        tags: [
            {
                label: "Freehold",
                icon: <Tag />
            },
            {
                label: "Commercial",
                icon: <House />
            },
            {
                label: "Highrise",
                icon: <Buildings />
            },
        ],
        carousel: [
            { url: "/projects/ren-residences-floor-plan.png", },
            { url: "/projects/ren-residences-map.png", },
            { url: "/projects/ren-residences-package.png", },
            { url: "/projects/ren-residences-project-plan.png" },
        ],
        brochureLink: "",
    },
    {
        title: "Veladaz Residences",
        priceMin: "795,900",
        priceMax: "831,400",
        sqftMin: "1012",
        sqftMax: "1012",
        expectedCompletion: "31 DEC 2027",
        description: "This property is perfect for first time home buyers and young couples as it provides an incredible amount of amenities and is so close to Pavilion Bukit Jalil, Propmoth staff was able to drive there within 10 minutes from the site",
        bannerImageUrl: "/projects/veladaz-residences-banner.png",
        mainImageUrl: "/projects/veladaz-residences-main.png",
        tags: [
            {
                label: "Freehold",
                icon: <Tag />
            },
            {
                label: "Commercial",
                icon: <House />
            },
            {
                label: "Highrise",
                icon: <Buildings />
            },
        ],
        carousel: [
            { url: "/projects/veladaz-residences-floor-plan.png", },
            { url: "/projects/veladaz-residences-map.png", },
            { url: "/projects/veladaz-residences-package.png", },
            { url: "/projects/veladaz-residences-project-plan.png" },
        ],
        brochureLink: "",
    },
    {
        title: "Flex Sovo",
        priceMin: "486,000",
        priceMax: "738,400",
        sqftMin: "376",
        sqftMax: "591",
        expectedCompletion: "28 DEC 2026",
        description: "Literally a 5 minute walk from Pavilion Bukit Jalil, there is no other project that offers the kind of upscale convenience for the same price. While looking into the project, we were convinced enough to buy 2 office units for Propmoth. Everyone involved in the development is top tier. We’re surprised it hasn’t sold out yet.",
        bannerImageUrl: "/projects/flex-sovo-banner.png",
        mainImageUrl: "/projects/flex-sovo-main.png",
        tags: [
            {
                label: "Freehold",
                icon: <Tag />
            },
            {
                label: "Commercial",
                icon: <House />
            },
            {
                label: "Highrise",
                icon: <Buildings />
            },
        ],
        carousel: [
            { url: "/projects/flex-sovo-floor-plan.png", },
            { url: "/projects/flex-sovo-map.png", },
            { url: "/projects/flex-sovo-project-plan.png" },
        ],
        brochureLink: "",
    },
    {
        title: "The Kingwoodz @ Bukit Jalil",
        comingSoon: true,
        priceMax: '400,000+',
        bannerImageUrl: "/projects/the-kingwoodz-banner.png",
        mainImageUrl: "/projects/the-kingwoodz-main.png",
        shareData: [
            "Walking distance to Awan Besar LRT",
            "Door Step to an 80acres park ",
            "Walking distance to Pavilion Bukit Jalil",
            "Sustainable future features by Exsim"
        ],
        carousel: [],
        brochureLink: "",
    }
]

const logos = [
    {
        title: "Propnex",
        img: "/propnex.png",
    },
    {
        title: "IQI",
        img: "/iqi.png",
    },
    {
        title: "Kith & Kin",
        img: "/kithandkin.png",
    },
];

const Project = () => {
    const [opened, setOpened] = useState(false)


    return (
        <>
            <Modal
                centered
                opened={opened}
                onClose={() => setOpened(false)}
                title={"Register your interest"}
            >
                <ProjectContactForm submitCallback={() => setOpened(false)}/>
            </Modal>
            <Container size="1440px" p={24}>
                <Stack>
                    <Stack spacing={4}>
                        <Text align="center" component='h1' fz={36} sx={{ lineHeight: 1, margin: 0, color: "#1F8150" }}>Bukit Jalil </Text>
                        <Text align="center" component='h1' fz={24} sx={{ lineHeight: 1, margin: 0 }} fw={600}>Affordable Yet Glamorous Beyond Belief</Text>
                        <Text align="center" component='h4' fw={600}>There are so many new projects in Bukit Jalil - but we at Propmoth have reviewed and selected the ones that we determine to be the best</Text>
                    </Stack>
                    <Grid>
                        {BUKIT_JALIL_PROJECTS.map(project => (
                            <Grid.Col sm={12} md={12}>
                                <ProjectCard project={project} />
                            </Grid.Col>
                        ))}
                    </Grid>
                    <Card>
                        <Stack>
                            <Text align='center'>We've selected the best agencies that cover these projects. Your number will not be shared with anyone else.</Text>
                            <Container size={1000}>
                                <Grid gutter={24}>
                                    {logos.map((logo) => (
                                        <Grid.Col xs={12} sm={4}>
                                            <Center sx={{ height: "100%" }}>
                                                <Image
                                                    src={logo.img}
                                                    alt={logo.title}
                                                    fit="contain"
                                                    width={150}
                                                />
                                            </Center>
                                        </Grid.Col>
                                    ))}
                                </Grid>
                            </Container>
                            <Group position='center'>
                                <Button onClick={() => setOpened(true)}>Register Your Interest</Button>
                            </Group>
                            <Stack sx={{ color: 'red' }} spacing={4}>
                                <Text align='center' fw={'bold'}>*Important Note*</Text>
                                <Text align='center'>
                                    If the person you are talking to is being unprofessional or is not taking care of your inquiry properly, please let us know here so we can take action.
                                </Text>
                                <Text align='center'>
                                    Propmoth is a platform that caters to buyers. We want to make sure that you are properly taken care of.
                                </Text>
                            </Stack>
                            <Text component='h1' align='center'>Not interested in these projects? <Anchor href="/register" sx={{ textDecoration: "underline" }}>Register</Anchor> on our Propmoth to find your desired property! We're the “Smartest Way To Find Property” </Text>
                        </Stack>
                    </Card>
                </Stack>
            </Container>
        </>
    );
};

export default Project;