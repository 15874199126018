import { Select } from '@mantine/core';
import React from 'react';

const BathroomsInput = ({ form }: any) => {
    return (
        <Select
            label="No. of bathrooms"
            data={["0","1", "2", "3", "4", "5", "6", "7"]}
            sx={{ flex: 1 }}
            {...form.getInputProps(
                "noOfBathrooms"
            )}
        />
    );
};

export default BathroomsInput;