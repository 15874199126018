// @ts-nocheck

import API from "api/API";
import { USER_ACTIONS } from "../context/user/userActions";
import { UserContext, UserDispatchContext } from "../context/user/userContext";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import jwtDecode from "jwt-decode";
import { useContext, useEffect, useMemo, useState } from "react";
import { UserType } from "../types/userTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { createAgentDetail } from "api/createAgentDetailAPI";
import { register } from "api/registerAPI";
import { accountTypes } from "types/accountTypes";
import { validateUserStatus } from "helpers/constants";
import { flattenObj } from "helpers/strapi";
import { useCreateRequest } from "pages/PropRequestForm/hooks/useCreateRequest";

export enum Permission {
    ANYONE,
    GUEST_ONLY,
    USER_ONLY,
}

export type LoginProps = {
    identifier: string;
    password: string;
};

export type RegisterProps = {
    username: string;
    email: string;
    password: string;
    fullname: string;
    phoneNumber: string;
};

export type RegisterListerProps = {
    fullname: string;
    email: string;
    password: string;
    phoneNumber: string;
    userType: string;
};

export type RegisterAgentProps = {
    email: string;
    password: string;
    fullname: string;
    phoneNumber: string;
    userType: string;
    renId: string;
    agencyName: string;
    renImg: any;
};

export type UpdateUserProps = {
    username?: string;
    email?: string;
    checkoutId?: string;
    externalLink?: string;
};

export const useTempProfileController = (user) => {
    const qs = require("qs");
    const agentQuery = qs.stringify(
        {
            filters: {
                agent: {
                    username: {
                        $eq: user.username,
                    },
                },
            },
            populate: ["renImg", "agent", "agent.avatar"],
        },
        { encodeValuesOnly: true }
    );
    const listerQuery = qs.stringify(
        {
            filters: {
                user: {
                    username: {
                        $eq: user.username,
                    },
                },
            },
            populate: ["renImg", "user", "user.avatar"],
        },
        { encodeValuesOnly: true }
    );
    const accountType = user.userType;

    const {
        data: agentData,
        error: agentError,
        isLoading: agentIsLoading,
        refetch: refetchAgent,
    } = useQuery<AgentDetail[]>({
        queryKey: [`/agent-details?${agentQuery}`, user.jwt],
        enabled: accountType === accountTypes.AGENT,
    });

    const {
        data: listerData,
        error: listerError,
        isLoading: listerIsLoading,
        refetch: refetchLister,
    } = useQuery<ListerDetail[]>({
        queryKey: [`/user-details?${listerQuery}`, user.jwt],
        enabled: accountType === accountTypes.LISTER,
    });

    const refetchProfile = () => {
        if (accountType === accountTypes.AGENT) {
            refetchAgent();
        } else {
            refetchLister();
        }
    };

    // useEffect(() => {
    //     console.log('agent data', agentData)
    // },[agentData])
    // useEffect(() => {
    //     console.log('lister data', listerData)
    // },[listerData])

    const profile = useMemo(() => {
        if (accountType === accountTypes.AGENT && agentData) {
            return agentData[0];
        } else if (listerData) {
            return listerData[0];
        }
        return null;
    }, [accountType, agentData, listerData]);

    const updateUserMutation = useMutation({
        mutationFn: (data: updateUserPayload) => updateUser(data),
        onSuccess: (data) => {
            // console.log('update mutation succ', data)
        },
    });

    const updateUserDetailMutation = useMutation({
        mutationFn: (data: updateUserDetailPayload) => {
            const payload: any = { ...data };
            if (
                payload.noOfChildren === "" ||
                payload.noOfChildren === undefined
            ) {
                payload.noOfChildren = null;
            }
            return API({ requireAuth: true }).put(
                `/user-details/${data.detailId}`,
                { data: payload }
            );
        },
        onSuccess: (data) => {
            console.log("usdetail on suc", data);
        },
    });

    let profileData = !!user.jwt
        ? user?.userType === accountTypes.AGENT
            ? (profile as AgentDetail)
            : (profile as ListerDetail)
        : {};

    return {
        view: accountType,
        profileData: profileData as any,
        profileIsLoading: agentIsLoading || listerIsLoading,
        refetchProfile,
        updateUserMutation,
        updateUserDetailMutation,
        user,
    };
};

export const useAuth = (permission: Permission) => {
    const qs = require("qs");
    const location = useLocation();
    const navigate = useNavigate();
    const agentOnlyRoutes = ["/requests/search", "/requests/:id/offer", "/tokens/purchase"];
    const listerOnlyRoutes = ["/requests/edit"];
    const publicOnlyRoutes = [
        "/login",
        "/register",
        "/register/lister",
        "/register/agent",
    ];
    const userOnlyRoutes = ["/profile"];
    // get an entire user object
    const user = useContext(UserContext);
    const { profileData } = useTempProfileController(user);

    // operations for user
    const userDispatch = useContext(UserDispatchContext);

    useEffect(() => {
        if (!user.loaded) return;
        if (!user.jwt) {
            if (
                [
                    ...agentOnlyRoutes,
                    ...listerOnlyRoutes,
                    ...userOnlyRoutes,
                ].includes(location.pathname)
            ) {
                navigate("/login");
            }
        } else if (!!user.jwt) {
            switch (user.userType) {
                case "Agent":
                    if (
                        [...listerOnlyRoutes, ...publicOnlyRoutes].includes(
                            location.pathname
                        )
                    ) {
                        navigate("/");
                    }
                    break;
                case "Lister":
                    if (
                        [...agentOnlyRoutes, ...publicOnlyRoutes].includes(
                            location.pathname
                        )
                    ) {
                        navigate("/");
                    }
                    break;
            }
        }
    }, [user]);

    // useEffect(() => {
    //     if (!user.loaded) return;
    //     checkAuth().then((result) => {
    //         // check permission
    //         switch (permission) {
    //             case Permission.GUEST_ONLY:
    //                 if (!!result) {
    //                     navigate("/");
    //                     return;
    //                 }
    //                 break;
    //             case Permission.USER_ONLY:
    //                 if (!result) {
    //                     navigate("/login");
    //                     return;
    //                 }
    //         }
    //     });
    // }, [user]);

    // checkAuth validates user and refresh when needed
    const checkAuth = async () => {
        if (!user?.jwt) return false;
        const { exp }: { exp: number } = jwtDecode(user.jwt);
        // if access token has expired
        if (new Date() >= new Date(exp * 1000)) {
            logout();
            return false;
        }
        return true;
    };

    // logout the users
    const logout = async () => {
        userDispatch({ type: USER_ACTIONS.LOGOUT, payload: null });
        navigate("/");
    };

    // login user
    const loginMutation = useMutation({
        mutationFn: (data: LoginProps) => {
            return API({}).post(`auth/local`, {
                identifier: data.identifier,
                password: data.password,
            });
        },
    });

    async function codeLogin(code: string) {
        const response = await API({}).get<UserType, any>(
            `passwordless/login?loginToken=${code}`
        );

        return response.data;
    }

    const useCodeLogin = (code: string) =>
        useQuery(["CODE_LOGIN", code], () => codeLogin(code), {
            enabled: false,
        });

    // register user
    const registerMutation = useMutation({
        mutationFn: (data: any) => {
            return register(data);
        },
    });

    async function facebookLogin(params: string) {
        const response = await API({}).get<UserType, any>(
            `auth/facebook/callback?${params}`
        );

        return response.data;
    }

    const useFacebookLogin = (params: string, enabled: boolean) =>
        useQuery(["FACEBOOK_LOGIN", params], () => facebookLogin(params), {
            enabled: !!params && !!enabled,
        });

    async function googleLogin(params: string) {
        const response = await API({}).get<UserType, any>(
            `auth/google/callback?${params}`
        );

        return response.data;
    }

    const useGoogleLogin = (params: string, enabled: boolean) =>
        useQuery(["GOOGLE_LOGIN", params], () => googleLogin(params), {
            enabled: !!params && !!enabled,
        });

    const validateMutation = useMutation({
        mutationFn: async (data: any) => {
            const filter = qs.stringify(
                {
                    filters: {
                        $or: [
                            {
                                username: {
                                    $eq: data.phoneNumber,
                                },
                            },
                            {
                                email: {
                                    $eq: data.email,
                                },
                            },
                        ],
                    },
                },
                { encodeValuesOnly: true }
            );
            const usersResponse = await API({}).get(`/users?${filter}`);
            const searchResults = flattenObj(usersResponse.data);
            const x: any = {
                status: validateUserStatus.VALID,
                formErrors: {},
                verifyUser: {
                    ...data,
                },
            };
            if (searchResults.length > 0) {
                const [existingUser] = searchResults;
                x.status = validateUserStatus.INVALID;
                if (existingUser.username === data.phoneNumber) {
                    x.formErrors.phoneNumber =
                        "This phone number is already in use";
                }
                if (existingUser.email === data.email) {
                    x.formErrors.email = "This email is already in use";
                }
            } else {
                const validateNumber = await API({}).post(
                    "/otp/validateNumber",
                    {
                        phoneNumber: data.phoneNumber,
                    }
                );
                if (validateNumber.data.status !== 0) {
                    x.status = validateUserStatus.INVALID;
                    x.formErrors.phoneNumber = "Invalid phone number";
                }
            }
            return {
                searchResults,
                ...x,
            };
        },
    });

    const requestOtpMutation = useMutation({
        mutationFn: (data: any) => API({}).post("/otp/request", data),
    });

    const agentRequestTncMutation = useMutation({
        mutationFn: (data: any) => 
            API({ requireAuth: true, token: user.jwt })
            .put(`user-details/${data.id}`, { data: data.payload })
    })

    return {
        logout,
        loginMutation,
        user,
        userDispatch,
        registerMutation,
        useCodeLogin,
        useFacebookLogin,
        useGoogleLogin,
        validateMutation,
        requestOtpMutation,
        agentRequestTncMutation,
        profileData
    };
};
