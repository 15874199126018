import { Anchor, Box, Button, Checkbox, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BaseFormInput } from 'components/Inputs/BaseFormInput';
import { BasePasswordInput } from 'components/Inputs/BasePasswordInput';
import { FormPasswordInput } from 'components/Inputs/FormPasswordInput';
import { validateUserStatus } from 'helpers/constants';
import { sanitizePhoneNumber } from 'helpers/utility';
import { strictNumberInput, validateEmail } from 'helpers/validations';
import { Permission, useAuth } from 'hooks/useAuth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accountTypes } from 'types/accountTypes';

const MiniRegisterForm = () => {
    const navigate = useNavigate();

    const {
        registerMutation,
        userDispatch,
        validateMutation,
        requestOtpMutation,
    } = useAuth(Permission.GUEST_ONLY);

    const [checkboxes, setCheckboxes] = useState<any>({
        tnc: false,
        privacy: false,
    });
    const handleChange = (key: string) => {
        setCheckboxes((prevState: any) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const handleRegisterRedirect = (data: any) => {
        navigate("/register/lister", {
            state: {
                data
            }
        });
    }

    const onSubmit = (values: any) => {
        // ReactPixel.trackCustom("CompleteRegistration", { type: 'lister' }, [REGISTRATION_PIXEL])
        let formattedIdentifier = sanitizePhoneNumber(`${values.phoneNumber}`);

        if (!!process.env.REACT_APP_IS_DEV) {
            handleRegisterRedirect({
                ...form.values,
                userType: accountTypes.LISTER,
                requestId: "dev",
                phoneNumber: `60${formattedIdentifier}`,
                username: `60${formattedIdentifier}`,
            })
        } else {
            validateMutation.mutate(
                {
                    ...values,
                    phoneNumber: `60${formattedIdentifier}`,
                },
                {
                    onSuccess: (validateData) => {
                        switch (validateData.status) {
                            case validateUserStatus.VALID: {
                                requestOtpMutation.mutate(
                                    { phoneNumber: `60${values.phoneNumber}` },
                                    {
                                        onSuccess: (data) => {
                                            handleRegisterRedirect({
                                                ...validateData.verifyUser,
                                                userType: accountTypes.LISTER,
                                                requestId: data.data.requestId,
                                            })
                                        },
                                    }
                                );
                                break;
                            }
                            case validateUserStatus.INVALID: {
                                for (const [field, error] of Object.entries(
                                    validateData.formErrors
                                )) {
                                    form.setFieldError(field, error as string);
                                }
                                break;
                            }
                        }
                    },
                }
            );
        }
    };

    const form = useForm({
        initialValues: {
            // fullname: "etst",
            // email: "tests@gmail.com",
            // password: "password",
            // passwordConfirm: "password",
            // phoneNumber: "127728181",
            phoneNumber: "",
            fullname: "",
            email: "",
            password: "",
            passwordConfirm: "",
        },
        validateInputOnChange: true,
        validate: (values) => ({
            fullname:
                values.fullname.length === 0 ? "Full name is required" : null,
            email: validateEmail(values.email),
            phoneNumber:
                values.phoneNumber.length === 0
                    ? "Phone number is required"
                    : null,
            passwordConfirm:
                values.passwordConfirm !== values.password
                    ? "Passwords do not match"
                    : null,
        }),
    });

    return (
        <Stack>
            <Stack spacing={2}>
                <Text size={24} weight={500}>
                    Just One Last Step
                </Text>
                <Text size={32} weight={500} lh={1}>
                    Create an Account
                </Text>
                <Text size={14}>
                    Your personal info is kept private and not shared to
                    agents.
                </Text>
            </Stack>
            <BaseFormInput
                label="Preferred Name (How should we greet you?)"
                required
                {...form.getInputProps("fullname")}
            />
            <BaseFormInput
                label="Email Address"
                required
                {...form.getInputProps("email")}
            />
            <Box>
                <BaseFormInput
                    type="number"
                    label="Phone Number"
                    icon={
                        <Text
                            color="black"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            {`MY (+60)`}
                        </Text>
                    }
                    iconWidth={90}
                    required
                    onKeyDown={(evt: any) => strictNumberInput(evt)}
                    {...form.getInputProps("phoneNumber")}
                />
                {!form.errors?.phoneNumber && (
                    <Text
                        mt={5}
                        size={14}
                        color="red"
                        sx={{ lineHeight: 1.2 }}
                    >
                        Please double-check this number
                    </Text>
                )}
            </Box>
            <FormPasswordInput
                {...form.getInputProps("password")}
                form={form}
                required
            />
            <BasePasswordInput
                label="Confirm Password"
                required
                {...form.getInputProps("passwordConfirm")}
            />
            <Checkbox
                mt={8}
                label={
                    <Text>
                        I have read and agree to the{" "}
                        <Anchor
                            href="/termsandconditions"
                            target="_blank"
                            sx={{ textDecoration: "underline" }}
                        >
                            Terms of Service
                        </Anchor>
                    </Text>
                }
                sx={{
                    ".mantine-Checkbox-body": {
                        display: "flex",
                        alignItems: "center",
                    },
                }}
                checked={checkboxes.tnc}
                onChange={() => handleChange("tnc")}
                mb={16}
            />
            <Checkbox
                label={
                    <Text>
                        I have read and agree to the{" "}
                        <Anchor
                            href="/privacypolicy"
                            target="_blank"
                            sx={{ textDecoration: "underline" }}
                        >
                            Privacy Policy
                        </Anchor>
                    </Text>
                }
                sx={{
                    ".mantine-Checkbox-body": {
                        display: "flex",
                        alignItems: "center",
                    },
                }}
                checked={checkboxes.privacy}
                onChange={() => handleChange("privacy")}
            />
            <Button
                size="lg"
                radius={10}
                my={16}
                // type="submit"
                onClick={() => onSubmit(form.values)}
                loading={validateMutation.isLoading || requestOtpMutation.isLoading}
                disabled={validateMutation.isLoading || requestOtpMutation.isLoading || !form.isValid()}
            >
                Register
            </Button>
        </Stack>
    );
};

export default MiniRegisterForm;