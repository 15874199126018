import React from "react";
import { Avatar, Box, Card, Container, Grid, Spoiler, Stack, Text } from "@mantine/core";
import RichTextBox from "components/RichTextBox";

export const ReplyCard = ({
    data,
}: any) => {
    return (
        <Card
            sx={{
                width: "100%",
                border: "1px solid #BFC8C3",
                height: '100%',
                flex: 3,
            }}
            shadow="sm"
        >
            <Stack spacing={8}>
                <Box
                    sx={{
                        minWidth: '400px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 16
                        }}
                    >
                        <Avatar
                            w={56}
                            h={56}
                            radius={"50%"}
                            src={data.agent.avatar?.formats?.thumbnail?.url ?? "avatar.png"}
                            alt="agent"
                        />
                        <Stack spacing={4} sx={{ height: '100%' }} justify="center">
                            <Text>
                                {data.agent.fullname}
                            </Text>
                            <Text>
                                {/* {data.agent.renId} */}
                            </Text>
                        </Stack>
                    </Box>
                </Box>
                <Text size='lg' weight='bold' ml={8}>
                    {data.title}
                </Text>
                <RichTextBox
                    content={data.intro}
                />
            </Stack>
        </Card>
    )
}