import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
import { Permission, useAuth } from "hooks/useAuth";
const qs = require("qs");

export const useProjectAPI = () => {
    const { user } = useAuth(Permission.USER_ONLY);

    const useCreateProjectLead = () =>
        useMutation(async (payload: any) => {
            const resp = await API({}).post<any, any>(`project-leads`, {
                data: {
                    ...payload.data,
                },
            });

            return flattenObj(resp);
        });
    return {
        useCreateProjectLead,
    };
};
