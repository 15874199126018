import React, { useState, useEffect } from "react";
import AgentRequestCard from "components/AgentRequestCard";
import { Container, Text, Box, Stack, Button, Flex, Group, Grid, Card, Center } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { useAgentRequestAPI } from "api/useAgentRequestApi";
import { ArrowLeft } from "tabler-icons-react";
import { RepliesList } from "./components/RepliesList";

const ViewAgentRequest = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { useGetOneAgentRequest } = useAgentRequestAPI()
    const {
        data,
        error,
        isLoading,
    } = useGetOneAgentRequest(id ?? '')
    return (
        <Box sx={{ height: "calc(100vh - 128px)", position: "relative", }} className="hide-scrollbar">
            <Container
                size="1200px"
                py={32}
                px={16}
            >
                <Stack spacing={24}>
                    <Group>
                        <Button variant="subtle" leftIcon={<ArrowLeft />} onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </Group>
                    {/* <Group>
                        <Text
                            fz={32}
                            fw={600}
                            sx={{ flexGrow: 1 }}
                        >
                            View Agent Request
                        </Text>
                    </Group> */}
                    <Box
                        sx={{
                            position: "relative",
                            height: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                position: "sticky",
                                top: 0,
                                left: 0,
                            }}
                        >
                            {!!data && (
                                <AgentRequestCard
                                    data={data}
                                />
                            )}
                        </Box>
                    </Box>
                    {!!data?.replies && data.replies.length > 0 && (
                        <Group>
                            <Text
                                fz={32}
                                fw={600}
                                sx={{ flexGrow: 1 }}
                            >
                                {`Agent Replies (${data.replies.length})`}
                            </Text>
                        </Group>
                    )}
                    {!!data?.replies && (
                        <RepliesList
                            data={data.replies}
                        />
                    )}
                    {!!data?.replies && data.replies.length === 0 && (
                        <Center>
                            <Stack align="center" spacing={8} py={32}>
                                <Text>No agents have replied to this request yet</Text>
                            </Stack>
                        </Center>
                    )}
                </Stack>
            </Container>
        </Box>
    )
}

export default ViewAgentRequest