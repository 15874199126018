import { Stack } from "@mantine/core";
import React from "react";
import { ReplyCard } from "./ReplyCard";

export const RepliesList = ({
    data,
}: any) => {
    return (
        <Stack spacing={8}>
            {!!data && data.map((reply: any) => (
                <ReplyCard data={reply}/>
            ))}
        </Stack>
    )
}