import { Box, Button, Card, Center, Checkbox, Container, Grid, Group, Image, Modal, Stack, Text } from "@mantine/core";
import { useInsightsAPI } from "api/useInsightsAPI";
import { useListerFormController } from "pages/RegisterLister";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RegisterForm from "./components/RegisterForm";
import RegisterFeedback from "components/RegisterFeedback";
import { Permission, useAuth } from "hooks/useAuth";
import { useForm } from "@mantine/form";
import { validateEmail } from "helpers/validations";
import { sanitizePhoneNumber } from "helpers/utility";
import { formStep, validateUserStatus } from "helpers/constants";
import { accountTypes } from "types/accountTypes";
import { useUserAPI } from "api/userAPI";

export const RegisterListerPromo: React.FC<any> = ({
    setView,
    setVerifyData,
    verifyData,
    ReactPixel,
    campaignId
}) => {
    const [promoOpen, setPromoOpen] = useState(true)
    const [currentCampaign, setCurrentCampaign] = useState<any>({})
    const navigate = useNavigate();
    const { useGetUserInsights } = useInsightsAPI()
    const { useGetCampaignDetails } = useUserAPI()
    const {
        data: insightsData,
        isLoading: insightsIsLoading,
        error: insightsError,
    } = useGetUserInsights();

    const {
        data: campaignData,
        isLoading: campaignIsLoading,
        error: campaignError
    } = useGetCampaignDetails({ uid: campaignId })

    const { form, onSubmit, registerMutation, allowSubmit, submitIsLoading } =
        useListerFormController({
            setView,
            setVerifyData,
            verifyData,
            ReactPixel
        });
    const [checkboxes, setCheckboxes] = useState<any>({
        tnc: false,
        privacy: false,
    });
    const handleChange = (key: string) => {
        setCheckboxes((prevState: any) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };
    const allowProceed = useMemo(
        () => Object.values(checkboxes).every((value) => !!value === true),
        [checkboxes]
    );

    useEffect(() => {
        // console.log(campaignData)
        if (!!campaignData && !campaignIsLoading) {
            setCurrentCampaign(campaignData[0])
        }
    }, [campaignData, campaignIsLoading])

    return (
        <>
            <Modal
                fullScreen
                opened={promoOpen && !!currentCampaign}
                onClose={() => setPromoOpen(false)}
                closeOnClickOutside={false}
                closeOnEscape={false}
                withCloseButton={false}
                styles={{
                    'body': {
                        backgroundColor: 'whitesmoke',
                        padding: 0,
                        height: '100vh'
                    }
                }}
            >
                <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
                    <Stack spacing={0} sx={{ height: '100%' }}>
                        {/* <Box sx={{ flexGrow: 1 }}>
                        </Box> */}
                        <Center sx={{ flexGrow: 1 }}>
                            <Image
                                src={currentCampaign?.campaignImage?.url ?? ""}
                                height={'80vh'}
                                fit="contain"
                            />
                        </Center>
                        <Card
                            sx={{ width: '100%' }}
                            // sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}
                            shadow="md"
                        >
                            <Container>
                                <Stack spacing={8}>
                                    <Text align="center" size={'lg'}>
                                        By clicking agree, you agree to our <Text component="span" onClick={() => window.open('/campaign/tnc', '_blank')} sx={{
                                            textDecoration: 'underline',
                                            color: '#417256',
                                            cursor: 'pointer'
                                        }}>terms and conditions</Text>
                                    </Text>
                                    <Group>
                                        <Button
                                            variant="outline"
                                            sx={{ flex: 1 }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            sx={{ flex: 1 }}
                                            onClick={() => {
                                                sessionStorage.setItem("campaign_uid", campaignId)
                                                setPromoOpen(false)
                                            }}
                                        >
                                            Agree
                                        </Button>
                                    </Group>
                                </Stack>
                            </Container>
                        </Card>
                    </Stack>
                </Box>
            </Modal >
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid m={0}>
                    <Grid.Col
                        p={0}
                        md={4}
                        sx={(theme) => ({
                            [theme.fn.smallerThan("md")]: {
                                display: "none",
                            },
                        })}
                    >
                        <Box
                            sx={{
                                backgroundImage: `url('/section-img.png')`,
                                width: "100%",
                                height: "100%",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                minHeight: "calc(100vh - 60px)",
                                position: 'relative'
                            }}
                        >
                            <Box sx={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,0.6)'
                            }}>
                                <Stack sx={{ height: '100%' }} align="center" justify="center" p={8}>
                                    <Text color="white" fz={'3vw'} align="center">
                                        Join <Text color="white" component="span" fw={700}>{100 + insightsData?.totalListers}</Text> others <br /> looking for property today!
                                    </Text>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid.Col>
                    <Grid.Col
                        md={8}
                        xs={12}
                        p={0}
                        sx={(theme) => ({
                            backgroundColor: theme.colors.bgYellow,
                            [theme.fn.largerThan("md")]: {
                                paddingTop: "96px",
                                paddingBottom: "96px",
                            },
                            [theme.fn.smallerThan("md")]: {
                                paddingTop: "24px",
                                paddingBottom: "32px",
                            },
                        })}
                    >

                        <Group sx={{ justifyContent: 'center' }}>
                            <Stack spacing={32} sx={{ maxWidth: "450px" }}>
                                <RegisterForm
                                    form={form}
                                    totalListers={insightsData?.totalListers || null}
                                    checkboxes={checkboxes}
                                    handleCheckboxChange={handleChange}
                                    onSubmit={onSubmit}
                                    submitIsLoading={submitIsLoading}
                                    isSubmitDisabled={submitIsLoading || !allowSubmit || !allowProceed}
                                />
                                <RegisterFeedback />
                            </Stack>
                        </Group>
                    </Grid.Col>
                </Grid>
            </form>
        </>
    );
};