import { Button, Group, Stack, Tabs } from '@mantine/core';
import MyPropRequest from 'pages/MyPropRequest';
import SearchCobrokes from 'pages/SearchCobrokes';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Paywall from './Paywall';
import { useProfileController } from 'hooks/useProfile';

const DashboardCobroke = () => {
    const { isPro, isBasic, isUnsubscribed } =
        useProfileController();
    const navigate = useNavigate()
    return (
        <Stack sx={{ position: "relative" }}>
            {!isPro && (
                <Paywall type="PRO" />
            )}
            <Group sx={{ width: '100%', p: 16 }} position='right'>
                <Button onClick={() => navigate("/requests/create")}>Create Co-Agency request</Button>
            </Group>
            <Tabs defaultValue="requests">
                <Tabs.List>
                    <Tabs.Tab value="requests">My requests</Tabs.Tab>
                    <Tabs.Tab value="browse">Browse</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="browse" pt="xs">
                    <SearchCobrokes />
                </Tabs.Panel>
                <Tabs.Panel value="requests" pt="xs">
                    <MyPropRequest />
                </Tabs.Panel>
            </Tabs>
        </Stack>
    );
};

export default DashboardCobroke;