//@ts-nocheck

import { Badge } from '@mantine/core';
import { PROPERTY_REQUEST_COLOR_SETTINGS } from 'helpers/constants';
import { formatListingType } from 'helpers/utility';
import React from 'react';

const TransactionTypeBadge = ({ transactionType }) => {
    return (
        <Badge
            sx={{
                color: PROPERTY_REQUEST_COLOR_SETTINGS[
                    transactionType
                ]?.color,
                backgroundColor:
                    PROPERTY_REQUEST_COLOR_SETTINGS[
                        transactionType
                    ]?.background,
            }}
        >
            {formatListingType(transactionType)}
        </Badge>
    );
};

export default TransactionTypeBadge;