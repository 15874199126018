import {
    Badge,
    Box,
    Button,
    Card,
    Center,
    Container,
    Grid,
    Group,
    Loader,
    Stack,
    Tabs,
    Image,
    Text,
    Flex,
    Avatar,
    AspectRatio
} from "@mantine/core";
import { PROPERTY_REQUEST_COLOR_SETTINGS, USER_TYPE } from "helpers/constants";
import { Bath, Bed, Edit } from "tabler-icons-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { Permission, useAuth } from "hooks/useAuth";

export default function AgentRequestCard({ ...props }) {
    const navigate = useNavigate();
    const { user } = useAuth(Permission.USER_ONLY)
    const agentRequestData = props.data as any;
    const transactionType = props.data.transactionType as
        | "RENT"
        | "SELL";
    // const tagArr =
    //     !!listingData.tags && listingData.tags.length > 0
    //         ? listingData.tags.map((t: any) => (
    //             <Badge
    //                 sx={{
    //                     background: t.tag.badgeBackground,
    //                     color: t.tag.badgeColor,
    //                 }}
    //             >
    //                 {t.value}
    //             </Badge>
    //         ))
    //         : [];

    // const mergedImages = useCallback(() => {
    //     let mergedArr = [] as any
    //     if (!!listingData?.images && listingData.images.length > 0) {
    //         const imageArr = listingData.images.map((i: any) => ({
    //             src: i.url,
    //             alt: "offer-image",
    //         }));
    //         mergedArr = [...mergedArr, ...imageArr]
    //     }

    //     if (!!listingData?.imageLinks && listingData.imageLinks.length > 0) {
    //         const imageArr = listingData.imageLinks.map((i: any) => ({
    //             src: i,
    //             alt: "offer-image",
    //         }))
    //         mergedArr = [...mergedArr, ...imageArr]
    //     }

    //     return mergedArr
    // }, [listingData])
    // const imageArr = listingData.images.map((i: any) => ({
    //     src: i.url,
    //     alt: "listing-image",
    // }));

    return (
        <Card
            sx={{
                width: "100%",
                border: "1px solid #BFC8C3",
                cursor: user.userType === USER_TYPE.LISTER ? "pointer" : 'auto',
                height: '100%',
                flex: 3,
            }}
            shadow="sm"
            onClick={() => user.userType === USER_TYPE.LISTER && navigate(`/agent-requests/view/${props.data.id}`)}
        >
            <Grid sx={{ height: '100%' }} m={0}>
                {/* <Grid.Col xs={props.vertical ? 12 : 3}>
                    <Center sx={{ height: "100%" }}>
                        <AspectRatio ratio={180/180} w={'100%'}>
                            <Image
                                width={"100%"}
                                height={"100%"}
                                fit="cover"
                                src={mergedImages()[0].src}
                            />
                        </AspectRatio>
                    </Center>
                </Grid.Col> */}
                <Grid.Col xs={12} sx={{ height: '100%' }} p={0}>
                    <Stack spacing={8} sx={{ height: '100%' }}>
                        <Group position="apart">
                            <Group spacing={8}>
                                <Badge
                                    // sx={{
                                    //     color: PROPERTY_REQUEST_COLOR_SETTINGS[
                                    //         transactionType
                                    //     ].color,
                                    //     backgroundColor:
                                    //         PROPERTY_REQUEST_COLOR_SETTINGS[
                                    //             transactionType
                                    //         ].background,
                                    // }}
                                >
                                    {transactionType}
                                </Badge>
                            </Group>
                            <Text fw={700} fz={20}>
                                RM
                                {parseInt(
                                    agentRequestData.askingPrice
                                ).toLocaleString()}
                            </Text>
                        </Group>
                        <Stack spacing={8} justify="space-between" sx={{ width: '100%', height: '100%', flex: 3 }}>
                            <Stack spacing={4} maw={400}>
                                <Text fw={700}>{agentRequestData.description}</Text>
                                <Text fw={500}>{agentRequestData.address}</Text>
                                <Text fw={500}>
                                    {`${agentRequestData.area}, ${agentRequestData.state}`}
                                </Text>
                            </Stack>
                            {/* <Group position="apart"> */}
                            <Stack spacing={8}>
                                <Group spacing={8}>
                                    <Text>
                                        {agentRequestData.propertyTitleType} |{" "}
                                        {agentRequestData.tenure}
                                    </Text>
                                </Group>
                                <Group spacing={8}>
                                    <Text>
                                        {agentRequestData.floorSize} sqft
                                    </Text>
                                    <Group spacing={4}>
                                        <Bed />
                                        <Text>{`${agentRequestData.noOfBedrooms} Bedroom`}</Text>
                                    </Group>
                                    <Group spacing={4}>
                                        <Bath />
                                        <Text>{`${agentRequestData.noOfBathrooms} Bathroom`}</Text>
                                    </Group>
                                </Group>
                                <Text fz={12} ta='right' mt='auto'>
                                    Requested{" "}
                                    {moment(agentRequestData.createdAt).toNow(true)}{" "}
                                    ago
                                </Text>
                                {/* <Group spacing={8}>
                                    <Group spacing={4}>
                                        <Text>
                                            {agentRequestData.noOfBedrooms}
                                        </Text>
                                    </Group>
                                    <Group spacing={4}>
                                        <Text>
                                            {agentRequestData.noOfBathrooms}
                                        </Text>
                                    </Group>
                                </Group> */}
                            </Stack>
                            {/* </Group> */}
                            {/* <Flex
                                sx={{
                                    flexDirection: props.vertical
                                        ? "column"
                                        : "row",
                                    gap: 16,
                                }}
                            >
                                <Box sx={{ flex: 1 }}>
                                    <Group>{tagArr}</Group>
                                </Box>
                                <Button
                                    onClick={() =>
                                        navigate(`/offer/${props.data.id}`)
                                    }
                                >
                                    View listing
                                </Button>
                            </Flex> */}
                        </Stack>
                    </Stack>
                </Grid.Col>
            </Grid>
        </Card>
    );
}
