import { useEffect, useMemo } from "react";
import { Permission, useAuth } from "./useAuth";
import { USER_STATUS } from "helpers/constants";
import { useQuery } from "@tanstack/react-query";
import API from "api/API";
import { flattenObj } from "helpers/strapi";

const REFRESH_USER = "REFRESH_USER";

export const useCanOffer = () => {
    const { user } = useAuth(Permission.USER_ONLY);

    const useRefreshUser = () =>
        useQuery([REFRESH_USER], async () => {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`users/me`);

            return flattenObj(response.data);
        });

    const { data, isLoading, error, refetch } = useRefreshUser();

    const canOffer = useMemo(() => {
        if (!!data && !isLoading) {
            return (
                data.status !== USER_STATUS.PENDING &&
                data.status !== USER_STATUS.BANNED
            );
        }

        return false;
    }, [data, isLoading]);

    return { canOffer, canOfferisLoading: isLoading };
};
