import React, { useMemo, useState, useEffect } from "react";
import {
    Box,
    Grid,
    Text,
    TextInput,
    Button,
    UnstyledButton,
    Center,
    Anchor,
    Checkbox,
    Stack,
    Group,
    Divider,
} from "@mantine/core";
import { BaseFormInput } from "../components/Inputs/BaseFormInput";
import { TransformedValues, useForm } from "@mantine/form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BaseButton } from "components/Buttons/SubmitButton";
import { useAuth, Permission } from "hooks/useAuth";
import { BasePasswordInput } from "components/Inputs/BasePasswordInput";
import { notifications } from "@mantine/notifications";
import { USER_ACTIONS } from "context/user/userActions";
import { RegisterTitle } from "components/Header/userHeader";
import { accountTypes } from "types/accountTypes";
import { AcceptTnc } from "components/Profile/AcceptTnc";
import { RegisterFooter } from "components/Footer/RegisterFooter";
import { FormPasswordInput } from "components/Inputs/FormPasswordInput";
import { RegisterOtp } from "./RegisterOtp";
import { REGISTRATION_PIXEL, validateUserStatus } from "helpers/constants";
import { formStep } from "helpers/constants";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { sanitizePhoneNumber } from "helpers/utility";
import jwt_decode from "jwt-decode";
import SocialLogins from "components/SocialLogins";
import { useInsightsAPI } from "api/useInsightsAPI";
import { validateEmail } from "helpers/validations";
import RegisterFeedback from "components/RegisterFeedback";
import RegisterForm from "./Register/components/RegisterForm";
import { ListerForm } from "./Register/RegisterListerForm";
import { RegisterListerPromo } from "./Register/RegisterListerPromo";
import MaintainenceModal from "components/Modals/MaintainenceModal";

export const useListerFormController = ({
    setView,
    setVerifyData,
    verifyData,
    ReactPixel
}: any) => {
    const {
        registerMutation,
        userDispatch,
        validateMutation,
        requestOtpMutation,
    } = useAuth(Permission.GUEST_ONLY);
    const location = useLocation();
    const navigate = useNavigate();

    const form = useForm({
        initialValues: {
            fullname: "",
            email: "",
            phoneNumber: "",
            password: "",
            passwordConfirm: "",
        },
        validateInputOnChange: true,
        validate: (values) => ({
            fullname:
                values.fullname.length === 0 ? "Full name is required" : null,
            email: validateEmail(values.email),
            phoneNumber:
                values.phoneNumber.length === 0
                    ? "Phone number is required"
                    : null,
            passwordConfirm:
                values.passwordConfirm !== values.password
                    ? "Passwords do not match"
                    : null,
        }),
    });
    type FormValues = typeof form.values;

    const onSubmit = (values: FormValues) => {
        ReactPixel.trackCustom("CompleteRegistration", { type: 'lister' }, [REGISTRATION_PIXEL])
        let formattedIdentifier = sanitizePhoneNumber(`${values.phoneNumber}`);

        if (!!process.env.REACT_APP_IS_DEV) {
            setView(formStep.OTP);
            setVerifyData({
                ...form.values,
                userType: accountTypes.LISTER,
                requestId: "dev",
                phoneNumber: `60${formattedIdentifier}`,
                username: `60${formattedIdentifier}`,
            });
        } else {
            validateMutation.mutate(
                {
                    ...values,
                    phoneNumber: `60${formattedIdentifier}`,
                },
                {
                    onSuccess: (validateData) => {
                        switch (validateData.status) {
                            case validateUserStatus.VALID: {
                                requestOtpMutation.mutate(
                                    { phoneNumber: `60${values.phoneNumber}` },
                                    {
                                        onSuccess: (data) => {
                                            setView(formStep.OTP);
                                            setVerifyData({
                                                ...validateData.verifyUser,
                                                userType: accountTypes.LISTER,
                                                requestId: data.data.requestId,
                                            });
                                        },
                                    }
                                );
                                break;
                            }
                            case validateUserStatus.INVALID: {
                                for (const [field, error] of Object.entries(
                                    validateData.formErrors
                                )) {
                                    form.setFieldError(field, error as string);
                                }
                                break;
                            }
                        }
                    },
                }
            );
        }
    };

    useEffect(() => {
        if (registerMutation.error) {
            const errors = (registerMutation.error as any)?.response?.data
                ?.error?.details?.errors;
            if (errors) {
                errors.forEach(({ path, message }: any) => {
                    // console.log(path[0], message);
                    if (path[0] === "username") {
                        form.setFieldError(
                            "phoneNumber",
                            message.replace("username", "phone number")
                        );
                    }
                    form.setFieldError(path[0], message);
                });
            }
        }
    }, [registerMutation.error]);

    const allowSubmit = useMemo(() => {
        return form.isValid() && Object.keys(form.errors).length === 0;
    }, [form]);

    useEffect(() => {
        if (verifyData) {
            form.setValues({
                fullname: verifyData.fullname,
                email: verifyData.email,
                phoneNumber: verifyData.phoneNumber.slice(2),
                password: verifyData.password,
                passwordConfirm: verifyData.password,
            });
        }
    }, [verifyData]);

    return {
        form,
        onSubmit,
        registerMutation,
        allowSubmit,
        submitIsLoading:
            validateMutation.isLoading || requestOtpMutation.isLoading,
    };
};


const RegisterLister = (props: any) => {
    const { user } = useAuth(Permission.GUEST_ONLY)
    useEffect(() => {
        if (!!user.jwt) {
            navigate("/")
        }
    }, [user])

    const navigate = useNavigate();
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const campaignId = searchParams.get("campaign")
    const [view, setView] = useState<formStep>(formStep.REGISTER);
    const [verifyData, setVerifyData] = useState<any>(null);
    // const {
    //     form,
    //     onSubmit,
    //     registerMutation,
    //     allowSubmit
    // } = useListerFormController({
    //     // onRegisterSuccess: () => {
    //     //     navigate("/profile", {
    //     //         state: {
    //     //             fromRegister: true,
    //     //         },
    //     //     });
    //     // },
    //     onRegisterSuccess: () => setView(formStep.OTP),
    // });

    const renderSwitch = () => {
        if (!!campaignId) {
            return <RegisterListerPromo
                setView={setView}
                setVerifyData={setVerifyData}
                verifyData={verifyData}
                {...props}
                campaignId={campaignId}
            />
        }

        return <ListerForm
            setView={setView}
            setVerifyData={setVerifyData}
            verifyData={verifyData}
            {...props}
        />
    }

    useEffect(() => {
        if (!!location.state) {
            setView(formStep.OTP)
            setVerifyData(location.state.data)
            console.log("state", location.state.data)
        }
    }, [location])

    const content = useMemo(() => {
        switch (view) {
            case formStep.REGISTER: {
                return (
                    <>
                        {renderSwitch()}
                    </>
                );
            }
            case formStep.OTP: {
                return (
                    <RegisterOtp verifyData={verifyData} setView={setView} {...props} />
                );
            }
        }
    }, [view, verifyData]);

    return (
        <>
            {/* <MaintainenceModal /> */}
            <Box>{content}</Box>
        </>
    );
};

export default RegisterLister;
