import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Text,
    ScrollArea,
    Container,
    Checkbox,
    Group,
    Button,
    Divider,
    Stack,
    Image,
} from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import ReactMarkdown from "react-markdown";

const tncMarkdown = `Please read these terms carefully before using the web site. Using the site indicates that you accept
these terms.

Use of site

**Propmoth.com** (the "Site") is operated by PropMoth Capital (Malaysia) Sdn. Bhd. incorporated in
Malaysia.

We may at any time change these terms and you should therefore periodically visit this page to review
the then current terms to which you are bound.

Unless otherwise noted, you should assume that the copyright in everything you see or read on the
Site including but not limited to any designs, text, graphics, source code, or software belongs to
PropMoth Capital (Malaysia) Sdn. Bhd. Your access to it does not imply a license to reproduce
and/or distribute this information which means that you cannot reproduce, modify, publicly display or
distribute the contents of this Site without the prior written consent of PropMoth Capital
(Malaysia) Sdn. Bhd.

The trademarks and logos displayed on the Site are the registered and unregistered trademarks of
their respective owners. Use of the trade marks displayed is strictly prohibited. You are advised that
the owners of the trade marks will aggressively enforce their intellectual property rights to the fullest
extent of the law, including seeking criminal prosecution.

You agree that you will not use this website or our services to carry out any "data mining" services to
collect or collate information about the identities and offers of our clients and/or advertisers.

About the information

PropMoth Capital (Malaysia) Sdn. Bhd. uses reasonable efforts to include accurate and up to
date information. However, PropMoth Capital (Malaysia) Sdn. Bhd. makes no warranties or
representations as to its accuracy. In particular, PropMoth Capital (Malaysia) Sdn. Bhd.
assumes no liability or responsibility for any errors or omissions in the content of the Site.

Everything on the Site is provided on an "as is" basis without any representation, endorsement or
warranty of any kind, either express or implied, including, but not limited to, warranties of title,
merchantability or fitness for a particular purpose or non-infringement.

Submitted information

Any information you provide to PropMoth Capital (Malaysia) Sdn. Bhd. through or in connection
with this Site will be treated as non-confidential and non-proprietary. Anything you transmit or post
becomes the property of PropMoth Capital (Malaysia) Sdn. Bhd. or its affiliates who are free to
use the information for any purpose.

You agree that no Submissions provided by you to the Site will violate any right of any third party,
including copyright, trademark, privacy or other personal or proprietary right(s). You are and shall
remain solely responsible for the content of any Submissions you make.

Through your usage of this Site, you may submit and/or PropMoth Capital (Malaysia) Sdn. Bhd.
may gather certain limited information about you and your Site usage. PropMoth Capital
(Malaysia) Sdn. Bhd. is free to use such information as stated in its privacy policy.

Links to other websites

This Site may contain links to other web sites. These are provided solely as a convenience to you and
access to any of these linked sites is at your own risk.

PropMoth Capital (Malaysia) Sdn. Bhd. does not control and is not responsible for any of these
sites or their content.

PropMoth Capital (Malaysia) Sdn. Bhd. does not allow unauthorised hypertext links to the Site.

Limitation of liability


You agree that your use of the Site is at your sole risk and that you are responsible for all costs
associated with your access to, or use of, the Site. PropMoth Capital (Malaysia) Sdn. Bhd., its
directors, employees or any other representative will not be liable for any damages, losses, costs,
claims or demands of any kind, whether direct, indirect, compensatory or consequential, related to
your use of this Site or the information content, materials or products included on this Site. These
terms are governed by Malaysia law and any dispute will be resolved exclusively by the Malaysian
courts.

Changes to these Terms and Conditions

The nature of our business is constantly evolving and therefore our Privacy Policy and Terms Of
Service will also change over time. You should check the website frequently to see if any recent
amendments or additions have been made.

**Privacy Policy**

PropMoth Pte Ltd’s subsidiaries, PropMoth Capital (Malaysia) Sdn. Bhd. and MyProperty Data
Sense Sdn Bhd (collectively referred to as "we" or "us" or “our”) operate
https://www.PropMoth.com.my/ and https://www.PropMothgroup.com/datasense/, respectively,
(collectively referred to as the "Website" or “Websites” as the context requires). PropMoth
International (Malaysia) Sdn. Bhd also operates the PropMoth mobile applications (the “Apps”). We
are committed to safeguarding your personal data.

This Privacy Policy (together with our Terms of Service https://www.PropMoth.com/terms-of-service
and any other documents referred to in it) sets out how personal data that we collect from you, or that
you provide to us, will be used, processed or disclosed by us, including how certain information would
be used to personalise advertising directed at you formulated in accordance with the Personal Data
Protection Act 2010 (“Act”). This Privacy Policy also sets out the types of personal data we collect,
how we keep it secure, as well as how you can access or alter your personal data stored by us. This
Privacy Policy covers use of the Website, as well as the Apps, and any other way you may connect to
or interact with us through the use of our products and services, hereinafter collectively referred to as
Services.

Please read the following carefully to understand our policy and practices regarding your personal
data and how we will treat it.

Unless restricted by applicable law, you agree that any and all personal data relating to you collected
by us or provided by you from time to time may be used, processed, and disclosed for such purposes
and to such persons as set out in this Privacy Policy.

We may amend this Privacy Policy at any time by posting a revised version on the Website. While we
endeavor to send an individual notice every time the policy is amended, you will be deemed to have
acknowledged and agreed to the amended Privacy Policy if you continue to use the Website, the Apps
or our services after the changes have taken effect. You are reminded to check this Website from
time to time for updates to this Privacy Policy.

## COLLECTION OF PERSONAL DATA


You can choose to browse the Website and the Apps without disclosing your personal data. You are
not required to provide personal data as a condition of using the Website or the Apps, except as may
be necessary for us to be able to provide the products and services which you purchase or access
through our Website or Apps, respond to your requests, or in cases where you contact us directly.

When you use our Services, we collect a variety of information from and about you, your devices, and
your interaction with the Services. Some of this information identifies you directly or can be used to
loosely identify you when combined with other data.

1.1 Information you willingly provide. When using our Services, you may be asked to provide personal
information about yourself, such as your name, contact information, payment information, details
about your home or properties you are interested in, financial information. This may occur, for
example,

when you carry out transactions with us on or in relation to the Website or the Apps;

when you register an account with us on the Website or the Apps;

when you contact our customer service or sales people by email, telephone, or in person, to resolve
any issues that you may be facing or with inquiries or requests; and

when you subscribe to the services that we are providing on the Website or the Apps, or for our email
notifications, and/or newsletters.

when you correspond with a real estate professional (such as a real estate agent or broker, mortgage
lender or loan officer, property manager, investor, homebuilder, or others) via the Services

when you complete other forms or transactions, such as a request for loan information or a rental
housing and background check application.

you may also provide information about a third party through the Services, for example, if you share a
real estate listing with a recipient via email or SMS. Some information you provide through our
Services is collected and processed by third parties (such as google analytics) on our behalf.

1.2 Cookies, Pixels, and other tracking mechanisms. We and our partners use various technologies to
collect information automatically when you access and use our Services, including cookies, and other
similar technologies. Cookies are bits of electronic information that can be transferred to your
computer or other electronic device to uniquely identify your browser. When you use the Services, we
and our partners may place one or more cookies on your computer or other electronic devices or use
other technologies that provide similar functionality.

We and our partners may use cookies to connect your activity on the Services with other information
we store about you in your account profile or your prior interactions on our Services to, for example,
store your preferences. The use of cookies helps us improve the quality of our Services to you, by
identifying information which is most interesting to you, tracking trends, measuring the effectiveness
of advertising, or storing information you may want to retrieve on a regular basis, such as your
shortlisted properties or preferred searches. At any time, you may adjust settings on your browser to
refuse cookies according to the instructions related to your browser. However, if you choose to
disable cookies, many of the free features of the Services may not operate properly.

Some of our pages may also include pixels, which are electronic files to count users who have visited
that page, to track activity over time and across different websites, to determine users’ interactions


with emails we send, to identify certain cookies on the computer or other electronic device accessing
that page, or to collect other related information, and this information may be associated with your
unique browser, device identifier, or IP address. We may, for example, implement a pixel on specific
Services where you view a certain listing so that we can track whether the listing is of relevance to
you while offering recommendations. Through these pixels and cookies, we collect information about
your use of our Services, including the type of browser you use, access times, pages viewed, your IP
address and the page you visited before navigating to our Services.

We also collect information about the computer or mobile device you use to access our Services,
such as the hardware model, operating system and version, unique device identifiers, mobile network
information, and browsing behaviour.

We also allow certain third parties to collect information about your online activities through cookies
and other technologies when you use the Website or the Apps. These third parties include (a)
business partners, who collect information when you view or interact with one of their advertisements
on the Website or the Apps, and (b) advertising networks, who collect information about your interests
when you view or interact with one of the advertisements they place on many different web sites on
the Internet. The information gathered by these third parties are non-personally identifiable
information which is used to make predictions about your characteristics, interests or preferences
and to display advertisements on the Website, the Apps, and across the Internet which are tailored to
your interests

Many of the third parties we allow to collect information on the Website and the Apps are members of
the Network Advertising Initiative, which offers a single location to opt out of advertising targeting
from member companies. To opt out, please follow the link:
[http://www.networkadvertising.org/choices/and](http://www.networkadvertising.org/choices/and) follow the instructions on the site. Another good way
of opting out is through the following link: [http://www.aboutads.info/choices/.](http://www.aboutads.info/choices/.) Lastly, you can
manage the type of cookies that are allowed through your browser settings, including completely
blocking all cookies if you so wish. For information about how to manage cookies on your browser,
please see the help section of the browser you are using.

1.3 Mobile device and mobile browser information. You may adjust settings on your mobile device
and mobile browser regarding cookies and sharing of certain information, such as your mobile device
model or the language your mobile device uses, by adjusting the privacy and security settings on your
mobile device. Please refer to the instructions provided by your mobile service provider or mobile
device manufacturer.

1.4 Location Data. If you enable location services on your mobile device, PropMoth Group with your
permission may collect the location of your device, which we use to provide you with location-based
information and advertising. If you wish to deactivate this feature, you can disable location services
on your mobile device.

1.5 Email Management. You may receive email from PropMoth Group for a variety of reasons - for
example, if you took an action through the Services, you signed up for a regular report, or you posted
your home for sale or rent and a buyer sent you a message. If you have an account with PropMoth
Group, you may edit your preferences through your account settings. Also, you can manage your
receipt of some types of communication by following the instructions included in the email we send
you. Please note that, even if you unsubscribe from certain email correspondences, we may still need
to email you with important transactional or administrative information.


## PURPOSES OF USE

2.1 The personal data which you provide may be used and processed by us in the aggregate or
individually and may be combined with other information for the purpose of managing and operating
the Website and the Apps, the scope of which may include, but is not limited to the following:

administering the Website and the Apps;

improving your browsing experience by personalising the Website and the Apps;

enabling your use of the services available on the Website and the Apps;

providing to you the products and services that you have specifically requested;

responding to your enquiries or requests pursuant to your emails and/or submission of form(s) on the
Website or the Apps;

responding to and taking follow-up action on complaints regarding the use of the Website, the Apps
or any product or service provided by us;

sending you general commercial communications via any communication channel, not limited to
email, voice call, SMS and app notifications;

performing office and operational administrative matters; and

providing third parties with statistical information about our users. Such statistical information may
be used to personalise advertising on the Website or the Apps, but cannot be used to identify any
individual user. For details on how to opt out of personalised advertising, please refer to section 8 of
this Privacy Policy.

2.2 We will not, without your express consent, disclose your personal data to third parties for the
purposes of direct marketing. Consent for disclosure of personal data to third parties, for the
purposes of receiving marketing communications, is given through our registration form, either online
or on paper. You can withdraw your consent by contacting our customer service:
https://www.PropMothgroup.com/contact-us/. If you have given your consent when registering as a
member on the Website, you can also withdraw your consent by revisiting the online registration form:
[http://www.PropMoth.com.my/user-profile,](http://www.PropMoth.com.my/user-profile,) and unticking the consent box.

## CONFIDENTIALITY

3.1 Personal Data held by us will be kept confidential in accordance with this Privacy Policy pursuant
to any applicable law that may from time to time be in force.

3.2 Any questions, comments, suggestions or information other than Personal Data sent or posted to
the Websites, or any part thereof by Users will be deemed voluntarily provided to us on a non-
confidential and non-proprietary basis.


3.3 We reserve the right to use, reproduce, disclose, transmit, publish, broadcast and/or post
elsewhere such information freely without further reference to you.

## SECURITY

4.1 We will take reasonable technical and organizational precautions to prevent the loss, misuse or
alteration of your personal data.

4.2 We will store all the personal data you provide on secure servers in an encrypted format.

4.3 You however understand that the transmission of information via the Internet is not completely
secure. Although we will do our best to protect your personal data, we cannot guarantee the security
of your personal data transmitted to the Website or the Apps; any transmission is at your own risk.

4.4 Where we have given you (or where you have chosen) a password which enables you to access
certain parts of the Website or the Apps, you are responsible for keeping this password confidential.
We ask you not to share a password with anyone. We will NEVER ask you for your password other
than when you log in to the Website or the Apps, as the case may be.

4.5 We urge you to take steps to keep your personal information safe, such as choosing a strong
password and keeping it private, as well as logging out of your user account, and closing your web
browser when finished using our services on a shared or unsecured device.

## DISCLOSURE OF PERSONAL DATA

5.1 In order to manage and operate the Website and the Apps, we may disclose your personal data to
any member of our group, which means our affiliates and subsidiaries. We may also disclose your
personal data to third parties such as our service providers and professional advisors for the
following purposes:

to comply with any legal requirement; to enforce or apply our Terms of Service
https://www.PropMoth.com.my/terms-of-service and other agreements;

to protect our rights, property, and safety, and the rights, property and safety of our affiliates and
subsidiaries, other users of the Website or the Apps, and others;

in connection with any legal proceedings or prospective legal proceedings; and

in connection with a business asset transaction (i.e. the purchase, sale, lease, merger or
amalgamation or any other acquisition, disposal or financing of an organisation or a portion of an


organisation or of any of the business or assets of an organisation) to which we are a party or a
prospective party.

## INTERNATIONAL DATA TRANSFERS

6.1 We operate in many countries and may temporarily store, process or transfer your personal data
between any of the countries in which we operate in order to enable us to use your personal data in
accordance with this Privacy Policy and our Terms of Service https://www.PropMoth.com.my/terms-
of-service.

6.2 We will take all steps reasonably necessary to ensure that your personal data is treated securely
and in accordance with this Privacy Policy.

## THIRD PARTY WEBSITES

7.1 The Website and the Apps may, from time to time, contain links to and from the websites of our
partner networks and vendors. If you follow a link to any of these websites, please note that these
websites have their own privacy policies and that we do not accept any responsibility or liability for
these policies. Please check these policies before you submit any personal data to these websites.

## ACCESS, DELETION AND CORRECTION

8.1 Our Data Protection officer is responsible for ensuring that requests for access, deletion and
amendment of personal data are properly dealt with in accordance with the procedures specified
herein.

8.2 All requests for access, deletion and amendment of personal data held by us shall be made by
completing our standard Request for Information/Deletion/Amendment of Information form (PDF
available here https://www.PropMoth.com.sg/customer-service/privacy/request-form), and sending a
copy of the duly completed form to us via post at:

PropMoth Capital (Malaysia) Sdn. Bhd.

Unit A- 9 - 10, United Point,

52100 Kuala Lumpur, Malaysia

Attention: Data Protection Officer


8.3 Please note that we will only accept and act on requests in the English language that comply with
this Privacy Policy. Any request that does not comply with the above will be rejected, and we will not
take any action in relation to such rejected requests.

8.4 We may refuse to disclose, delete or amend any personal data for the reasons set out in the Act.

8.5 As far as reasonably possible, we will respond to your request within ten (10) working days from
the date of receipt of the request. If that is not possible, you will be so notified within ten (10) working
days from the date of receipt of the request.

## DISCLAIMER

9.1 We shall not be liable for any voluntary disclosure of personal data by you to other users in
connection with the use of the Website or the Apps, as the case may be.

## CONTACT US

10.1 Should you require further information about the treatment of your personal data by us, please
do not hesitate to contact our Data Protection Officer at: admin@propmoth.com.

## CONFLICT

11.1 In the event of conflict between the English and Bahasa Melayu versions of the Privacy Policy,
the English version of the Privacy Policy shall prevail.`;

export const AgentRequestTnc: React.FC<any> = ({}) => {
    const navigate = useNavigate();
    const [scroll, scrollTo] = useWindowScroll();
    const [checkboxes, setCheckboxes] = useState<any>({
        tnc: false,
        privacy: false,
    });
    const handleChange = (key: string) => {
        setCheckboxes((prevState: any) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };
    const allowProceed = useMemo(
        () => Object.values(checkboxes).every((value) => !!value === true),
        [checkboxes]
    );

    useEffect(() => {
        scrollTo({ y: 0 });
    }, []);

    return (
        <Container
            size="1200px"
            sx={(theme) => ({
                backgroundColor: 'whitesmoke',
            })}
        >
            <Stack spacing={24}>
                <Group py={16}>
                    <Image
                        src={"/logo.svg"}
                        alt={"propmoth logo"}
                        width={148}
                        height={48}
                    />
                </Group>
                <Group position="apart">
                    <Text fz={32} fw={600}>
                        Terms and Conditions
                    </Text>
                </Group>
                <Divider my={8} />
                <Box>
                    <ReactMarkdown>{tncMarkdown}</ReactMarkdown>
                </Box>
            </Stack>
        </Container>
    );
};
