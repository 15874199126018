import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    Text,
    Grid,
    Flex,
    Avatar,
    Divider,
    AspectRatio,
    Image,
    Button,
    useMantineTheme,
    Group,
    Stack,
    Card,
} from "@mantine/core";
import testImage3 from "assets/testImage3.png";
import {
    IconUser,
    IconMail,
    IconPhoneFilled,
    IconFlag,
    IconUsers,
    IconBrandGooglePhotos,
    IconTool,
    IconMapPin,
    IconInfoCircle,
    IconPencil,
    IconBrush,
    IconPawFilled,
    IconFence,
    IconTrain,
    IconShieldCheckered,
} from "@tabler/icons-react";
import { useMediaQuery, useScrollIntoView } from "@mantine/hooks";
import { DetailsMultipleColumn } from "components/Details/Details";
import { useProfileController } from "hooks/useProfile";
import { DATE_DISPLAYS, PROPERTY_REQUEST_TYPE, USER_TYPE } from "helpers/constants";
import { ListerDetail } from "types/userTypes";
import moment from "moment";
import { useListingAPI } from "api/useListingAPI";
import ViewPropRequest from "pages/ViewPropRequest";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FeedbackBanner from "./FeedbackBanner";
import OfferWhatsappBanner from "components/Banners/OfferWhatsappBanner";
import AgentRequestsList from "components/AgentRequestsList";
import { notifications } from "@mantine/notifications";
import ConfirmeDeleteRequestModal from "components/Modals/ConfirmDeleteRequestModal";
import { Permission, useAuth } from "hooks/useAuth";
import { UserDetailModal } from "components/Modals/UserDetailModal";
import ContinueDraftModal from "components/Modals/ContinueDraftModal";
import { accountTypes } from "types/accountTypes";
import CustomHeader from "components/Header/Header";
import CustomFooter from "components/Footer/Footer";

export const ListerProfile: React.FC<any> = ({ }) => {
    const { user } = useAuth(Permission.ANYONE);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { hash } = useLocation()
    const navigate = useNavigate();
    const { useGetMyPropRequests, useUpdatePropRequest } = useListingAPI();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const { profileData, profileIsLoading, view } = useProfileController();
    const [isReadMore, setIsReadMore] = useState(true);
    const [selectedRequestType, setSelectedRequestType] = useState(
        PROPERTY_REQUEST_TYPE.BUY
    );
    const [showDelete, setShowDelete] = useState(false)

    const updatePropRequest = useUpdatePropRequest();
    const { data, isLoading, error, refetch } = useGetMyPropRequests(
        profileData?.user?.id.toString() || "0"
    );
    const { scrollIntoView, targetRef } = useScrollIntoView<
        HTMLDivElement,
        HTMLDivElement
    >();

    const [detailModalOpened, setDetailModalOpened] = useState(false);
    const [draftModalOpened, setDraftModalOpened] = useState(false);
    const [draftData, setDraftData] = useState<any>(null)

    useEffect(() => {
        const tab = searchParams.get("tab")
        if (!!tab && tab === "chats") {
            navigate("/chats")
        }
    }, [searchParams])

    useEffect(() => {
        console.log(hash)
        if (!!hash && hash === "#offers") {
            scrollIntoView()
        }
    }, [hash])

    const activePropRequestData = useMemo(() => {
        if (!!data && !isLoading) {
            if (data.length > 0) {
                let [propRequest] = data;

                return propRequest;
            }

            return null;
        }

        return null;
    }, [data, isLoading]);

    const onSelectRequestType = (type: any) => {
        setSelectedRequestType(type);
    };

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    const userData = useMemo(
        () => ({
            profilePic: profileData?.user?.avatar?.formats?.thumbnail?.url,
            name: profileData?.user?.fullname,
            joinDate: moment(profileData?.user?.createdAt).format(
                DATE_DISPLAYS.PROFILE
            ),
            about: profileData?.remark,
            buyRequest: 3,
            rentRequest: 5,
            investRequest: 0,
        }),
        [profileData]
    );

    const userSummary = useMemo(
        () => [
            {
                icon: <IconUser width={20} height={20} />,
                type: "Name",
                text: profileData?.user?.fullname,
            },
            {
                icon: <IconMail width={20} height={20} />,
                type: "Email",
                text: profileData?.user?.email,
            },
            {
                icon: <IconPhoneFilled width={20} height={20} />,
                type: "Phone Number",
                text: profileData?.user?.phoneNumber,
            },
            {
                icon: <IconFlag width={20} height={20} />,
                type: "Nationality",
                text: profileData?.nationality,
            },
            {
                icon: <IconUser width={20} height={20} />,
                type: "Race",
                text: profileData?.race,
            },
            {
                icon: <IconUsers width={20} height={20} />,
                type: "Marital Status",
                text: profileData?.maritalStatus,
            },
            {
                icon: <IconBrandGooglePhotos width={20} height={20} />,
                type: "No. of Children",
                text: profileData?.noOfChildren,
            },
            {
                icon: <IconTool width={20} height={20} />,
                type: "Occupation",
                text: profileData?.occupation,
            },
            {
                icon: <IconMapPin width={20} height={20} />,
                type: "Postcode",
                text: "",
            },
        ],
        [profileData]
    );

    const handleDeletePropRequest = useCallback(async () => {
        try {
            await updatePropRequest.mutateAsync({
                status: "DELETED",
                id: activePropRequestData?.id
            })

            setShowDelete(false)

            refetch()

            notifications.show({
                title: "Success!",
                message: "Successfully deleted property request, you may create another now!",
                autoClose: 2000,
            });
        } catch (e) {
            notifications.show({
                title: "Error deleted property request",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    }, [activePropRequestData])

    const UserDetails = () => {
        return (
            <Grid gutter={"31.5px"} pb={32} px={0} m={0}>
                <Grid.Col pb={16} p={0}>
                    <Text
                        weight={600}
                        size={20}
                        sx={{ lineHeight: "30px" }}
                    >{`About ${userData.name}`}</Text>
                </Grid.Col>
                <Grid.Col md={4} p={0}>
                    <Flex
                        w={"100%"}
                        direction="column"
                        align="center"
                        p={24}
                        gap="32px"
                        sx={{
                            border: "1px solid #BFC8C3",
                            background: "#FFFFFF",
                            borderRadius: "12px",
                        }}
                    >
                        <Flex
                            direction="column"
                            align="center"
                            w={"100%"}
                            p={0}
                            gap={10}
                        >
                            <Avatar
                                w={56}
                                h={56}
                                src={userData.profilePic}
                                alt="agent"
                            />
                            <Flex
                                w={"100%"}
                                direction="column"
                                align="center"
                                p={0}
                                gap={8}
                            >
                                <Flex
                                    w={"100%"}
                                    direction="column"
                                    align="center"
                                    p={0}
                                    gap={0}
                                >
                                    <Text
                                        weight={500}
                                        size={14}
                                        sx={{ lineHeight: "20px" }}
                                    >
                                        {userData.name}
                                    </Text>
                                    <Text
                                        size={12}
                                        sx={{ lineHeight: "18px" }}
                                    >{`Joined PropMoth on ${userData.joinDate}`}</Text>
                                </Flex>
                                <Text
                                    align="center"
                                    size={12}
                                    sx={{ lineHeight: "18px" }}
                                >
                                    {userData.about}
                                </Text>
                            </Flex>
                        </Flex>
                        {/* <Grid grow gutter={24} m={0} p={0} w={"100%"}>
                            <Grid.Col span={"auto"} py={0} pl={0}>
                                <Text
                                    size={12}
                                    align="center"
                                    sx={{ lineHeight: "18px" }}
                                >
                                    Buy request
                                </Text>
                                <Text
                                    size={14}
                                    align="center"
                                    sx={{ lineHeight: "20px" }}
                                >
                                    {userData.buyRequest}
                                </Text>
                            </Grid.Col>

                            <Divider orientation="vertical" size={"sm"} />
                            <Grid.Col span={"auto"} py={0}>
                                <Text
                                    size={12}
                                    align="center"
                                    sx={{ lineHeight: "18px" }}
                                >
                                    Rent request
                                </Text>
                                <Text
                                    size={14}
                                    align="center"
                                    sx={{ lineHeight: "20px" }}
                                >
                                    {userData.rentRequest}
                                </Text>
                            </Grid.Col>
                            <Divider orientation="vertical" size={"sm"} />
                            <Grid.Col span={"auto"} py={0} pr={0}>
                                <Text
                                    size={12}
                                    align="center"
                                    sx={{ lineHeight: "18px" }}
                                >
                                    Invest request
                                </Text>
                                <Text
                                    size={14}
                                    align="center"
                                    sx={{ lineHeight: "20px" }}
                                >
                                    {userData.investRequest}
                                </Text>
                            </Grid.Col>
                        </Grid> */}
                    </Flex>
                </Grid.Col>
                <Grid.Col md={8}>
                    <Grid>
                        {Object.values(userSummary).map(
                            (row: any, index: number) => (
                                <Grid.Col key={index} md={6} sm={6}>
                                    <Group spacing={4}>
                                        {row.icon}
                                        <Text
                                            pl={8}
                                            pr={4}
                                        >{`${row.type}:`}</Text>
                                        <Text weight={600}>{`${row.text === "" || row.text === null
                                            ? "-"
                                            : row.text
                                            }`}</Text>
                                    </Group>
                                </Grid.Col>
                            )
                        )}
                    </Grid>
                </Grid.Col>
            </Grid>
        );
    };

    useEffect(() => {
        if (location?.state?.fromRegister) {
            if (user?.userType === accountTypes.LISTER) {
                setDetailModalOpened(true);
            }
            window.history.replaceState({}, document.title);
        }
    }, [user, location?.state]);

    useEffect(() => {
        if (!!user && !!user.jwt) {
            const hasPostLoginAction = sessionStorage.getItem("pre_login_draft");

            if (!!hasPostLoginAction && user.userType === USER_TYPE.LISTER) {

                let draft = JSON.parse(hasPostLoginAction)
                console.log({ draft })
                setDraftData(draft)
                setDraftModalOpened(true)
            }
        }
    }, [user])

    const shouldDetailModalOpen = useMemo(() => {
        return detailModalOpened && !draftModalOpened
    }, [detailModalOpened, draftModalOpened])

    const handleDraftClose = () => {
        refetch()
        setDraftModalOpened(false)
    }

    return (
        <>
            <CustomHeader />
            <Stack py={32}>
                <ConfirmeDeleteRequestModal
                    opened={showDelete}
                    onClose={() => setShowDelete(false)}
                    onConfirm={() => handleDeletePropRequest()}
                />
                <FeedbackBanner />
                <Grid>
                    <Grid.Col md={6}>
                        <Card
                            shadow="md"
                            radius={'md'}
                            sx={{ border: "1px solid #417256", height: '100%', cursor: 'pointer' }}
                            onClick={() => navigate("/requests/create")}
                        >
                            <Stack align="center" h="100%" justify="space-between">
                                <Text align="center" fz={24} fw="bold" color="#417256">Buy / Rent / Browse</Text>
                                <Text align="center">Start looking for the perfect property with request system!</Text>
                                <Button
                                    onClick={() => navigate("/requests/create")}
                                >
                                    Create Property Request
                                </Button>
                            </Stack>
                        </Card>
                    </Grid.Col>
                    <Grid.Col md={6}>
                        <Card
                            shadow="md"
                            radius={'md'}
                            sx={{ border: "1px solid #417256", height: '100%', cursor: 'pointer' }}
                            onClick={() => navigate("/agent-requests/create")}
                        >
                            <Stack align="center" h="100%" justify="space-between">
                                <Text align="center" fz={24} fw="bold" color="#417256">Sell / Rent Your Property</Text>
                                <Text align="center">Sell or Rent out your property by listing it so that vendors can offer their services to help you</Text>
                                <Button onClick={() => navigate("/agent-requests/create")}>List your Property</Button>
                            </Stack>
                        </Card>
                    </Grid.Col>
                </Grid>

                <Box
                    sx={(theme) => ({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "2px solid lightgrey",
                        [theme.fn.smallerThan('xs')]: {
                            flexDirection: "column"
                        }
                    })}
                    py={24}
                >
                    <Text size={32} weight={500}>
                        My profile
                    </Text>
                    <Group>
                        {!activePropRequestData ? (
                            <>
                            </>
                        ) : (
                            <Button
                                variant="outline"
                                onClick={() => scrollIntoView()}
                            >
                                View Offers
                            </Button>
                        )}
                        <Button onClick={() => navigate("edit")}>
                            Edit Profile
                        </Button>
                    </Group>
                </Box>
                <UserDetails />
                {!!activePropRequestData && (
                    <Stack>
                        <OfferWhatsappBanner />
                        <Card
                            radius={"md"}
                            sx={{
                                backgroundColor: "#F1FFF8",
                                border: "1px solid #2C5314"
                            }}
                            shadow="md"
                        >
                            <Stack>
                                <Group position="right">
                                    <Button variant="outline" color="red" onClick={() => setShowDelete(true)}>Delete</Button>
                                    <Button variant="outline" onClick={() => navigate(`/requests/edit/${activePropRequestData?.id}`)}>Edit</Button>
                                </Group>
                                <ViewPropRequest
                                    requestId={activePropRequestData?.id}
                                    externalRef={targetRef}
                                    externalScrollIntoView={scrollIntoView}
                                    profileView
                                />
                            </Stack>
                        </Card>
                    </Stack>
                )}
                <AgentRequestsList />
                <UserDetailModal
                    opened={shouldDetailModalOpen}
                    onCancel={() => setDetailModalOpened((state) => !state)}
                />
                <ContinueDraftModal
                    opened={draftModalOpened}
                    close={() => handleDraftClose()}
                    draftData={draftData}
                />
                {/* <RequestDetails /> */}
            </Stack>
        </>
    );
};
