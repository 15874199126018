import { Box, Button, Container, Divider, Group, Modal, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useAgentRequestAPI } from 'api/useAgentRequestApi';
import AgentRequestCard from 'components/AgentRequestCard';
import RichTextEditor from 'components/RichTextEditor';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft } from 'tabler-icons-react';

const AgentReplyForm = () => {
    const navigate = useNavigate()
    const [submitConfirm, setSubmitConfirm] = useState(false)
    const { id } = useParams()
    const { useCreateAgentRequestReply, useGetOneAgentRequest } = useAgentRequestAPI()
    const {
        data: agentRequestData,
        error: agentRequestError,
        isLoading: agentRequestIsLoading,
    } = useGetOneAgentRequest(id ?? '')
    const createReply = useCreateAgentRequestReply()
    const form = useForm<any>({
        initialValues: {
            title: "",
            intro: null,
        },
        validate: {
            title: (value) => (!!value ? null : "Please fill in this field"),
            intro: value => !!value ? null : "Please fill in this field"
        }
    })

    const onChange = (json: any) => {
        form.setFieldValue('intro', json)
    }

    const onSubmit = () => {
        const payload = {
            ...form.values,
            agentRequest: !!id ? +id : '',
        }
        createReply.mutate(payload, {
            onSuccess: () => {
                notifications.show({
                    title: "Success!",
                    message: "Successfully posted reply",
                    autoClose: 2000,
                })
                navigate('/masterlist')
            },
            onError: () => {
                notifications.show({
                    title: "Error posting reply",
                    message: "If this issue persist, please contact our support",
                    autoClose: 2000,
                });
            }
        })
    }

    const validateForm = () => {
        form.validate()
        const isValid = form.isValid();
        if (isValid) {
            setSubmitConfirm(true)
            return
        }
    }

    return (
        <Box
            sx={{
                position: "relative",
            }}
        >
            <Modal
                centered
                opened={submitConfirm}
                onClose={() => setSubmitConfirm(false)}
                title={"Confirm Post Reply?"}
            >
                <Stack>
                    <Group sx={{ width: "100%" }}>
                        <Button
                            onClick={() => setSubmitConfirm(false)}
                            sx={{ flexGrow: 1 }}
                            variant="outline"
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => onSubmit()}
                            sx={{ flexGrow: 1 }}
                        >
                            Yes
                        </Button>
                    </Group>
                </Stack>
            </Modal>
            <Container
                size="1200px"
                pt={32}
                px={16}
            >
                <Stack spacing={24}>
                    <Group>
                        <Button
                            variant="subtle"
                            leftIcon={<ArrowLeft />}
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </Button>
                    </Group>
                    {agentRequestData && (
                        <AgentRequestCard data={agentRequestData} />
                    )}
                    <Group
                        position="apart"
                        sx={{ flexWrap: "nowrap" }}
                    >
                        <Text
                            fz={32}
                            fw={600}
                            sx={{ flexGrow: 1 }}
                        >
                            Reply to agent request
                        </Text>
                    </Group>
                    <Divider my={8} />
                </Stack>
            </Container>
            <Container
                size="1200px"
                pb={32}
                px={16}
            >
                <Stack pos="relative">
                    <TextInput
                        withAsterisk
                        label="Title"
                        radius="md"
                        {...form.getInputProps(
                            "title"
                        )}
                    />
                    <Stack spacing={2}>
                        <Text>Intro/Proposal <Text size={12} color="red" component='span'>*</Text></Text>
                        <RichTextEditor onChange={onChange} error={form.errors.intro} />
                        <Text size='sm'>Emojis are not yet supported*</Text>
                        {!!form.errors.intro && (
                            <Text size='xs' color='red'>{form.errors.intro}</Text>
                        )}
                    </Stack>
                    <Group position="right">
                        <Button
                            onClick={() => validateForm()}
                            loading={createReply.isLoading}
                        >
                            Post
                        </Button>
                    </Group>
                </Stack>
            </Container>
        </Box >
    );
};

export default AgentReplyForm;