import { Badge, Box, Button, Card, Center, Grid, Group, Image, Indicator, Stack, Switch, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Check, X } from '@phosphor-icons/react';
import { IconCross } from '@tabler/icons-react';
import { useSubscribePlan } from 'api/tokenAPI';
import { useUserAPI } from 'api/userAPI';
import { SubscriptionBadge } from 'components/Badges';
import ConfirmCancelSubModal from 'components/Modals/ConfirmCancelSubModal';
import ConfirmeCancelSubModal from 'components/Modals/ConfirmCancelSubModal';
import { PAYMENT_TYPE, basicPackage, proPackage } from 'helpers/constants';
import { useProfileController } from 'hooks/useProfile';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PackageSelect from './PackageSelect';
import moment from 'moment';

const Subscription = () => {
    const subscribePlan = useSubscribePlan()
    const { currentActiveSubscription, isPro, isBasic, isUnsubscribed, refetchProfile } =
        useProfileController();
    const { useCancelSubscription } = useUserAPI()
    const cancelSubcription = useCancelSubscription()
    const [cancelSubOpen, setCancelSubOpen] = useState(false)

    const handleSubscribe = async (priceId?: string, couponId?: string) => {
        if (!!priceId) {
            const redirect = await subscribePlan.mutateAsync({
                priceId,
                coupon: couponId ?? null
            })

            if (!!redirect.url) {
                window.location.href = redirect.url;
            } else {
                notifications.show({
                    title: "Oops, something went wrong",
                    message:
                        "Please try again, if this issue persists please contact our customer service for help",
                });
            }
        }
    }

    const handleUnsub = useCallback(async () => {
        if (!!currentActiveSubscription?.subscriptionId) {
            try {
                console.log("currentActiveSubscription", currentActiveSubscription)
                await cancelSubcription.mutateAsync({
                    data: { subscriptionId: currentActiveSubscription.subscriptionId }
                })

                await refetchProfile()
                setCancelSubOpen(false)
                notifications.show({
                    title: "We are sad to see you go",
                    message: "Your subscription has been cancelled",
                });
            } catch (err) {
                setCancelSubOpen(false)
                notifications.show({
                    title: "Oops, something went wrong",
                    message:
                        "Please try again, if this issue persists please contact our customer service for help",
                });
            }
        }
    }, [currentActiveSubscription])

    const CancelRow = () => {
        return (
            <Group>
                <Button
                    variant='subtle'
                    color="red"
                    onClick={() => setCancelSubOpen(true)}
                >
                    Cancel Subscription
                </Button>
            </Group>
        )
    }

    const NotSubscribed = ({ isCurrent }: any) => {
        return (
            <Card shadow='md' radius={16}>
                <Stack>
                    {!!isCurrent && (
                        <Group position='apart'>
                            <Text fw={700}> Current Subscription</Text>
                        </Group>
                    )}
                    <Group position='apart'>
                        <SubscriptionBadge type="UNSUBBED" />
                    </Group>
                    <Box sx={{ backgroundColor: "rgb(232, 248, 239)" }} p={8}>
                        <Stack spacing={4}>
                            <Group>
                                <Check color="green" />
                                <Text>Agent Profile</Text>
                            </Group>
                            <Group>
                                <Check color="green" />
                                <Text color="red">Limited Locality insights</Text>
                            </Group>
                            <Group>
                                <Check color="green" />
                                <Text color="red">Only 2 listings</Text>
                            </Group>
                            <Group>
                                <X color='red' />
                                <Text>Whatsapp Notifications</Text>
                            </Group>
                            <Group>
                                <X color='red' />
                                <Text>Lead Browsing</Text>
                            </Group>
                            <Group>
                                <X color='red' />
                                <Text>Advanced Lead Filters</Text>
                            </Group>
                            <Group>
                                <X color='red' />
                                <Text>Access to co-agency features</Text>
                            </Group>
                        </Stack>
                    </Box>

                </Stack>
            </Card>
        )
    }
    const MothBasic = ({ isCurrent, endPeriod }: any) => {
        return (
            <Card shadow='md' radius={16}>
                <Stack>
                    {!!isCurrent && (
                        <Group position='apart'>
                            <Text fw={700}> Current Subscription</Text>
                            {!!endPeriod && (<Text>Expiring at : {moment.unix(endPeriod).format("DD/MM/YYYY")}</Text>)}
                        </Group>
                    )}
                    <Group position='apart'>
                        <SubscriptionBadge type="BASIC" />
                        {!!isCurrent && (
                            <CancelRow />
                        )}
                    </Group>
                    <Box sx={{ backgroundColor: "rgb(232, 248, 239)" }} p={8}>
                        <Stack spacing={4}>
                            <Group>
                                <Check color="green" />
                                <Text>Agent Profile</Text>
                            </Group>
                            <Group>
                                <Check color="green" />
                                <Text>Locality insights</Text>
                            </Group>
                            <Group>
                                <Check color="green" />
                                <Text>Up to 20 listings!</Text>
                            </Group>
                            <Group>
                                <Check color="green" />
                                <Text>Whatsapp Notifications</Text>
                            </Group>
                            <Group>
                                <Check color="green" />
                                <Text>Lead Browsing</Text>
                            </Group>
                            <Group>
                                <X color='red' />
                                <Text>Advanced Lead Filters</Text>
                            </Group>

                            <Group>
                                <X color='red' />
                                <Text>Access to co-agency features</Text>
                            </Group>
                        </Stack>
                    </Box>
                    {!isCurrent && (
                        <Button
                            onClick={() => handleSubscribe(process.env.REACT_APP_BASIC_PRICE_ID)}
                        >
                            Subscribe (RM 50/month)
                        </Button>
                    )}
                </Stack>
            </Card>
        )
    }

    const MothPro = ({ isCurrent, endPeriod }: any) => {
        return (
            <Card shadow='md' radius={16}>
                <Stack>
                    {!!isCurrent && (
                        <Group position='apart'>
                            <Text fw={700}> Current Subscription</Text>
                            {!!endPeriod && (<Text>Expiring at : {moment.unix(endPeriod).format("DD/MM/YYYY")}</Text>)}
                        </Group>
                    )}
                    <Group position='apart'>
                        <SubscriptionBadge type="PRO" />
                        {!!isCurrent && (
                            <CancelRow />
                        )}
                    </Group>
                    <Box sx={{ backgroundColor: "rgb(232, 248, 239)" }} p={8}>
                        <Stack spacing={4}>
                            <Group>
                                <Check color='green' />
                                <Text>Agent Profile</Text>
                            </Group>
                            <Group>
                                <Check color='green' />
                                <Text>Locality insights</Text>
                            </Group>
                            <Group>
                                <Check color="green" />
                                <Text>Up to 100 listings!</Text>
                            </Group>
                            <Group>
                                <Check color='green' />
                                <Text>Whatsapp Notifications</Text>
                            </Group>
                            <Group>
                                <Check color="green" />
                                <Text>Lead Browsing</Text>
                            </Group>
                            <Group>
                                <Check color='green' />
                                <Text>Advanced Lead Filters</Text>
                            </Group>
                            <Group>
                                <Check color='green' />
                                <Text>Access to co-agency features</Text>
                            </Group>
                        </Stack>
                    </Box>
                    {!isCurrent && (
                        <Button
                            onClick={() => handleSubscribe(process.env.REACT_APP_PRO_PRICE_ID)}
                        >
                            Subscribe (RM 100/month)
                        </Button>
                    )}
                </Stack>
            </Card>
        )
    }

    return (
        <>
            <ConfirmCancelSubModal
                opened={cancelSubOpen}
                onCancel={() => setCancelSubOpen(false)}
                onConfirm={() => handleUnsub()}
            />
            <Stack>
                <Text fz={24} fw={700}>My Subscription</Text>
                {!!currentActiveSubscription && (
                    <>
                        {currentActiveSubscription.package === "BASIC" ? (
                            <MothBasic isCurrent={true} endPeriod={currentActiveSubscription.currentEndPeriod}/>
                        ) : currentActiveSubscription.package === "PRO" ? (
                            <MothPro isCurrent={true} endPeriod={currentActiveSubscription.currentEndPeriod}/>
                        ) : (
                            <>
                                {/* <NotSubscribed isCurrent={true} /> */}
                            </>
                        )}
                    </>
                )}
                {(!!isBasic || !!isUnsubscribed) && (
                    <PackageSelect />
                )}
            </Stack>
        </>
    );
};

export default Subscription;