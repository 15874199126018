import React from "react";
import {
    BackgroundImage,
    Button,
    Card,
    Group,
    Stack,
    Text,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

export const BlogCard: React.FC<any> = ({ ...props }) => {
    const navigate = useNavigate();
    return (
        <Card
            shadow="md"
            sx={{ cursor: "pointer", border: "1px solid #ced4da" }}
            onClick={() => navigate(`/articles/${props.data.slug}`)}
        >
            <Stack spacing={8}>
                {!!props.data.banner && (
                    <BackgroundImage
                        src={props.data.banner.url}
                        sx={{ height: 200 }}
                    />
                )}
                <Stack spacing={16}>
                    <Text component="h1" my={0} lineClamp={1}>
                        {props.data.title}
                    </Text>
                    <Text component="p" lineClamp={2} my={0}>
                        {props.data.content.split(/\s+/).slice(0, 50).join(" ").replace(/[^\w\s,.'"-]/g, '')}
                    </Text>
                </Stack>
                <Group position="right">
                    <Button variant="subtle">See More</Button>
                </Group>
            </Stack>
        </Card>
    );
};
