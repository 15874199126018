// @ts-nocheck
import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useLayoutEffect,
    useCallback,
} from "react";
import {
    ActionIcon,
    Anchor,
    Avatar,
    Box,
    Button,
    Card,
    Center,
    Container,
    Divider,
    Drawer,
    FileButton,
    Grid,
    Group,
    Image,
    LoadingOverlay,
    ScrollArea,
    Stack,
    Text,
    TextInput,
    useMantineTheme,
    AspectRatio,
    Modal,
} from "@mantine/core";
import {
    useCreateMessage,
    useGetChat,
    useGetChatMessagesAfterId,
    useGetChats,
} from "api/chatAPI";
import { Permission, useAuth } from "hooks/useAuth";
import { File, Paperclip, Send, Trash } from "tabler-icons-react";
import { ChatHeader } from "components/ChatList/ChatHeader";
import { ChatSelector } from "components/ChatList/ChatSelector";
import { useMediaQuery } from "@mantine/hooks";
import { useLocation, useSearchParams } from "react-router-dom";
import {
    addDoc,
    collection,
    doc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    where,
} from "firebase/firestore";
import { useListingAPI } from "api/useListingAPI";
import { subtle } from "crypto";
import { convertFileToPreview } from "helpers/utility";
import { useUpload } from "hooks/useUpload";
import { Carousel, useAnimationOffsetEffect } from "@mantine/carousel";
import { LightBox } from "components/LightBox";

export const ChatList: React.FC<any> = ({ db, isComponent }) => {
    const { user } = useAuth(Permission.USER_ONLY);
    const { useFulfillPropRequest } = useListingAPI();
    const location = useLocation();
    const viewport = useRef<HTMLDivElement | null>(null);
    const scroll = useRef<HTMLDivElement>(null);
    const shouldScrollRef = useRef<boolean>(true);
    const createMessage = useCreateMessage()

    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const [messages, setMessages] = useState<any[]>([]);
    const [message, setMessage] = useState("");
    const [activeChatId, setActiveChatId] = useState<number>(0);
    const [drawerOpen, setDrawenOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [intervalId, setIntervalId] = useState<any>(null);
    const [chatList, setChatList] = useState([]);
    const [sendIsLoading, setSendIsloading] = useState(false);

    const [files, setFiles] = useState<File[]>([]);
    const [base64Preview, setBase64Preview] = useState<any[]>([]);
    const [uploadIsLoading, setUploadIsLoading] = useState(false);

    const [imagePopupData, setImagePopupData] = useState<any>(false);
    const [embla, setEmbla] = useState<Embla | null>(null);

    const TRANSITION_DURATION = 200;
    useAnimationOffsetEffect(embla, TRANSITION_DURATION);

    const fulfillPropRequestMutation = useFulfillPropRequest();
    const { uploadMutation } = useUpload();
    const {
        data: chatData,
        isLoading: chatIsLoading,
        error: chatError,
        refetch: refetchChats,
    } = useGetChats({
        userId: user.id,
        userType: user.userType
    });

    const {
        data: activeChatData,
        isLoading: activeChatIsLoading,
        error: activeChatError,
    } = useGetChat(activeChatId);

    const lastId = useMemo(() => {
        if (!messages || messages?.length === 0) {
            return 0;
        }

        return messages[messages.length - 1].id;
    }, [messages]);

    const sendMessage = async (e: any) => {
        e.preventDefault();
        if (message === "" && files.length === 0) {
            return;
        }

        try {
            setSendIsloading(true);
            let sentMessage = message;
            let imgArr = [];

            for (const img of files) {
                const uploadId = await uploadMutation.mutateAsync({
                    file: img,
                    path: `chats/${activeChatId}`,
                });

                imgArr.push({
                    url: uploadId[0].url,
                    type: img.type,
                    name: img.name,
                });
            }

            let messageObj = {
                isRead: false,
                senderId: user.id.toString(),
                message: sentMessage,
                chatId: activeChatData.id.toString(),
                timestamp: new Date().getTime(),
            };

            if (imgArr.length > 0) {
                messageObj.imageAttachments = JSON.stringify(
                    imgArr.filter((i) => i.type !== "application/pdf")
                );
                messageObj.docAttachments = JSON.stringify(
                    imgArr.filter((i) => i.type === "application/pdf")
                );
            }

            await addDoc(collection(db, "messages"), messageObj);
            await createMessage.mutateAsync({
                sender: user.id,
                data: messageObj,
                chat: activeChatData.id
            })

            setMessage("");
            setFiles([]);
            setBase64Preview([]);
            setSendIsloading(false);
        } catch (e) {
            setSendIsloading(false);
            console.log(e);
        }
    };

    useEffect(() => {
        if (!!location.state?.id) {
            setActiveChatId(parseInt(location.state.id));
        }
    }, [location]);

    // useEffect(() => {
    //     console.log("activeChatId", activeChatId)
    // }, [activeChatId]);

    useEffect(() => {
        const q = query(
            collection(db, "messages"),
            orderBy("timestamp"),
            where("chatId", "==", activeChatId.toString())
        );

        getDocs(q).then((querySnapshot) => {
            const messagesArr = [];

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                let chatData = doc.data();
                if (!!chatData.docAttachments) {
                    chatData.docAttachments = JSON.parse(
                        chatData.docAttachments
                    );
                }

                if (!!chatData.imageAttachments) {
                    chatData.imageAttachments = JSON.parse(
                        chatData.imageAttachments
                    );
                }

                messagesArr.push(chatData);
            });

            setMessages(messagesArr);
        });

        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            let messages = [];
            QuerySnapshot.forEach((doc) => {
                let chatData = doc.data();
                if (!!chatData.docAttachments) {
                    chatData.docAttachments = JSON.parse(
                        chatData.docAttachments
                    );
                }

                if (!!chatData.imageAttachments) {
                    chatData.imageAttachments = JSON.parse(
                        chatData.imageAttachments
                    );
                }

                messages.push(chatData);
            });
            setMessages(messages);
    });

        return () => unsubscribe;
    }, [activeChatId]);

    const clearIntervalHandler = () => {
        clearInterval(intervalId);
        setIntervalId(null);
    };

    const handleUpload = async (e: any) => {
        // setValue(e);
        setUploadIsLoading((state) => !state);

        const convertFileToBase64 = (file: Blob): Promise<string> => {
            return new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
            });
        };

        const previewArr = [] as any[];
        const fileArr = [] as File[];

        for (const file of e) {
            if (file.type === "application/pdf") {
                previewArr.push("/document.png");
            } else {
                const base64: string | unknown = await convertFileToBase64(
                    file
                );
                previewArr.push(base64);
            }
            fileArr.push(file);
        }
        setBase64Preview([...base64Preview, ...previewArr]);
        setFiles([...files, ...fileArr]);
        setUploadIsLoading((state) => !state);
    };

    const clearFileUpload = (index: number) => {
        const filtered = base64Preview.filter((p, i) => i !== index);
        const filteredFile = files.filter((p, i) => i !== index);

        setBase64Preview(filtered);
        setFiles(filteredFile);
    };

    useEffect(() => {
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (!!chatData && !chatIsLoading && chatData.length > 0) {
            if (!location.state?.id) {
                setActiveChatId(chatData[0].id);
            }

            setChatList(chatData);
        }
    }, [chatData, chatIsLoading, location]);

    const scrollToBottom = () => {
        if (viewport.current != null) {
            viewport.current.scrollTop = viewport.current?.scrollHeight;
        }
    };

    useEffect(() => {
        if (!!activeChatData && !activeChatIsLoading) {
            scrollToBottom();
        }
    }, [activeChatData, activeChatIsLoading]);

    useLayoutEffect(() => {
        if (shouldScrollRef.current) {
            scrollToBottom();
        }
    }, [messages, shouldScrollRef]);

    const onChatSelect = (id: any) => {
        setDrawenOpen(false);
        setActiveChatId(id);
        // refetchChats();
    };

    const handleScroll = () => {
        const container = viewport.current;
        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;
            shouldScrollRef.current = scrollTop + clientHeight === scrollHeight;
        }
    };

    const checkIsMessageOwner = (senderId) => {
        return parseInt(senderId) === parseInt(user.id);
    };

    const handleAccept = async (activeChatId) => {
        const chat = chatData.find((c) => {
            return c.id === activeChatId;
        });
        // console.log("chat", chat);

        await fulfillPropRequestMutation.mutateAsync({
            id: chat?.offer?.id,
        });
    };

    const canChat = useCallback(() => {
        if (!!activeChatData) {
            return activeChatData?.offer?.listing.propertyRequest !== "rejected"
        }
    }, [activeChatData])

    return (
        <>
            <LightBox
                showLightBox={!!imagePopupData}
                onClose={() => setImagePopupData(false)}
                images={imagePopupData}
            />
            <Modal
                centered
                opened={!!imagePopupData}
                onClose={() => setImagePopupData(false)}
                size={"100%"}
                padding={0}
                withCloseButton={false}
                transitionProps={{ duration: TRANSITION_DURATION }}
                sx={{
                    "& .mantine-Modal-content": {
                        backgroundColor: "transparent !important",
                    },
                }}
            >
                <Carousel
                    w="90%"
                    h="70%"
                    slideGap="xs"
                    mx={"auto"}
                    withIndicators
                    slideSize="100%"
                    withControls={!!imagePopupData && imagePopupData.length > 1}
                    getEmblaApi={setEmbla}
                >
                    {!!imagePopupData &&
                        imagePopupData?.map((img, i) => (
                            <Carousel.Slide key={`carousel-${i}`}>
                                {/* <Box>{i}</Box> */}
                                <Image
                                    src={img.url}
                                    height={"100%"}
                                    width={"100%"}
                                    fit="contain"
                                    imageProps={{
                                        style: {
                                            maxHeight: "70vh",
                                        },
                                    }}
                                />
                            </Carousel.Slide>
                        ))}
                </Carousel>
            </Modal>
            <Drawer
                opened={isMobile && drawerOpen}
                onClose={() => setDrawenOpen(false)}
                position="left"
                styles={{
                    body: {
                        height: "calc(100% - 64px)",
                        padding: "0px",
                    },
                }}
                title="Chats"
            >
                <Box sx={{ height: "100%" }}>
                    <ScrollArea
                        sx={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#F2EFE7",
                        }}
                        p={16}
                    >
                        <Stack spacing={16}>
                            {!!chatList &&
                                chatList.length > 0 &&
                                chatList.map((chat: any) => (
                                    <ChatSelector
                                        chat={chat}
                                        userType={user.userType}
                                        activeChatId={activeChatId}
                                        onClick={onChatSelect}
                                    />
                                ))}
                            {/* {!!chatData && chatData.length === 0 (
                                    
                                )} */}
                        </Stack>
                    </ScrollArea>
                </Box>
            </Drawer>

            <Container
                size={isComponent ? "100%" : "1200px"}
                p={isComponent ? 0 : 16}
                sx={{
                    maxHeight: "calc(100vh - 65px)",
                    height: "100%",
                }}
            >
                <Stack spacing={8} sx={{ height: "100%" }}>
                    {!!isMobile && (
                        <Group
                            position="left"
                            p={16}
                            sx={{ backgroundColor: "#F2EFE7" }}
                            onClick={() => setDrawenOpen(true)}
                        >
                            <Button>View Chats</Button>
                        </Group>
                    )}
                    <Grid sx={{ height: "100%", flexGrow: 1 }} gutter={0}>
                        {!isMobile && (
                            <Grid.Col md={3} sm={12} xs={12}>
                                <ScrollArea
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "#F2EFE7",
                                    }}
                                    p={16}
                                >
                                    <Stack spacing={16}>
                                        {!!chatData &&
                                            chatData.length > 0 &&
                                            chatData.map((chat: any) => (
                                                <ChatSelector
                                                    chat={chat}
                                                    userType={user.userType}
                                                    activeChatId={activeChatId}
                                                    onClick={onChatSelect}
                                                />
                                            ))}
                                    </Stack>
                                </ScrollArea>
                            </Grid.Col>
                        )}
                        <Grid.Col md={9} sm={12} sx={{ flexGrow: 1 }}>
                            {/* <Stack sx={{height: '100%', width: '100%'}} align="center" justify="center">
                            <Text fw={700} fz={24}> You have no chats currenty</Text>
                        </Stack> */}
                            {!!chatData && chatData.length === 0 ? (
                                <Card shadow="md">
                                    <Center>
                                        <Text> You have no chats currenty</Text>
                                    </Center>
                                </Card>
                            ) : (
                                <Card
                                    p={0}
                                    sx={{
                                        border: "1px solid #BFC8C3",
                                        height: "100%",
                                        background: "#FBFAF7",
                                    }}
                                >
                                    <LoadingOverlay
                                        visible={
                                            !activeChatData ||
                                            !!activeChatIsLoading ||
                                            !chatData
                                        }
                                        overlayBlur={2}
                                    />
                                    {!!activeChatData && (
                                        <Stack
                                            spacing={4}
                                            sx={{ height: "100%" }}
                                        >
                                            <LoadingOverlay
                                                visible={
                                                    !!activeChatData.isLoading
                                                }
                                                overlayBlur={2}
                                            />
                                            <ChatHeader
                                                activeChatData={activeChatData}
                                                userType={user.userType}
                                                onAccept={() =>
                                                    handleAccept(activeChatId)
                                                }
                                            />
                                            <Divider m={0} />
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    maxHeight: `calc(100vh - 250px)`,
                                                }}
                                                p={16}
                                                viewportProps={{
                                                    onScroll: handleScroll(),
                                                }}
                                            >
                                                <ScrollArea
                                                    sx={{ height: "100%" }}
                                                    viewportRef={viewport}
                                                >
                                                    <Stack spacing={8}>
                                                        {!!messages &&
                                                            messages.length >
                                                            0 &&
                                                            messages.map(
                                                                (message) => (
                                                                    <Group
                                                                        spacing={
                                                                            8
                                                                        }
                                                                        align="flex-end"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        position={
                                                                            checkIsMessageOwner(
                                                                                message.senderId
                                                                            )
                                                                                ? "right"
                                                                                : "left"
                                                                        }
                                                                    >
                                                                        <Card
                                                                            sx={
                                                                                checkIsMessageOwner(
                                                                                    message.senderId
                                                                                )
                                                                                    ? {
                                                                                        border: "1px solid #417256",
                                                                                        borderBottomLeftRadius:
                                                                                            "0px",
                                                                                        background:
                                                                                            " #417256",
                                                                                        maxWidth:
                                                                                            "90%",
                                                                                    }
                                                                                    : {
                                                                                        border: "1px solid whitesmoke",
                                                                                        background:
                                                                                            "whitesmoke",
                                                                                        borderBottomRightRadius:
                                                                                            "0px",
                                                                                        maxWidth:
                                                                                            "90%",
                                                                                    }
                                                                            }
                                                                            radius="md"
                                                                            p={8}
                                                                        >
                                                                            <Stack
                                                                                spacing={
                                                                                    4
                                                                                }
                                                                                sx={{
                                                                                    height: "100%",
                                                                                    width: "100%",
                                                                                }}
                                                                            >
                                                                                {!!message.imageAttachments && (
                                                                                    <Grid
                                                                                        columns={
                                                                                            message
                                                                                                .imageAttachments
                                                                                                .length *
                                                                                                4 <
                                                                                                12
                                                                                                ? message
                                                                                                    .imageAttachments
                                                                                                    .length *
                                                                                                4
                                                                                                : 12
                                                                                        }
                                                                                    >
                                                                                        {message
                                                                                            .imageAttachments
                                                                                            .length >
                                                                                            0 &&
                                                                                            message.imageAttachments?.map(
                                                                                                (
                                                                                                    i
                                                                                                ) => (
                                                                                                    <Grid.Col
                                                                                                        span={
                                                                                                            4
                                                                                                        }
                                                                                                    >
                                                                                                        <Image
                                                                                                            src={
                                                                                                                i.url ?? "/img-placeholder.jpeg"
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                            fit="contain"
                                                                                                            imageProps={{
                                                                                                                style: {
                                                                                                                    maxHeight:
                                                                                                                        "250px",
                                                                                                                },
                                                                                                            }}
                                                                                                            sx={{
                                                                                                                cursor: "pointer",
                                                                                                            }}
                                                                                                            onClick={() =>
                                                                                                                setImagePopupData(
                                                                                                                    message.imageAttachments
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    </Grid.Col>
                                                                                                )
                                                                                            )}
                                                                                    </Grid>
                                                                                )}
                                                                                {!!message.message && (
                                                                                    <Text
                                                                                        color={
                                                                                            checkIsMessageOwner(
                                                                                                message.senderId
                                                                                            )
                                                                                                ? "white"
                                                                                                : "black"
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            message.message
                                                                                        }
                                                                                    </Text>
                                                                                )}
                                                                                {!!message.docAttachments && (
                                                                                    <Group>
                                                                                        {message.docAttachments.map(
                                                                                            (
                                                                                                d
                                                                                            ) => (
                                                                                                <Group
                                                                                                    spacing={
                                                                                                        4
                                                                                                    }
                                                                                                >
                                                                                                    <File
                                                                                                        color={
                                                                                                            checkIsMessageOwner(
                                                                                                                message.senderId
                                                                                                            )
                                                                                                                ? "white"
                                                                                                                : "black"
                                                                                                        }
                                                                                                    />
                                                                                                    <Anchor
                                                                                                        href={
                                                                                                            d.url
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                        underline={
                                                                                                            false
                                                                                                        }
                                                                                                        sx={{
                                                                                                            ":hover":
                                                                                                            {
                                                                                                                textDecoration:
                                                                                                                    "underline",
                                                                                                                textDecorationColor:
                                                                                                                    checkIsMessageOwner(
                                                                                                                        message.senderId
                                                                                                                    )
                                                                                                                        ? "white"
                                                                                                                        : "black",
                                                                                                            },
                                                                                                        }}
                                                                                                    >
                                                                                                        <Text
                                                                                                            size="sm"
                                                                                                            color={
                                                                                                                checkIsMessageOwner(
                                                                                                                    message.senderId
                                                                                                                )
                                                                                                                    ? "white"
                                                                                                                    : "black"
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                d.name
                                                                                                            }
                                                                                                        </Text>
                                                                                                    </Anchor>
                                                                                                </Group>
                                                                                            )
                                                                                        )}
                                                                                    </Group>
                                                                                )}
                                                                            </Stack>
                                                                        </Card>
                                                                    </Group>
                                                                )
                                                            )}
                                                    </Stack>
                                                </ScrollArea>
                                            </Box>

                                            <Stack
                                                sx={{
                                                    borderTop:
                                                        "1px solid #ced4da",
                                                }}
                                                spacing={0}
                                            >
                                                {base64Preview.length > 0 && (
                                                    <Group
                                                        p={16}
                                                        sx={
                                                            {
                                                                // backgroundColor:
                                                                //     "whitesmoke",
                                                            }
                                                        }
                                                    >
                                                        {base64Preview.map(
                                                            (preview, i) => (
                                                                <Center
                                                                    sx={{
                                                                        position:
                                                                            "relative",
                                                                        border: "1px solid #BFC8C3",
                                                                    }}
                                                                    p={
                                                                        preview ===
                                                                            "/document.png"
                                                                            ? 24
                                                                            : 0
                                                                    }
                                                                >
                                                                    <Image
                                                                        width={
                                                                            preview ===
                                                                                "/document.png"
                                                                                ? 32
                                                                                : 80
                                                                        }
                                                                        height={
                                                                            preview ===
                                                                                "/document.png"
                                                                                ? 32
                                                                                : 80
                                                                        }
                                                                        fit="contain"
                                                                        src={
                                                                            preview
                                                                        }
                                                                    />
                                                                    {preview ===
                                                                        "/document.png" && (
                                                                            <Text
                                                                                sx={{
                                                                                    position:
                                                                                        "absolute",
                                                                                    bottom: 4,
                                                                                    left: "50%",
                                                                                    transform:
                                                                                        "translateX(-50%)",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    files[
                                                                                        i
                                                                                    ]
                                                                                        .name
                                                                                }
                                                                            </Text>
                                                                        )}

                                                                    <ActionIcon
                                                                        sx={{
                                                                            borderRadius:
                                                                                "50%",
                                                                            backgroundColor:
                                                                                "white",
                                                                            border: "1px solid #BFC8C3",
                                                                            position:
                                                                                "absolute",
                                                                            top: -10,
                                                                            right: -10,
                                                                        }}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            e.preventDefault();
                                                                            clearFileUpload(
                                                                                i
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Trash
                                                                            size={
                                                                                16
                                                                            }
                                                                            color="red"
                                                                        />
                                                                    </ActionIcon>
                                                                </Center>
                                                            )
                                                        )}
                                                    </Group>
                                                )}
                                                {!!canChat() ? (
                                                    <form
                                                        onSubmit={(e) =>
                                                            sendMessage(e)
                                                        }
                                                    >
                                                        <Group
                                                            p={16}
                                                            sx={{ width: "100%" }}
                                                        >
                                                            <Group
                                                                spacing={4}
                                                                sx={{
                                                                    flexGrow: 1,
                                                                    flex: 1,
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <FileButton
                                                                    onChange={
                                                                        handleUpload
                                                                    }
                                                                    accept="image/*,application/pdf"
                                                                    multiple
                                                                    variant="subtle"
                                                                    disabled={
                                                                        sendIsLoading
                                                                    }
                                                                >
                                                                    {(props) => (
                                                                        <Button
                                                                            variant="subtle"
                                                                            px={4}
                                                                            {...props}
                                                                        >
                                                                            <Paperclip />
                                                                        </Button>
                                                                    )}
                                                                </FileButton>
                                                                <TextInput
                                                                    sx={{
                                                                        flexGrow: 1,
                                                                        flex: 1,
                                                                    }}
                                                                    value={message}
                                                                    onChange={(e) =>
                                                                        setMessage(
                                                                            e.target
                                                                                .value
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        sendIsLoading
                                                                    }
                                                                    placeholder={
                                                                        sendIsLoading
                                                                            ? "Loading..."
                                                                            : "Type a message"
                                                                    }
                                                                />
                                                            </Group>
                                                            <Button
                                                                type="submit"
                                                                disabled={
                                                                    !chatData ||
                                                                    sendIsLoading
                                                                }
                                                                loading={
                                                                    sendIsLoading
                                                                }
                                                            >
                                                                <Send />
                                                            </Button>
                                                        </Group>
                                                    </form>
                                                ) : (
                                                    <Group
                                                        p={16}
                                                        sx={{ width: "100%" }}
                                                    >
                                                        <Text align="center" sx={{ width: '100%', color: '#aaa' }}>This property request has been fulfilled</Text>
                                                    </Group>
                                                )}
                                            </Stack>
                                        </Stack>
                                    )}
                                </Card>
                            )}
                        </Grid.Col>
                    </Grid>
                </Stack>
            </Container>
        </>
    );
};
