import React, { 
    useState, 
    useEffect,
    useContext,
    useMemo
} from "react";
import { 
    Box, 
    Container,
    Text,
    Button,
} from "@mantine/core";
import { UserContext } from "context/user/userContext";
import { Permission, useAuth } from "hooks/useAuth";
import { useQuery } from "react-query";
import { accountTypes } from "types/accountTypes";
import { USER_ACTIONS } from "context/user/userActions";
import { AgentProfile } from "../components/Profile/AgentProfile";
import { ListerProfile } from "../components/Profile/ListerProfile";
import { useNavigate } from "react-router-dom";
import { EditAgentForm } from "../components/Profile/EditAgentForm";
import { EditListerForm } from "../components/Profile/EditListerForm";
import { useProfileController } from "hooks/useProfile";

const EditProfileHeader: React.FC = ({}) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                borderBottom: '1px solid #BFC8C3'
            }}
            py={24}
        >
            <Text size={32} weight={500}>
                Edit Profile
            </Text>
        </Box>
    )
}

export const EditProfile = ({}) => {
    const {
        view,
    } = useProfileController();

    return (
        <Box
            sx={theme => ({
                // height: '100%',
                backgroundColor: theme.colors.bgYellow,
            })}
        >
            <Container
                size="1200px"
            >
                <EditProfileHeader />
                {view === accountTypes.AGENT
                    ?  <EditAgentForm 
                        />
                    :   <EditListerForm
                        />
                }
            </Container>
        </Box>
    )
}