import { useCallback, useEffect, useMemo, useState } from "react";
import { LightBox } from "components/LightBox";
import { DetailsMultipleRow, DetailsMobile } from "components/Details/Details";
import {
    Box,
    Text,
    Divider,
    Flex,
    Image,
    Avatar,
    AspectRatio,
    Button,
    Container,
    Grid,
    Stack,
    useMantineTheme,
    Center,
    Overlay,
    Group,
    Badge,
    BackgroundImage,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
    IconMapPin,
    IconLink,
    IconDiscountCheckFilled,
    IconStarFilled,
} from "@tabler/icons-react";
import testImage from "assets/testImage.png";
import testImage2 from "assets/testImage2.png";
import testImage3 from "assets/testImage3.png";
import testImage4 from "assets/testImage4.png";
import testImage5 from "assets/testImage5.png";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useGetAgentDetail } from "api/userAPI";
import { useCreateChat, useGetOfferChat } from "api/chatAPI";
import { Permission, useAuth } from "hooks/useAuth";
import { USER_TYPE } from "helpers/constants";
import { useListingAPI } from "api/useListingAPI";
import { ArrowLeft } from "tabler-icons-react";
import { Carousel } from "@mantine/carousel";
import ImageGrid from "./ViewListing/components/ImageGrid";

export default function ViewOffer() {
    const { useGetOneOffer, useSeenOffer } = useListingAPI();
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    let { id } = useParams();
    const { user } = useAuth(Permission.ANYONE);
    const [showLightBox, setShowLightBox] = useState(false);
    const [clickedImage, setClickedImage] = useState(0);
    const { data, isLoading, error } = useGetOneOffer(id as string);
    const {
        data: agentData,
        isLoading: agentIsLoading,
        error: agentError,
    } = useGetAgentDetail(data?.listing?.agent?.id || "");
    const seenOffer = useSeenOffer();
    const {
        data: offerChatData,
        isLoading: offerChatIsLoading,
        error: offerChatError,
    } = useGetOfferChat(user.id, user.userType, parseInt(id as string));

    const createChatMutation = useCreateChat();

    // const mergedImages = useCallback(() => {
    //     let mergedArr = [] as any
    //     if (!!listingData?.images && listingData.images.length > 0) {
    //         const imageArr = listingData.images.map((i: any) => ({
    //             src: i.url,
    //             alt: "offer-image",
    //         }));
    //         mergedArr = [...mergedArr, ...imageArr]
    //     }

    //     if (!!listingData?.imageLinks && listingData.imageLinks.length > 0) {
    //         const imageArr = listingData.imageLinks.map((i: any) => ({
    //             src: i,
    //             alt: "offer-image",
    //         }))
    //         mergedArr = [...mergedArr, ...imageArr]
    //     }

    //     return mergedArr
    // }, [listingData])

    const formattedData = useMemo(() => {
        if (!!data && !isLoading && !!agentData && !agentIsLoading) {
            const { listing, propertyRequest, seen } = data;
            const [agent] = agentData;
            let images = [] as any[]

            if (!!listing.images && listing.images.length > 0) {
                const arr = data.listing.images.map((i: any) => ({
                    url: i.url ?? "/img-placeholder.jpeg",
                }));

                images = [...images, ...arr]
            }

            if (!!listing.imageLinks && listing.imageLinks.length > 0) {
                const arr = data.listing.imageLinks.map((i: any) => ({
                    url: i,
                }));

                images = [...images, ...arr]
            }

            const obj = {
                offerImages: images.slice(1),
                offerMainImage: images[0],
                offerSummary: {
                    price: listing.price,
                    title: listing.title,
                    addressLine1: listing.address,
                    originalListingURL: listing.importLink,
                },
                offerDetails: [
                    {
                        type: "Developer",
                        text: listing.developer,
                    },
                    {
                        type: "No. of Bathroom(s)",
                        text: listing.noOfBathrooms,
                    },
                    {
                        type: "No. of Bedroom(s)",
                        text: listing.noOfBedrooms,
                    },
                    {
                        type: "Floor Size",
                        text: `${listing.floorSize} sqft`,
                    },
                    {
                        type: "Land Size",
                        text: `${listing.landSize} sqft`,
                    },
                    {
                        type: "Property Title Type",
                        text: listing.propertyTitleType,
                    },
                ],
                propertyDetails: data.listing.tags.map((t: any) => ({
                    value: t.value,
                    background: t.tag?.badgeBackground ?? "#00A550",
                    color: t.tag?.badgeColor ?? "#FFFFFF",
                })),
                agentInfo: {
                    name: listing.agent.fullname,
                    renId: agent.renId,
                    joinDate: moment(listing.agent.createdAt).format(
                        "DD MMM, YYYY"
                    ),
                    profilePic: agentData[0].agent.avatar?.url ?? "/avatar",
                    // averageRating: "4.3",
                    // noOfReviews: 15,
                },
                embeds: listing?.listingEmbeds?.map((url: any) => {
                    const urlsplit = url.link.split("/")
                    const id = urlsplit[urlsplit.length - 1]

                    return "https://www.tiktok.com/embed/" + id
                }) ?? []
            };
            return obj;
        }

        return {
            offerImages: [
                {
                    src: "",
                },
            ],
            offerMainImage: {
                src: "",
            },
            offerSummary: {
                title: "",
                price: "",
                addressLine1: "",
                originalListingURL: "",
            },
            offerDetails: [
                {
                    type: "Developer",
                    text: "",
                },
                {
                    type: "No. of Bathroom(s)",
                    text: "",
                },
                {
                    type: "No. of Bedroom(s)",
                    text: "",
                },
                {
                    type: "Floor Size",
                    text: "",
                },
                {
                    type: "Land Size",
                    text: "",
                },
                {
                    type: "Property Title Type",
                    text: "",
                },
            ],
            propertyDetails: [],
            agentInfo: {
                name: "",
                renId: "",
                joinDate: "",
                profilePic: "",
            },
            embeds: []
        };
    }, [data, agentData]);

    const canChat = useMemo(() => {
        return !!user.jwt && data?.propertyRequest.lister.id === user.id;
    }, [user, data]);

    const hasChat = useMemo(() => {
        return (
            !!offerChatData && offerChatData.length === 1 && !offerChatIsLoading
        );
    }, [offerChatData, offerChatIsLoading]);

    const handleChatNow = async () => {
        if (hasChat) {
            navigate(`/chats`, { state: { id: offerChatData[0].id } });
        } else {
            const chat = await createChatMutation.mutateAsync({
                lister: user.id,
                agent: data?.listing.agent.id,
                offer: parseInt(id as string),
            });

            navigate(`/chats`, { state: { id: chat.data.id } });
        }
    };

    useEffect(() => {
        if (!!data && !!user) {
            if (!data.seen && data.propertyRequest.lister.id === user.id) {
                seenOffer.mutate({ id: id as string });
            }
            // if(user.id === data)
            // console.log("data", data);
        }
    }, [data, user]);

    const OfferImages = () => {
        return (
            <Grid>
                <Grid.Col md={formattedData.offerImages.length > 1 ? 9 : 12}>
                    <AspectRatio
                        w={"100%"}
                        // h={185}
                        ratio={3 / 2}
                        onClick={() => {
                            setShowLightBox(!showLightBox);
                            setClickedImage(0);
                        }}
                        sx={{
                            borderRadius: "8px",
                            background: `rgba(0,0,0, 0.05)`,
                            "&:hover": { cursor: "pointer" },
                        }}
                    >
                        <BackgroundImage
                            src={formattedData.offerImages[0].url}
                            radius="md"
                        />
                    </AspectRatio>
                </Grid.Col>
                {formattedData.offerImages.length > 1 && (
                    <Grid.Col md={3}>
                        <Flex
                            direction={isMobile ? "row" : "column"}
                            sx={{ height: "100%" }}
                            gap={8}
                        >
                            {formattedData.offerImages.map(
                                (data: any, index: number) => {
                                    if (index === 3) {
                                        return (
                                            <AspectRatio
                                                key={index}
                                                w={"100%"}
                                                onClick={() => {
                                                    setShowLightBox(
                                                        !showLightBox
                                                    );
                                                    setClickedImage(index);
                                                }}
                                                ratio={3 / 2}
                                                sx={{
                                                    borderRadius: "8px",
                                                    background: `rgba(0,0,0, 0.05)`,
                                                    backgroundSize: "cover",
                                                    "&:hover": {
                                                        cursor: "pointer",
                                                    },
                                                    flex: 1,
                                                }}
                                            >
                                                <BackgroundImage
                                                    src={data.url}
                                                    radius="md"
                                                />
                                                {/* <Image
                                                    fit="contain"
                                                    src={data.src}
                                                /> */}
                                                {formattedData.offerImages
                                                    .length > 4 && (
                                                        <Overlay
                                                            color="#000"
                                                            opacity={0.75}
                                                            // sx={{
                                                            //     borderRadius: "8px",
                                                            // }}
                                                            radius="md"
                                                        >
                                                            <Text
                                                                sx={{
                                                                    fontFamily:
                                                                        "Public Sans, sans-serif",
                                                                    fontStyle:
                                                                        "normal",
                                                                    fontWeight: 400,
                                                                    fontSize:
                                                                        "16px",
                                                                    lineHeight:
                                                                        "24px",
                                                                    color: "#FFFFFF",
                                                                }}
                                                            >
                                                                {`+${formattedData
                                                                    .offerImages
                                                                    .length - 4
                                                                    } photos`}
                                                            </Text>
                                                        </Overlay>
                                                    )}
                                            </AspectRatio>
                                        );
                                    } else if (index > 0 && index < 3) {
                                        return (
                                            <AspectRatio
                                                key={index}
                                                w={"100%"}
                                                // h={185}
                                                ratio={3 / 2}
                                                onClick={() => {
                                                    setShowLightBox(
                                                        !showLightBox
                                                    );
                                                    setClickedImage(index);
                                                }}
                                                sx={{
                                                    borderRadius: "8px",
                                                    background: `rgba(0,0,0, 0.05)`,
                                                    "&:hover": {
                                                        cursor: "pointer",
                                                    },
                                                    flex: 1,
                                                }}
                                            >
                                                <BackgroundImage
                                                    src={data.url}
                                                    radius="md"
                                                />
                                                {/* <Image
                                                    fit="contain"
                                                    src={data.src}
                                                    sx={{
                                                        borderRadius: "8px",
                                                    }}
                                                /> */}
                                            </AspectRatio>
                                        );
                                    } else return <></>;
                                }
                            )}
                        </Flex>
                    </Grid.Col>
                )}
            </Grid>
        );
    };

    const AgentDetails = () => {
        return (
            <Flex
                w={"100%"}
                h={"fit-content"}
                direction="column"
                align="center"
                p={isMobile ? 24 : 16}
                gap="16px"
                sx={{
                    border: "1px solid #BFC8C3",
                    background: "#FFFFFF",
                    borderRadius: "12px",
                }}
            >
                <Flex
                    w={"100%"}
                    h={"100%"}
                    direction="column"
                    justify="center"
                    align="center"
                    gap="24px"
                >
                    <Flex direction="column" align="center" gap="10px">
                        <Avatar
                            src={formattedData.agentInfo.profilePic}
                            alt="agent"
                            radius="50%"
                        />

                        <Flex direction="column" align="center" gap="8px">
                            <Flex direction="column" align="center" gap="0px">
                                <Flex
                                    direction="column"
                                    align="center"
                                    gap="0px"
                                // sx={{
                                //     "& .tabler-icon-discount-check-filled":
                                //         { color: "#4299e1 !important" },
                                // }}
                                >
                                    <Text
                                        sx={{
                                            fontFamily:
                                                "Public Sans, sans-serif",
                                            fontStyle: "normal",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            color: "#212922",
                                        }}
                                    >
                                        {formattedData.agentInfo.name}
                                    </Text>
                                    <Text
                                        sx={{
                                            fontFamily:
                                                "Public Sans, sans-serif",
                                            fontStyle: "normal",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            color: "#212922",
                                        }}
                                    >
                                        ({formattedData.agentInfo.renId})
                                    </Text>

                                </Flex>
                                <Text
                                    sx={{
                                        fontFamily: "Public Sans, sans-serif",
                                        fontStyle: "normal",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "20px",
                                        color: "#212922",
                                    }}
                                >
                                    Joined PropMoth on{" "}
                                    {formattedData.agentInfo.joinDate}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex direction="column" align="center" gap="16px" w="100%">
                        {/* <Button
                            component="a"
                            href=""
                            h={44}
                            sx={{
                                // padding: "10px 115px",
                                backgroundColor: "#294936",
                                fontFamily: "Public Sans, sans-serif",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                width: "100%",
                            }}
                        >
                            Accept Offer
                        </Button> */}
                        {!!canChat && (
                            <>
                                <Button
                                    sx={{
                                        // padding: "10px 115px",
                                        // border: "1px solid #294936",
                                        // color: "#294936",
                                        // backgroundColor: "#FFFFFF",
                                        // fontFamily: "Public Sans, sans-serif",
                                        // fontStyle: "normal",
                                        // fontWeight: 500,
                                        // fontSize: "16px",
                                        // lineHeight: "24px",
                                        width: "100%",
                                    }}
                                    onClick={() => handleChatNow()}
                                >
                                    Chat Now
                                </Button>
                                <Text
                                    sx={{
                                        fontFamily: "Public Sans, sans-serif",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#294936",
                                    }}
                                >
                                    Personal information is kept private while
                                    you chat
                                </Text>
                            </>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        );
    };

    return (
        <Container
            size="1200px"
            py={32}
            px={16}
            sx={{ minHeight: "fit-content !important" }}
        >
            <Group mb={16}>
                <Button variant="subtle" leftIcon={<ArrowLeft />} onClick={() => navigate(-1)}>
                    Back
                </Button>
            </Group>
            {!!showLightBox && (
                <LightBox
                    showLightBox={showLightBox}
                    onClose={() => setShowLightBox(false)}
                    // image={formattedData.offerImages[0]?.src || ""}
                    images={formattedData.offerImages}
                    clickedImage={clickedImage}
                />
            )}
            <Box pb={32}>
                <Text
                    sx={{
                        fontWeight: 600,
                        fontSize: "36px",
                        lineHeight: "44px",
                        letterSpacing: "-0.02em",
                    }}
                >
                    Offer Details
                </Text>
            </Box>
            {!isMobile && (
                <Divider my="sm" pb={32} m={"0 !important"} w={"100%"} />
            )}
            <ImageGrid mainImage={formattedData.offerMainImage} images={formattedData.offerImages} />
            <Box>
                <Grid gutter={24} sx={{ height: '100%' }}>
                    <Grid.Col md={8} sm={12}>
                        <Flex
                            w={"100%"}
                            direction="column"
                            align="flex-start"
                            p={0}
                            gap="16px"
                        >
                            <Flex
                                direction="column"
                                align="flex-start"
                                p={0}
                                gap="8px"
                            >
                                <Text
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "20px",
                                        lineHeight: "30px",
                                        color: "#212922",
                                    }}
                                >
                                    {`RM ${parseInt(
                                        formattedData.offerSummary.price
                                    ).toLocaleString()}`}
                                </Text>
                                <Text
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "20px",
                                        lineHeight: "30px",
                                        color: "#212922",
                                    }}
                                >
                                    {formattedData.offerSummary.title}
                                </Text>
                                <Group spacing={8}>
                                    <Avatar src={formattedData.agentInfo.profilePic ?? "avatar.png"} radius='50%' />
                                    <Text fz={12}>Offered by <Text component="span" color="#417256">{formattedData.agentInfo.name}</Text></Text>
                                </Group>
                                <Flex
                                    direction="row"
                                    align="center"
                                    p={0}
                                    gap="8px"
                                >
                                    <IconMapPin
                                        width={"16px"}
                                        height={"16px"}
                                        color="#546D5E"
                                    />
                                    <Text
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            color: "#294936",
                                        }}
                                    >
                                        {formattedData.offerSummary.addressLine1}
                                    </Text>
                                </Flex>
                                {!!formattedData.offerSummary
                                    .originalListingURL && (
                                        <Group>
                                            <IconLink
                                                width={"24px"}
                                                height={"24px"}
                                                color="#546D5E"
                                                style={{ transform: "rotate(45deg)" }}
                                            />
                                            <Text
                                                component="a"
                                                href={
                                                    formattedData.offerSummary
                                                        .originalListingURL
                                                }
                                                target="_blank"
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: 500,
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    color: "#294936",
                                                    textDecorationLine: "underline",
                                                }}
                                            >
                                                View original listing
                                            </Text>
                                        </Group>
                                    )}
                                <Group mt={8}>
                                    {formattedData.propertyDetails.map((t: any) => (
                                        <Badge
                                            sx={{
                                                background: t.backgroundColor,
                                                color: t.color,
                                            }}
                                        >
                                            {t.value}
                                        </Badge>
                                    ))}
                                </Group>
                            </Flex>
                            <Divider my="sm" w={"100%"} />

                            <DetailsMultipleRow
                                title="Offer details"
                                detailsInfo={formattedData.offerDetails}
                            />
                            {formattedData.embeds.length > 0 && (
                                <>
                                    <Divider my="sm" w={"100%"} />
                                    <Text
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "20px",
                                            lineHeight: "30px",
                                            color: "#212922",
                                        }}
                                    >
                                        Embeded Media
                                    </Text>
                                    <Box sx={{ width: '100%' }}>
                                        {formattedData.embeds.length > 2 ? (
                                            <Carousel
                                                withIndicators
                                                slideSize="33.333333%"
                                                slideGap="md"
                                                loop
                                                align="start"
                                                slidesToScroll={3}
                                                dragFree
                                            >
                                                {formattedData.embeds.map((url: string) => (
                                                    <Carousel.Slide>
                                                        <iframe
                                                            height={574}
                                                            width={323}
                                                            src={url}
                                                            frameBorder={0}
                                                        />
                                                    </Carousel.Slide>
                                                ))}
                                            </Carousel>
                                        ) : (
                                            <>
                                                {
                                                    formattedData.embeds.map((url: string) => (
                                                        <iframe
                                                            height={574}
                                                            width={323}
                                                            src={url}
                                                            frameBorder={0}
                                                        />
                                                    ))
                                                }
                                            </>

                                        )}
                                    </Box>
                                </>
                            )
                            }

                        </Flex >
                    </Grid.Col >
                    <Grid.Col md={4} sm={12}>
                        <Box sx={{ height: '100%', position: 'relative' }}>
                            <Box sx={{ position: 'sticky', top: 0, right: 0 }}>

                                <AgentDetails />
                            </Box>
                        </Box>
                    </Grid.Col>
                </Grid >
            </Box >
        </Container >
    );
}
