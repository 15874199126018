import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import {
    Box,
    Text,
    Container,
    TextInput,
    Group,
    Affix,
    FileInput,
    Button,
    Image,
    UnstyledButton,
    Center,
    ActionIcon,
    PasswordInput,
    Select,
    Stack,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useProfileController } from "hooks/useProfile";
import { AgentDetail } from "types/userTypes";
import { EditInput } from "components/Inputs/EditInput";
import { SectionContainer } from "./SectionContainer";
import { Trash } from "tabler-icons-react";
import { updateUserPayload } from "api/updateUserAPI";
import { accountTypes } from "types/accountTypes";
import { useNavigate } from "react-router-dom";
import { EditFormImageInput } from "components/Inputs/EditFormImageInput";
import { notifications } from "@mantine/notifications";
import { useGetConfigs } from "api/configAPI";
import { CONFIG_KEYS } from "helpers/constants";
import { sanitizePhoneNumber, selectFilter } from "helpers/utility";
import { Permission, useAuth } from "hooks/useAuth";
import { USER_ACTIONS } from "context/user/userActions";
import PageLoader from "components/LoadingStates/PageLoader";

interface FormValues {
    avatar: File | string | null
    fullname: string
    renId: string
    agencyName: string
    renImg: File | string | null
    email: string
    publicProfileSlug: string
    phoneNumber: string
    password: string
    preferredName: string
    localityState: string
    localityArea: string
}

const slugRegex = /^[A-Za-z0-9_-]+$/;

const useAgentFormController = () => {
    const {
        profileData,
        profileIsLoading,
        updateUserMutation,
        updateAgentDetailMutation
    } = useProfileController();
    const navigate = useNavigate();
    const [states, setStates] = useState([]);
    const { userDispatch } = useAuth(Permission.USER_ONLY);

    const profile = profileData as AgentDetail
    // useEffect(() => {
    //     console.log('PROFILE', profile)
    // }, [profile])
    const form = useForm<FormValues>({
        initialValues: {
            avatar: null,
            fullname: '',
            renId: '',
            agencyName: '',
            preferredName: '',
            renImg: null,
            email: '',
            phoneNumber: '',
            password: '',
            localityState: '',
            localityArea: '',
            publicProfileSlug: '',
        },
        validateInputOnChange: true,
        validate: values => ({
            fullname: !values.fullname ? 'Full name is required' : null,
            email: !values.email ? 'Email is required' : null,
            phoneNumber: !values.phoneNumber ? 'Phone number is required' : null,
            renId: !values.renId ? 'REN/PEA/REA tag is required' : null,
            agencyName: !values.agencyName ? 'REN/PEA/REA tag is required' : null,
            renImg: !values.renImg ? 'REN image is required' : null,
            publicProfileSlug: !values.publicProfileSlug ? 'This field is required' : !slugRegex.test(values.publicProfileSlug) ? 'Only alphanumeric characters, dashes and underscores are allowed' : null
        })
    })

    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);

    useEffect(() => {
        let statesData = statesAndAreas
            .map((s: any) => ({
                value: s.state,
                label: s.state,
            }))
            .filter((e: any) => !!e);

        setStates(statesData);
    }, [configData]);

    const areas = useMemo(() => {
        if (!!form.values.localityState) {
            const stateObj = statesAndAreas.find(
                (s: any) => s.state === form.values.localityState
            );
            // console.log(stateObj);
            if (!!stateObj) {
                return stateObj.areas
                    .map((a: any) => ({
                        value: a,
                        label: a,
                    }))
                    .filter((e: any) => !!e);
            }
            return [];
        }

        return [
            {
                value: "",
                label: "Please select a state first",
            },
        ];
    }, [form.values]);

    useEffect(() => {
        if (profile) {
            console.log("profile", profile)
            let formattedIdentifier = sanitizePhoneNumber(`${profile?.agent?.phoneNumber}`);

            form.setValues({
                avatar: profile.agent?.avatar?.formats?.thumbnail?.url,
                fullname: profile.agent?.fullname,
                renId: profile?.renId,
                agencyName: profile?.agencyName,
                renImg: profile?.renImg?.formats?.thumbnail?.url,
                email: profile?.agent?.email,
                phoneNumber: formattedIdentifier,
                preferredName: profile?.preferredName,
                localityState: profile?.localityState,
                localityArea: profile?.localityArea,
                publicProfileSlug: profile?.publicProfileSlug ?? profile?.preferredName?.replace(/\s+/g, '-'),
            })
        }
    }, [profile])

    const onSubmit = (values: FormValues) => {
        let formattedIdentifier = sanitizePhoneNumber(`${values.phoneNumber}`);
        // console.log('in onsubmit', values)
        if (profile?.agent?.id) {
            const payload: updateUserPayload = {
                ...values,
                username: `60${formattedIdentifier}`,
                phoneNumber: `60${formattedIdentifier}`,
                userType: accountTypes.AGENT,
                id: profile.agent.id,
                detailId: profile.id
            }
            console.log('payload', payload)
            updateUserMutation.mutate(payload, {
                onSuccess: (data) => {
                    updateAgentDetailMutation.mutate({
                        detailId: profile.id,
                        publicProfileSlug: values.publicProfileSlug
                    }, {
                        onSuccess: (detailData) => {
                            console.log("detailData", data)
                            navigate('/profile')
                        },
                        onError: (error: any) => {

                            const errorMessage = error?.response?.data?.error?.message;

                            if (errorMessage === "This attribute must be unique") {
                                notifications.show({
                                    title: "",
                                    message: `Someone has already used this public profile url: "propmoth.com/agent/${values.publicProfileSlug}", please choose another one.`,
                                });
                            }
                        },
                    })
                    console.log("data", data)

                    userDispatch({
                        type: USER_ACTIONS.EDIT,
                        payload: {
                            email: data.email,
                            username: data.username,
                            fullname: data.fullname,
                            status: data.status,
                        } as any,
                    });
                },
                onError: (error: any) => {
                    const errorMessage = error?.response?.data?.error?.message;
                    const errorDetails = error?.response?.data?.error?.details;
                    if (errorDetails && Object.keys(errorDetails).length === 0) {
                        notifications.show({
                            title: "",
                            message: errorMessage || "",
                        });
                    }
                },
            })
        }
    }

    useEffect(() => {
        if (updateUserMutation.error) {
            // const error: axios
            // console.log('type', typeof updateUserMutation.error, updateUserMutation.error)
            const errors = (updateUserMutation.error as any)?.response?.data
                ?.error?.details?.errors;
            if (errors) {
                errors.forEach(({ path, message }: any) => {
                    // console.log(path[0], message);
                    if (path[0] === "username") {
                        form.setFieldError(
                            "phoneNumber",
                            message.replace("username", "phone number")
                        );
                    }
                    form.setFieldError(path[0], message);
                });
            }
        }
    }, [updateUserMutation.error]);

    return {
        form,
        profileIsLoading,
        onSubmit,
        updateUserMutation,
        areas,
        states
    }
}

export const EditAgentForm: React.FC<any> = ({
}) => {
    const navigate = useNavigate();
    const {
        form,
        onSubmit,
        updateUserMutation,
        profileIsLoading,
        areas,
        states,
    } = useAgentFormController()

    if (!!profileIsLoading) {
        <PageLoader title="Edit Profile" />
    }
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Box
                pb={48}
            >
                <Container size={"800px"}>
                    <SectionContainer>
                        <Text size={20} weight={600}>Basic Info</Text>
                        {/* <EditFormImageInput
                            label='Profile Picture'
                            {...form.getInputProps('avatar')}
                        /> */}
                        <TextInput
                            {...form.getInputProps('preferredName')}
                            label='Preferred Name'
                            required
                        />
                        <Stack spacing={4}>
                            <TextInput
                                {...form.getInputProps('publicProfileSlug')}
                                label='Public profile url'
                                required
                            />
                            <Text size={'sm'}>Your public profile link will look like: <Text component="span" sx={{ color: "#2e523c" }} fw="bold" fz={18}>propmoth.com/agent/{form.values.publicProfileSlug}</Text></Text>
                        </Stack>
                        <TextInput
                            disabled
                            {...form.getInputProps('fullname')}
                            label='Full Name'
                            required
                        />
                        <TextInput
                            disabled
                            {...form.getInputProps('renId')}
                            label='REN/PEA/REA Tag'
                            required
                        />
                        <TextInput
                            disabled
                            {...form.getInputProps('agencyName')}
                            label='Firm/Agency Name'
                            required
                        />
                        <EditFormImageInput
                            {...form.getInputProps('renImg')}
                            label='REN Image'
                            required
                        />
                        {/* <Select
                            label="Locality State"
                            placeholder="Type to search..."
                            data={states}
                            searchable
                            nothingFound="No options"
                            filter={selectFilter}
                            {...form.getInputProps(
                                "localityState"
                            )}
                            onChange={(e) => {
                                form.setFieldValue(
                                    "localityState",
                                    e || ""
                                );
                                form.setFieldValue(
                                    "localityArea",
                                    ""
                                );
                            }}
                        />
                        <Select
                            label="Locality Area"
                            placeholder={!form.values.localityState ? "Select state first" : "Type to search..."}
                            data={
                                !form.values.localityState
                                    ? []
                                    : areas
                            }
                            disabled={
                                !form.values.localityState
                            }
                            searchable
                            nothingFound="No options"
                            filter={selectFilter}
                            {...form.getInputProps(
                                "localityArea"
                            )}
                        /> */}
                    </SectionContainer>
                    <SectionContainer>
                        <Text size={20} weight={600}>Contact Info</Text>
                        <TextInput
                            {...form.getInputProps('email')}
                            label='Email Address'
                            required
                            disabled
                        />
                        <TextInput
                            {...form.getInputProps('phoneNumber')}
                            label='Phone Number'
                            required
                            icon={
                                <Text
                                    color="black"
                                    sx={{ whiteSpace: "nowrap" }}
                                    size={"sm"}
                                >
                                    {`+60`}
                                </Text>
                            }
                            disabled
                        />
                    </SectionContainer>
                    <SectionContainer>
                        <Text size={20} weight={600}>Security Info</Text>
                        <PasswordInput
                            label='Set your new password'
                            {...form.getInputProps('password')}
                        />
                    </SectionContainer>
                </Container>
                <Container
                    size="800px"
                >
                    <Box
                        sx={theme => ({
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: 12,
                            padding: '32px 0',
                            [theme.fn.smallerThan('xs')]: {
                                padding: '16px 0'
                            }
                        })}
                    >
                        <Button
                            variant='outline'
                            onClick={() => navigate('/profile')}
                            sx={theme => ({
                                [theme.fn.smallerThan('xs')]: {
                                    flexGrow: 1
                                }
                            })}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            disabled={updateUserMutation.isLoading}
                            onClick={() => onSubmit(form.values)}
                            sx={theme => ({
                                [theme.fn.smallerThan('xs')]: {
                                    flexGrow: 1
                                }
                            })}
                        >
                            Save Changes
                        </Button>
                    </Box>
                </Container>
                {/* <Affix position={{ bottom: 0 }}>
                    <Box
                        sx={{
                            width: '100vw',
                            borderTop: `1px solid #BFC8C3`,
                            backgroundColor: 'white',
                        }}
                    >
                        <Container
                            size="1200px"
                        >
                            <Box
                                sx={theme => ({
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: 12,
                                    padding: '32px 0',
                                    [theme.fn.smallerThan('xs')]: {
                                        padding: '16px 0'
                                    }
                                })}
                            >
                                <Button 
                                    variant='outline'
                                    onClick={() => navigate('/profile')}
                                    sx={theme => ({
                                        [theme.fn.smallerThan('xs')]: {
                                            flexGrow: 1
                                        }
                                    })}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    onClick={() => onSubmit(form.values)}
                                    sx={theme => ({
                                        [theme.fn.smallerThan('xs')]: {
                                            flexGrow: 1
                                        }
                                    })}
                                >
                                    Save Changes
                                </Button>
                            </Box>
                        </Container>
                    </Box>
                </Affix> */}
            </Box>
        </form>
    )
}