import { Center, Image, Loader, Stack, Text } from '@mantine/core';
import React from 'react';

const PageLoader = ({ title }: { title: string }) => {
    return (
        <Center sx={{ minHeight: '100vh' }}>
            <Stack align="center">
                {/* <Group spacing={8}>
                </Group> */}
                <Image
                    src={"/logo.svg"}
                    alt={"propmoth logo"}
                    width={148}
                    height={48}
                />
                <Text fz={36} fw={'bold'}>{title}</Text>
                <Loader />
            </Stack>
        </Center>
    );
};

export default PageLoader;