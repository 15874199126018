import React, {
    useState,
    useEffect,
    useMemo,
    useCallback
} from "react";
import { 
    Container,
    Title,
    Text,
    Box,
    Group,
    Button,
    useMantineTheme,
    PinInput,
} from "@mantine/core";
import { BaseFormInput } from "components/Inputs/BaseFormInput";
import { useForm } from "@mantine/form";
import { forgotStep } from "helpers/constants";
import { useMutation } from "react-query";
import API from "api/API";
import { notifications } from "@mantine/notifications";
import { EditInput } from "components/Inputs/EditInput";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { FormPasswordInput } from "components/Inputs/FormPasswordInput";
import { BasePasswordInput } from "components/Inputs/BasePasswordInput";

const useForgotPasswordController = () => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const navigate = useNavigate();
    const [step, setStep] = useState<forgotStep>(forgotStep.PHONE_NUMBER)
    const [requestId, setRequestId] = useState(null)
    const phoneNumberForm = useForm<{ phoneNumber: string }>({
        initialValues: {
            phoneNumber: "",
        },
        validateInputOnChange: true,
        validate: (values) => ({
            phoneNumber:
                values.phoneNumber.length === 0
                    ? "Phone number is required"
                    : null,
        }),
    })
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState("");

    const forgotPasswordMutation = useMutation({
        mutationFn: (data: any) => API({}).post("/auth/otp", data)
    })

    const submitPhoneNumber = ({ phoneNumber }: { phoneNumber: string }) => {
        forgotPasswordMutation.mutate(
            { phoneNumber: `60${phoneNumber}` },
            {
                onSuccess: (data: any) => {
                    const {
                        success,
                        message,
                        requestId
                    } = data.data
                    // console.log('forgot pw res', data)
                    if (success) {
                        setStep(forgotStep.OTP);
                        setRequestId(requestId)
                        notifications.show({
                            message,
                        });
                    } else {
                        phoneNumberForm.setFieldError('phoneNumber', message)
                    }
                },
            }
        )
    }

    const verifyOtpMutation = useMutation({
        mutationFn: (data: any) => API({}).post("/otp/verify", data),
    });

    const submitOtp = () => {
        verifyOtpMutation.mutate(
            {
                requestId,
                code
            },
            {
                onSuccess: (data) => {
                    // console.log("otp verify succ", data.data);
                    if (data.data.status !== "0") {
                        setCodeError(`The code you've entered is invalid`);
                    } else {
                        setStep(forgotStep.RESET)
                    }
                },
            }
        )
    }

    const resetPasswordForm = useForm({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validateInputOnChange: true,
        validate: (values) => ({
            confirmPassword:
                values.confirmPassword !== values.password
                    ? "Passwords do not match"
                    : null,
        }),
    })

    const allowReset = useMemo(() => {
        return resetPasswordForm.isValid() && Object.keys(resetPasswordForm.errors).length === 0;
    }, [resetPasswordForm]);

    const resetPasswordMutation = useMutation({
        mutationFn: (data: any) => API({}).post("/auth/otp/reset", data)
    })

    const submitResetPassword = (values: {
        password: string,
        confirmPassword: string
    }) => {
        const { password, confirmPassword } = values
        resetPasswordMutation.mutate(
            {
                requestId,
                password,
                confirmPassword,
            },
            {
                onSuccess: (data: any) => {
                    const { success, message } = data.data
                    if (success) {
                        navigate('/login')
                        notifications.show({
                            message
                        })
                    } else {
                        notifications.show({
                            message
                        })
                    }
                }
            }
        )
    }

    const renderContent = () => {
        switch (step) {
            case forgotStep.PHONE_NUMBER:
                return <form onSubmit={phoneNumberForm.onSubmit(submitPhoneNumber)}>
                    <Title order={1} align="center" py={24}>
                        Forgot Password
                    </Title>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Text color="primaryGreen" size={18}>
                            {`Please enter your phone number.`}
                        </Text>
                        <Box
                            mt={24}
                        >
                            <BaseFormInput
                                label="Phone Number"
                                icon={
                                    <Text
                                        color="black"
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {`MY (+60)`}
                                    </Text>
                                }
                                iconWidth={90}
                                {...phoneNumberForm.getInputProps("phoneNumber")}
                                required
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '300px',
                                // gap: 8
                            }}
                            py={32}
                        >
                            {/* <Button onClick={() => navigate("/login")} variant="outline" sx={{ width: '50%' }}>
                                Cancel
                            </Button> */}
                            <Button
                                fullWidth
                                disabled={
                                    forgotPasswordMutation.isLoading ||
                                    phoneNumberForm.values.phoneNumber.length < 9
                                }
                                loading={forgotPasswordMutation.isLoading}
                                type='submit'
                            >
                                Verify
                            </Button>
                        </Box>
                    </Box>
                </form>
            case forgotStep.OTP:
                return <form onSubmit={phoneNumberForm.onSubmit(submitOtp)}>
                    <Title order={1} align="center" py={24}>
                        Verify OTP
                    </Title>
                    <Text color="primaryGreen" size={18}>
                        {`Please enter the 4-digit code we sent over SMS to 60${phoneNumberForm.values.phoneNumber}.`}
                    </Text>
                    <PinInput
                        length={4}
                        mt={24}
                        type="number"
                        placeholder=""
                        size="xl"
                        oneTimeCode // to enable auto complete
                        autoFocus
                        value={code}
                        onChange={(x) => {
                            setCode(x);
                            setCodeError("");
                        }}
                        onComplete={submitOtp}
                        disabled={
                            verifyOtpMutation.isLoading
                        }
                        error={!!codeError}
                        sx={{
                            input: {
                                height: 80,
                                width: 80,
                                fontSize: "48px",
                            },
                        }}
                    />
                    {codeError && (
                        <Text color="red" size={14}>
                            {codeError}
                        </Text>
                    )}
                    <Box
                        sx={{
                            position: "sticky",
                            bottom: 0,
                        }}
                    >
                        <Container size="1200px" px={0}>
                            <Group position="right" py={32}>
                                {/* <Button onClick={() => navigate("/")} variant="outline">
                                    Cancel
                                </Button> */}
                                <Button
                                    disabled={
                                        verifyOtpMutation.isLoading ||
                                        !!codeError ||
                                        code.length < 4
                                    }
                                    loading={verifyOtpMutation.isLoading}
                                    type='submit'
                                >
                                    Verify
                                </Button>
                            </Group>
                        </Container>
                    </Box>
                </form>
            case forgotStep.RESET:
                return <form onSubmit={resetPasswordForm.onSubmit(submitResetPassword)}>
                    <Title order={1} align="center" py={24}>
                        Set new password
                    </Title>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Text color="primaryGreen" size={18}>
                            {`Please enter your new password.`}
                        </Text>
                        <Box
                            mt={24}
                            // sx={{
                            //     display: 'flex',
                            //     flexDirection: 'column',
                            //     gap: 8
                            // }}
                        >
                        </Box>
                            <FormPasswordInput
                                {...resetPasswordForm.getInputProps("password")}
                                form={resetPasswordForm}
                                required
                                sx={{
                                    width: '300px'
                                }}
                            />
                            <BasePasswordInput
                                label="Confirm Password"
                                {...resetPasswordForm.getInputProps("confirmPassword")}
                                required
                                sx={{
                                    width: '300px'
                                }}
                            />
                        <Box
                            sx={{
                                display: 'flex',
                                width: '300px',
                                // gap: 8
                            }}
                            py={32}
                        >
                            {/* <Button onClick={() => navigate("/login")} variant="outline" sx={{ width: '50%' }}>
                                Cancel
                            </Button> */}
                            <Button
                                fullWidth
                                disabled={
                                    resetPasswordMutation.isLoading ||
                                    !allowReset
                                }
                                loading={resetPasswordMutation.isLoading}
                                type='submit'
                            >
                                Change Password
                            </Button>
                        </Box>
                    </Box>
                </form>
        }
    }
    return {
        step,
        setStep,
        renderContent,
    }
}

export const ForgotPassword = ({}) => {
    const form = useForm({
        initialValues: {
            phoneNumber: "",
            password: "",
            confirmPassword: "",
        },
        validateInputOnChange: true,
        validate: (values) => ({
            phoneNumber:
                values.phoneNumber.length === 0
                    ? "Phone number is required"
                    : null,
            confirmPassword:
                values.confirmPassword !== values.password
                    ? "Passwords do not match"
                    : null,
        }),
    })
    const { renderContent } = useForgotPasswordController();
    return (
        <Container size="800px">
            {renderContent()}
        </Container>
    )
}