import { Box, Flex, Grid, Group, Image, Stack, Text } from '@mantine/core';
import React from 'react';
import { Link, Rocket, Spy } from 'tabler-icons-react';

const WhyPropmoth = () => {
    const whyPropmoth = [
        {
            title: "Direct to Developer",
            content: (
                <Text>
                    Get direct access to projects via{" "}
                    <Text component="span" sx={{ color: "#407A3F" }}>
                        sponsored offers
                    </Text>{" "}
                    by developers when your details match
                </Text>
            ),
            icon: <Link width={32} height={32} color="white" />,
        },
        {
            title: "Anonimity",
            content: (
                <Text>
                    Keep your personal number and emails private to{" "}
                    <Text component="span" sx={{ color: "#407A3F" }}>
                        prevent unwanted spam
                    </Text>{" "}
                    from agents
                </Text>
            ),
            icon: <Spy width={32} height={32} color="white" />,
        },
        {
            title: "Efficiency",
            content: (
                <Text>
                    Every offer you browse is genuine. Agents will no longer
                    waste your time with{" "}
                    <Text component="span" sx={{ color: "#407A3F" }}>
                        fake listings
                    </Text>
                </Text>
            ),
            icon: <Rocket width={32} height={32} color="white" />,
        },
    ];

    return (
        <>
            <Stack px={16}>
                <Group spacing={8}>
                    <Text
                        component="h1"
                        sx={{
                            fontSize: "1.5em",
                            lineHeight: 1,
                        }}
                    >
                        Why
                    </Text>
                    <Image
                        src={"/logo.svg"}
                        alt="propmoth logo"
                        width={95}
                        height={32}
                    />
                </Group>

                <Text component="h2" fw={700} fz="xl">
                    You're the one paying, so why shouldnt <br />
                    you get the best offers?
                </Text>
            </Stack>
            <Grid>
                {whyPropmoth.map((p) => (
                    <Grid.Col md={4}>
                        <Stack spacing={24} align="flex-start">
                            <Flex
                                sx={{
                                    backgroundColor: "#15BC6A",
                                    width: "48px",
                                    height: "48px",
                                    borderRadius: "50%",
                                }}
                                justify="center"
                                align="center"
                                ml={16}
                            >
                                {p.icon}
                            </Flex>
                            <Box
                                sx={{
                                    borderLeft: "1px solid #000",
                                }}
                                px={16}
                            >
                                <Text
                                    component="h2"
                                    sx={{ margin: 0 }}
                                >
                                    {p.title}
                                </Text>
                            </Box>
                            <Box px={16}>{p.content}</Box>
                        </Stack>
                    </Grid.Col>
                ))}
            </Grid>
        </>
    );
};

export default WhyPropmoth;