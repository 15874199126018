import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
const qs = require("qs");

// keys
export const GET_ARTICLE_LIST = "GET_ARTICLE_LIST";
export const GET_LATEST_ARTICLE_LIST = "GET_LATEST_ARTICLE_LIST";
export const GET_RELEVENT_ARITICLE_LIST = "GET_RELEVENT_ARITICLE_LIST";
export const GET_ARTICLE_BY_SLUG = "GET_ARTICLE_BY_SLUG";

//functions
async function getArticles() {
    const query = qs.stringify(
        {
            populate: {
                banner: true,
            },
            filters: {
                published: {
                    $eq: true,
                },
            },
        },
        {
            encodeValuesOnly: true, // prettify URL
        }
    );
    try {
        const response = await API({}).get<any, any>(`articles?${query}`);

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetArticles = () =>
    useQuery([GET_ARTICLE_LIST], () => getArticles());

async function getLatestArticles() {
    const query = qs.stringify(
        {
            populate: {
                banner: true,
            },
            filters: {
                published: {
                    $eq: true,
                },
            },
            pagination: {
                page: 1,
                pageSize: 10,
            },
            sort: ["createdAt:desc"],
        },
        {
            encodeValuesOnly: true, // prettify URL
        }
    );
    try {
        const response = await API({}).get<any, any>(`articles?${query}`);

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetLatestArticles = () =>
    useQuery([GET_LATEST_ARTICLE_LIST], () => getLatestArticles());

async function getArticleBySlug(slug: string) {
    const query = qs.stringify(
        {
            populate: {
                banner: true,
            },
            filters: {
                published: {
                    $eq: true,
                },
                slug: {
                    $eq: slug,
                },
            },
        },
        {
            encodeValuesOnly: true, // prettify URL
        }
    );
    try {
        const response = await API({}).get<any, any>(`articles?${query}`);

        return flattenObj(response.data);
    } catch (e) {
        return e;
    }
}

export const useGetArticleBySlug = (slug: string) =>
    useQuery([GET_ARTICLE_BY_SLUG], () => getArticleBySlug(slug), {
        enabled: !!slug,
    });
