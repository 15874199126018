//@ts-nocheck
import { BackgroundImage, Box, Button, Card, Center, Container, Flex, Grid, Group, Image, Stack, Text } from '@mantine/core';
import { useInsightsAPI } from 'api/useInsightsAPI';
import CustomHeader from 'components/Header/Header';
import React, { useEffect, useMemo } from 'react';
import { ArrowNarrowRight } from 'tabler-icons-react';
import AgentPromoHero from './AgentPromoHero';
import ReactPixel from 'react-facebook-pixel';


//@ts-ignore
import { catchUserData, sendScheduleEvent } from 'helpers/pixelEvents';
import { CHRISTMAS_PROMO_PIXEL } from 'helpers/constants';

const AgentPromo = ({ ReactPixel }: any) => {
    useEffect(() => {
        ReactPixel.pageView(null, [CHRISTMAS_PROMO_PIXEL])
    }, [])

    const sidePadding = 24;

    const logos = [
        {
            title: "Propnex",
            img: "/propnex.png",
        },
        {
            title: "IQI",
            img: "/iqi.png",
        },
        {
            title: "Kith & Kin",
            img: "/kithandkin.png",
        },
        // {
        //     title: "Century Properties",
        //     img: "/century.png",
        // },
    ];

    const stats = [
        {
            title: "100+",
            content: (
                <Text color="#fff" component="p">
                    Verified participating agents that are transitioning using the platform to get ahead of the competition
                </Text>
            ),
        },
        {
            title: "3",
            content: (
                <Text color="#fff" component="p" m={0}>
                    Partnered agencies that have special contracts with us to make sure that their agents don’t have to spend as much per lead
                </Text>
            ),
        },
        {
            title: "500+",
            content: (
                <Text color="#fff" component="p">
                    Leads and counting at an average of 50 per week!
                </Text>
            ),
        },
    ];

    const faq = [
        {
            title: "Do I need to pay to sign up?",
            content:
                "No, you do not need to pay to sign up. The only times that payment is required is when you make an offer or list property!",
        },
        {
            title: "How do I know the leads are legitimate?",
            content:
                "We do our best at Propmoth to verify the leads that we acquire. We will directly contact the requester and figure out their specific needs and help tailor the requests. We also have policies and features in place to make sure that agents never waste any money on a bad lead.",
        },
        {
            title: "I signed up, now what?",
            content:
                "First off, thank you for signing up! You can now create property listings as with any other property website. But we specialize in property requests! Find the perfect lead and offer them property ASAP!",
        },
    ];

    const handleSchedule = () => {
        sendScheduleEvent()
        window.open("https://calendly.com/propmothadmin/30min")
    }

    const SkewDivider = ({
        primaryColor,
        skewColor,
        skewZIndex,
        lineColor,
    }: {
        primaryColor: string;
        skewColor: string;
        skewZIndex?: number;
        lineColor?: string;
    }) => {
        return (
            <Box
                sx={{
                    background: primaryColor,
                    minHeight: 50,
                    width: "100%",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        top: -20
                    }}
                >
                    <Button
                        sx={{ backgroundColor: "#15BC69" }}
                        radius={'lg'}
                        onClick={() => handleSchedule()}
                    >
                        <Text color="#000" px={20} fw={700}>Start Now</Text>
                    </Button>
                </Box>
                <Box
                    sx={{
                        background: skewColor,
                        width: "100%",
                        height: 100,
                        // marginBottom: "-50px",
                        position: "absolute",
                        left: 0,
                        top: "90%",
                        transform: `skew(0deg, -2deg) translateY(-50%)`,
                        zIndex: skewZIndex ?? 0,
                    }}
                >
                    <Container
                        size="1200px"
                        px={sidePadding}
                        sx={{ height: "100%" }}
                    >
                        <Box
                            sx={{
                                borderLeft:
                                    lineColor ?? ".5px solid rgba(0,0,0,0.3)",
                                borderRight:
                                    lineColor ?? ".5px solid rgba(0,0,0,0.3)",
                                height: "100%",
                            }}
                            px={sidePadding}
                        />
                    </Container>
                </Box>
                <Container
                    size="1200px"
                    px={sidePadding}
                    sx={{ height: "50px" }}
                >
                    <Box
                        sx={{
                            borderLeft: ".5px solid rgba(0,0,0,0.3)",
                            borderRight: ".5px solid rgba(0,0,0,0.3)",
                            height: "100%",
                        }}
                        px={sidePadding}
                    />
                </Container>
            </Box>
        );
    };

    return (
        <>
            <AgentPromoHero handleSchedule={handleSchedule} />
            <Container size="1200px" px={sidePadding}>
                <Stack
                    sx={{
                        // borderLeft: ".5px solid rgba(0,0,0,0.3)",
                        // borderRight: ".5px solid rgba(0,0,0,0.3)",
                    }}
                    px={sidePadding}
                    pb={100}
                >
                    <Text
                        component="h1"
                        sx={(theme) => ({
                            fontSize: "1.5em",
                            lineHeight: 1,
                            textAlign: "center",
                        })}
                    >
                        Join other agents from
                    </Text>
                    {/* <Group position="apart" align="center">
                        </Group> */}
                    <Grid gutter={24}>
                        {logos.map((logo) => (
                            <Grid.Col xs={12} sm={6} md={4} key={logo.title}>
                                <Center sx={{ height: "100%" }}>
                                    <Image
                                        src={logo.img}
                                        alt={logo.title}
                                        fit="contain"
                                        width={150}
                                    />
                                </Center>
                            </Grid.Col>
                        ))}
                        <Grid.Col span={12}>
                            <Text fw="bold" align="center">and more!</Text>
                        </Grid.Col>
                    </Grid>
                </Stack>
            </Container>
            <Box sx={{ backgroundColor: "#DDFFEE" }}>
                <Container size="1200px" px={sidePadding}>
                    <Stack
                        spacing={48}
                        sx={{
                            borderLeft: ".5px solid rgba(0,0,0,0.3)",
                            borderRight: ".5px solid rgba(0,0,0,0.3)",
                        }}
                        py={50}
                        px={sidePadding}
                    >
                        <Flex sx={(theme) => ({
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: 'row',
                            gap: 24,
                            [theme.fn.smallerThan('sm')]: {
                                flexDirection: 'column',
                            }
                        })}>
                            <Image src="/promo-middle-1.png" width={128} />
                            <Stack spacing={4}>
                                <Text align='center' fw={800} fz={24} sx={{ color: "#15BC69" }}>100% Response-Rate</Text>
                                <Text align='center' fw={800} fz={24}>OR</Text>
                                <Text align='center' fw={800} fz={24} color="red">FULL REFUND<Text component='span' color="black">*</Text></Text>
                                <Text align='center' fw={700} fz={10}>*refund only applies to token and not cash</Text>
                            </Stack>
                            <Image src="/promo-middle-2.png" width={128} />
                        </Flex>
                    </Stack>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: "#F1FFF8" }}>
                <Container size="1200px" px={sidePadding}>
                    <Stack
                        spacing={48}
                        sx={{
                            borderLeft: ".5px solid rgba(0,0,0,0.3)",
                            borderRight: ".5px solid rgba(0,0,0,0.3)",
                        }}
                        py={50}
                        px={sidePadding}
                    >
                        <Group spacing={8} position='center' align='center'>
                            <Text fw={900} fz={32}>How does</Text>
                            <Image
                                src="/propmoth-logo-text.png"
                                width={159.5}
                            />
                            <Text fw={900} fz={32}>help me?</Text>
                        </Group>
                        <Grid gutter={'lg'}>
                            <Grid.Col span={12}>
                                <Group position='center'>
                                    <Stack
                                        spacing={4}
                                        sx={(theme) => ({
                                            maxWidth: '80%',
                                            [theme.fn.smallerThan("sm")]: {
                                                maxWidth: '100%',
                                            }
                                        })}
                                        align='center'
                                    >
                                        <Image
                                            src="/promo-top-icon-1.png"
                                            width={64}
                                            height={64}
                                        />

                                        <Text fw={700} component='h2' fz={24} sx={{ margin: 0 }}>Real Clients Only</Text>
                                        <Text align='center' component='h3' fw={500} fz={16} sx={{ margin: 0 }}>
                                            We’ve created a system where you only pay for
                                            responsive leads. If the clients aren’t active, all
                                            agents that have spent a token to offer will
                                            be refunded!
                                        </Text>
                                    </Stack>
                                </Group>
                            </Grid.Col>
                            <Grid.Col md={6} sm={12}>
                                <Group position='center'>
                                    <Stack
                                        spacing={4}
                                        sx={(theme) => ({
                                            maxWidth: '80%',
                                            [theme.fn.smallerThan("sm")]: {
                                                maxWidth: '100%',
                                            }
                                        })}
                                        align='center'
                                    >
                                        <Image
                                            src="/promo-top-icon-2.png"
                                            width={64}
                                            height={64}
                                        />

                                        <Text fw={700} component='h2' fz={24} sx={{ margin: 0 }}>Pay-Per-Lead</Text>
                                        <Text align='center' component='h3' fw={500} fz={16} sx={{ margin: 0 }}>
                                            Control your budget by only offering to leads
                                            that you have confidence closing!
                                            Don’t worry - you can filter the leads yourself!
                                        </Text>
                                    </Stack>
                                </Group>
                            </Grid.Col>
                            <Grid.Col md={6} sm={12}>
                                <Group position='center'>
                                    <Stack spacing={4}
                                        sx={(theme) => ({
                                            maxWidth: '80%',
                                            [theme.fn.smallerThan("sm")]: {
                                                maxWidth: '100%',
                                            }
                                        })}
                                        align='center'
                                    >
                                        <Image
                                            src="/promo-top-icon-3.png"
                                            width={64}
                                            height={64}
                                        />

                                        <Text fw={700} component='h2' fz={24} sx={{ margin: 0 }}>High Quality Leads</Text>
                                        <Text align='center' component='h3' fw={500} fz={16} sx={{ margin: 0 }}>
                                            Our users have to fill out multiple forms and
                                            provide their personal info for them to
                                            list a request. This ensures that only serious
                                            clients will make it to the end for you to offer.
                                        </Text>
                                    </Stack>
                                </Group>
                            </Grid.Col>
                        </Grid>
                        <Text fw={700} fz={32} align='center'>Our Lead Verification Process Includes:</Text>
                        <Grid align='center'>
                            <Grid.Col xs={12} sm={4}>
                                <Image
                                    src="/lead-verification-1.png"
                                    width="100%"
                                />
                            </Grid.Col>
                            <Grid.Col xs={12} sm={4}>
                                <Image
                                    src="/lead-verification-2.png"
                                    width="100%"
                                />
                            </Grid.Col>
                            <Grid.Col xs={12} sm={4}>
                                <Image
                                    src="/lead-verification-3.png"
                                    width="100%"
                                />
                            </Grid.Col>
                        </Grid>
                    </Stack>
                </Container>
                <SkewDivider
                    primaryColor="#F1FFF8"
                    skewColor="#4E7A4D"
                    lineColor=".5px solid rgba(255,255,255,0.3)"
                />
                <Box
                    sx={{
                        background: "#4E7A4D",
                    }}
                >
                    <Container size="1200px" px={sidePadding}>
                        <Stack
                            spacing={36}
                            sx={{
                                borderLeft: ".5px solid rgba(255,255,255,0.3)",
                                borderRight: ".5px solid rgba(255,255,255,0.3)",
                            }}
                            py={50}
                        >
                            <Stack sx={{ maxWidth: "450px" }} spacing={16} px={16}>
                                <Text
                                    component="h1"
                                    sx={{
                                        fontSize: "1.5em",
                                        lineHeight: 1,
                                        color: "#B4E562",
                                    }}
                                >
                                    Transparency above all else
                                </Text>
                                <Text
                                    component="h2"
                                    color="#fff"
                                    sx={{
                                        fontSize: "3em",
                                        margin: "0px",
                                        lineHeight: 1,
                                    }}
                                >
                                    Genuine leads for high performing agents
                                </Text>
                                <Text component="p" color="#fff">
                                    Be part of the growing force fighting back against the predatory industry of property. Never lose to another agent again just because they know how to run better ads.
                                </Text>
                            </Stack>
                            <Grid>
                                {stats.map((s) => (
                                    <Grid.Col md={4} key={s.title}>
                                        <Stack spacing={8} align="flex-start">
                                            <Box
                                                sx={{
                                                    borderLeft: "1px solid #FFF",
                                                }}
                                                px={16}
                                            >
                                                <Text
                                                    component="h2"
                                                    sx={{ margin: 0 }}
                                                    color="#fff"
                                                >
                                                    {s.title}
                                                </Text>
                                            </Box>
                                            <Box px={16}>{s.content}</Box>
                                        </Stack>
                                    </Grid.Col>
                                ))}
                            </Grid>
                        </Stack>
                    </Container>
                </Box>
                <Box
                    sx={{
                        background: "#4E7A4D",
                        zIndex: 10,
                        position: "relative",
                    }}
                >
                    <Container size="1200px" px={sidePadding}>
                        <Stack
                            spacing={24}
                            sx={{
                                borderLeft: ".5px solid rgba(255,255,255,0.3)",
                                borderRight: ".5px solid rgba(255,255,255,0.3)",
                                height: "100%",
                                paddingBottom: 48,
                            }}
                            px={sidePadding}
                        // py={50}
                        >
                            <Text
                                component="h1"
                                sx={{
                                    fontSize: "1.5em",
                                    lineHeight: 1,
                                    color: "#B4E562",
                                }}
                            >
                                Frequently asked questions
                            </Text>
                            {faq.map((f) => (
                                <Box key={f.title}>
                                    <Text
                                        color="white"
                                        fw={700}
                                        sx={{ fontSize: 24, margin: 0 }}
                                        component="h2"
                                    >
                                        {f.title}
                                    </Text>
                                    <Text color="white" fz="sm">
                                        {f.content}
                                    </Text>
                                </Box>
                            ))}
                        </Stack>
                    </Container>
                </Box>
            </Box>
        </>
    );
};

export default AgentPromo;