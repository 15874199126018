import {
    Accordion,
    Badge,
    Box,
    Button,
    Card,
    Center,
    Container,
    Flex,
    Grid,
    Group,
    Image,
    Overlay,
    Stack,
    Text,
    AspectRatio,
    useMantineTheme,
    MediaQuery,
    Avatar,
    ActionIcon,
    Anchor,
    Stepper,
    BackgroundImage,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { useMediaQuery, useScrollIntoView } from "@mantine/hooks";
import { REGISTRATION_PIXEL, USER_TYPE } from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    MapPin,
    Home2,
    FileText,
    Tag,
    Message,
    Messages,
    Link,
    Spy,
    Rocket,
    ChevronRight,
} from "tabler-icons-react";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { relative } from "path";
import CustomHeader from "components/Header/Header";
import { useListingAPI } from "api/useListingAPI";
import TransparencyAboveAllElse from "./MainLanding/TransparencyAboveAllElse";
import WhyPropmoth from "./MainLanding/WhyPropmoth";
import HowItWorks from "./MainLanding/HowItWorks";
import WhyWeExist from "./MainLanding/WhyWeExist";
import FeaturedOn from "./MainLanding/FeaturedOn";
import InPartnershipWith from "./MainLanding/InPartnershipWith";
import FAQ from "./MainLanding/FAQ";
import PropRequestRiver from "./MainLanding/PropRequestRiver";
import ListingsRiver from "./MainLanding/ListingsRiver";
import FeaturedAgencies from "./MainLanding/FeaturedAgencies";
import SupportedBy from "./MainLanding/SupportedBy";

export default function Landing2({ ReactPixel }: any) {
    const navigate = useNavigate();
    const { useGetMyPropRequests } = useListingAPI();
    const location = useLocation();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const showMobileHeader = useMediaQuery(`(max-width: 780px)`);
    const { user } = useAuth(Permission.ANYONE);
    const { data, isLoading, error, refetch } = useGetMyPropRequests(
        user?.id.toString() || "0"
    );
    const { scrollIntoView, targetRef } = useScrollIntoView<
        HTMLDivElement,
        HTMLDivElement
    >();
    const { scrollIntoView: scrollWhyWeExist, targetRef: whyWeExist } =
        useScrollIntoView<HTMLDivElement, HTMLDivElement>();
    const [stepperIndex, setStepperIndex] = useState(0);

    const sidePadding = 24;




    const ctaNavigateLink = useMemo(() => {
        if (!!user.jwt) {
            if (user.userType === USER_TYPE.LISTER) {
                if (!!data && !isLoading) {
                    if (data.length > 0) {
                        return "/profile"
                    }
                }
                return "/requests/create";
            }

            if (user.userType === USER_TYPE.AGENT) {
                return "/profile?tab=leads";
            }
        }

        return "/requests/create";
        // return "/register/lister";
    }, [user, data, isLoading]);

    useEffect(() => {
        if (location.hash === "#why-we-exist") {
            scrollWhyWeExist({});
        }
    }, [location]);

    const particlesInit = useCallback(async (engine: any) => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: any) => {
        // await console.log(container);
    }, []);

    const SkewDivider = ({
        primaryColor,
        skewColor,
        skewZIndex,
        lineColor,
    }: {
        primaryColor: string;
        skewColor: string;
        skewZIndex?: number;
        lineColor?: string;
    }) => {
        return (
            <Box
                sx={{
                    background: primaryColor,
                    minHeight: 50,
                    width: "100%",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        background: skewColor,
                        width: "100%",
                        height: 100,
                        // marginBottom: "-50px",
                        position: "absolute",
                        left: 0,
                        top: "90%",
                        transform: `skew(0deg, -2deg) translateY(-50%)`,
                        zIndex: skewZIndex ?? 0,
                    }}
                >
                    <Container
                        size="1200px"
                        // px={sidePadding}
                        sx={{ height: "100%" }}
                    >
                        <Box
                            sx={{
                                // borderLeft:
                                //     lineColor ?? ".5px solid rgba(0,0,0,0.3)",
                                // borderRight:
                                //     lineColor ?? ".5px solid rgba(0,0,0,0.3)",
                                height: "100%",
                            }}
                        // px={sidePadding}
                        />
                    </Container>
                </Box>
                <Container
                    size="1200px"
                    // px={sidePadding}
                    sx={{ height: "50px" }}
                >
                    <Box
                        sx={{
                            // borderLeft: ".5px solid rgba(0,0,0,0.3)",
                            // borderRight: ".5px solid rgba(0,0,0,0.3)",
                            height: "100%",
                        }}
                    // px={sidePadding}
                    />
                </Container>
            </Box>
        );
    };

    useEffect(() => {
        // console.log(props)
        ReactPixel.pageView(null, [REGISTRATION_PIXEL])
    }, [])


    return (
        <Box pb={16}>
            <Box
                sx={{
                    position: "relative",
                    overflow: "hidden"
                }}
                mb={32}
            >
                <Box
                    sx={{
                        height: "100%",
                        zIndex: 100,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                    }}
                >
                    <CustomHeader isLanding />
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        width: "180vw",
                        height: "125vw",
                        borderBottomLeftRadius: "50%",
                        borderBottomRightRadius: "50%",
                        bottom: 0,
                        left: 0,
                        overflow: "hidden",
                        [theme.fn.smallerThan("md")]: {
                            bottom: "18%",
                        },
                        [theme.fn.smallerThan("xs")]: {
                            top: "-30%",
                            bottom: "auto",
                        },
                    }}
                >
                    <Particles
                        id="tsparticles"
                        url="./particlesjs-config.json"
                        init={particlesInit}
                        loaded={particlesLoaded}
                    />
                </Box>
                <Container
                    size="1200px"
                    px={sidePadding}
                    sx={{
                        height: "100%",
                        width: "100%",
                        minHeight: "100vh",
                        [theme.fn.smallerThan("xs")]: {
                            minHeight: "80vh",
                        },
                    }}
                >
                    <Grid>
                        <Grid.Col sm={6} xs={12}>
                            <Stack
                                sx={{
                                    // maxWidth: 500,
                                    minHeight: "100vh",
                                    [theme.fn.smallerThan("sm")]: {
                                        maxWidth: "none",
                                    },
                                    [theme.fn.smallerThan("xs")]: {
                                        minHeight: "auto",
                                        paddingTop: "30%",
                                    },
                                    justifyContent: "center",
                                }}
                                spacing={16}
                            >
                                <Stack
                                    sx={(theme) => ({
                                        height: "100%",
                                        padding: `16px`,
                                        zIndex: 100,
                                        borderRadius: 8,
                                        [theme.fn.largerThan("xs")]: {
                                            paddingLeft: 0,
                                            marginTop: "20%",
                                        },
                                    })}
                                >
                                    <Text
                                        sx={(theme) => ({
                                            fontSize: "5vw",
                                            [theme.fn.largerThan("xl")]: {
                                                fontSize: "5vw",
                                            },
                                            margin: 0,
                                            zIndex: 100,
                                            lineHeight: 1,
                                            [theme.fn.smallerThan("md")]: {
                                                fontSize: "7vw",
                                            },
                                            [theme.fn.smallerThan("sm")]: {
                                                fontSize: "48px",
                                            },
                                            [theme.fn.smallerThan("xs")]: {
                                                fontSize: "13vw",
                                            },
                                        })}
                                        fw="700"
                                        component="h1"
                                    >
                                        Find{" "}
                                        <Text
                                            component="span"
                                            sx={{
                                                color: "#B4E562",
                                                margin: 0,
                                                lineHeight: 1,
                                            }}
                                        >
                                            exactly
                                        </Text>{" "}
                                        <br />
                                        what you're <br /> looking for
                                    </Text>
                                    <Text
                                        sx={(theme) => ({
                                            zIndex: 100,
                                            fontSize: "20px",
                                            [theme.fn.smallerThan("md")]: {
                                                fontSize: "16px",
                                            },
                                        })}
                                        component="h3"
                                        fw={500}
                                    >
                                        List your dream home location and
                                        budget, professional agents are the ones
                                        who will be reaching out this time.
                                        <br />
                                        <br />
                                        We're giving back power to the users.
                                    </Text>
                                    <Group spacing={4}>
                                        <Button
                                            radius="xl"
                                            onClick={() =>
                                                navigate(ctaNavigateLink)
                                            }
                                            sx={(theme) => ({
                                                [theme.fn.smallerThan("xs")]: {
                                                    flex: 1,
                                                },
                                            })}
                                            rightIcon={
                                                <ChevronRight width={14} />
                                            }
                                        >
                                            <Text fz="md">Start Now </Text>
                                        </Button>
                                        <Button
                                            radius="xl"
                                            variant={
                                                isXs ? "outlined" : "subtle"
                                            }
                                            // color={isXs ? "white" : "primary"}
                                            // onClick={() => scrollIntoView({})}
                                            onClick={() => navigate("/agents")}
                                            sx={(theme) => ({
                                                [theme.fn.smallerThan("xs")]: {
                                                    flex: 1,
                                                },
                                            })}
                                        >
                                            <Text fz="md">I'm an agent</Text>
                                        </Button>
                                    </Group>
                                </Stack>
                            </Stack>
                        </Grid.Col>
                        <Grid.Col sm={6} xs={12}>
                            <Stack
                                sx={{
                                    position: "relative",
                                    height: "100%",
                                    width: "100%",
                                }}
                                justify="center"
                            >
                                <Stack
                                    sx={{
                                        width: '100%',
                                        background: "#E4E4E4",
                                        borderRadius: 16,
                                        zIndex: 100,

                                    }}
                                    p={8}
                                    spacing={8}
                                >
                                    <Box
                                        component="video"
                                        src="/landing_video_male.mp4"
                                        width={"100%"}
                                        controls
                                    >
                                    </Box>
                                    <Text align="center">Watch the video to find out why you should use Propmoth</Text>
                                </Stack>
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </Container>
            </Box>
            <Stack spacing={64}>
                <Container
                    size="1200px"
                    // px={sidePadding} 
                    w="100%"
                >
                    <PropRequestRiver />
                </Container>
                <Container
                    size="1200px"
                    // px={sidePadding} 
                    w="100%"
                >
                    <ListingsRiver />
                </Container>
                <Container size="1200px">
                    <Stack
                        // sx={{
                        //     borderLeft: ".5px solid rgba(0,0,0,0.3)",
                        //     borderRight: ".5px solid rgba(0,0,0,0.3)",
                        // }}
                        // px={24}
                        pb={24}
                        pt={24}
                    >
                        <FeaturedAgencies />
                    </Stack>
                    <Stack
                        // sx={{
                        //     borderLeft: ".5px solid rgba(0,0,0,0.3)",
                        //     borderRight: ".5px solid rgba(0,0,0,0.3)",
                        // }}
                        // px={24}
                        pb={24}
                        pt={24}
                    >
                        <InPartnershipWith />
                    </Stack>
                    <Stack
                        // sx={{
                        //     borderLeft: ".5px solid rgba(0,0,0,0.3)",
                        //     borderRight: ".5px solid rgba(0,0,0,0.3)",
                        // }}
                        // px={24}
                        pb={24}
                    >
                        <FeaturedOn />
                    </Stack>
                </Container>
            </Stack>
            <Container
                size="1200px"
                // px={sidePadding}
                ref={targetRef}
                mt={64}
            >
                <Stack
                    // sx={{
                    //     borderLeft: ".5px solid rgba(0,0,0,0.3)",
                    //     borderRight: ".5px solid rgba(0,0,0,0.3)",
                    // }}
                    pb={50}
                    mt={16}
                >
                    <WhyPropmoth />
                </Stack>
            </Container>
            <Box sx={{ background: "#4E7A4D" }}>
                <Container size="1200px" px={sidePadding}>
                    <Stack
                        spacing={24}
                        sx={{
                            // borderLeft: ".5px solid rgba(255,255,255,0.3)",
                            // borderRight: ".5px solid rgba(255,255,255,0.3)",
                            height: "100%",
                        }}
                    // py={50}
                    >
                        <TransparencyAboveAllElse />
                    </Stack>
                </Container>
                <Container size="1200px" px={sidePadding} >
                    <Stack
                        spacing={24}
                        sx={{
                            // borderLeft: ".5px solid rgba(255,255,255,0.3)",
                            // borderRight: ".5px solid rgba(255,255,255,0.3)",
                            height: "100%",
                        }}
                        // px={sidePadding}
                        pb={64}
                    >
                        <FAQ />
                    </Stack>
                </Container>
            </Box>
            <SkewDivider
                primaryColor="#4E7A4D"
                skewColor="#F1FFF8"
                lineColor=".5px solid rgba(0,0,0,0.3)"
            />
            <Box sx={{ background: "#F1FFF8" }}>
                <Container
                    size="1200px"
                // px={sidePadding}
                >
                    <Stack
                        // sx={{
                        //     borderLeft: ".5px solid rgba(0,0,0,0.3)",
                        //     borderRight: ".5px solid rgba(0,0,0,0.3)",
                        // }}
                        // px={sidePadding}
                        py={50}
                    >
                        <WhyWeExist ctaNavigateLink={ctaNavigateLink} />
                    </Stack>
                </Container>
            </Box>
            <Box >
                <Container
                    size="1200px"
                    // px={sidePadding} 
                    ref={targetRef}
                >
                    <Stack
                        pb={24}
                    >
                        <HowItWorks />
                        <SupportedBy />
                    </Stack>
                </Container>
            </Box>
        </Box >
    );
}
