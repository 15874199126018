import React, { useEffect } from 'react';
import { RichTextEditor as MantineRichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';

const filterEmojis = (data: any) => {
    if (data?.content && data.content.length > 0) {
        data.content = data.content.map((x: any) => filterEmojis(x))
    }
    if (data?.type === 'text' && !!data?.text) {
        data.text = data.text.replace(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu, ' ') // cannot replace with '', empty text nodes not allowed
        // data.text = data.text.replace(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu, '\uFFFD') // replace with unicode non-character; for security shouldnt replace with ''
    }
    return data
}

const RichTextEditor = ({ onChange, error }: any) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content: '',
        editorProps: {
            attributes: {
                class: 'richtext-customized',
            },
        },
        onUpdate: ({ editor }) => {
            const json = editor.getJSON()
            const filtered = filterEmojis(json)
            onChange(filtered)
            editor.commands.setContent(filtered)
        }
    });

    return (
        <MantineRichTextEditor 
            editor={editor} 
            sx={theme => ({
                borderRadius: '0.5rem',
                borderColor: !!error ? theme.colors.red[6] : theme.colors.grey[2],
                '.mantine-RichTextEditor-toolbar': {
                    backgroundColor: 'white',
                    borderRadius: '0.5rem 0.5rem 0 0',
                    borderColor: !!error ? theme.colors.red[6] : theme.colors.grey[2],
                },
                '.mantine-RichTextEditor-content': {
                    backgroundColor: 'white',
                    borderRadius: '0.5rem'
                }
            })}
        >
            <MantineRichTextEditor.Toolbar sticky stickyOffset={60}>
                <MantineRichTextEditor.ControlsGroup>
                    <MantineRichTextEditor.Bold />
                    <MantineRichTextEditor.Italic />
                    <MantineRichTextEditor.Underline />
                    <MantineRichTextEditor.Strikethrough />
                    <MantineRichTextEditor.ClearFormatting />
                    <MantineRichTextEditor.Highlight />
                    <MantineRichTextEditor.Code />
                </MantineRichTextEditor.ControlsGroup>

                <MantineRichTextEditor.ControlsGroup>
                    <MantineRichTextEditor.H1 />
                    <MantineRichTextEditor.H2 />
                    <MantineRichTextEditor.H3 />
                    <MantineRichTextEditor.H4 />
                </MantineRichTextEditor.ControlsGroup>

                <MantineRichTextEditor.ControlsGroup>
                    <MantineRichTextEditor.Blockquote />
                    <MantineRichTextEditor.Hr />
                    <MantineRichTextEditor.BulletList />
                    <MantineRichTextEditor.OrderedList />
                    <MantineRichTextEditor.Subscript />
                    <MantineRichTextEditor.Superscript />
                </MantineRichTextEditor.ControlsGroup>

                <MantineRichTextEditor.ControlsGroup>
                    <MantineRichTextEditor.Link />
                    <MantineRichTextEditor.Unlink />
                </MantineRichTextEditor.ControlsGroup>

                <MantineRichTextEditor.ControlsGroup>
                    <MantineRichTextEditor.AlignLeft />
                    <MantineRichTextEditor.AlignCenter />
                    <MantineRichTextEditor.AlignJustify />
                    <MantineRichTextEditor.AlignRight />
                </MantineRichTextEditor.ControlsGroup>
            </MantineRichTextEditor.Toolbar>

            <MantineRichTextEditor.Content />
        </MantineRichTextEditor>
    );
};

export default RichTextEditor;