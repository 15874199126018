import {
    Badge,
    Box,
    Button,
    Card,
    Center,
    Chip,
    Container,
    Divider,
    Flex,
    Grid,
    Group,
    LoadingOverlay,
    Modal,
    Pagination,
    ScrollArea,
    Stack,
    Sx,
    Tabs,
    Text,
    TextInput,
    Loader
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useListingAPI } from "api/useListingAPI";
import FeedbackBanner from "components/Profile/FeedbackBanner";
import OfferCard from "components/PropRequest/OfferCard";
import PropRequestCard from "components/PropRequest/PropRequestCard";
import {
    AGENT_REQUEST_STATUS,
    PROPERTY_REQUEST_COLOR_SETTINGS,
    PROPERTY_REQUEST_TYPE,
} from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Bath, Bed, BorderRadius, Edit, Home2, Search } from "tabler-icons-react";
import ListingCard from "./PropRequest/ListingsCard";
import useApiPagination from "hooks/useApiPagination";
import { APIHookResponse, ApiResponse } from "types/systemTypes";
import { useDebouncedValue } from "@mantine/hooks";
import { useAgentRequestAPI } from "api/useAgentRequestApi";
import AgentRequestCard from "./AgentRequestCard";
import { notifications } from "@mantine/notifications";

export default function MasterList(props: any) {
    const { pagination } = useApiPagination()
    const [backConfirm, setBackConfirm] = useState(false)
    // const [debouncedKeyword] = useDebouncedValue(keyword, 200);
    const { user } = useAuth(Permission.USER_ONLY);
    const { useGetMasterList } = useAgentRequestAPI()
    const {
        data,
        error,
        isLoading,
        refetch
    } = useGetMasterList({
        pagination: pagination.paginationObj,
        // filters: {
        //     title: {
        //         $containsi: debouncedKeyword
        //     }
        // }
    }) as APIHookResponse
    const navigate = useNavigate();
    
    // const [openDelete, setOpenDelete] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    // const [keyword, setKeyword] = useState("")

    useEffect(() => {
        if (!!data?.meta) {
            pagination.setTotalPages(data?.meta.pagination.pageCount)
            pagination.setActivePage(data?.meta.pagination.page)
        }
    }, [data])

    // useEffect(() => {
    //     setOpenDelete(!!deleteId)
    // }, [deleteId]);


    // const handleDelete = () => {
    //     updateAgentRequest.mutate({
    //         status: AGENT_REQUEST_STATUS.DELETED,
    //         id: deleteId
    //     }, {
    //         onSuccess: () => {
    //             notifications.show({
    //                 title: "Success!",
    //                 message: "Successfully deleted agent request",
    //                 autoClose: 2000,
    //             });
    //             setDeleteId('')
    //             refetch()
    //             props?.callback && props.callback()
    //         }
    //     })
    // }

    const handleBack = () => {
        setBackConfirm(true)
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <Container
                size="1200px"
                py={32}
                px={16}
            >
                <Stack spacing={24}>
                    <Group>
                        <Button 
                            variant="subtle" 
                            leftIcon={<ArrowLeft />} 
                            onClick={() => handleBack()}
                        >
                            Back
                        </Button>
                    </Group>
                    <Group
                        position="apart"
                        sx={{ flexWrap: "nowrap" }}
                    >
                        <Text
                            fz={32}
                            fw={600}
                            sx={{ flexGrow: 1 }}
                        >
                            Propmoth Masterlist
                        </Text>
                    </Group>
                    <Divider my={8} />
                    {!!isLoading && (
                        <Center p={32}> <Loader /> </Center>
                    )}
                    <Grid>
                        {!!data && data.data.length > 0 &&
                            data.data.map((agentRequest: any, i: number) => (
                                <Grid.Col md={6}>
                                    <Card
                                        sx={{
                                            height: "100%",
                                            border: "1px solid #BFC8C3",
                                        }}
                                        shadow="sm"
                                        key={`agent-request-${i}`}
                                    >
                                        <Stack sx={{ height: '100%' }}>
                                            <AgentRequestCard data={agentRequest} />
                                        
                                            <Group grow>
                                                <Button onClick={() => navigate(`/agent-requests/${agentRequest.id}/reply`)}>Reply</Button>
                                            </Group>
                                        </Stack>
                                    </Card>
                                </Grid.Col>
                            ))}
                    </Grid>
                    {!!data && data.data.length > 0 && (
                        <Group sx={{ width: '100%', justifyContent: 'center' }}>
                            <Pagination total={pagination.totalPages} onChange={pagination.setActivePage} value={pagination.activePage} />
                        </Group>
                    )}
                    {!!data && data.data.length === 0 && (
                        <Center>
                            <Stack align="center" spacing={8} py={32}>
                                <Home2 size={32} />
                                <Text>No agent requests currently</Text>
                                {/* <Button onClick={() => navigate("/agent-requests/create")}>Create Agent Request</Button> */}
                            </Stack>
                        </Center>
                    )}
                </Stack>
            </Container>
            {/* <Modal
                centered
                opened={openDelete}
                onClose={() => setDeleteId("")}
                title="Are you sure?"
            >
                <Stack p={8}>
                    <Group sx={{ width: "100%" }}>
                        <Button
                            onClick={() => setDeleteId("")}
                            sx={{ flexGrow: 1 }}
                            variant="outline"
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => handleDelete()}
                            sx={{ flexGrow: 1 }}
                            color="red"
                        >
                            Yes
                        </Button>
                    </Group>
                </Stack>
            </Modal> */}
            {/* <LoadingOverlay visible={isLoading} overlayBlur={2} /> */}
            <Stack spacing={24}>
                {/* {!!data && data.data.length > 0 && (
                    <Flex sx={{ width: '100%', justifyContent: 'flex-end' }} pt={24}>
                        <Button onClick={() => navigate("/agent-requests/create")}>Create Agent Request</Button>
                    </Flex>
                )} */}
                {/* <TextInput
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    sx={{ width: '100%' }}
                    rightSection={<Search color="#aaa" />}
                    placeholder="Search agent request title"
                /> */}
            </Stack>
            <Modal
                centered
                opened={backConfirm}
                onClose={() => setBackConfirm(false)}
                title="Are you sure you want to leave this page?"
            >
                <Group sx={{ width: "100%" }}>
                    <Button
                        onClick={() => setBackConfirm(false)}
                        sx={{ flexGrow: 1 }}
                        variant="outline"
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => navigate(-1)}
                        sx={{ flexGrow: 1 }}
                    >
                        Yes
                    </Button>
                </Group>
            </Modal>
        </Box>
    );
}
