import { PasswordInput } from '@mantine/core';
import React from 'react';

const BasePasswordInput: React.FC<any> = ({ ...props }) => {
    return (
        <PasswordInput
            sx={{
                // input: {
                //     height: 40
                // }
            }}
            size='md'
            radius={8}
            {...props}
        />
    )
}

export { BasePasswordInput }