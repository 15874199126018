import { useGetWallet } from "api/tokenAPI";

export const useWallet = (id: string | null) => {
    const {
        data: walletData,
        isLoading: walletIsLoading,
        error: walletError,
    } = useGetWallet(id);

    return {
        walletData,
        walletIsLoading,
        walletError,
    };
};
