import { ActionType } from "types/systemTypes";
import { UserType } from "types/userTypes";
import { Reducer } from "react";
import { USER_ACTIONS } from "./userActions";

declare const window: Window &
    typeof globalThis & {
        BE_API: any;
    };

export const userInitialState: UserType = {
    jwt: "",
    refreshToken: "",
    blocked: false,
    confirmed: false,
    createdAt: new Date(),
    email: "",
    id: 0,
    provider: "",
    updatedAt: new Date(),
    username: "",
    fullname: "",
    checkoutId: "",
    userType: "",
    status: "PENDING",
    wallet: 0,
    loaded: false,
};

export const userReducer = (
    state: UserType,
    action: ActionType<USER_ACTIONS, UserType | null>
) => {
    switch (action.type) {
        case USER_ACTIONS.SET:
            if (action.payload?.email) {
                setTimeout(() => {
                    if (window.BE_API) {
                        // The chat widget default name may be overwritten, we set custom attributes {email, name} for backup purpose
                        window.BE_API.setUserAttributes({
                            default_email: action.payload?.email,
                            default_name: action.payload?.username,
                            email: action.payload?.email,
                            name: action.payload?.username,
                        });
                    }
                }, 100);
            }
            return { ...(action.payload ?? state), loaded: true };
        case USER_ACTIONS.EDIT:
            console.log("edit", state)
            console.log("payload", action.payload)
            console.log("all", { ...state, ...action.payload, loaded: true })
            return { ...state, ...action.payload, loaded: true };
        case USER_ACTIONS.REGISTER:
            return { ...(action.payload ?? state), loaded: true };
        case USER_ACTIONS.LOGOUT:
            localStorage.removeItem("user");
            return { ...userInitialState, loaded: true };
        default:
            throw new Error("Unsupported action type");
    }
};
