import { Flex, Grid, Stack, Text } from "@mantine/core";
import { useProfileController } from "hooks/useProfile";
import { useMemo } from "react";
import { accountTypes } from "types/accountTypes";

interface DetailsProps {
    title: string;
    view?: string;
    detailsInfo: Array<object>;
}

export function DetailsSingleRow(props: DetailsProps) {
    return (
        <Flex w={"100%"} direction="column" align="flex-start" gap="24px">
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "30px",
                    color: "#212922",
                }}
            >
                {props.title}
            </Text>
            <Grid w={"100%"}>
                {props.detailsInfo.map((data: any, index: number) => {
                    return (
                        <Grid.Col md={3}>
                            <Stack>
                                <Text
                                    sx={{
                                        fontFamily: "Public Sans, sans-serif",
                                        fontStyle: "normal",
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        lineHeight: "24px",
                                        color: "#212922",
                                    }}
                                >
                                    {data.type}
                                </Text>
                                <Text
                                    sx={{
                                        fontFamily: "Public Sans, sans-serif",
                                        fontStyle: "normal",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        lineHeight: "24px",
                                        color: "#294936",
                                    }}
                                >
                                    {data.text}
                                </Text>
                            </Stack>
                        </Grid.Col>
                    );
                })}
            </Grid>
        </Flex>
    );
}

export function DetailsMultipleRow(props: DetailsProps) {
    const data = props.detailsInfo;

    return (
        <Flex
            direction="column"
            align="flex-start"
            gap="24px"
            sx={{ width: "100%" }}
        >
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "30px",
                    color: "#212922",
                }}
            >
                {props.title}
            </Text>
            <Grid sx={{ width: "100%" }}>
                {data.map((col: any) => (
                    <Grid.Col md={3} sm={3} xs={4}>
                        <Stack spacing={4}>
                            <Text
                                sx={{
                                    fontFamily: "Public Sans, sans-serif",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: "#212922",
                                }}
                            >
                                {col.type}
                            </Text>
                            <Text
                                sx={{
                                    fontFamily: "Public Sans, sans-serif",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    lineHeight: "24px",
                                    color: "#294936",
                                }}
                            >
                                {col.text}
                            </Text>
                        </Stack>
                    </Grid.Col>
                ))}
            </Grid>
        </Flex>
    );
}

export function DetailsMultipleColumn(props: DetailsProps) {
    // const { view } = useProfileController();

    function formatData() {
        const data = props.detailsInfo;
        const formattedArray: any = [];
        const tempArray: any = [];

        data.map((data: any, index: number) => {
            tempArray.push(data);
            if (props.view === accountTypes.AGENT) {
                if (
                    tempArray.length === 3 ||
                    index === props.detailsInfo.length - 1
                ) {
                    formattedArray.push(tempArray.splice(0, 3));
                }
            } else {
                if (
                    tempArray.length === 5 ||
                    index === props.detailsInfo.length - 1
                ) {
                    formattedArray.push(tempArray.splice(0, 5));
                }
            }
        });

        return formattedArray;
    }

    return (
        <>
            {formatData().map((column: any, index: number) => (
                <Grid.Col key={index} md={"auto"}>
                    <Grid>
                        {column.map((row: any, index: number) => (
                            <Grid.Col key={index}>
                                <Flex>
                                    {row.icon}
                                    <Text pl={8} pr={4}>{`${row.type}:`}</Text>
                                    <Text weight={600}>{`${
                                        (row.text === "" || row.text === null) ? "-" : row.text
                                    }`}</Text>
                                </Flex>
                            </Grid.Col>
                        ))}
                    </Grid>
                </Grid.Col>
            ))}
        </>
    );
}

export function DetailsMobile(props: DetailsProps) {
    return (
        <Flex direction="column" align="flex-start" gap="24px">
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "30px",
                    color: "#212922",
                }}
            >
                {props.title}
            </Text>
            <Flex maw={"100%"} direction="column" align="flex-start" gap="16px">
                {props.detailsInfo.map((data: any, index: number) => {
                    return (
                        <Flex
                            key={index}
                            w="100%"
                            direction="column"
                            align="flex-start"
                            gap="8px"
                        >
                            <Text
                                sx={{
                                    fontFamily: "Public Sans, sans-serif",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: "#212922",
                                }}
                            >
                                {data.type}
                            </Text>
                            <Text
                                sx={{
                                    fontFamily: "Public Sans, sans-serif",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    lineHeight: "24px",
                                    color: "#294936",
                                }}
                            >
                                {data.text}
                            </Text>
                        </Flex>
                    );
                })}
            </Flex>
        </Flex>
    );
}
