import { NumberInput, TextInput } from '@mantine/core';
import React from 'react';

const BaseFormInput: React.FC<any> = ({ sx, ...props }) => {

    if (props.type === "number") {
        return (
            <>
                <NumberInput
                    sx={theme => ({
                        input: {
                            height: 40,
                        },
                        ...sx,
                    })}
                    size='md'
                    radius={8}
                    hideControls={true}
                    {...props}
                    onWheel={(evt) => evt.currentTarget.blur()}
                />
            </>
        )
    }

    return (
        <>
            <TextInput
                sx={theme => ({
                    input: {
                        height: 40,
                    },
                    ...sx,
                })}
                size='md'
                radius={8}
                {...props}
            />
        </>
    )
}

export { BaseFormInput }