// @ts-nocheck

// import '@mantine/tiptap/styles.css';

import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from "react-query";
import { socketConnect } from "socket.io-react";
import { AppShell, MantineProvider, Text } from "@mantine/core";
import {
    BrowserRouter,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import Landing from "./pages/Landing";
import Layout from "./components/Layout/Layout";
import { Login } from "./pages/Login";
import RegisterAgent from "pages/RegisterAgent";
import RegisterLister from "pages/RegisterLister";
import PropRequestForm from "./pages/PropRequestForm";
import ViewPropRequest from "./pages/ViewPropRequest";
import { Profile } from "pages/Profile";
import { Notifications } from "@mantine/notifications";
import { defaultQueryFn } from "api/defaultQueryFn";
import { EditProfile } from "pages/EditProfile";
import MyPropRequest from "pages/MyPropRequest";
import ViewOffer from "pages/ViewOffer";
import { AccountSelect } from "pages/AccountSelect";
import CreateOffer from "pages/CreateOffer";
import SearchRequests from "pages/SearchRequests";
import PurchaseToken from "pages/PurchaseToken";
import PurchaseSuccess from "pages/PurchaseSuccess";
import PurchaseFail from "pages/PurchaseFail";
import { NotificationPage } from "pages/NotificationPage";
import { useEffect } from "react";
import { TermsAndConditions } from "pages/TermsAndConditions";
import OurStory from "pages/OurStory";
import { ChatList } from "pages/ChatList";
import BlankLayout from "components/Layout/BlankLayout";
import { ThemeProvider } from "ThemeProvider";
import BlogList from "pages/BlogList";
import BlogPost from "pages/BlogPost";
import { ForgotPassword } from "pages/ForgotPassword";
import Landing2 from "pages/Landing2";
import ReferralPage from "pages/ReferralPage";
import Browse from "pages/Browse";
import ViewListing from "pages/ViewListing";
import NotFound from "pages/NotFound";
import Feedback from "pages/Feedback";
import ServerError from "pages/ServerError";
import AgentLanding from "pages/AgentLanding";
import AgentPromo from "pages/AgentPromo";
import AgentDashboard from "pages/AgentDashboard";
import ListingEdit from "pages/ListingEdit";
import ListingForm from "pages/OfferForm";
import KonvaTesting from "pages/KonvaTesting";
import PromoTnc from "pages/PromoTnc"
import TempPromoRegister from "pages/Register/TempPromo";
import AgentRequestForm from "pages/AgentRequestForm";
import MasterList from "components/MasterList";
import AgentReplyForm from "pages/AgentReplyForm";
import ViewAgentRequest from "pages/ViewAgentRequest";
import { RefundPolicy } from "pages/RefundPolicy";
import { PrivacyPolicy } from "pages/PrivacyPolicy";
import AgentDashboardv2 from "pages/AgentDashboard/components/DashboardV2";
import SubscribeSuccess from "pages/SubscriptionSuccess";
import SubscribeFail from "pages/SubscriptionFail";
import Project from "pages/Project";
import AgentPublicProfile from "pages/AgentPublicProfile";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: defaultQueryFn,
        },
    },
});

// // const analytics = getAnalytics(app);

// const messaging = getMessaging();
// getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUB })
//     .then((currentToken) => {
//         if (currentToken) {
//             // Send the token to your server and update the UI if necessary
//             console.log("currentToken", currentToken);
//             // ...
//         } else {
//             // Show permission request UI
//             console.log(
//                 "No registration token available. Request permission to generate one."
//             );
//             // ...
//         }
//     })
//     .catch((err) => {
//         console.log("An error occurred while retrieving token. ", err);
//         // ...
//     });
// function requestPermission() {
//     console.log("Requesting permission...");
//     Notification.requestPermission().then((permission) => {
//         if (permission === "granted") {
//             console.log(permission);
//             console.log("Notification permission granted.");
//         }
//     });
// }

function App(props) {
    return (
        <QueryClientProvider client={queryClient}>
            <MantineProvider withGlobalStyles withNormalizeCSS>
                <Notifications position="top-right" />
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/"
                            element={<Layout {...props} queryClient={queryClient} />}
                        >
                            {/* <Route path="/" element={<Landing />} /> */}
                            <Route path="/" element={<Landing2 {...props} />} />
                            {/* <Route path="/watermark" element={<KonvaTesting />} /> */}
                            <Route path="/agents" element={<AgentLanding {...props} />} />
                            <Route path="/agent-promo" element={<AgentPromo {...props} />} />
                            <Route
                                path="/404"
                                element={<NotFound />}
                            />
                            <Route
                                path="/502"
                                element={<ServerError />}
                            />
                            <Route
                                path="/feedback"
                                element={<Feedback />}
                            />
                            <Route
                                path="/login"
                                element={<Login {...props} />}
                            />
                            <Route
                                path="/forgot-password"
                                element={<ForgotPassword />}
                            />
                            <Route
                                path="/register"
                                element={<AccountSelect {...props} />}
                            />
                            <Route
                                path="/register/lister"
                                element={<RegisterLister {...props} />}
                            />
                            <Route
                                path="/register/agent"
                                element={<RegisterAgent {...props} />}
                            />
                            <Route
                                path="/refer/:code"
                                element={<RegisterAgent {...props} />}
                            />
                            <Route
                                path="/listings"
                                element={<Browse />}
                            />
                            <Route
                                path="/listings/:id"
                                element={<ViewListing />}
                            />
                            <Route
                                path="/listings/edit/:id"
                                element={<ListingForm />}
                            />
                            <Route
                                path="/listings/create"
                                element={<ListingForm />}
                            />
                            <Route
                                path="/requests"
                                element={<MyPropRequest />}
                            />
                            <Route
                                path="/requests/search"
                                element={<SearchRequests />}
                            />
                            <Route
                                path="/requests/:id"
                                element={<ViewPropRequest />}
                            />
                            <Route
                                path="/requests/:id/offer"
                                element={<CreateOffer />}
                            />
                            <Route
                                path="/agent-requests/:id/reply"
                                element={<AgentReplyForm />}
                            />
                            <Route
                                path="/agent-requests/create"
                                element={<AgentRequestForm />}
                            />
                            <Route
                                path="/agent-requests/view/:id"
                                element={<ViewAgentRequest />}
                            />
                            <Route
                                path="/agent-requests/edit/:id"
                                element={<AgentRequestForm />}
                            />
                            <Route path="/offer/:id" element={<ViewOffer />} />

                            <Route path="/profile" element={<Profile {...props}/>} />
                            <Route path="/profile/" element={<Profile {...props}/>} />
                            <Route path="/profile/dashboard" element={<AgentDashboardv2 {...props}/>} />
                            <Route
                                path="/profile/edit"
                                element={<EditProfile />}
                            />
                            <Route
                                path="/tokens/purchase"
                                element={<PurchaseToken />}
                            />
                            <Route
                                path="/tokens/purchase/success"
                                element={<PurchaseSuccess />}
                            />
                            <Route
                                path="/tokens/purchase/fail"
                                element={<PurchaseFail />}
                            />
                            <Route
                                path="/subscribe/success"
                                element={<SubscribeSuccess />}
                            />
                            <Route
                                path="/subscribe/fail"
                                element={<SubscribeFail />}
                            />
                            <Route
                                path="/notifications"
                                element={<NotificationPage />}
                            />
                            <Route
                                path="/termsandconditions"
                                element={<TermsAndConditions />}
                            />
                            <Route
                                path="/privacypolicy"
                                element={<PrivacyPolicy />}
                            />
                            <Route
                                path="/refundpolicy"
                                element={<RefundPolicy />}
                            />
                            <Route
                                path="/campaign/tnc"
                                element={<PromoTnc />}
                            />
                            <Route
                                path="/promo/:slug"
                                element={<TempPromoRegister {...props} />}
                            />
                            <Route path="/our-story" element={<OurStory />} />
                            <Route
                                path="/chats"
                                element={<ChatList {...props} />}
                            />
                            <Route
                                path="/requests/create"
                                element={<PropRequestForm />}
                            />
                            <Route
                                path="/requests/edit/:id"
                                element={<PropRequestForm />}
                            />
                            <Route path="/articles" element={<BlogList />} />
                            <Route
                                path="/articles/:slug"
                                element={<BlogPost />}
                            />
                            <Route
                                path="/masterlist"
                                element={<MasterList />}
                            />

                            <Route
                                path="/projects"
                                element={<Project />}
                            />

                            <Route
                                path="/agent/:slug"
                                element={<AgentPublicProfile />}
                            />

                            <Route
                                path="/*"
                                element={<NotFound />}
                            >

                            </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </MantineProvider>
        </QueryClientProvider>
    );
}

export default socketConnect(App);
