import React, { useMemo, useState } from "react";

const useApiPagination = (pageSizeOption?: number) => {
    const [pageSize, setPageSize] = useState(pageSizeOption ?? 10);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const paginationObj = useMemo(() => {
        return {
            page: activePage,
            pageSize,
        };
    }, [activePage, pageSize]);

    return {
        pagination: {
            activePage,
            setActivePage,
            totalPages,
            setTotalPages,
            paginationObj,
        },
    };
};

export default useApiPagination;
