import {
    Badge,
    Box,
    Button,
    Card,
    Center,
    Chip,
    Container,
    Divider,
    Group,
    Loader,
    LoadingOverlay,
    Pagination,
    ScrollArea,
    Stack,
    Sx,
    Tabs,
    Text,
    TextInput,
} from "@mantine/core";
import { useDebouncedState, useDebouncedValue } from "@mantine/hooks";
import { useListingAPI } from "api/useListingAPI";
import FeedbackBanner from "components/Profile/FeedbackBanner";
import OfferCard from "components/PropRequest/OfferCard";
import PropRequestCard from "components/PropRequest/PropRequestCard";
import {
    PROPERTY_REQUEST_COLOR_SETTINGS,
    PROPERTY_REQUEST_TYPE,
} from "helpers/constants";
import useApiPagination from "hooks/useApiPagination";
import { Permission, useAuth } from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bath, Bed, BorderRadius, Edit, Home2, Search } from "tabler-icons-react";
import { APIHookResponse } from "types/systemTypes";

export default function OfferList(props: any) {
    const { useGetMyOffers } = useListingAPI()
    const navigate = useNavigate();
    const { user } = useAuth(Permission.USER_ONLY);
    const [keyword, setKeyword] = useState("")
    const [debouncedKeyword] = useDebouncedValue(keyword, 200);


    const { pagination } = useApiPagination()

    const { data, isLoading, error, refetch } = useGetMyOffers({
        id: user.id.toString(),
        pagination: pagination.paginationObj,
        filters: {
            title: {
                $containsi: debouncedKeyword
            }
        }
    }) as APIHookResponse;

    useEffect(() => {
        refetch()
    }, [])

    return (
        <>
            {/* <LoadingOverlay visible={isLoading} overlayBlur={2} /> */}
            <Stack spacing={24}>
                <TextInput
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    sx={{ width: '100%' }}
                    rightSection={<Search color="#aaa" />}
                    placeholder="Search listing title"
                />
                {!!isLoading && (
                    <Center p={32}> <Loader /> </Center>
                )}
                {!!data &&
                    data.data.length > 0 &&
                    data.data.map((offer: any, i: number) => <OfferCard data={offer} key={`offer-${i}`} />)}
                {!!data && data.data.length === 0 && (
                    <Center>
                        <Stack align="center" spacing={8} py={32}>
                            <Home2 size={32} />
                            <Text>You have no offers currently</Text>
                            <Button
                                onClick={() => navigate("/profile?tab=leads")}
                            >
                                Browse Property Requests
                            </Button>
                        </Stack>
                    </Center>
                )}
                {!!data &&
                    data.data.length > 0 && (
                        <Group sx={{ width: '100%', justifyContent: 'center' }}>
                            <Pagination total={pagination.totalPages} onChange={pagination.setActivePage} />
                        </Group>
                    )}
            </Stack>
        </>
    );
}
