// @ts-nocheck
import { Affix, Box, Button, Card, Center, Checkbox, Container, Divider, Grid, Group, Image, Modal, ScrollArea, Stack, Text } from "@mantine/core";
import { useInsightsAPI } from "api/useInsightsAPI";
import { useListerFormController } from "pages/RegisterLister";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { redirect, useLocation, useNavigate, useParams } from "react-router-dom";
import RegisterForm from "./components/RegisterForm";
import RegisterFeedback from "components/RegisterFeedback";
import { Permission, useAuth } from "hooks/useAuth";
import { useForm } from "@mantine/form";
import { validateEmail } from "helpers/validations";
import { sanitizePhoneNumber } from "helpers/utility";
import { USER_TYPE, formStep, validateUserStatus } from "helpers/constants";
import { accountTypes } from "types/accountTypes";
import { useUserAPI } from "api/userAPI";
import { Carousel } from "@mantine/carousel";
import Autoplay from 'embla-carousel-autoplay';
import { usePromoAPI } from "api/usePromoAPI";
import { notifications } from "@mantine/notifications";
import Markdown from 'react-markdown'

import { promoTnc } from "./constants/PromoTnc";
import MaintainenceModal from "components/Modals/MaintainenceModal";


export const RegisterListerTempPromo: React.FC<any> = ({
    setView,
    setVerifyData,
    verifyData,
    ReactPixel,
}) => {
    const autoplay = useRef(Autoplay({ delay: 2000 }));
    const { slug } = useParams()
    const { user } = useAuth(Permission.ANYONE)
    const { useJoinPromo, useGetCampaign } = usePromoAPI()
    const joinPromoMutation = useJoinPromo()

    const [promoOpen, setPromoOpen] = useState(true)
    const [currentCampaign, setCurrentCampaign] = useState<any>({})
    const navigate = useNavigate();
    const { useGetUserInsights } = useInsightsAPI()
    const {
        data: insightsData,
        isLoading: insightsIsLoading,
        error: insightsError,
    } = useGetUserInsights();

    const {
        data: campaignData,
        isLoading: campaignIsLoading,
        error: campaignError
    } = useGetCampaign({
        slug
    })

    const { form, onSubmit, registerMutation, allowSubmit, submitIsLoading } =
        useListerFormController({
            setView,
            setVerifyData,
            verifyData,
            ReactPixel
        });
    const [checkboxes, setCheckboxes] = useState<any>({
        tnc: false,
        privacy: false,
    });
    const handleChange = (key: string) => {
        setCheckboxes((prevState: any) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };
    const allowProceed = useMemo(
        () => Object.values(checkboxes).every((value) => !!value === true),
        [checkboxes]
    );

    useEffect(() => {
        if (!!user.jwt) {
            if (user.userType === USER_TYPE.AGENT) {
                setPromoOpen(false)
                navigate("/profile");
                notifications.show({
                    title: "Oops!",
                    message: "You are not authorized to see this page",
                });
            }
        }
    }, [user])

    const handleLater = useCallback(async () => {
        if (!user.jwt) {
            setPromoOpen(false)
            navigate("/register/lister")
            return
        }

        return navigate("/profile")
    }, [user])

    const handleJoinButton = useCallback(async () => {
        if (!user.jwt) {
            setPromoOpen(false)
            sessionStorage.setItem("promo_id", campaignData.id)
            return
        }

        if (!!user.id && !!campaignData) {
            await joinPromoMutation.mutateAsync({
                userId: user.id,
                id: campaignData.id
            })

            navigate("/profile")

            notifications.show({
                title: "Success!",
                message: "You have successfully joined this promo",
            });
            return
        }
    }, [user, campaignData])

    return (
        <>
            {/* <MaintainenceModal /> */}
            <Modal
                fullScreen
                opened={promoOpen}
                onClose={() => setPromoOpen(false)}
                closeOnClickOutside={false}
                closeOnEscape={false}
                withCloseButton={false}
                styles={{
                    'body': {
                        backgroundColor: 'whitesmoke',
                        padding: 0,
                        minHeight: '100vh'
                    }
                }}
            >
                <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
                    <Stack spacing={0} sx={{ height: '100%' }}>
                        <Group p={16}>
                            <Image
                                src={"/logo.svg"}
                                alt={"propmoth logo"}
                                width={148}
                                height={48}
                            />
                        </Group>
                        <Stack spacing={24} sx={{ padding: '0px 16px' }}>
                            <Group position="apart">
                                <Text fz={32} fw={600}>
                                    Terms and Conditions
                                </Text>
                            </Group>
                            <Divider />
                            <Box sx={(theme) => ({
                                margin: 'auto',
                                width: '100vw',
                                [theme.fn.largerThan('sm')]: {
                                    width: '80vw'
                                }
                            })}>
                                <Carousel
                                    loop
                                    withIndicators
                                    slideSize="70%"
                                    slideGap='sm'
                                    styles={{
                                        'indicator': {
                                            backgroundColor: '#417256'
                                        }
                                    }}
                                >
                                    <Carousel.Slide>
                                        <Image
                                            src={'https://propmoth-images.s3.ap-southeast-1.amazonaws.com/Creative_3_bf31f65c61.png?updated_at=2023-09-02T06:41:48.623Z'}
                                            height={'73vh'}
                                            fit="contain"
                                        />
                                    </Carousel.Slide>
                                    <Carousel.Slide>
                                        <Image
                                            src={'https://propmoth-images.s3.ap-southeast-1.amazonaws.com/Creative_1_9e49670eeb.png?updated_at=2023-09-02T06:41:48.419Z'}
                                            height={'73vh'}
                                            fit="contain"
                                        />
                                    </Carousel.Slide>
                                    <Carousel.Slide>
                                        <Image
                                            src={'https://propmoth-images.s3.ap-southeast-1.amazonaws.com/Creative_2_89a1d3a370.png?updated_at=2023-09-02T06:41:48.341Z'}
                                            height={'73vh'}
                                            fit="contain"
                                        />
                                    </Carousel.Slide>

                                </Carousel>
                            </Box>

                            <Box
                                sx={{
                                    height: "100%",
                                    paddingLeft: 16,
                                    paddingRight: 16
                                }}
                            >
                                
                                <Markdown>{promoTnc}</Markdown>
                            </Box>
                        </Stack>
                        <Card
                            // sx={{ position: 'sticky', bottom: 0, left: 0, width: '100%' }}
                            shadow="md"
                        >
                            <Container>
                                <Stack spacing={8}>
                                    <Text align="center" size={'lg'}>
                                        By clicking join, you agree to our terms and conditions
                                    </Text>
                                    <Group>
                                        <Button
                                            variant="outline"
                                            sx={{ flex: 1 }}
                                            onClick={() => {
                                                handleLater()
                                            }}
                                        >
                                            Later
                                        </Button>
                                        <Button
                                            sx={{ flex: 1 }}
                                            onClick={() => {
                                                handleJoinButton()
                                            }}
                                        >
                                            Join
                                        </Button>
                                    </Group>
                                </Stack>
                            </Container>
                        </Card>
                    </Stack>
                </Box>
            </Modal >
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid m={0}>
                    <Grid.Col
                        p={0}
                        md={4}
                        sx={(theme) => ({
                            [theme.fn.smallerThan("md")]: {
                                display: "none",
                            },
                        })}
                    >
                        <Box
                            sx={{
                                backgroundImage: `url('/section-img.png')`,
                                width: "100%",
                                height: "100%",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                minHeight: "calc(100vh - 60px)",
                                position: 'relative'
                            }}
                        >
                            <Box sx={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,0.6)'
                            }}>
                                <Stack sx={{ height: '100%' }} align="center" justify="center" p={8}>
                                    <Text color="white" fz={'3vw'} align="center">
                                        Join <Text color="white" component="span" fw={700}>{100 + insightsData?.totalListers}</Text> others <br /> looking for property today!
                                    </Text>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid.Col>
                    <Grid.Col
                        md={8}
                        xs={12}
                        p={0}
                        sx={(theme) => ({
                            backgroundColor: theme.colors.bgYellow,
                            [theme.fn.largerThan("md")]: {
                                paddingTop: "96px",
                                paddingBottom: "96px",
                            },
                            [theme.fn.smallerThan("md")]: {
                                paddingTop: "24px",
                                paddingBottom: "32px",
                            },
                        })}
                    >

                        <Group sx={{ justifyContent: 'center' }}>
                            <Stack spacing={32} sx={{ maxWidth: "450px" }}>
                                <RegisterForm
                                    form={form}
                                    totalListers={insightsData?.totalListers || null}
                                    checkboxes={checkboxes}
                                    handleCheckboxChange={handleChange}
                                    onSubmit={onSubmit}
                                    submitIsLoading={submitIsLoading}
                                    isSubmitDisabled={submitIsLoading || !allowSubmit || !allowProceed}
                                />
                                <RegisterFeedback />
                            </Stack>
                        </Group>
                    </Grid.Col>
                </Grid>
            </form>
        </>
    );
};