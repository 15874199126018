// @ts-nocheck
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClient } from "@tanstack/react-query";
import reportWebVitals from "./reportWebVitals";
import { SocketProvider, socketConnect } from "socket.io-react";
import io from "socket.io-client";
import { UserProvider } from "context/user/userProvider";
import { defaultQueryFn } from "api/defaultQueryFn";
import "./App.css"; // Tell webpack that Button.js uses these styles
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import ReactPixel from 'react-facebook-pixel';
import { CHRISTMAS_PROMO_PIXEL, HASSEL_FREE_AGENT_LP_PIXEL, REGISTRATION_PIXEL } from "helpers/constants";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: `${process.env.REACT_APP_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_MESSAGING_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};

ReactPixel.init(REGISTRATION_PIXEL, advancedMatching, options);
ReactPixel.init(CHRISTMAS_PROMO_PIXEL, advancedMatching, options);
ReactPixel.init(HASSEL_FREE_AGENT_LP_PIXEL, advancedMatching, options);

root.render(
    <StrictMode>
        <App
            db={db}
            auth={auth}
            ReactPixel={ReactPixel}
        />
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
