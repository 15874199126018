import { Button, Group } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ChatHeaderButtons = ({ isAgent, activeChatData, setAcceptPopup }: any) => {
    const navigate = useNavigate()

    if (!!activeChatData.propertyRequest) {
        return (
            <Group>
                <Button
                    variant="outline"
                    onClick={() =>
                        navigate(`/requests/${activeChatData.propertyRequest.id}`)
                    }
                >
                    View Request
                </Button>
            </Group>
        )
    }

    if (!!activeChatData.listing) {
        return (
            <Group>
                <Button
                    variant="outline"
                    onClick={() =>
                        navigate(`/listings/${activeChatData?.listing?.id}`)
                    }
                >
                    View Listing
                </Button>
            </Group>
        )
    }

    return (
        <Group>
            {!isAgent ? (
                <>
                    {activeChatData?.offer && (
                        <>
                            <Button
                                variant="outline"
                                onClick={() =>
                                    navigate(`/offer/${activeChatData?.offer?.id}`)
                                }
                            >
                                View Offer
                            </Button>
                            {activeChatData?.offer?.listing.propertyRequest === "ACTIVE" && (
                                <Button onClick={() => setAcceptPopup(true)}>
                                    Accept Offer
                                </Button>
                            )}
                        </>
                    )}
                </>
            ) : (
                <Button
                    variant="outline"
                    onClick={() =>
                        navigate(`/requests/${activeChatData?.offer?.propertyRequest?.id}`)
                    }
                >
                    View Request
                </Button>
            )}
        </Group>
    );
};

export default ChatHeaderButtons;