import {
    Badge,
    Box,
    Button,
    Card,
    Center,
    Chip,
    Container,
    Divider,
    Flex,
    Grid,
    Group,
    LoadingOverlay,
    Modal,
    Pagination,
    ScrollArea,
    Stack,
    Sx,
    Tabs,
    Text,
    TextInput,
    Loader
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useListingAPI } from "api/useListingAPI";
import FeedbackBanner from "components/Profile/FeedbackBanner";
import OfferCard from "components/PropRequest/OfferCard";
import PropRequestCard from "components/PropRequest/PropRequestCard";
import {
    PROPERTY_REQUEST_COLOR_SETTINGS,
    PROPERTY_REQUEST_TYPE,
} from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bath, Bed, BorderRadius, Edit, Home2, Search } from "tabler-icons-react";
import ListingCard from "./PropRequest/ListingsCard";
import useApiPagination from "hooks/useApiPagination";
import { APIHookResponse, ApiResponse } from "types/systemTypes";
import { useDebouncedValue } from "@mantine/hooks";

export default function ListingList(props: any) {
    const { useGetMyListings, useHideListing, useDeleteListing } = useListingAPI()
    const hideListing = useHideListing()
    const deleteListing = useDeleteListing()

    const navigate = useNavigate();
    const { user } = useAuth(Permission.USER_ONLY);
    const [openDelete, setOpenDelete] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const { pagination } = useApiPagination()
    const [keyword, setKeyword] = useState("")
    const [debouncedKeyword] = useDebouncedValue(keyword, 200);

    const { data, isLoading, error, refetch } = useGetMyListings({
        id: user.id.toString(),
        pagination: pagination.paginationObj,
        filters: {
            title: {
                $containsi: debouncedKeyword
            }
        }
    }) as APIHookResponse

    useEffect(() => {
        if (!!data?.meta) {
            pagination.setTotalPages(data?.meta.pagination.pageCount)
        }
    }, [data])

    useEffect(() => {
        setOpenDelete(!!deleteId)
    }, [deleteId]);


    const handleDelete = async () => {
        if (!!deleteId) {
            await deleteListing.mutateAsync({
                id: deleteId
            })
            setDeleteId("")
            refetch()
            props.callback()
        }
    }

    return (
        <>
            <Modal
                centered
                opened={openDelete}
                onClose={() => setDeleteId("")}
                title="Are you sure?"
            >
                <Stack p={8}>
                    <Text align="center">All offers connected to this listing <br />  will be removed too</Text>
                    <Group sx={{ width: "100%" }}>
                        <Button
                            onClick={() => setDeleteId("")}
                            sx={{ flexGrow: 1 }}
                            variant="outline"
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => handleDelete()}
                            sx={{ flexGrow: 1 }}
                            color="red"
                        >
                            Yes
                        </Button>
                    </Group>
                </Stack>
            </Modal>
            {/* <LoadingOverlay visible={isLoading} overlayBlur={2} /> */}
            <Stack spacing={24}>
                <Flex sx={{ width: '100%', justifyContent: 'flex-end' }} pt={24}>
                    <Button onClick={() => navigate("/listings/create")}>Create Listing</Button>
                </Flex>
                <TextInput
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    sx={{ width: '100%' }}
                    rightSection={<Search color="#aaa" />}
                    placeholder="Search listing title"
                />
                {!!isLoading && (
                    <Center p={32}> <Loader /> </Center>
                )}
                {!!data &&
                    data.data.length > 0 &&
                    data.data.map((listing: any, i: number) => (
                        <Card
                            sx={{
                                height: "100%",
                                border: "1px solid #BFC8C3",
                            }}
                            shadow="sm"
                            key={`listing-${i}`}
                        >
                            <Stack>
                                {listing.status === "pending" && (
                                    <Box p={8} sx={{ background: "#eed202", borderRadius: 8 }} >
                                        <Text align="center" fw={'bold'} fz={18}>Visible to public</Text>
                                    </Box>
                                )}
                                <Grid gutter={8}>
                                    <Grid.Col md={10}>
                                        <ListingCard data={listing} />
                                    </Grid.Col>
                                    <Grid.Col md={2}>
                                        <Stack>
                                            <Button onClick={() => navigate(`/listings/edit/${listing.id}`)}>Edit</Button>
                                            <Button color="red" onClick={() => setDeleteId(listing.id)}>Delete</Button>
                                        </Stack>
                                    </Grid.Col>
                                </Grid>
                            </Stack>
                        </Card>

                    ))}
                {!!data &&
                    data.data.length > 0 && (
                        <Group sx={{ width: '100%', justifyContent: 'center' }}>
                            <Pagination total={pagination.totalPages} onChange={pagination.setActivePage} />
                        </Group>
                    )}
                {!!data && data.data.length === 0 && (
                    <Center>
                        <Stack align="center" spacing={8} py={32}>
                            <Home2 size={32} />
                            <Text>You have no listings currently</Text>
                            <Button onClick={() => navigate("/listings/create")}>Create Listing</Button>
                        </Stack>
                    </Center>
                )}
            </Stack>
        </>
    );
}
