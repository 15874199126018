import { Anchor, Avatar, Badge, Box, Button, Card, Checkbox, Container, Divider, Grid, Group, Image, Loader, LoadingOverlay, ScrollArea, Select, Stack, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useGetAgentReferral, useUserAPI } from 'api/userAPI';
import CustomHeader from 'components/Header/Header';
import FeedbackBanner from 'components/Profile/FeedbackBanner';
import { useProfileController } from 'hooks/useProfile';
import { ChatListComponent } from 'pages/AgentDashboard/components/ChatListComponent';
import { ChatList } from 'pages/ChatList';
import SearchRequestsModified from 'pages/SearchRequestsModified';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AgentDetail } from 'types/userTypes';
import DashboardComponent from './DashboardComponent';
import RequestTutorialModal from 'components/Modals/RequestTutorialModal';
import { AgentDetailModal } from 'components/Modals/AgentDetailModal';
import Subscription from 'pages/Subscriptions';
import { SubscriptionBadge } from 'components/Badges';
import { ChevronDown } from 'tabler-icons-react';
import DashboardControlCard from '../DashboardControlCard';
import DashboardReferral from './DashboardReferral';
import DashboardLeads from './DashboardLeads';
import DashboardCobroke from './DashboardCobroke';

const AgentDashboardv2 = (props: any) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const { profileData, profileIsLoading, view, user, refetchProfile, currentActiveSubscription } =
        useProfileController();
    const {
        data: referral,
        isLoading,
        error,
    } = useGetAgentReferral(profileData?.agent?.id ?? null);
    const profile = profileData as AgentDetail;
    const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
    const [activeTab, setActiveTab] = useState("dashboard")

    const [agentDetailOpen, setAgentDetailOpen] = useState(false)
    const [agentTutorialOpen, setAgentTutorialOpen] = useState(false)
    const [isTutorialFromHelp, setIsTutorialFromHelp] = useState(false)

    const [locationFilters, setLocationFilters] = useState<any>({
        state: [],
        area: [],
    });

    // const { data, isLoading, error, refetch } = useGetListingInsights();
    useEffect(() => {
        refetchProfile()
    }, [])

    useEffect(() => {
        if (!!currentActiveSubscription) {
            if (currentActiveSubscription.package === "UNSUBBED") {
                setActiveTab("subscription")
            }
        }
    }, [currentActiveSubscription])

    useEffect(() => {
        if (!!profileData) {
            const sessionSkip = sessionStorage.getItem("skipAgentDetail")

            let stateArr = [] as string[]
            let areaArr = [] as string[]

            if (!profileData.requestTutorialComplete) {
                // setIsTutorialFromHelp(false)
                // setAgentTutorialOpen(true)
            } else {
                if (!!profileData?.localityState) {
                    stateArr = [profileData.localityState]

                    if (!!profileData.localityArea) {
                        areaArr = [profileData.localityArea]
                    }
                    setLocationFilters({
                        state: stateArr,
                        area: areaArr
                    })
                } else {
                    setAgentDetailOpen(!profileData?.localityState && !sessionSkip)
                }
            }
        }

    }, [profileData])

    useEffect(() => {
        const tab = searchParams.get("tab")
        if (!!tab) {
            setActiveTab(tab)
        }
    }, [searchParams])

    const handleCloseTutorialOpen = useCallback(() => {
        if (!!profileData) {
            setAgentTutorialOpen(false)
            if (!isTutorialFromHelp) {
                refetchProfile()
            }
            if (!profileData?.localityState) {
                setAgentDetailOpen(true)
            }
        }
    }, [profileData])

    const handleAgentDetailClose = () => {
        sessionStorage.removeItem("skipAgentDetail")

        setAgentDetailOpen(false)
        refetchProfile()
    }

    const handleAgentDetailSkip = () => {
        sessionStorage.setItem("skipAgentDetail", "true")
        setAgentDetailOpen(false)
    }

    return (
        <>
            <RequestTutorialModal
                opened={agentTutorialOpen}
                onClose={() => handleCloseTutorialOpen()}
                profileData={profileData}
                isHelp={isTutorialFromHelp}
            />
            <AgentDetailModal
                opened={agentDetailOpen}
                onClose={() => handleAgentDetailClose()}
                onSkip={() => handleAgentDetailSkip()}
            />
            <Box sx={{ height: "calc(100vh)", position: "relative", }} className="hide-scrollbar">
                <CustomHeader />
                <Grid>
                    <Grid.Col md={3}>
                        <Box
                            sx={{
                                position: "relative",
                                height: "100%",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "sticky",
                                    top: 0,
                                    left: 0,
                                }}
                            >
                                <DashboardControlCard
                                    currentActiveSubscription={currentActiveSubscription}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                />
                            </Box>
                        </Box>
                    </Grid.Col>
                    <Grid.Col md={9}>
                        <Stack pt={16} sx={{ height: '100%', width: '100%' }}>
                            <Box sx={{ background: "#417256", padding: 16, mb: 16 }}>
                                <Text align='center' color="white">If you have any questions, you are welcome to contact</Text>
                                <Text align='center' color="white">our customer support via phone or whatsapp at <Anchor href="https://wa.link/eemueh" sx={{ color: "white", textDecoration: "underline", cursor: "pointer" }} target='_blank'>012-3384374</Anchor></Text>
                            </Box>
                            {activeTab === "dashboard" && (
                                <Container size="800px" sx={{ width: '100%' }}>
                                    <DashboardComponent />
                                </Container>
                            )}

                            {activeTab === "leads" && (
                                <DashboardLeads />
                            )}

                            {activeTab === "chats" && (
                                <Box pr={16}>
                                    <ChatListComponent {...props} />
                                </Box>
                            )}

                            {activeTab === "subscription" && (
                                <Container size="800px" sx={{ width: '100%' }}>
                                    <Subscription />
                                </Container>
                            )}
                            {activeTab === "referral" && (
                                <Container size="800px">
                                    <DashboardReferral />
                                </Container>
                            )}
                            {activeTab === "coagency" && (
                                <DashboardCobroke />
                            )}
                        </Stack>
                    </Grid.Col>
                </Grid>
            </Box>
        </>
    );
};

export default AgentDashboardv2;