import { Group, Button, Modal, Stack, Text, Title } from '@mantine/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmeDeleteRequestModal = (props: any) => {

    return (
        <Modal
            centered
            opened={props.opened}
            onClose={() => props.onClose()}
            withCloseButton={false}
            closeOnClickOutside={false}
        >
            <Stack>
                <Text align='center' color="red" fz="lg" fw={700}>Are you sure you want to delete this property request?</Text>
                <Text align='center'>This action can not be reversed</Text>
                <Group sx={{width: '100%'}} grow>
                    <Button onClick={() => props.onClose()}>
                        Cancel
                    </Button>
                    <Button onClick={() => props.onConfirm()} color="red">
                        OK
                    </Button>
                </Group>
            </Stack>
        </Modal>
    );
};

export default ConfirmeDeleteRequestModal;