import React, { useEffect, useMemo, useState } from "react";
import {
    Box,
    Modal,
    Text,
    Button,
    ActionIcon,
    Textarea,
    Grid,
    Group,
    Select,
} from "@mantine/core";
import { ActionButton } from "components/Buttons/ActionButton";
import {
    Check,
    CircleCheck,
    CirclePlus,
    CircleX,
    Plus,
    User,
} from "tabler-icons-react";
import { useProfileController } from "hooks/useProfile";
import { ListerDetail } from "types/userTypes";
import { EditInput } from "components/Inputs/EditInput";
import { useForm } from "@mantine/form";
import {
    UserDetailData,
    updateUserDetailPayload,
    updateUserPayload,
} from "api/updateUserAPI";
import { notifications } from "@mantine/notifications";
import { allCountries } from "country-region-data";
import { useGetConfigs } from "api/configAPI";
import { CONFIG_KEYS } from "helpers/constants";
import { DateInput } from "@mantine/dates";
import { UpdateUserDetail } from "components/Profile/UpdateUserDetail";

type FormValues = {
    nationality: string;
    race: string;
    maritalStatus: string;
    noOfChildren: number | string;
    occupation: string;
    dob: Date | undefined;
    remark: string;
};

type Props = {};

enum detailFormSteps {
    VIEW,
    UPDATE,
}


const DetailFieldLine: React.FC<any> = ({ field, value, isLast }) => {
    return (
        <Box
            sx={(theme) => ({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: !isLast
                    ? `1px solid ${theme.colors.grey[1]}`
                    : "none",
            })}
            py={16}
        >
            <Text>{field}</Text>
            {!!value ? (
                <CircleCheck size={24} strokeWidth={1.5} color="green" />
            ) : (
                <CircleX size={24} strokeWidth={1.5} color="red" />
            )}
        </Box>
    );
};

const ViewUserDetail: React.FC<any> = ({ onClose, onConfirm }) => {
    const { profileData } = useProfileController();
    const profile = profileData as ListerDetail;

    const checkCount = useMemo(() => {
        const data = [
            profile?.user?.fullname,
            profile?.user?.phoneNumber,
            profile?.user?.email,
            profile?.nationality,
            profile?.race,
            profile?.dob,
            profile?.maritalStatus,
            profile?.occupation,
        ];

        return data.filter((e) => !!e).length;
    }, [profileData]);

    return (
        <>
            <Box
                mt={16}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                }}
            >
                <Text size={18} weight={600}>
                    Additional info ({checkCount}/8)
                </Text>
                <Box>
                    <DetailFieldLine
                        field={"Full name"}
                        value={profile?.user?.fullname}
                    />
                    <DetailFieldLine
                        field={"Phone number"}
                        value={profile?.user?.phoneNumber}
                    />
                    <DetailFieldLine
                        field={"Email"}
                        value={profile?.user?.email}
                    />
                    <DetailFieldLine
                        field={"Nationality"}
                        value={profile?.nationality}
                    />
                    <DetailFieldLine field={"Race"} value={profile?.race} />
                    <DetailFieldLine field={"DoB"} value={profile?.dob} />
                    <DetailFieldLine
                        field={"Marital status"}
                        value={profile?.maritalStatus}
                    />
                    <DetailFieldLine
                        field={"Occupation"}
                        value={profile?.occupation}
                        isLast
                    />
                </Box>
                {/* <Box
                    sx={{
                        display: "flex",
                        gap: 8,
                    }}
                    mt={8}
                >
                    <ActionButton
                        variant="outline"
                        sx={{ flex: 1 }}
                        onClick={onClose}
                    >
                        Cancel
                    </ActionButton>
                    <ActionButton onClick={onConfirm} sx={{ flex: 1 }}>
                        Complete Info
                    </ActionButton>
                </Box> */}
            </Box>
        </>
    );
};

export const UserDetailModal: React.FC<any> = ({ opened, onCancel }) => {
    const [step, setStep] = useState(detailFormSteps.VIEW);

    const handleClose = () => {
        setTimeout(() => {
            setStep(detailFormSteps.VIEW);
        }, 500);
        onCancel();
    };

    return (
        <Modal
            opened={opened}
            size="100%"
            overlayProps={{
                opacity: 0.55,
                blur: 1,
            }}
            withCloseButton={false}
            closeOnClickOutside={false}
            onClose={handleClose}
            sx={{
                ".mantine-Modal-body": {
                    padding: 24,
                },
                ".mantine-Modal-header": {
                    padding: 0,
                },
            }}
            centered
        >
            <Modal.Header>
                <Group py={8} position="apart" sx={{ width: "100%" }}>
                    <Modal.Title>
                        {/* <ActionIcon
                        size={48}
                        radius={8}
                        sx={(theme) => ({
                            backgroundColor: theme.colors.grey[0],
                            pointerEvents: "none",
                        })}
                    >
                        <User width={24} color="#A9B6AF" />
                    </ActionIcon> */}
                        <Text size={18} weight={600}>
                            Just one more step!
                        </Text>
                    </Modal.Title>
                    <Modal.CloseButton />
                </Group>
            </Modal.Header>
            <Grid gutter={24}>
                <Grid.Col span={12}>
                    <Box>
                        <Text size={14} color="primaryGreen">
                            Please provide
                            additional details. Your information will be kept
                            confidential and never shared to third parties.
                        </Text>
                    </Box>
                </Grid.Col>
                <Grid.Col sm={3} xs={12}>
                    <ViewUserDetail
                        onClose={handleClose}
                        // onConfirm={() => setStep(detailFormSteps.UPDATE)}
                    />
                </Grid.Col>
                <Grid.Col sm={9} xs={12}>
                    <UpdateUserDetail onClose={handleClose} />
                </Grid.Col>
            </Grid>
            {/* {step === detailFormSteps.VIEW ? (
                <ViewUserDetail
                    onClose={handleClose}
                    onConfirm={() => setStep(detailFormSteps.UPDATE)}
                />
            ) : (
                <UpdateUserDetail onClose={handleClose} />
            )} */}
        </Modal>
    );
};
