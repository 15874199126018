import { Carousel } from '@mantine/carousel';
import { Box, Grid, Image, Stack, Stepper, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useState } from 'react';

const HowItWorks = () => {
    const theme = useMantineTheme();
    const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const [stepperIndex, setStepperIndex] = useState(0);

    const howItWorks = [
        {
            title: "Create a",
            highlight: "Request",
            img: "/hiw-1-3.png",
        },
        {
            title: "Browse your",
            highlight: "Offers",
            img: "/hiw-2-3.png",
        },
        {
            title: "Chat with",
            highlight: "Agents",
            img: "/hiw-3-3.png",
        },
    ];

    return (
        <>
            <Grid>
                <Grid.Col md={12}>
                    <Stack>
                        <Text
                            component="h1"
                            sx={{
                                fontSize: "1.5em",
                                lineHeight: 1,
                            }}
                        >
                            How it works
                        </Text>
                    </Stack>
                </Grid.Col>
                {isXs ? (
                    <Grid.Col xs={12}>
                        <Stack>
                            <Stepper size="xs" active={stepperIndex}>
                                {howItWorks.map((h, i) => (
                                    <Stepper.Step
                                        completedIcon={i + 1}
                                        label={`Step ${i + 1}`}
                                        description={
                                            <Text fw={700} align="center">
                                                {h.title}
                                                <Text
                                                    component="span"
                                                    sx={{
                                                        color: "#4E7A4D",
                                                    }}
                                                    ml={4}
                                                >
                                                    {h.highlight}
                                                </Text>
                                            </Text>
                                        }
                                    />
                                ))}
                            </Stepper>
                            <Carousel
                                slideSize="70%"
                                // height={200}
                                slideGap="md"
                                // withControls={false}
                                onSlideChange={(index) => {
                                    setStepperIndex(index + 1);
                                }}
                            >
                                {howItWorks.map((h, i) => (
                                    <Carousel.Slide>
                                        <Image src={h.img} fit="contain" />
                                    </Carousel.Slide>
                                ))}
                            </Carousel>
                        </Stack>
                    </Grid.Col>
                ) : (
                    <>
                        {howItWorks.map((h, i) => (
                            <Grid.Col xs={4}>
                                <Stack
                                    spacing={4}
                                // sx={(theme) => ({
                                //     [theme.fn.smallerThan("sm")]: {
                                //         maxWidth: "40%",
                                //     },
                                // })}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor:
                                                "rgba(44, 83, 20, 0.3)",
                                            borderRadius: 16,
                                        }}
                                        p={8}
                                    >
                                        <Text fw={700} align="center">
                                            {i + 1}. {h.title}
                                            <Text
                                                component="span"
                                                sx={{
                                                    color: "#4E7A4D",
                                                }}
                                                ml={4}
                                            >
                                                {h.highlight}
                                            </Text>
                                        </Text>
                                    </Box>
                                    <Image src={h.img} fit="contain" />
                                </Stack>
                            </Grid.Col>
                        ))}
                    </>
                )}
            </Grid>
        </>
    );
};

export default HowItWorks;