import { AxiosError } from "axios";
import API from "./API";
import { accountTypes } from "types/accountTypes";

type Payload = {
    fullname: string,
    username: string,
    email: string,
    password: string,
    phoneNumber: string,
    userType: string,
    preferredName?: string,
    renId?: string,
    renImg?: any,
    agencyName?: string,
    agentFormData: FormData,
    referredBy?: any
}

//functions
export const register = async (data: Payload) =>  {
    const registerPayload = {
        fullname: data.fullname,
        email: data.email,
        phoneNumber: data.phoneNumber,
        username: data.phoneNumber,
        password: data.password,
        userType: data.userType,
        referredBy: data.referredBy ?? null
    }
    // const response = await API({}).get<any, any>(`tags`);
    const registerUrl = `/auth/local/register`
    const registerRes = await API({}).post(registerUrl, registerPayload)
    if (registerRes?.data?.jwt) {
        if (registerPayload.userType === accountTypes.AGENT) {
            const agentPayload = {
                agent: registerRes.data.user.id,
                agencyName: data.agencyName,
                renId: data.renId,
                preferredName: data.preferredName ?? ""
            };
            const formData = new FormData()
            if (data?.renImg) {
                formData.append('files.renImg', data.renImg, data.renImg.name)
            }
            formData.append('data', JSON.stringify(agentPayload))
            const agentResponse = await API({ token: registerRes.data.jwt, contentType: 'multipart/form-data' })
                .post('agent-details', formData)
            return {
                ...registerRes.data,
                ...agentResponse.data
            }
        } else {
            const listerPayload = {
                user: registerRes.data.user.id,
            }
            const listerResponse = await API({ token: registerRes.data.jwt }).post('user-details', { data: listerPayload })
            return {
                ...registerRes.data
            }
        }
    }
    return registerRes.data
}

