import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
const qs = require("qs");

// keys
export const GET_WALLET = "GET_WALLET";

async function getWallet(id: string | null) {
    const query = qs.stringify({
        filters: {
            user: {
                id: {
                    $eq: id,
                },
            },
        },
    });
    const response = await API({ requireAuth: true }).get<any, any>(
        `wallets?${query}`
    );

    return flattenObj(response.data)[0];
}

export const useGetWallet = (id: string | null) =>
    useQuery([GET_WALLET, id], () => getWallet(id), {
        enabled: !!id,
    });

const purchaseTokenPayex = async (payload: {
    amount: number;
    tokenAmount: number;
}) => {
    const resp = await API({ requireAuth: true }).post<any, any>(`points`, {
        ...payload,
    });

    return flattenObj(resp);
};

const purchaseToken = async (payload: {
    user: number;
    amount: number;
    tokenAmount: number;
    packageKey?: string;
}) => {
    const resp = await API({ requireAuth: true }).post<any, any>(`points`, {
        ...payload,
    });

    return flattenObj(resp);
};

export const usePurchaseToken = () => useMutation(purchaseToken);

const subscribePlan = async (payload: { priceId: string; coupon?: string | null, oneOff?: boolean | null }) => {
    const resp = await API({ requireAuth: true }).post<any, any>(`subscribe`, {
        ...payload,
    });

    return flattenObj(resp);
};

export const useSubscribePlan = () => useMutation(subscribePlan);
