import React from 'react'
import { 
    Box,
    Text,
    UnstyledButton
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'

export const RegisterFooter: React.FC<any> = ({}) => {
    const navigate = useNavigate();
    return (
        <Box>
            <Text size={16} align='center'>
                Already have an account?&nbsp; 
                <UnstyledButton onClick={() => navigate('/login')}>
                    <Text 
                        component='span' 
                        underline
                        weight={500}>
                        Log In
                    </Text>
                </UnstyledButton>
            </Text>
        </Box>
    )
}