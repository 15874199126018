import { MultiSelect } from '@mantine/core';
import { useGetConfigs } from 'api/configAPI';
import { CONFIG_KEYS } from 'helpers/constants';
import React, { useCallback, useMemo } from 'react';

const LocationFilter = ({ dispatch, state }: any) => {

    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    const mappedValue = useCallback(() => {
        if (state.state.length === 0 && state.area.length === 0) {
            return []
        }

        const states = state.state.map((s: string) => `state-${s}`)

        const area = state.area.map((a: string) => `area-${a}`)


        return [...states, ...area]
    }, [state])

    const handleLocationFilters = (e: any[]) => {
        const stateFilters = e.filter((v) => {
            return v.split('-')[0] === "state"
        }).map((v) => v.split('-')[1])

        const areaFilters = e.filter((v) => {
            return v.split('-')[0] === "area"
        }).map((v) => v.split('-')[1])

        dispatch({
            type: "LOCATION_SELECT_CHANGE",
            payload: {
                state: stateFilters,
                area: areaFilters
            },
        });
    }

    const searchArray = useMemo(() => {
        if (!!configData && !configIsLoading) {
            const statesAndAreas = configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;

            if (!statesAndAreas) {
                return []
            }

            const searchList = [] as any[]

            statesAndAreas.forEach((element: any) => {
                searchList.push({
                    value: `state-${element.state}`,
                    label: element.state,
                })

                element.areas.forEach((area: string) => {
                    const find = searchList.find((v) => v.value === `area-${area}`)
                    if (!find) {
                        searchList.push({
                            value: `area-${area}`,
                            label: area,
                        })
                    }
                })
            });
            
            return searchList
        }

        return [];
    }, [configData]);

    return (
        <MultiSelect
            data={searchArray}
            searchable
            nothingFound="Nothing found"
            value={mappedValue()}
            onChange={(e) => {
                handleLocationFilters(e)
            }}
            placeholder="Search location..."
        />
    );
};

export default LocationFilter;