import React, { FC, useEffect, useReducer } from "react";

import { USER_ACTIONS } from "./userActions";
import { UserContext, UserDispatchContext } from "./userContext";
import { userInitialState, userReducer } from "./userReducer";
import usePostLoginFunctions from "hooks/usePostLoginFunctions";

export type UserProviderProps = {
    // All other props
    [x: string]: any;
};

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, userInitialState);
    // const { createPropRequest } = usePostLoginFunctions()

    // on load, get persisted user state
    useEffect(() => {
        const value = localStorage.getItem("user");
        if (value) {
            dispatch({ type: USER_ACTIONS.SET, payload: JSON.parse(value) });
        } else {
            dispatch({ type: USER_ACTIONS.SET, payload: userInitialState });
        }
    }, []);

    // persist user state (except log out)
    useEffect(() => {
        if (!!state.id) localStorage.setItem("user", JSON.stringify(state));

        if(!!state.jwt){
            const preLogin = sessionStorage.getItem("pre_login_draft");
            if (!!preLogin) {

                const { data: parsed, action } = JSON.parse(preLogin);
            }

            // createPropRequest()
        }
    }, [state]);


    return (
        <UserDispatchContext.Provider value={dispatch}>
            <UserContext.Provider value={state}>
                {children}
            </UserContext.Provider>
        </UserDispatchContext.Provider>
    );
};
