import {
    Accordion,
    Badge,
    Box,
    Button,
    Card,
    Center,
    Container,
    Flex,
    Grid,
    Group,
    Image,
    Overlay,
    Stack,
    Text,
    AspectRatio,
    useMantineTheme,
    MediaQuery,
    Avatar,
    ActionIcon,
    Anchor,
    Stepper,
    BackgroundImage,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { useMediaQuery, useScrollIntoView } from "@mantine/hooks";
import { HASSEL_FREE_AGENT_LP_PIXEL, USER_TYPE } from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    MapPin,
    Home2,
    FileText,
    Tag,
    Message,
    Messages,
    Link,
    Spy,
    Rocket,
    ChevronRight,
} from "tabler-icons-react";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { relative } from "path";
import CustomHeader from "components/Header/Header";
import { useListingAPI } from "api/useListingAPI";
import { sendAgentRegisterClickEvent } from "helpers/pixelEvents";

export default function AgentLanding({ ReactPixel }: any) {
    const navigate = useNavigate();
    const { useGetMyPropRequests } = useListingAPI();
    const location = useLocation();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const showMobileHeader = useMediaQuery(`(max-width: 780px)`);
    const { user } = useAuth(Permission.ANYONE);
    const { data, isLoading, error, refetch } = useGetMyPropRequests(
        user?.id.toString() || "0"
    );
    const { scrollIntoView, targetRef } = useScrollIntoView<
        HTMLDivElement,
        HTMLDivElement
    >();
    const { scrollIntoView: scrollWhyWeExist, targetRef: whyWeExist } =
        useScrollIntoView<HTMLDivElement, HTMLDivElement>();
    const [stepperIndex, setStepperIndex] = useState(0);

    const sidePadding = 24;

    const faq = [
        {
            title: "Do I need to pay to sign up?",
            content:
                "No, you do not need to pay to sign up. The only times that payment is required is when you make an offer or list property!",
        },
        {
            title: "How do I know the leads are legitimate?",
            content:
                "We do our best at Propmoth to verify the leads that we acquire. We will directly contact the requester and figure out their specific needs and help tailor the requests. We also have policies and features in place to make sure that agents never waste any money on a bad lead.",
        },
        {
            title: "I signed up, now what?",
            content:
                "First off, thank you for signing up! You can now create property listings as with any other property website. But we specialize in property requests! Find the perfect lead and offer them property ASAP!",
        },
    ];

    const logos = [
        {
            title: "Propnex",
            img: "/propnex.png",
        },
        {
            title: "IQI",
            img: "/iqi.png",
        },
        {
            title: "Kith & Kin",
            img: "/kithandkin.png",
        },
        // {
        //     title: "Century Properties",
        //     img: "/century.png",
        // },
    ];

    const featuredOn = [
        {
            title: "Digital News Asia",
            img: "/digital-news-asia.png",
            preview: "/dna-preview.png",
            text: "MYStartup selects 26 startups for their pre-accelerator cohort 3",
            credit: "By Digital News Asia March 16, 2024",
            link: "https://www.digitalnewsasia.com/startups/mystartup-selects-26-startups-their-pre-accelerator-cohort-3"

        },
        {
            title: "Tech Node Global",
            img: "/tnglobal.png",
            preview: "/tnglobal-preview.png",
            text: "MALAYSIA'S MYSTARTUP SELECTS 26 STARTUPS FOR PRE-ACCELERATOR COHORT 3 PROGRAM",
            credit: "By Technode Global Staff March 15, 2024",
            link: "https://technode.global/2024/03/15/malaysias-mystartup-selects-26-startups-for-pre-accelerator-cohort-3-program/"
        },
        {
            title: "Vulcan Post",
            img: "/vulcan.png",
            preview: "/vulcan-preview.png",
            text: "26 out of 136 startups were chosen for MYStartup's 3rd pre-accelerator, here's what they do",
            credit: "By Claudia Khaw, Vulcan Post March 19, 2024",
            link: "https://vulcanpost.com/854831/mystartup-pre-accelerator-cohort-3-startups-malaysia/"
        },
    ]

    const supportedBy = [
        {
            title: "Cradle Malaysia",
            img: "/cradle.png",

        },
        {
            title: "MY Startup",
            img: "/MyStartUp.png",
        },
        {
            title: "MOSTI",
            img: "/MOSTI.png",
        },
        {
            title: "AWS Startups",
            img: "/aws-startup.jpeg",
        },
    ]
    const howItWorks = [
        {
            title: "Create an",
            highlight: "Account",
            img: "/agent-hiw-1.png",
        },
        {
            title: "Browse",
            highlight: "Requests",
            img: "/agent-hiw-2.png",
        },
        {
            title: "Make your",
            highlight: "Offer",
            img: "/agent-hiw-3.png",
        },
    ];

    const ctaNavigateLink = useMemo(() => {
        if (!!user.jwt) {
            if (user.userType === USER_TYPE.LISTER) {
                if (!!data && !isLoading) {
                    if (data.length > 0) {
                        return "/profile"
                    }
                }
                return "/register/agent";
            }

            if (user.userType === USER_TYPE.AGENT) {
                return "/profile?tab=leads";
            }
        }

        return "/register";
    }, [user, data, isLoading]);

    useEffect(() => {
        if (location.hash === "#why-we-exist") {
            scrollWhyWeExist({});
        }
    }, [location]);

    const particlesInit = useCallback(async (engine: any) => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: any) => {
        // await console.log(container);
    }, []);

    const SkewDivider = ({
        primaryColor,
        skewColor,
        skewZIndex,
        lineColor,
    }: {
        primaryColor: string;
        skewColor: string;
        skewZIndex?: number;
        lineColor?: string;
    }) => {
        return (
            <Box
                sx={{
                    background: primaryColor,
                    minHeight: 50,
                    width: "100%",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        background: skewColor,
                        width: "100%",
                        height: 100,
                        // marginBottom: "-50px",
                        position: "absolute",
                        left: 0,
                        top: "90%",
                        transform: `skew(0deg, -2deg) translateY(-50%)`,
                        zIndex: skewZIndex ?? 0,
                    }}
                >
                    <Container
                        size="1200px"
                        px={sidePadding}
                        sx={{ height: "100%" }}
                    >
                        <Box
                            sx={{
                                borderLeft:
                                    lineColor ?? ".5px solid rgba(0,0,0,0.3)",
                                borderRight:
                                    lineColor ?? ".5px solid rgba(0,0,0,0.3)",
                                height: "100%",
                            }}
                            px={sidePadding}
                        />
                    </Container>
                </Box>
                <Container
                    size="1200px"
                    px={sidePadding}
                    sx={{ height: "50px" }}
                >
                    <Box
                        sx={{
                            borderLeft: ".5px solid rgba(0,0,0,0.3)",
                            borderRight: ".5px solid rgba(0,0,0,0.3)",
                            height: "100%",
                        }}
                        px={sidePadding}
                    />
                </Container>
            </Box>
        );
    };

    useEffect(() => {
        ReactPixel.pageView(null, [HASSEL_FREE_AGENT_LP_PIXEL])
    }, [])

    return (
        <Box>
            <Box
                sx={{
                    position: "relative",
                    // overflow: "hidden",
                    // height: "100vh",
                }}
            >
                <Box
                    sx={{
                        zIndex: 100,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                    }}
                >
                    <CustomHeader isLanding agentLanding />
                </Box>
                <BackgroundImage
                    src="/cropped-hero.png"
                    sx={{
                        minHeight: '100vh',
                        width: '100vw',
                        backgroundPosition: 'left-center',
                        paddingBottom: 50,
                        position: 'relative'
                    }}
                >
                    <Container
                        size="1200px"
                        px={sidePadding}
                        sx={{
                            height: "100%",
                            width: "100%",
                            minHeight: "100vh",
                            [theme.fn.smallerThan("xs")]: {
                                minHeight: "80vh",
                            },
                        }}
                    >
                        <Grid>
                            <Grid.Col sm={6} xs={7}>
                                <Stack
                                    sx={{
                                        // maxWidth: 500,
                                        minHeight: "100vh",
                                        [theme.fn.smallerThan("sm")]: {
                                            maxWidth: "none",
                                        },
                                        [theme.fn.smallerThan("xs")]: {
                                            minHeight: "auto",
                                            paddingTop: "30%",
                                        },
                                        justifyContent: "center",
                                    }}
                                    spacing={16}
                                >
                                    <Stack
                                        sx={(theme) => ({
                                            height: "100%",
                                            // maxWidth: "630px",
                                            // background: "rgba(255,255,255, 0.3)",
                                            padding: `16px`,
                                            zIndex: 100,
                                            borderRadius: 8,
                                            [theme.fn.largerThan("xs")]: {
                                                paddingLeft: 0,
                                                marginTop: "20%",
                                            },
                                        })}
                                    >
                                        <Text
                                            sx={(theme) => ({
                                                fontSize: "5vw",
                                                [theme.fn.largerThan("xl")]: {
                                                    fontSize: "5vw",
                                                },
                                                margin: 0,
                                                zIndex: 100,
                                                lineHeight: 1,
                                                [theme.fn.smallerThan("md")]: {
                                                    fontSize: "7vw",
                                                },
                                                [theme.fn.smallerThan("sm")]: {
                                                    fontSize: "48px",
                                                },
                                                [theme.fn.smallerThan("xs")]: {
                                                    fontSize: "13vw",
                                                },
                                                // [theme.fn.smallerThan("xs")]: {
                                                //     fontSize: "32px",
                                                // },
                                            })}
                                            fw="700"
                                            component="h1"
                                            color="white"
                                        >
                                            Let us find the
                                            <Text
                                                component="span"
                                                sx={{
                                                    color: "#B4E562",
                                                    margin: 0,
                                                    lineHeight: 1,
                                                    paddingLeft: 6
                                                }}
                                            >
                                                clients
                                            </Text> for you
                                        </Text>
                                        <Text
                                            sx={(theme) => ({
                                                zIndex: 100,
                                                fontSize: "20px",
                                                // [theme.fn.smallerThan("lg")]: {
                                                //     fontSize: "24px",
                                                // },
                                                [theme.fn.smallerThan("md")]: {
                                                    fontSize: "16px",
                                                },
                                            })}
                                            color="white"
                                            component="h3"
                                            fw={500}
                                        >
                                            Ever wondered how your colleagues got their leads?
                                            Take control of your spending and find people that are actively looking for property.
                                            <br />
                                            <br />
                                            Each request is tied to a phone number so that we can verify each property request!
                                        </Text>
                                        <Group spacing={4}>
                                            <Button
                                                radius="xl"
                                                onClick={() => {
                                                    sendAgentRegisterClickEvent()
                                                    navigate(ctaNavigateLink)
                                                }}
                                                sx={(theme) => ({
                                                    [theme.fn.smallerThan("xs")]: {
                                                        flex: 1,
                                                    },
                                                })}
                                                rightIcon={
                                                    <ChevronRight width={14} />
                                                }
                                            >
                                                <Text fz="md">Start Now </Text>
                                            </Button>
                                            <Button
                                                radius="xl"
                                                variant={
                                                    isXs ? "outlined" : "subtle"
                                                }
                                                color={'primary'}
                                                // color={isXs ? "white" : "primary"}
                                                onClick={() => scrollIntoView({})}
                                                sx={(theme) => ({
                                                    [theme.fn.smallerThan("xs")]: {
                                                        flex: 1,
                                                    },
                                                    ":hover": {
                                                        background: 'transparent'
                                                    }
                                                })}
                                            >
                                                <Text fz="md" color={'white'}>How it works</Text>
                                            </Button>
                                        </Group>
                                    </Stack>
                                </Stack>
                            </Grid.Col>
                            {/* {!isXs && (
                                <Grid.Col sm={6} xs={5}>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <Image
                                            src="./agent-landing-asset.png"
                                            sx={(theme) => ({
                                                position: "absolute",
                                                top: "calc(5% + 64px)",
                                                left: "0",
                                                width: "60vw !important",
                                                [theme.fn.smallerThan("lg")]: {
                                                    // width: "80vw !important",
                                                    top: "calc(50% + 32px)",
                                                    transform: "translateY(-50%)",
                                                    width: "100vw !important",
                                                    left: "15%",
                                                },
                                                [theme.fn.smallerThan("md")]: {
                                                    width: "60vw !important",
                                                    left: "0",
                                                },
                                            })}
                                            fit="contain"
                                        />
                                    </Box>
                                </Grid.Col>
                            )} */}
                        </Grid>
                    </Container>
                </BackgroundImage>
                {/* <Box
                    sx={{
                        position: "absolute",
                        width: "180vw",
                        height: "125vw",
                        borderBottomLeftRadius: "50%",
                        borderBottomRightRadius: "50%",
                        // borderRadius: "50%",
                        // backgroundImage: "url('./hero-bg2.png')",
                        // backgroundSize: "cover",
                        // backgroundRepeat: "no-repeat",
                        // transform: 'scaleX(-1)',
                        // rotate: '270deg',
                        // backgroundColor: "#4E7A4D",
                        // background: `linear-gradient(217deg, rgba(78,122,77,1) 0%, rgba(78,122,77,0.5) 100%)`,
                        bottom: 0,
                        left: 0,
                        // bottom: 0,
                        // zIndex: -1
                        overflow: "hidden",
                        [theme.fn.smallerThan("md")]: {
                            bottom: "18%",
                        },
                        [theme.fn.smallerThan("xs")]: {
                            // flex: 1,
                            // width: "100vw",
                            // height: "100vh",
                            // borderRadius: 0,
                            top: "-30%",
                            bottom: "auto",
                        },
                    }}
                >
                    <Particles
                        id="tsparticles"
                        url="./particlesjs-config.json"
                        init={particlesInit}
                        loaded={particlesLoaded}
                    />
                </Box> */}
                {/* {!!isXs && (
                    <Box sx={{ marginTop: "-16px" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1440 320"
                        >
                            <path
                                fill="#4e7a4d"
                                fill-opacity="0.7"
                                d="M0,160L80,144C160,128,320,96,480,117.3C640,139,800,213,960,213.3C1120,213,1280,139,1360,101.3L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
                            ></path>
                        </svg>
                    </Box>
                )} */}
            </Box>
            <Container size="1200px" px={sidePadding}>
                <Stack
                    px={sidePadding}
                    pb={24}
                    pt={24}
                >
                    <Text
                        component="h1"
                        sx={(theme) => ({
                            fontSize: "1.5em",
                            lineHeight: 1,
                            [theme.fn.smallerThan("xs")]: {
                                textAlign: "center",
                            },
                        })}
                        align="center"
                    >
                        Supported By
                    </Text>
                    {/* <Group position="apart" align="center">
                        </Group> */}
                    <Grid gutter={24}>
                        {supportedBy.map((logo) => (
                            <Grid.Col xs={12} sm={6} md={3}>
                                <Center sx={{ height: "100%" }}>
                                    <Image
                                        src={logo.img}
                                        alt={logo.title}
                                        fit="contain"
                                        width={150}
                                    />
                                </Center>
                            </Grid.Col>
                        ))}
                    </Grid>
                </Stack>
                <Stack
                    sx={{
                        // borderLeft: ".5px solid rgba(0,0,0,0.3)",
                        // borderRight: ".5px solid rgba(0,0,0,0.3)",
                    }}
                    px={sidePadding}
                    pb={24}
                    pt={24}
                >
                    <Text
                        component="h1"
                        sx={(theme) => ({
                            fontSize: "1.5em",
                            lineHeight: 1,
                            textAlign: "center",
                        })}
                    >
                        Join other agents from
                    </Text>
                    {/* <Group position="apart" align="center">
                        </Group> */}
                    <Grid gutter={24}>
                        {logos.map((logo) => (
                            <Grid.Col xs={12} sm={6} md={4}>
                                <Center sx={{ height: "100%" }}>
                                    <Image
                                        src={logo.img}
                                        alt={logo.title}
                                        fit="contain"
                                        width={150}
                                    />
                                </Center>
                            </Grid.Col>
                        ))}
                        <Grid.Col span={12}>
                            <Text fw="bold" align="center">and more!</Text>
                        </Grid.Col>
                    </Grid>
                </Stack>

                <Stack
                    // sx={{
                    //     borderLeft: ".5px solid rgba(0,0,0,0.3)",
                    //     borderRight: ".5px solid rgba(0,0,0,0.3)",
                    // }}
                    px={sidePadding}
                    pb={24}
                >
                    <Text
                        component="h1"
                        sx={(theme) => ({
                            fontSize: "1.5em",
                            lineHeight: 1,
                            [theme.fn.smallerThan("xs")]: {
                                textAlign: "center",
                            },
                        })}
                        align="center"
                    >
                        Featured On
                    </Text>
                    {/* <Group position="apart" align="center">
                        </Group> */}
                    <Grid gutter={24}>
                        {featuredOn.map((logo) => (
                            <Grid.Col xs={12} sm={6}>
                                <Center
                                    sx={{
                                        height: "100%",
                                        background: 'rgba(78,122,77,0.6)',
                                        borderRadius: 16,
                                        cursor: 'pointer'
                                    }}
                                    p={16}
                                    onClick={() => window.open(logo.link, "_newtab")}
                                >
                                    <Stack spacing={8} align="center">
                                        <Image
                                            src={logo.img}
                                            alt={logo.title}
                                            fit="contain"
                                            height={38}
                                        />
                                        <Text color="#fff" align="center" component="h1">{logo.title}</Text>
                                        <Card sx={{ borderRadius: 8 }}>
                                            <Grid>
                                                <Grid.Col span={5}>
                                                    <Card withBorder shadow="md" p={0} >
                                                        <BackgroundImage
                                                            src={logo.preview}
                                                            sx={{ height: 312, width: '100%', borderRadius: 8, backgroundPosition: 'top' }}
                                                        >
                                                            <img src={logo.preview} alt={`${logo.title}-preview`} className='sr-only' />
                                                        </BackgroundImage>
                                                    </Card>
                                                </Grid.Col>
                                                <Grid.Col span={7}>
                                                    <Box component="article" sx={{ flex: 1 }}>
                                                        <Stack spacing={2}>
                                                            <Text component="h1">{logo.text}</Text>
                                                            <Text size="xs" align="right">{logo.credit}</Text>
                                                        </Stack>
                                                    </Box>
                                                </Grid.Col>
                                            </Grid>
                                        </Card>
                                    </Stack>
                                </Center>
                            </Grid.Col>
                        ))}
                    </Grid>
                </Stack>
            </Container>
            <Box sx={{ backgroundColor: "#F1FFF8" }} ref={whyWeExist}>
                <Container size="1200px" px={sidePadding}>
                    <Stack
                        spacing={48}
                        sx={{
                            // borderLeft: ".5px solid rgba(0,0,0,0.3)",
                            // borderRight: ".5px solid rgba(0,0,0,0.3)",
                        }}
                        py={50}
                        px={sidePadding}
                    >
                        <Group spacing={8} position='center' align='center'>
                            <Text fw={900} fz={32}>How does</Text>
                            <Image
                                src="/propmoth-logo-text.png"
                                width={159.5}
                            />
                            <Text fw={900} fz={32}>help me?</Text>
                        </Group>
                        <Grid gutter={'lg'}>
                            <Grid.Col span={12}>
                                <Group position='center'>
                                    <Stack
                                        spacing={4}
                                        sx={(theme) => ({
                                            maxWidth: '80%',
                                            [theme.fn.smallerThan("sm")]: {
                                                maxWidth: '100%',
                                            }
                                        })}
                                        align='center'
                                    >
                                        <Image
                                            src="/promo-top-icon-1.png"
                                            width={64}
                                            height={64}
                                        />

                                        <Text fw={700} component='h2' fz={24} sx={{ margin: 0 }}>Real Clients Only</Text>
                                        <Text align='center' component='h3' fw={500} fz={16} sx={{ margin: 0 }}>
                                            We’ve created a system where you only pay for
                                            responsive leads. If the clients aren’t active, all
                                            agents that have spent a token to offer will
                                            be refunded!
                                        </Text>
                                    </Stack>
                                </Group>
                            </Grid.Col>
                            <Grid.Col md={6} sm={12}>
                                <Group position='center'>
                                    <Stack
                                        spacing={4}
                                        sx={(theme) => ({
                                            maxWidth: '80%',
                                            [theme.fn.smallerThan("sm")]: {
                                                maxWidth: '100%',
                                            }
                                        })}
                                        align='center'
                                    >
                                        <Image
                                            src="/promo-top-icon-2.png"
                                            width={64}
                                            height={64}
                                        />

                                        <Text fw={700} component='h2' fz={24} sx={{ margin: 0 }}>Cost Efficient</Text>
                                        <Text align='center' component='h3' fw={500} fz={16} sx={{ margin: 0 }}>
                                            Propmoth is the only platform that allows gives you value for a flat fee. You don't have to pay more to get more leads!
                                        </Text>
                                    </Stack>
                                </Group>
                            </Grid.Col>
                            <Grid.Col md={6} sm={12}>
                                <Group position='center'>
                                    <Stack spacing={4}
                                        sx={(theme) => ({
                                            maxWidth: '80%',
                                            [theme.fn.smallerThan("sm")]: {
                                                maxWidth: '100%',
                                            }
                                        })}
                                        align='center'
                                    >
                                        <Image
                                            src="/promo-top-icon-3.png"
                                            width={64}
                                            height={64}
                                        />

                                        <Text fw={700} component='h2' fz={24} sx={{ margin: 0 }}>High Quality Leads</Text>
                                        <Text align='center' component='h3' fw={500} fz={16} sx={{ margin: 0 }}>
                                            Our users have to fill out multiple forms and
                                            provide their personal info for them to
                                            list a request. This ensures that only serious
                                            clients will make it to the end for you to offer.
                                        </Text>
                                    </Stack>
                                </Group>
                            </Grid.Col>
                        </Grid>
                        <Text fw={700} fz={32} align='center'>Our Lead Verification Process Includes:</Text>
                        <Grid align='center'>
                            <Grid.Col xs={12} sm={4}>
                                <Image
                                    src="/lead-verification-1.png"
                                    width="100%"
                                />
                            </Grid.Col>
                            <Grid.Col xs={12} sm={4}>
                                <Image
                                    src="/lead-verification-2.png"
                                    width="100%"
                                />
                            </Grid.Col>
                            <Grid.Col xs={12} sm={4}>
                                <Image
                                    src="/lead-verification-3.png"
                                    width="100%"
                                />
                            </Grid.Col>
                        </Grid>
                    </Stack>
                </Container>
                <Container size="1200px" px={sidePadding}>
                    <Stack
                        sx={{
                            // borderLeft: ".5px solid rgba(0,0,0,0.3)",
                            // borderRight: ".5px solid rgba(0,0,0,0.3)",
                        }}
                        // px={sidePadding}
                        py={50}
                    >
                        <Grid>
                            <Grid.Col span={12}>
                                <Grid>
                                    <Grid.Col md={6}>
                                        <Text component="h2" sx={{ color: "#2C5314" }}>
                                            How Propmoth helps you Save and Succeed
                                        </Text>
                                        <br />
                                        <Text>
                                            We understand that agents spend more and more
                                            money and time on property listing and running ads on platforms such as Facebook and Instagram with slow results.
                                        </Text>
                                        <br />
                                        <Text>
                                            People often use property platforms to window
                                            shop or click on your Facebook ads and waste
                                            your ad budget without following through.
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <Text component="h2" sx={{ color: "#2C5314" }}>
                                            Take advantage by being earlier than your competition
                                        </Text>
                                        <br />
                                        <Text>
                                            Get our leads early and offer the perfect property! Share requests with other agents
                                        </Text>
                                        <br />
                                        <Text>
                                            We do our best to make sure that the leads are 100% legitimate and verify them by directly contacting them for you.
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Group>
                                            <Button
                                                radius="xl"
                                                onClick={() => {
                                                    sendAgentRegisterClickEvent()
                                                    navigate(ctaNavigateLink)
                                                }}
                                            >
                                                <Group
                                                    spacing={4}
                                                    align="center"
                                                >
                                                    <Text fz="md">
                                                        Start Now{" "}
                                                    </Text>
                                                    <ChevronRight width={14} />
                                                </Group>
                                            </Button>
                                        </Group>
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                        </Grid>
                    </Stack>
                </Container>
            </Box >
            <Box sx={{ backgroundColor: "#F1FFF8", paddingBottom: 48 }}>
                <Container size="1200px" px={sidePadding} ref={targetRef} >
                    {/* <Stack
                        sx={{
                            borderLeft: ".5px solid rgba(0,0,0,0.3)",
                            borderRight: ".5px solid rgba(0,0,0,0.3)",
                        }}
                        px={sidePadding}
                        py={50}
                    >
                    </Stack> */}
                    <Grid>
                        <Grid.Col md={12}>
                            <Stack>
                                <Text
                                    component="h1"
                                    sx={{
                                        fontSize: "1.5em",
                                        lineHeight: 1,
                                    }}
                                >
                                    How it works
                                </Text>
                            </Stack>
                        </Grid.Col>
                        {isXs ? (
                            <Grid.Col xs={12}>
                                <Stack>
                                    <Stepper size="xs" active={stepperIndex}>
                                        {howItWorks.map((h, i) => (
                                            <Stepper.Step
                                                completedIcon={i + 1}
                                                label={`Step ${i + 1}`}
                                                description={
                                                    <Text fw={700} align="center">
                                                        {h.title}
                                                        <Text
                                                            component="span"
                                                            sx={{
                                                                color: "#4E7A4D",
                                                            }}
                                                            ml={4}
                                                        >
                                                            {h.highlight}
                                                        </Text>
                                                    </Text>
                                                }
                                            />
                                        ))}
                                    </Stepper>
                                    <Carousel
                                        slideSize="70%"
                                        // height={200}
                                        slideGap="md"
                                        // withControls={false}
                                        onSlideChange={(index) => {
                                            setStepperIndex(index + 1);
                                        }}
                                    >
                                        {howItWorks.map((h, i) => (
                                            <Carousel.Slide>
                                                <Image src={h.img} fit="contain" />
                                            </Carousel.Slide>
                                        ))}
                                    </Carousel>
                                </Stack>
                            </Grid.Col>
                        ) : (
                            <>
                                {howItWorks.map((h, i) => (
                                    <Grid.Col xs={4}>
                                        <Stack
                                            spacing={4}
                                        // sx={(theme) => ({

                                        //     [theme.fn.smallerThan("sm")]: {
                                        //         maxWidth: "40%",
                                        //     },
                                        // })}
                                        >
                                            <Box
                                                sx={{
                                                    backgroundColor:
                                                        "rgba(44, 83, 20, 0.3)",
                                                    borderRadius: 16,
                                                }}
                                                p={8}
                                            >
                                                <Text fw={700} align="center">
                                                    {i + 1}. {h.title}
                                                    <Text
                                                        component="span"
                                                        sx={{
                                                            color: "#4E7A4D",
                                                        }}
                                                        ml={4}
                                                    >
                                                        {h.highlight}
                                                    </Text>
                                                </Text>
                                            </Box>
                                            <Box sx={{ border: "2px solid #2C5314" }}>
                                                <Image src={h.img} fit="contain" />
                                            </Box>
                                        </Stack>
                                    </Grid.Col>
                                ))}
                            </>
                        )}
                    </Grid>
                </Container>
            </Box>


            <SkewDivider primaryColor="#F1FFF8" skewColor="#4E7A4D" lineColor={'.5px solid rgba(255,255,255,0.3)'} />
            <Box
                sx={{
                    // background: "#4E7A4D",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        // position: "absolute",
                        // transform: `skew(0deg, -2deg) translateY(-50%)`,
                        height: "120%",
                        width: "100%",
                        background: "#4E7A4D",
                        top: "50%",
                    }}
                >
                    <Container
                        size="1200px"
                        px={sidePadding}
                        sx={{ height: "100%" }}
                    >
                        <Box
                            sx={{
                                borderLeft: ".5px solid rgba(255,255,255,0.3)",
                                borderRight: ".5px solid rgba(255,255,255,0.3)",
                                height: "100%",
                            }}
                            px={sidePadding}
                        />
                    </Container>
                </Box>
                <Box
                    sx={{
                        background: "#4E7A4D",
                        zIndex: 10,
                        position: "relative",
                    }}
                >
                    <Container size="1200px" px={sidePadding}>
                        <Stack
                            spacing={24}
                            sx={{
                                borderLeft: ".5px solid rgba(255,255,255,0.3)",
                                borderRight: ".5px solid rgba(255,255,255,0.3)",
                                height: "100%",
                                paddingBottom: 48,
                            }}
                            px={sidePadding}
                        // py={50}
                        >
                            <Text
                                component="h1"
                                sx={{
                                    fontSize: "1.5em",
                                    lineHeight: 1,
                                    color: "#B4E562",
                                }}
                            >
                                Frequently asked questions
                            </Text>
                            {faq.map((f) => (
                                <Box>
                                    <Text
                                        color="white"
                                        fw={700}
                                        sx={{ fontSize: 24, margin: 0 }}
                                        component="h2"
                                    >
                                        {f.title}
                                    </Text>
                                    <Text color="white" fz="sm">
                                        {f.content}
                                    </Text>
                                </Box>
                            ))}
                        </Stack>
                    </Container>
                </Box>
            </Box>
        </Box >
    );
}
