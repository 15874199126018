import { Box, Text } from "@mantine/core"

type RegisterTitleProps = {
    title: string;
    subtitle: string;
}

const RegisterTitle: React.FC<RegisterTitleProps> = ({
    title,
    subtitle,
}) => {
    return (
        <Box pb={16}>
            <Text size={32} weight={500}>
                {title}
            </Text>
            <Text size={16}>
                {subtitle}
            </Text>
        </Box>
    )
}

export { RegisterTitle }