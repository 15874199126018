import { Box, Button, Center, Group, Modal, Overlay, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { SubscriptionBadge } from 'components/Badges';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Paywall = ({ type }: any) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(true)
        // navigate("/profile?tab=subscription")
    }

    return (
        <>
            <Overlay color="#000" opacity={0} onClick={() => handleClick()}>
                {/* <Center
                    sx={{ height: '100%', cursor: 'pointer' }}
                    onClick={() => handleClick()}
                    p={16}
                >
                    <Group spacing={4} position='center'>
                        <Text color="white" fz={24} fw={700}>Subscribe to</Text>
                        <SubscriptionBadge type={type} />
                    </Group>
                </Center> */}
            </Overlay>
            <Modal
                centered
                opened={open}
                onClose={() => setOpen(false)}
                title="You are not subscribed"
                sx={{ zIndex: 1001 }}
            >
                <Stack>
                    <Stack spacing={4}>
                        <Text lh={1} fz={24} align='center' color="#417256" fw={'bold'}>Subscribe now</Text>
                        <Text lh={1} fz={24} align='center'>to gain access to this feature</Text>
                    </Stack>
                    <Group sx={{ width: '100%' }}>
                        <Button sx={{ flex: 1 }} variant='subtle' onClick={() => setOpen(false)}>Cancel</Button>
                        <Button
                            sx={{ flex: 1 }}
                            onClick={() => {
                                setOpen(false)
                                navigate("/profile?tab=subscription")
                            }}
                        >
                            View Packages
                        </Button>
                    </Group>
                </Stack>
            </Modal>
        </>
    );
};

export default Paywall;