import {
    Flex,
    Container,
    Grid,
    Text,
    Button,
    useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { IconCircleX } from "@tabler/icons-react";

export default function SubscribeFail() {
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

    const onClickTryAgain = () => {
        navigate("/profile");
    };

    return (
        <Container size={"1200px"}>
            <Grid p={0} m={0} py={32} align="center" justify="center">
                <Grid.Col
                    p={0}
                    display={"flex"}
                    sx={{ alignContent: "center", justifyContent: "center" }}
                >
                    <Text
                        weight={600}
                        size={isMobile ? 20 : 36}
                        pb={64}
                        sx={{ lineHeight: "44px", ...(isMobile && {lineHeight: '30px'}) }}
                    >
                        Purchase unsuccessful!
                    </Text>
                </Grid.Col>
                <Grid.Col p={0}>
                    <Flex
                        direction="column"
                        align={"center"}
                        justify={"center"}
                    >
                        <IconCircleX
                            width={40}
                            height={40}
                            color="#D51920"
                            style={{ marginBottom: "16px" }}
                        />
                        <Flex
                            direction={"row"}
                            w={"100%"}
                            pb={24}
                            align={"center"}
                            justify={"center"}
                        >
                            <Text
                                display={"inline"}
                                weight={500}
                                size={16}
                                align="center"
                                color="#294936"
                                sx={{ lineHeight: "24px" }}
                            >
                                Please try again or contact{" "}
                                <Text
                                    component="a"
                                    href="mailto:admin@propmoth.com"
                                    display={"inline"}
                                    sx={{ textDecoration: "underline" }}
                                >
                                    customer support
                                </Text>{" "}
                                for assistance.
                            </Text>
                        </Flex>
                        <Button
                            h={44}
                            mb={8}
                            radius={"8px"}
                            onClick={() => onClickTryAgain()}
                            sx={{
                                padding: "10px 32px",
                                backgroundColor: "#294936",
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        >
                            Try again
                        </Button>
                    </Flex>
                </Grid.Col>
            </Grid>
        </Container>
    );
}
