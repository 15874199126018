import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { USER_TYPE } from "helpers/constants";
import { flattenObj } from "helpers/strapi";
import { Permission, useAuth } from "hooks/useAuth";
const qs = require("qs");

// keys
export const GET_AGENT_DETAIL = "GET_AGENT_DETAIL";
export const GET_AGENT_REFERRAL_CODE = "GET_AGENT_REFERRAL_CODE";
export const GET_AGENT_FROM_REFERRAL_CODE = "GET_AGENT_FROM_REFERRAL_CODE";
export const GET_LISTER_DETAIL = "GET_LISTER_DETAIL";
export const GET_NOTIFICATION_COUNT = "GET_NOTIFICATION_COUNT";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_AGENT_FILTER = "GET_AGENT_FILTER";
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const GET_AGENT_DASHBOARD_INSIGHTS = "GET_AGENT_DASHBOARD_INSIGHTS";
export const GET_AGENT_DASHBOARD_STATS = "GET_AGENT_DASHBOARD_STATS";

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";

async function getAgentDetail(id: string) {
    const query = qs.stringify(
        {
            filters: {
                agent: {
                    id: {
                        $eq: id,
                    },
                },
            },
            populate: ["agent", "agent.avatar"],
        },
        { encodeValuesOnly: true }
    );
    const response = await API({ requireAuth: true }).get<any, any>(
        `agent-details?${query}`
    );

    return flattenObj(response.data);
}

export const useGetAgentDetail = (id: string) =>
    useQuery([GET_AGENT_DETAIL, id], () => getAgentDetail(id));

async function getAgentReferral(id: string) {
    const query = qs.stringify(
        {
            filters: {
                user: {
                    id: {
                        $eq: id,
                    },
                },
            },
            populate: ["referred"],
        },
        { encodeValuesOnly: true }
    );
    const response = await API({ requireAuth: true }).get<any, any>(
        `referrals?${query}`
    );

    return flattenObj(response.data);
}

export const useGetAgentReferral = (id: string) =>
    useQuery([GET_AGENT_REFERRAL_CODE, id], () => getAgentReferral(id));

async function getAgentFromReferral(code: string) {
    const query = qs.stringify(
        {
            filters: {
                $or: [
                    {
                        code: {
                            $eq: code,
                        },
                    },
                    {
                        customCode: {
                            $eq: code,
                        },
                    },
                ],
            },
            populate: ["user"],
        },
        { encodeValuesOnly: true }
    );
    const response = await API({ requireAuth: true }).get<any, any>(
        `referrals?${query}`
    );

    return flattenObj(response.data);
}

export const useGetAgentFromReferral = (code: string) =>
    useQuery([GET_AGENT_FROM_REFERRAL_CODE, code], () =>
        getAgentFromReferral(code)
    );

async function getListerDetail(id: string) {
    const query = qs.stringify({
        filters: {
            user: {
                id: {
                    $eq: id,
                },
            },
        },
    });
    const response = await API({}).get<any, any>(`user-details?${query}`);

    return flattenObj(response.data);
}

export const useGetListerDetail = (id: string) =>
    useQuery([GET_LISTER_DETAIL, id], () => getListerDetail(id));

async function getNotificationCount(id: number, jwt: string) {
    const query = qs.stringify(
        {
            populate: ["receiver", "receiver.avatar"],
            filters: {
                receiver: {
                    id: { $eq: id },
                },
                read: {
                    $eq: 0,
                },
            },
        },
        { encodeValuesOnly: true }
    );
    const response = await API({ requireAuth: true, token: jwt }).get<any, any>(
        `notifications?${query}`
    );

    return flattenObj(response.data);
}

export const useGetNotificationCount = (payload: any) =>
    useQuery(
        [GET_NOTIFICATION_COUNT, payload],
        () => getNotificationCount(payload.id, payload.jwt),
        {
            enabled: !!payload.id && !!payload.jwt,
        }
    );

async function getNotifications(id: number, jwt: string) {
    const query = qs.stringify(
        {
            populate: ["receiver", "receiver.avatar"],
            filters: {
                receiver: {
                    id: { $eq: id },
                },
            },
            sort: ["createdAt:desc"],
            pagination: {
                start: 0,
                limit: 10,
            },
        },
        { encodeValuesOnly: true }
    );
    const response = await API({ requireAuth: true, token: jwt }).get<any, any>(
        `notifications?${query}`
    );

    return flattenObj(response.data);
}

export const useGetNotifications = (payload: any) =>
    useQuery(
        [GET_NOTIFICATIONS, payload],
        () => getNotifications(payload.id, payload.jwt),
        {
            enabled: !!payload.id && !!payload.jwt,
        }
    );

export const useUserAPI = () => {
    const { user } = useAuth(Permission.USER_ONLY);

    const createFeedback = async (payload: { content: string }) => {
        if (!!user.jwt) {
            const resp = await API({ requireAuth: true, token: user.jwt }).post<
                any,
                any
            >(`feedbacks`, {
                data: {
                    ...payload,
                    user: user.id,
                },
            });
            return flattenObj(resp);
        }

        const resp = await API({}).post<any, any>(`feedbacks`, {
            data: {
                ...payload,
            },
        });
        return flattenObj(resp);
    };

    const useCreateFeedback = () => useMutation(createFeedback);

    const agentUpdateLocality = async (payload: any) => {
        try {
            const resp = await API({ requireAuth: true, token: user.jwt }).put<
                any,
                any
            >(`agent-details/${payload.id}`, {
                data: {
                    localityState: payload.localityState,
                    localityArea: payload.localityArea,
                },
            });

            return flattenObj(resp);
        } catch (e) {
            console.log(e);
        }
    };

    const useAgentUpdateLocality = () => useMutation(agentUpdateLocality);

    const useGetAgentCustomFilter = () =>
        useQuery([GET_AGENT_FILTER], async () => {
            try {
                const resp = await API({
                    requireAuth: true,
                    token: user.jwt,
                }).get<any, any>(`agent-filters`);

                return flattenObj(resp);
            } catch (e) {
                console.log(e);
            }
        });

    const useUpdateAgentCustomFilter = () =>
        useMutation(async (payload: any) => {
            try {
                const resp = await API({
                    requireAuth: true,
                    token: user.jwt,
                }).put<any, any>(`agent-filters/${payload.id}`, {
                    data: {
                        ...payload.customFilter,
                    },
                });

                return flattenObj(resp);
            } catch (e) {
                console.log(e);
            }
        });

    const agentUpdateRequestTutorialComplete = async (payload: any) => {
        try {
            const resp = await API({ requireAuth: true, token: user.jwt }).put<
                any,
                any
            >(`agent-details/${payload.id}`, {
                data: {
                    requestTutorialComplete: payload.requestTutorialComplete,
                },
            });

            return flattenObj(resp);
        } catch (e) {
            console.log(e);
        }
    };

    const useAgentUpdateRequestTutorialComplete = () =>
        useMutation(agentUpdateRequestTutorialComplete);

    const useGetCampaignDetails = ({ uid }: any) =>
        useQuery([GET_CAMPAIGNS, uid], async () => {
            const query = qs.stringify(
                {
                    populate: ["campaignImage"],
                    filters: {
                        uid,
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            try {
                const response = await API({
                    requireAuth: true,
                    token: user.jwt,
                }).get<any, any>(`campaigns?${query}`);

                return flattenObj(response.data);
            } catch (e) {
                return e;
            }
        });

    const useGetSubscription = () =>
        useQuery(
            [GET_SUBSCRIPTION],
            async () => {
                try {
                    const response = await API({
                        requireAuth: true,
                        token: user.jwt,
                    }).get<any, any>(`subscription`);

                    return flattenObj(response.data);
                } catch (e) {
                    return e;
                }
            },
            {
                enabled: user.userType === USER_TYPE.AGENT,
            }
        );

    const useCancelSubscription = () =>
        useMutation(async (payload: any) => {
            try {
                const resp = await API({
                    requireAuth: true,
                    token: user.jwt,
                }).post<any, any>(`subscription/cancel`, {
                    data: {
                        ...payload.data,
                    },
                });

                return flattenObj(resp);
            } catch (e) {
                console.log(e);
            }
        });

    const useGetAgentDashboardInsights = () =>
        useQuery(
            [GET_AGENT_DASHBOARD_INSIGHTS],
            async () => {
                try {
                    const response = await API({
                        requireAuth: true,
                        token: user.jwt,
                    }).get<any, any>(`insights/agent/dashboard`);

                    return flattenObj(response.data);
                } catch (e) {
                    return e;
                }
            },
            {
                enabled: user.userType === USER_TYPE.AGENT,
            }
        );

    const useGetAgentDashboardStats = () =>
        useQuery(
            [GET_AGENT_DASHBOARD_STATS],
            async () => {
                try {
                    const response = await API({
                        requireAuth: true,
                        token: user.jwt,
                    }).get<any, any>(`insights/agent/stats`);

                    return flattenObj(response.data);
                } catch (e) {
                    return e;
                }
            },
            {
                enabled: user.userType === USER_TYPE.AGENT,
            }
        );

    return {
        useCreateFeedback,
        useAgentUpdateLocality,
        useGetCampaignDetails,
        useAgentUpdateRequestTutorialComplete,
        useUpdateAgentCustomFilter,
        useGetAgentCustomFilter,
        useGetSubscription,
        useGetAgentDashboardInsights,
        useGetAgentDashboardStats,
        useCancelSubscription,
    };
};
