import {
    Badge,
    Box,
    Button,
    Card,
    Center,
    Container,
    Grid,
    Group,
    Loader,
    Stack,
    Tabs,
    Image,
    Text,
    Flex,
    Avatar,
} from "@mantine/core";
import { PROPERTY_REQUEST_COLOR_SETTINGS } from "helpers/constants";
import { Bath, Bed, Edit, Eye } from "tabler-icons-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { Permission, useAuth } from "hooks/useAuth";
import { useCreateChat } from "api/chatAPI";
import { formatListingType } from "helpers/utility";

export default function OfferCard({ ...props }) {
    const navigate = useNavigate();
    const offerData = props.data
    const data = offerData.propertyRequest as any;
    const listingData = offerData.listing as any;
    const transactionType = data?.transactionType as "BUY" | "RENT" | "INVEST";
    const tagArr =
        !!listingData.tags && listingData.tags.length > 0
            ? listingData.tags.map((t: any) => (
                <Badge
                    sx={{
                        background: t.tag?.badgeBackground ?? "#00A550",
                        color: t.tag?.badgeColor ?? "#FFFFFF",
                    }}
                >
                    {t.value}
                </Badge>
            ))
            : [];


    const mergedImages = useCallback(() => {
        let mergedArr = [] as any
        if (!!listingData?.images && listingData.images.length > 0) {
            const imageArr = listingData.images.map((i: any) => ({
                src: i?.url ?? "/img-placeholder.jpeg",
                alt: "offer-image",
            }));
            mergedArr = [...mergedArr, ...imageArr]
        }

        if (!!listingData?.imageLinks && listingData.imageLinks.length > 0) {
            const imageArr = listingData.imageLinks.map((i: any) => ({
                src: i,
                alt: "offer-image",
            }))
            mergedArr = [...mergedArr, ...imageArr]
        }

        return mergedArr
    }, [listingData])

    const statusBadge = useMemo(() => {
        if (props.data.seen) {
            return (
                <Badge color="green" variant="filled" size="lg">
                    <Group spacing={4} align="center">
                        <Eye />
                        <Text>Seen</Text>
                    </Group>
                </Badge>
            );
        }

        return (
            <Badge color="black" size="lg">
                Posted
            </Badge>
        );
    }, [data]);

    return (
        <Card
            sx={{
                width: "100%",
                border: "1px solid #BFC8C3",
                cursor: "pointer",
            }}
            // onClick={(e: any) => {
            //     console.log(e);
            //     navigate(`/requests/${data.id}`);
            // }}
            shadow="sm"
            onClick={() => navigate(`/offer/${props.data.id}`)}
        >
            <Grid>
                <Grid.Col xs={props.vertical ? 12 : 3}>
                    <Center sx={{ height: "100%" }}>
                        <Image
                            width={"100%"}
                            height={"100%"}
                            fit="cover"
                            src={mergedImages()[0]?.src}
                        />
                    </Center>
                </Grid.Col>
                <Grid.Col xs={props.vertical ? 12 : 9}>
                    <Stack spacing={8}>
                        <Group position="apart">
                            <Badge
                                sx={{
                                    color: PROPERTY_REQUEST_COLOR_SETTINGS[
                                        transactionType
                                    ]?.color,
                                    backgroundColor:
                                        PROPERTY_REQUEST_COLOR_SETTINGS[
                                            transactionType
                                        ]?.background,
                                }}
                            >
                                {formatListingType(transactionType)}
                            </Badge>
                            <Group spacing={8}>
                                <Text fz={12}>
                                    Offered{" "}
                                    {moment(listingData.createdAt).toNow(true)}{" "}
                                    ago
                                </Text>
                                {statusBadge}
                            </Group>
                            {/* <Edit /> */}
                        </Group>
                        <Stack spacing={16}>
                            <Group position="apart">
                                <Stack spacing={4}>
                                    <Text fw={700}>{listingData.title}</Text>
                                    <Text fw={500}>{listingData.address}</Text>
                                </Stack>
                                <Text fw={700} fz={20} align="right">
                                    RM
                                    {parseInt(
                                        listingData.price
                                    ).toLocaleString()}
                                </Text>
                            </Group>
                            <Group position="apart">
                                <Stack spacing={4}>
                                    <Group spacing={8}>
                                        <Text>
                                            {listingData.propertyType} |{" "}
                                            {listingData.tenure}
                                        </Text>
                                        <Group spacing={8}>
                                            <Text>
                                                {listingData.floorSize} sqft
                                            </Text>
                                        </Group>
                                    </Group>
                                    <Group spacing={8}>
                                        <Group spacing={4}>
                                            <Text>
                                                {listingData.noOfBedrooms}
                                            </Text>
                                            <Bed />
                                        </Group>
                                        <Group spacing={4}>
                                            <Text>
                                                {listingData.noOfBathrooms}
                                            </Text>
                                            <Bath />
                                        </Group>
                                    </Group>
                                </Stack>
                                <Stack spacing={4}>
                                    {data?.lister && (
                                        <Text fz={12} align="right">
                                            Requested by {data?.lister.fullname}
                                            {/* <Text
                                                fz={14}
                                                fw={700}
                                                span
                                                sx={(theme) => ({
                                                    color: theme.colors
                                                        .primaryGreen["6"],
                                                })}
                                                td="underline"
                                                // onClick={() => }
                                            >
                                                {data.lister.fullname}
                                            </Text> */}
                                        </Text>
                                    )}
                                    <Group spacing={8}>
                                        <Avatar src={listingData?.agent?.avatar?.url ?? "avatar.png"} radius='50%' />
                                        <Text fz={12}>Offered by <Text component="span" color="#417256">{listingData.agent.fullname}</Text></Text>
                                    </Group>
                                </Stack>
                            </Group>
                            {offerData?.pitch?.length > 0 && (
                                <Stack
                                    sx={{
                                        backgroundColor: "whitesmoke"
                                    }}
                                    p={8}
                                    spacing={2}
                                >
                                    <Text fw="bold">Offer Description:</Text>
                                    <Group position="apart">
                                        <Text lineClamp={2}>
                                            {offerData?.pitch}
                                        </Text>
                                    </Group>
                                </Stack>
                            )}
                            <Flex
                                sx={(theme) => ({
                                    flexDirection: props.vertical
                                        ? "column"
                                        : "row",
                                    [theme.fn.smallerThan("xs")]: {
                                        flexDirection: 'column'
                                    },
                                    gap: 16,
                                })}
                            >
                                <Box sx={{ flex: 1 }}>
                                    <Group>{tagArr}</Group>
                                </Box>
                                <Button
                                    onClick={() =>
                                        navigate(`/offer/${props.data.id}`)
                                    }
                                >
                                    View Offer Details
                                </Button>
                            </Flex>
                        </Stack>
                    </Stack>
                </Grid.Col>
            </Grid>
        </Card>
    );
}
