import { Button, Group, Stack, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useUserAPI } from 'api/userAPI';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RegisterFeedback = () => {
    const navigate = useNavigate()
    const { useCreateFeedback } = useUserAPI()
    const createFeedback = useCreateFeedback()
    const [feedbackSuccess, setFeedbackSuccess] = useState(false)
    const [feedbackFail, setFeedbackFail] = useState(false)



    const form = useForm({
        initialValues: {
            content: ""
        },
        validate: {
            content: (value) => {
                if (!value) {
                    return "Please fill in this field"
                }

                if (value.length < 50) {
                    return "At least 50 characters are required"
                }
                return null
            },
        }
    })

    const onSubmit = () => {
        const { hasErrors } = form.validate()
        if (!hasErrors) {

            createFeedback.mutate(form.values, {
                onSuccess: () => {
                    form.reset()
                    notifications.show({
                        title: "Thank You!",
                        message: "Your feedback has been submitted",
                    });
                },
                onError: () => {
                    setFeedbackFail(true)
                },
            })
        }

    }

    const handleClose = () => {
        setFeedbackSuccess(false)
        setFeedbackFail(false)
    }

    return (
        <Stack>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    <Text>Having trouble signing up? Let us know as there are some issues where we need your help to find out!</Text>
                    <Textarea
                        minRows={5}
                        {...form.getInputProps("content")}
                        error={form.errors.content}
                    />
                    <Group sx={{ width: '100%', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => onSubmit()}
                            loading={createFeedback.isLoading}
                            disabled={form.values.content.length <= 0}
                        >
                            Submit
                        </Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    );
};

export default RegisterFeedback;