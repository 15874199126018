import { Group, Select } from '@mantine/core';
import React, { useCallback, useEffect } from 'react';

const FloorSizeInput = ({ form }: any) => {
    const rentOptions = [
        { "label": "500 sqft (46 sqm)", "value": 500 },
        { "label": "750 sqft (70 sqm)", "value": 750 },
        { "label": "1,000 sqft (93 sqm)", "value": 1000 },
        { "label": "1,200 sqft (112 sqm)", "value": 1200 },
        { "label": "1,500 sqft (139 sqm)", "value": 1500 },
        { "label": "2,000 sqft (186 sqm)", "value": 2000 },
        { "label": "2,500 sqft (232 sqm)", "value": 2500 },
        { "label": "3,000 sqft (279 sqm)", "value": 3000 },
        { "label": "4,000 sqft (372 sqm)", "value": 4000 },
        { "label": "5,000 sqft (465 sqm)", "value": 5000 },
        { "label": "7,500 sqft (697 sqm)", "value": 7500 },
        { "label": "10,000 sqft (929 sqm)", "value": 10000 }
    ]

    useEffect(() => {
        if (!!form.values.maxSquareFt && !form.values.minSquareFt) {
            form.setFieldValue('minSquareFt', form.values.maxSquareFt)
        }

        if (!form.values.maxSquareFt && !!form.values.minSquareFt) {
            form.setFieldValue('maxSquareFt', form.values.minSquareFt)
        }

        if (!!form.values.maxSquareFt && !!form.values.minSquareFt && form.values.minSquareFt > form.values.maxSquareFt) {
            form.setFieldValue('maxSquareFt', form.values.minSquareFt)
        }

        if (!!form.values.maxSquareFt && !!form.values.minSquareFt && form.values.maxSquareFt < form.values.minSquareFt) {
            form.setFieldValue('minSquareFt', form.values.maxSquareFt)
        }

    }, [form.values])

    return (
        <Group>
            <Select
                label="Minimum Sqft"
                data={rentOptions}
                {...form.getInputProps(
                    "minSquareFt"
                )}
                sx={{
                    flex: 1,
                    width: "100%",
                }}

            />
            <Select
                label="Maximum Sqft"
                data={rentOptions}
                {...form.getInputProps(
                    "maxSquareFt"
                )}
                sx={{
                    flex: 1,
                    width: "100%",
                }}

            />
        </Group>
    );
};

export default FloorSizeInput;