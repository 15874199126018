import { useMediaQuery, useScrollIntoView } from "@mantine/hooks";
import {
    Box,
    Text,
    Flex,
    Divider,
    Button,
    Container,
    Grid,
    LoadingOverlay,
    Badge,
    Group,
    CopyButton,
    Tooltip,
    ActionIcon,
    Stack,
    Drawer,
    Paper,
    useMantineTheme,
    Image,
    Card,
} from "@mantine/core";
import {
    DetailsSingleRow,
    DetailsMultipleRow,
    DetailsMobile,
} from "components/Details/Details";
import { IconLink, IconCircleCheck, IconCircleX } from "@tabler/icons-react";
import { redirect, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Bath, Bed, Copy, Home2 } from "tabler-icons-react";
import {
    PROPERTY_REQUEST_COLOR_SETTINGS,
    propRequestPropertyTitleOptions,
    propRequestTenureOptions,
    USER_TYPE,
} from "helpers/constants";
import { formatSeriousness, getReadableValueFromSelect } from "helpers/utility";
import { useGetListerDetail } from "api/userAPI";
import { Permission, useAuth } from "hooks/useAuth";
import OfferCard from "components/PropRequest/OfferCard";
import { theme } from "ThemeProvider";
import { useListingAPI } from "api/useListingAPI";
import TransactionTypeBadge from "components/TransactionTypeBadge";
import HoverBox from "components/HoverBox";
import FixedLayout from "components/Layout/FixedLayout";
import { useCreateChat } from "api/chatAPI";
import { TokensModal } from "components/Modals/TokensModal";
import { notifications } from "@mantine/notifications";
import { useProfileController } from "hooks/useProfile";

export default function ViewPropRequest({
    requestId,
    profileView,
    externalRef,
    externalScrollIntoView,
}: {
    requestId?: string;
    profileView?: boolean;
    externalRef?: any;
    externalScrollIntoView?: any;
}) {
    const { useGetOnePropRequests, useGetOfferByRequest } = useListingAPI();
    const navigate = useNavigate();
    const { user } = useAuth(Permission.ANYONE);
    const theme = useMantineTheme();
    const createChatMutation = useCreateChat();
    const [purchaseTokensPopup, setPurchaseTokensPopup] = useState(false);
    const { currentActiveSubscription } = useProfileController();

    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const [offersOpen, setOffersOpen] = useState(false);
    let { id } = useParams();
    const { scrollIntoView, targetRef } = useScrollIntoView<
        HTMLDivElement,
        HTMLDivElement
    >();

    const [offerStatusFilter, setOfferStatusFilter] = useState("All");

    const offerStatuses = ["All", "Pending", "Seen"];

    useEffect(() => {
        if (!!currentActiveSubscription && currentActiveSubscription.package === "UNSUBBED" && user.userType === USER_TYPE.AGENT) {
            navigate("/profile?tab=subscription")
            notifications.show({
                title: "You are not subscribed",
                message:
                    "Subscribe now to gain access to this feature",
            });
        }
    }, [currentActiveSubscription, user])

    useEffect(() => {
        console.log(user)
        if (!!user.loaded && !user.id) {
            navigate("/login")
            notifications.show({
                title: "You are not logged in",
                message: "Login or register to view this request",
                autoClose: 5000,
            });
        }
    }, [user])

    const {
        data: requestData,
        isLoading: requestIsLoading,
        error: requestError,
    } = useGetOnePropRequests(requestId ?? (id as string));

    const {
        data: offersData,
        isLoading: offersIsLoading,
        error: offersError
    } = useGetOfferByRequest(requestId ?? (id as string))

    // useEffect(() => {
    //     console.log("offersData", offersData)
    // }, [offersData])

    const {
        data: listerData,
        isLoading: listerIsLoading,
        error: listerError,
    } = useGetListerDetail(requestData?.lister?.id || "");

    const tagArr = () => {
        return !!requestData?.tags && requestData?.tags.length > 0
            ? requestData.tags.map((t: any) => (
                <Badge
                    sx={{
                        background: t.tag?.badgeBackground ?? "#00A550",
                        color: t.tag?.badgeColor ?? "#FFFFFF",
                    }}
                >
                    {t.value}
                </Badge>
            ))
            : [];
    };

    const titleType = useMemo(() => {
        let defaultValue = "Property Request"

        if (!!requestData?.isCobroke) {
            defaultValue = "Co-Agency"
        }

        return defaultValue
    }, [requestData])

    const additionalInfo = useMemo(() => {
        const data = !!listerData && listerData.length === 1 ? listerData[0] : {};
        const requestListerData = !!requestData && !!requestData?.lister ? requestData?.lister : {}

        let template = {
            fullname: {
                type: "Full name",
                available: !!requestListerData.fullname,
            },
            phoneNumber: {
                type: "Phone number",
                available: !!requestListerData.phoneNumber,
                data: !!requestData?.isUrgent || !!requestData?.showPhoneNumber ? `${requestListerData.phoneNumber}` ?? null : null
            },
            email: {
                type: "Email address",
                available: !!requestListerData.email,
                data: !!requestData?.isUrgent ? requestListerData.email ?? null : null
            },
            nationality: {
                type: "Nationality",
                available: !!data.nationality,
                data: data.nationality ?? null
            },
            race: {
                type: "Ethnicity",
                available: !!data.race,
                data: data.race ?? null
            },
            dob: {
                type: "DOB",
                available: !!data.dob,
                data: data.dob ?? null
            },
            maritalStatus: {
                type: "Marital status",
                available: !!data.maritalStatus,
                data: data.maritalStatus ?? null
            },
            occupation: {
                type: "Occupation",
                available: !!data.occupation,
                data: data.occupation ?? null
            },
        };

        return template;
    }, [listerData, requestData]);

    const isInfoComplete = useMemo(() => {
        return Object.values(additionalInfo).filter(
            (i) => !!i.available
        ).length === Object.values(additionalInfo).length
    }, [])

    const hasChat = useMemo(() => {
        if (!!requestData) {
            return !!requestData?.chat?.id
        }

        return false
    }, [requestData])

    const handleCreateChat = useCallback(async () => {
        try {
            const chat = await createChatMutation.mutateAsync({
                lister: requestData.lister.id,
                agent: user.id,
                propertyRequest: requestData.id
            });

            navigate(`/chats`, { state: { id: chat.data.id } });

            console.log(requestData)
        } catch (e: any) {
            if (e?.response?.data?.error?.message === "Insufficient tokens") {
                setPurchaseTokensPopup(true);
            }
        }
    }, [requestData])

    const AdditionalDetails = () => {
        return (
            <>
                <Flex
                    w={"100%"}
                    direction="column"
                    align="center"
                    p={24}
                    gap="32px"
                    sx={{
                        border: "1px solid #BFC8C3",
                        background: "#FFFFFF",
                        borderRadius: "12px",
                    }}
                >
                    <Flex
                        direction="column"
                        align="flex-start"
                        w={"100%"}
                        p={0}
                        gap={8}
                    >
                        <Text
                            sx={{
                                fontFamily: "Public Sans, sans-serif",
                                fontStyle: "normal",
                                fontSize: "18px",
                                fontWeight: 600,
                                lineHeight: "28px",
                                color: "#212922",
                            }}
                        >
                            Optional info (
                            {
                                Object.values(additionalInfo).filter(
                                    (i) => !!i.available
                                ).length
                            }
                            /{Object.values(additionalInfo).length})
                        </Text>
                        <Grid>
                            {Object.values(additionalInfo).map(
                                (data: any, index: number) => {
                                    return (
                                        <React.Fragment key={`info-${index}`}>
                                            <Grid.Col span={10}>
                                                <Group spacing={4}>
                                                    <Text
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: 400,
                                                            fontSize: "14px",
                                                            lineHeight: "20px",
                                                            color: "#212922",
                                                        }}
                                                    >
                                                        {data.type}
                                                    </Text>
                                                    {!!data.data && !!user.jwt && user.userType === USER_TYPE.AGENT && (
                                                        <HoverBox text={`: ${data.data}`} />
                                                    )}
                                                </Group>
                                            </Grid.Col>
                                            <Grid.Col span={2}>
                                                {data.available ? (
                                                    <IconCircleCheck
                                                        width={20}
                                                        height={20}
                                                        color="#027A48"
                                                    />
                                                ) : (
                                                    <IconCircleX
                                                        width={20}
                                                        height={20}
                                                        color="#D51920"
                                                    />
                                                )}
                                            </Grid.Col>
                                        </React.Fragment>
                                    );
                                }
                            )}
                        </Grid>
                        {/* <Flex
                        w={"100%"}
                        direction="column"
                        align="flex-start"
                        p={0}
                        gap={8}
                    >
                        {Object.values(additionalInfo).map(
                            (data: any, index: number) => {
                                return (
                                    <Box key={index} w={"100%"}>
                                        <Flex
                                            w={"100%"}
                                            direction="row"
                                            justify={"space-between"}
                                            align={"flex-start"}
                                            p="10px 0"
                                            gap={96}
                                            sx={{
                                                "& .tabler-icon-circle-check": {
                                                    color: "#027A48",
                                                },
                                                "& .tabler-icon-circle-x": {
                                                    color: "#D51920",
                                                },
                                            }}
                                        >
                                            <Text
                                                sx={{
                                                    fontFamily:
                                                        "Public Sans, sans-serif",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "20px",
                                                    color: "#212922",
                                                }}
                                            >
                                                {data.type}
                                            </Text>
                                            {data.available ? (
                                                <IconCircleCheck
                                                    width={20}
                                                    height={20}
                                                />
                                            ) : (
                                                <IconCircleX
                                                    width={20}
                                                    height={20}
                                                />
                                            )}
                                        </Flex>
                                        {index <
                                            Object.values(additionalInfo)
                                                .length -
                                                1 && (
                                            <Divider
                                                my="sm"
                                                w={"100%"}
                                                m={"0 !important"}
                                            />
                                        )}
                                    </Box>
                                );
                            }
                        )}
                    </Flex> */}
                    </Flex>

                    {!!user.jwt && user.id === requestData?.lister.id && (
                        <>
                            {isInfoComplete ? (
                                <Text align="center" size="md" color="#417256">Thank you for completing your info!</Text>
                            ) : (

                                <Stack spacing={4}>
                                    <Text align="center" size="sm">Complete your info to get more offers!</Text>
                                    <Button w="100%" onClick={() => navigate("/profile/edit")}>
                                        Complete Info
                                    </Button>
                                </Stack>
                            )
                            }
                        </>
                    )
                    }
                </Flex >
            </>
        );
    };

    const LinkCopyButton = ({ ...props }) => {
        const link = profileView ? `${window.location.origin}/requests/${props.id}` : window.location.href
        return (
            <CopyButton value={link}>
                {({ copied, copy }) => (
                    <Button
                        variant={copied ? "outline" : "subtle"}
                        onClick={copy}
                        leftIcon={
                            <IconLink
                                width={"16px"}
                                height={"16px"}
                                color={"#546D5E"}
                                style={{
                                    transform: "rotate(45deg)",
                                }}
                            />
                        }
                    >
                        {copied ? "Copied url" : "Copy url"}
                    </Button>
                )}
            </CopyButton>
        );
    };

    const filterOffers = useCallback(
        (offers: any) => {
            if (!!offers) {
                return offers.filter((o: any) => {
                    switch (offerStatusFilter) {
                        case "All":
                            return true;
                        case "Pending":
                            return !o.seen;
                        case "Seen":
                            return !!o.seen;
                    }
                });
            }

            return [];
        },
        [requestData?.offers, offerStatusFilter]
    );

    return (
        <>
            {/* <Drawer
                opened={offersOpen}
                onClose={() => setOffersOpen(false)}
                position="right"
            >
                {requestData?.offers.length > 0 && (
                    <Stack>
                        {requestData?.offers.map((offer: any) => (
                            <OfferCard data={offer} vertical />
                        ))}
                    </Stack>
                )}
            </Drawer> */}
            <Stack sx={{ height: "100%", width: '100%' }} spacing={0}>
                <Container
                    size="1200px"
                    py={32}
                    px={16}
                    sx={{
                        flexGrow: 1,
                        width: '100%'
                    }}
                >
                    <LoadingOverlay
                        visible={requestIsLoading}
                        overlayBlur={2}
                    />
                    <Stack spacing={24}>
                        <Grid gutter={24}>
                            <Grid.Col>
                                <Grid
                                    gutter={24}
                                    sx={(theme) => ({
                                        [theme.fn.smallerThan("sm")]: {
                                            flexDirection: "column-reverse",
                                        },
                                    })}
                                >
                                    <Grid.Col sm={8}>
                                        <Flex w="100%" direction={"column"} sx={{ gap: 8 }}>
                                            <Text
                                                w="100%"
                                                sx={{
                                                    fontWeight: 600,
                                                    fontSize: "36px",
                                                    lineHeight: "44px",
                                                    letterSpacing: "-0.02em",
                                                }}
                                            >
                                                {`${titleType}`}
                                                {/* by{" "}
                                                <Text
                                                    ml={6}
                                                    fw={700}
                                                    span
                                                    sx={(theme) => ({
                                                        color: theme.colors.primaryGreen["6"],
                                                        cursor: "pointer",
                                                    })}
                                                    td="underline"
                                                    // onClick={() => }
                                                >
                                                    {requestData?.lister?.fullname}
                                                </Text> */}
                                            </Text>

                                            <Text
                                                sx={{
                                                    fontFamily:
                                                        "Public Sans, sans-serif",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "24px",
                                                    color: "#212922",
                                                }}
                                            >
                                                {`Requested ${moment(
                                                    requestData?.updatedAt
                                                ).toNow(true)} ago by`}
                                                <Text component="span" fw={700} fz={'lg'}> {requestData?.lister?.fullname}</Text>
                                            </Text>
                                            {!!requestData?.seriousnessRating && (
                                                <Card
                                                    sx={{
                                                        width: "auto"
                                                    }}
                                                    shadow="md"
                                                    radius={'md'}
                                                >
                                                    <Stack spacing={4}>
                                                        <Text fw={'bold'}>Serioussness Rating: {requestData?.seriousnessRating}</Text>
                                                        <Text>{formatSeriousness(requestData?.seriousnessRating)?.label}</Text>
                                                    </Stack>
                                                </Card>
                                            )}
                                            <Group mt={16}>
                                                {!!requestData?.subSalesOk && (
                                                    <Badge color="dark" size={'lg'}>Subsales OK</Badge>
                                                )}
                                                {!!requestData?.newProjectsOnly && (
                                                    <Badge color="blue" size={'lg'}>NEW PROJECTS OK</Badge>
                                                )}
                                            </Group>
                                            <Group mt={16}>
                                                {!!requestData?.isUrgent && (
                                                    <Tooltip label={'User has agreed to reveal phone number and email'}>
                                                        <Badge color="red">⚠️ Urgent</Badge>
                                                    </Tooltip>
                                                )}
                                                {!!requestData?.isFirstTime && (
                                                    <Badge color="yellow">First Time</Badge>
                                                )}
                                                {requestData?.transactionType && (
                                                    <TransactionTypeBadge transactionType={requestData.transactionType} />
                                                )}
                                            </Group>
                                        </Flex>
                                    </Grid.Col>
                                    {!isMobile ? (
                                        <Grid.Col sm={4}>
                                            <Flex
                                                direction={"row"}
                                                sx={(theme) => ({
                                                    gap: 8,
                                                    alignItems: "center",
                                                    height: "100%",
                                                    justifyContent: "flex-end",
                                                    [theme.fn.smallerThan(
                                                        "sm"
                                                    )]: {
                                                        justifyContent:
                                                            "flex-start",
                                                    },
                                                })}
                                            >
                                                <LinkCopyButton id={requestData?.id} />
                                                {!!user.jwt &&
                                                    !profileView &&
                                                    user.id ===
                                                    requestData?.lister
                                                        .id && (
                                                        <Button
                                                            h={44}
                                                            p={"10px 18px"}
                                                            disabled={
                                                                requestData
                                                                    ?.offers
                                                                    .length ===
                                                                0
                                                            }
                                                            sx={{
                                                                borderRadius:
                                                                    "8px",
                                                                fontFamily:
                                                                    "Public Sans, sans-serif",
                                                                fontWeight: 500,
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "24px",
                                                                backgroundColor:
                                                                    "#294936",
                                                            }}
                                                            onClick={() =>
                                                                !!externalScrollIntoView
                                                                    ? externalScrollIntoView()
                                                                    : scrollIntoView()
                                                            }
                                                        >
                                                            View Offers (
                                                            {
                                                                requestData
                                                                    ?.offers
                                                                    .length
                                                            }
                                                            )
                                                        </Button>
                                                    )}
                                            </Flex>
                                        </Grid.Col>
                                    ) : (
                                        <Grid.Col sm={4}>
                                            <Flex
                                                direction={"row"}
                                                sx={(theme) => ({
                                                    gap: 8,
                                                    alignItems: "center",
                                                    height: "100%",
                                                    justifyContent: "flex-end",
                                                    [theme.fn.smallerThan(
                                                        "sm"
                                                    )]: {
                                                        justifyContent:
                                                            "flex-start",
                                                    },
                                                })}
                                            >
                                                <LinkCopyButton id={requestData?.id} />
                                                {!!user.jwt &&
                                                    user.id ===
                                                    requestData?.lister
                                                        .id && (
                                                        <Button
                                                            h={44}
                                                            p={"10px 18px"}
                                                            disabled={
                                                                requestData
                                                                    ?.offers
                                                                    .length ===
                                                                0
                                                            }
                                                            sx={{
                                                                borderRadius:
                                                                    "8px",
                                                                fontFamily:
                                                                    "Public Sans, sans-serif",
                                                                fontWeight: 500,
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "24px",
                                                                backgroundColor:
                                                                    "#294936",
                                                            }}
                                                            onClick={() =>
                                                                !!externalScrollIntoView
                                                                    ? externalScrollIntoView()
                                                                    : scrollIntoView()
                                                            }
                                                        >
                                                            View Offers (
                                                            {
                                                                requestData
                                                                    ?.offers
                                                                    .length
                                                            }
                                                            )
                                                        </Button>
                                                    )}
                                            </Flex>
                                        </Grid.Col>
                                    )}
                                </Grid>
                            </Grid.Col>
                            <Grid.Col sm={8} xs={12}>
                                <Flex
                                    gap="26px"
                                    w={"100%"}
                                    justify="flex-start"
                                    align="flex-start"
                                    direction={isMobile ? "column" : "row"}
                                    wrap="wrap"
                                >
                                    <Flex
                                        w={"100%"}
                                        direction="column"
                                        align="flex-start"
                                        p={0}
                                        gap="32px"
                                    >
                                        <Divider
                                            my="sm"
                                            w={"100%"}
                                            m={"0 !important"}
                                        />
                                        <Stack>
                                            <Text fz={18} fw={700}>
                                                {`${titleType} Details`}
                                            </Text>
                                            <Grid>
                                                <Grid.Col sm={6}>
                                                    <Group spacing={4}>
                                                        <Text fw={700}>
                                                            Preferred Location:{" "}
                                                        </Text>
                                                        <Text fw={400}>
                                                            {requestData?.postcode ??
                                                                ""}
                                                            {requestData?.area},{" "}
                                                            {requestData?.state}
                                                        </Text>
                                                    </Group>
                                                </Grid.Col>
                                                <Grid.Col sm={6}>
                                                    <Group spacing={4}>
                                                        <Text fw={700}>
                                                            Property Type:{" "}
                                                        </Text>
                                                        <Text fw={400}>
                                                            {requestData?.propertyType}
                                                        </Text>
                                                    </Group>
                                                </Grid.Col>
                                                <Grid.Col sm={6}>
                                                    <Group spacing={4}>
                                                        <Text fw={700}>
                                                            Tenure:{" "}
                                                        </Text>
                                                        <Text fw={400}>
                                                            {getReadableValueFromSelect(
                                                                propRequestTenureOptions,
                                                                requestData?.tenure
                                                            )}
                                                        </Text>
                                                    </Group>
                                                </Grid.Col>
                                                <Grid.Col sm={6}>
                                                    <Group spacing={4}>
                                                        <Text fw={700}>
                                                            Property Title Type:
                                                        </Text>
                                                        <Text fw={400}>
                                                            {getReadableValueFromSelect(
                                                                propRequestPropertyTitleOptions,
                                                                requestData?.propertyTitleType
                                                            )}
                                                        </Text>
                                                    </Group>
                                                </Grid.Col>
                                                <Grid.Col sm={6}>
                                                    <Group spacing={8}>
                                                        {requestData?.incomeRange ? (
                                                            <>
                                                                <Text fw={700}>
                                                                    Income Range:{" "}
                                                                </Text>
                                                                <Text fw={400}>
                                                                    {requestData?.incomeRange}
                                                                </Text>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Text fw={700}>
                                                                    Budget:{" "}
                                                                </Text>
                                                                <Text fw={400}>
                                                                    {`RM ${requestData?.budgetMin?.toLocaleString()} - RM ${requestData?.budgetMax?.toLocaleString()}`}
                                                                </Text>
                                                            </>
                                                        )}
                                                    </Group>
                                                </Grid.Col>
                                            </Grid>
                                        </Stack>
                                        <Divider
                                            my="sm"
                                            w={"100%"}
                                            m={"0 !important"}
                                        />
                                        <Stack>
                                            <Group>
                                                <Text fz={18} fw={700}>
                                                    Tags
                                                </Text>
                                            </Group>
                                            <Group>{tagArr()}</Group>
                                        </Stack>
                                        <Divider
                                            my="sm"
                                            w={"100%"}
                                            m={"0 !important"}
                                        />
                                        <Stack>
                                            <Group>
                                                <Text fz={18} fw={700}>
                                                    Property Size and Ammenities
                                                </Text>
                                            </Group>
                                            <Group>
                                                <Group spacing={8}>
                                                    <Text>
                                                        {
                                                            requestData?.minSquareFt
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            requestData?.maxSquareFt
                                                        }{" "}
                                                        sqft
                                                    </Text>
                                                </Group>
                                                <Group spacing={8}>
                                                    <Bed />
                                                    <Text>
                                                        {
                                                            requestData?.noOfBedrooms
                                                        }{" "}
                                                        Bedroom
                                                    </Text>
                                                </Group>
                                                <Group spacing={8}>
                                                    <Bath />
                                                    <Text>
                                                        {
                                                            requestData?.noOfBathrooms
                                                        }{" "}
                                                        Bathroom
                                                    </Text>
                                                </Group>
                                            </Group>
                                        </Stack>
                                        {!!requestData?.additionalNotes && (
                                            <>
                                                <Divider
                                                    my="sm"
                                                    w={"100%"}
                                                    m={"0 !important"}
                                                />
                                                <Stack>
                                                    <Group>
                                                        <Text fz={18} fw={700}>
                                                            Additional Notes
                                                        </Text>
                                                    </Group>
                                                    <Text
                                                        sx={{
                                                            fontFamily:
                                                                "Public Sans, sans-serif",
                                                            fontStyle: "normal",
                                                            fontWeight: 400,
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            color: "#546D5E",
                                                        }}
                                                    >
                                                        {
                                                            requestData?.additionalNotes
                                                        }
                                                    </Text>
                                                </Stack>
                                            </>
                                        )}
                                    </Flex>
                                </Flex>
                            </Grid.Col>
                            <Grid.Col sm={4} xs={12}>
                                <AdditionalDetails />
                            </Grid.Col>
                        </Grid>
                        {!!profileView && (
                            <Stack
                                ref={externalRef ?? targetRef}
                                pt={16}
                                sx={{ borderTop: "1px solid #ddd" }}
                            >
                                <Text size="xl" fw={700}>
                                    Offers
                                </Text>
                                <Group
                                    sx={{ borderBottom: "1px solid #ddd" }}
                                    pb={8}
                                >
                                    {offerStatuses.map((o) => (
                                        <Button
                                            variant={
                                                offerStatusFilter === o
                                                    ? "filled"
                                                    : "subtle"
                                            }
                                            onClick={() => setOfferStatusFilter(o)}
                                        >
                                            {o}
                                        </Button>
                                    ))}
                                </Group>
                                {offersData?.length > 0 && (
                                    <Stack>
                                        {filterOffers(offersData).map(
                                            (offer: any) => (
                                                <OfferCard data={offer} />
                                            )
                                        )}
                                        {filterOffers(offersData)
                                            .length === 0 && (
                                                <Stack
                                                    align="center"
                                                    spacing={8}
                                                    py={32}
                                                >
                                                    <Home2 size={32} />
                                                    <Text fz={24}>No Data</Text>
                                                </Stack>
                                            )}
                                    </Stack>
                                )}
                                {offersData?.length === 0 && (
                                    <Box p={16}>
                                        <Text fz="lg" align="center" sx={{ width: '100%' }}>You haven't received any offers yet</Text>
                                    </Box>
                                )}
                            </Stack>
                        )}
                    </Stack>
                </Container>
                {!!user.jwt && user.userType === USER_TYPE.AGENT && (
                    <Paper
                        shadow="md"
                        sx={{
                            backgroundColor: "#fff",
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            boxShadow: `0px -8px 16px 0px rgba(0,0,0,0.2)`,
                        }}
                    >
                        <Container size="1200px" p={16}>
                            <Group position="right">
                                {!!isMobile && <LinkCopyButton id={requestData?.id} />}
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleCreateChat()
                                    }}
                                    variant="outline"
                                >
                                    <Group spacing={8}>
                                        {!!hasChat ? "Chat" : "Start Chat"}
                                        {/* {!hasChat && (
                                            <Group spacing={4} align="center">
                                                <Text>5</Text>
                                                <Image
                                                    src={"/token.png"}
                                                    height={16}
                                                    width={16}
                                                    style={{ marginRight: "4px" }}
                                                />
                                            </Group>
                                        )} */}
                                    </Group>
                                </Button>
                                <Button
                                    onClick={() => {
                                        navigate(`/requests/${id}/offer`);
                                    }}
                                >
                                    Make Offer
                                </Button>
                            </Group>
                        </Container>
                    </Paper>
                )}
            </Stack>
            <TokensModal
                opened={purchaseTokensPopup}
                onCancel={() => setPurchaseTokensPopup(false)}
                tokenAmount={5}
            />
        </>
    );
}
