import { Box, Group, Table, Text } from '@mantine/core';
import { useUserAPI } from 'api/userAPI';
import { SubscriptionBadge } from 'components/Badges';
import { useProfileController } from 'hooks/useProfile';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardStateData = () => {
    const navigate = useNavigate()
    const { currentActiveSubscription } = useProfileController();

    const { useGetAgentDashboardInsights } = useUserAPI()
    const {
        data: agentDashboardInsight,
        isLoading: agentDashboardInsightIsLoading,
        error: agentDashboardInsightError
    } = useGetAgentDashboardInsights()

    useEffect(() => {
        console.log({ agentDashboardInsight })
    }, [agentDashboardInsight])


    const hasAccess = useMemo(() => {
        return !!currentActiveSubscription && currentActiveSubscription?.package !== "UNSUBBED"
    }, [currentActiveSubscription])
    return (
        <Table withBorder>
            <thead>
                <tr>
                    <Box
                        component="th"
                    >
                        Location
                    </Box>
                    <Box
                        component="th"
                    >
                        Leads
                    </Box>
                    <Box
                        component="th"
                    >
                        Chats
                    </Box>
                    <Box
                        component="th"
                    >
                        Views
                    </Box>
                    {/* <Box
                        component="th"
                    >
                        Co-Agency
                    </Box>
                    <Box
                        component="th"
                    >
                        Referral
                    </Box> */}
                </tr>
            </thead>
            <tbody>
                {!!agentDashboardInsight && agentDashboardInsight.length > 0 && (
                    <>
                        {agentDashboardInsight.map((data: any) => (
                            <tr>
                                <Box
                                    component="td"
                                >
                                    {data.state}
                                </Box>
                                <Box
                                    component="td"
                                >
                                    {data.totalLeads}
                                </Box>
                                <Box
                                    component="td"
                                >
                                    {data.chatTotal}
                                </Box>
                                <Box
                                    component="td"
                                >
                                    {data.seen}
                                </Box>
                            </tr>
                        ))}
                    </>
                )}
                {!hasAccess && (
                    <tr>
                        <Box
                            component='td'
                            colSpan={4}
                            sx={{ padding: '0px !important', cursor: 'pointer' }}
                            onClick={() => navigate("/profile?tab=subscription")}
                        >
                            <Group
                                align='center'
                                position='center'
                                spacing={4}
                                sx={{
                                    width: '100%',
                                    backgroundColor: "#417256",
                                    padding: 8
                                }}
                            >
                                <Text fz={16} color="white" fw={700}>Subscribe to</Text>
                                <SubscriptionBadge type={"BASIC"} />
                                <Text fz={16} color="white" fw={700}>for more insights</Text>
                            </Group>
                        </Box>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};

export default DashboardStateData;