import React, { useEffect, useMemo, useState } from "react";
import {
    Box,
    Modal,
    Text,
    Button,
    ActionIcon,
    Textarea,
    Grid,
    Group,
    Select,
    Card,
    Stack,
    Flex,
    ScrollArea,
    Image,
} from "@mantine/core";
import { ActionButton } from "components/Buttons/ActionButton";
import {
    Check,
    CircleCheck,
    CirclePlus,
    CircleX,
    Plus,
    User,
} from "tabler-icons-react";
import { useProfileController } from "hooks/useProfile";
import { ListerDetail } from "types/userTypes";
import { EditInput } from "components/Inputs/EditInput";
import { useForm } from "@mantine/form";
import {
    UserDetailData,
    updateUserDetailPayload,
    updateUserPayload,
} from "api/updateUserAPI";
import { notifications } from "@mantine/notifications";
import { allCountries } from "country-region-data";
import { useGetConfigs } from "api/configAPI";
import { CONFIG_KEYS, tokenData } from "helpers/constants";
import { DateInput } from "@mantine/dates";
import { IconCoin } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export const TokensModal: React.FC<any> = ({ opened, onCancel, tokenAmount, type }) => {
    const navigate = useNavigate();
    // const [selectedAmount, setSelectedAmount] = useState({
    //     amount: 23.5,
    //     tokenAmount: 5,
    // });

    const handleClose = () => {
        onCancel();
    };

    // const onClickTile = (tokenAmount: number, amount: number) => {
    //     setSelectedAmount({ tokenAmount, amount });
    // };

    return (
        <Modal
            centered
            opened={opened}
            onClose={onCancel}
            title="Insufficient tokens"
        >
            <Card p={0} sx={{ maxHeight: "70vh" }}>
                <ScrollArea>
                    <Stack spacing={8} align="center" sx={{ height: "100%" }}>
                        {!!tokenAmount && (
                            <Group spacing={4} align="center">
                                <Text fw={700}>{tokenAmount}</Text>
                                <Image
                                    src={"/token.png"}
                                    height={24}
                                    width={24}
                                    style={{ marginRight: "4px" }}
                                />
                            </Group>
                        )}
                        <Text
                            align="center"
                            component="h1"
                            m={0}
                            fw={700}
                            fz={18}
                        >
                            You do not have enough tokens for this action
                        </Text>

                        {/* <Grid m={0} p={0}>
                            {tokenData.map((data: any, index: number) => (
                                <Grid.Col
                                    span={12}
                                    key={index}
                                    m={0}
                                    px={24}
                                    py={8}
                                    mb={8}
                                    onClick={() =>
                                        onClickTile(
                                            data.tokenAmount,
                                            parseFloat(data.price)
                                        )
                                    }
                                    sx={{
                                        border: "1px solid #BFC8C3",
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: "12px",
                                        cursor: "pointer",
                                        ...(selectedAmount.tokenAmount ===
                                            data.tokenAmount && {
                                            border: "2px solid #212922",
                                            backgroundColor: "#EAEDEB",
                                        }),
                                    }}
                                >
                                    <Grid
                                        m={0}
                                        p={0}
                                        align={"center"}
                                        justify={"center"}
                                    >
                                        <Grid.Col span={6} p={0}>
                                            <Flex align={"center"}>
                                                <Flex
                                                    h={32}
                                                    w={32}
                                                    mr={12}
                                                    align={"center"}
                                                    justify={"center"}
                                                    sx={{
                                                        backgroundColor:
                                                            "#FEF0C7",
                                                    }}
                                                >
                                                    <IconCoin
                                                        width={14}
                                                        height={14}
                                                        color="#FDB022"
                                                    />
                                                </Flex>
                                                <Flex direction={"column"}>
                                                    <Text
                                                        weight={500}
                                                        size={14}
                                                        sx={{
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        {`${data.tokenAmount} ${
                                                            data.tokenAmount > 1
                                                                ? "Tokens"
                                                                : "Token"
                                                        }`}
                                                    </Text>
                                                    <Text
                                                        size={14}
                                                        sx={{
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        RM {data.pricePerLead} /
                                                        lead
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Grid.Col>
                                        <Grid.Col span={6} p={0}>
                                            <Flex
                                                direction={"column"}
                                                align={"flex-end"}
                                                justify={"center"}
                                            >
                                                <Text
                                                    weight={500}
                                                    size={14}
                                                    sx={{ lineHeight: "20px" }}
                                                >
                                                    RM {data.price}
                                                </Text>
                                                {data.saveAmount !== "" && (
                                                    <Text
                                                        weight={500}
                                                        size={14}
                                                        color="#F14950"
                                                        sx={{
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        Save RM{" "}
                                                        {data.saveAmount}
                                                    </Text>
                                                )}
                                            </Flex>
                                        </Grid.Col>
                                    </Grid>
                                </Grid.Col>
                            ))}
                        </Grid> */}
                        <Group sx={{ width: "100%" }}>
                            <Button
                                onClick={onCancel}
                                sx={{ flexGrow: 1 }}
                                variant="outline"
                            >
                                Later
                            </Button>
                            <Button
                                onClick={() => navigate("/tokens/purchase")}
                                sx={{ flexGrow: 1 }}
                            >
                                Buy

                            </Button>
                        </Group>
                    </Stack>
                </ScrollArea>
            </Card>
        </Modal>
    );
};
