import React from "react";
import { Button } from "@mantine/core";

export const ActionButton: React.FC<any> = ({
    children,
    ...props
}) => {
    return (
        <Button
            radius={8}
            size='md'
            {...props}
        >
            {children}
        </Button>
    )
}