//@ts-nocheck
import ReCAPTCHA from "react-google-recaptcha";

import { Button, Select, Stack, Text, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useGetConfigs } from 'api/configAPI';
import { useProjectAPI } from 'api/useProjectAPI';
import { BaseFormInput } from 'components/Inputs/BaseFormInput';
import { CONFIG_KEYS, incomeOptions } from 'helpers/constants';
import { strictNumberInput } from 'helpers/validations';
import React, { useCallback, useMemo, useState } from 'react';
import { BUKIT_JALIL_PROJECTS } from '.';

const ProjectContactForm = ({ submitCallback, projectName }: any) => {
    const [captchaPassed, setCaptchaPassed] = useState(false)
    const { useCreateProjectLead } = useProjectAPI()
    const createProjectLead = useCreateProjectLead()
    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    const allOccupations = useMemo(() => {

        if (!!configData && !configIsLoading) {
            return configData.find((p: any) => p.key === CONFIG_KEYS.OCCUPATION)
                .value;
        }

        return [];
    }, [configData]);

    const form = useForm({
        initialValues: {
            name: "",
            phoneNumber: "",
            purpose: "",
            occupation: "",
            annualIncomeRange: "",
            maritalStatus: "",
            ethnicity: "",
            additionalInfo: "",
            project: projectName ?? ""
        },
        validate: {
            name: (value) => !value ? "Please fill in this field" : null,
            phoneNumber: (value) => !value ? "Please fill in this field" : null,
            purpose: (value) => !value ? "Please fill in this field" : null,
            occupation: (value) => !value ? "Please fill in this field" : null,
            annualIncomeRange: (value) => !value ? "Please fill in this field" : null,
            maritalStatus: (value) => !value ? "Please fill in this field" : null,
            ethnicity: (value) => !value ? "Please fill in this field" : null,
            project: (value) => !value ? "Please fill in this field" : null,
            additionalInfo: (value) => {
                if (!value) {
                    return "Please fill in this field"
                }

                if (value.length < 5) {
                    return "At least 5 characters are required"
                }
                return null
            },
        }
    })

    const handleSubmit = useCallback(async () => {
        const { hasErrors } = form.validate()
        if (hasErrors) {
            return
        }

        await createProjectLead.mutateAsync({
            data: {
                ...form.values,
                phoneNumber: `+60${form.values.phoneNumber}`,
                projectName: projectName ?? form.values.project
            }
        })

        notifications.show({
            title: "Contact form submitted!",
            message: "Our partnered agents will contact you shortly",
            autoClose: 5000,
        });
        submitCallback && submitCallback()
        console.log(form.values)

    }, [form.values, projectName])

    return (
        <Stack>
            {!projectName && (
                <Select
                    withAsterisk
                    label="Interested Project"
                    data={BUKIT_JALIL_PROJECTS.map(p => p.title)}
                    {...form.getInputProps("project")}
                />
            )}
            <TextInput
                withAsterisk
                label="Preferred Name"
                {...form.getInputProps("name")}
            />
            <BaseFormInput
                withAsterisk
                type="number"
                label="Phone Number"
                icon={
                    <Text
                        color="black"
                        sx={{ whiteSpace: "nowrap" }}
                    >
                        {`(+60)`}
                    </Text>
                }
                onKeyDown={(evt: any) => strictNumberInput(evt)}
                iconWidth={50}
                {...form.getInputProps("phoneNumber")}
            />
            <Select
                withAsterisk
                label="Purpose"
                data={[
                    {
                        label: "Own-stay",
                        value: "Own-stay",
                    },
                    {
                        label: "Investment",
                        value: "Investment",
                    },
                ]}
                {...form.getInputProps("purpose")}
            />
            <Select
                withAsterisk
                label="Monthly Income Range"
                data={incomeOptions}
                {...form.getInputProps("annualIncomeRange")}
            />
            <Select
                withAsterisk
                label="Marital Status"
                data={["Single", "Married", "Divorced", "Widowed"]}
                {...form.getInputProps("maritalStatus")}
            />
            <Select
                withAsterisk
                label="Ethnicity"
                data={["Chinese", "Malay", "Indian", "Other"]}
                {...form.getInputProps("ethnicity")}
            />
            <Select
                withAsterisk
                label="Occupation"
                placeholder="Pick one"
                radius="md"
                searchable
                nothingFound="No options"
                data={allOccupations}
                {...form.getInputProps("occupation")}
            />
            <Stack spacing={2} sx={{ position: 'relative' }}>
                <Textarea
                    withAsterisk
                    label="Questions/Additional info"
                    {...form.getInputProps("additionalInfo")}
                    minRows={5}
                    styles={{
                        input: {
                            paddingBottom: 32
                        }
                    }}
                />
                <Text align="right" size={'xs'} sx={{ position: 'absolute', bottom: 4, right: 4 }}>{form.values.additionalInfo.length}/20</Text>
            </Stack>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                onChange={(e) => {
                    setCaptchaPassed(!!e)
                }}
            />
            <Button onClick={() => handleSubmit()} disabled={!captchaPassed || !form.isValid()}>Submit</Button>
            <Stack spacing={4}>
                <Text align='center'>The more you tell us about yourself,</Text>
                <Text align='center'>the better we can serve you</Text>
            </Stack>
            <Text align='center'>
                Your are an important client to us, We will <Text component='span' fw='bold' color="red">NEVER </Text>
                share your contact details to anyone other
                than the agents we work closely with.
            </Text>
        </Stack>
    );
};

export default ProjectContactForm;