import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
import { Permission, useAuth } from "hooks/useAuth";
const qs = require("qs");

export const GET_USER_INSIGHTS = "GET_USER_INSIGHTS";
export const GET_REQUEST_INSIGHTS = "GET_REQUEST_INSIGHTS";
export const GET_LISTING_INSIGHTS = "GET_LISTING_INSIGHTS";
export const GET_REFERRAL_INSIGHTS = "GET_REFERRAL_INSIGHTS";

export const useInsightsAPI = () => {
    const { user } = useAuth(Permission.USER_ONLY);

    const useGetUserInsights = () =>
        useQuery([GET_USER_INSIGHTS], async () => {
            try {
                const response = await API({
                    requireAuth: true,
                    token: user.jwt,
                }).get<any, any>(`insights/users`);

                return flattenObj(response.data);
            } catch (e) {
                return e;
            }
        });

    const useGetRequestInsights = () =>
        useQuery([GET_REQUEST_INSIGHTS], async () => {
            try {
                const response = await API({
                    requireAuth: true,
                    token: user.jwt,
                }).get<any, any>(`insights/requests`);

                return flattenObj(response.data);
            } catch (e) {
                return e;
            }
        });

    const useGetReferralInsights = () =>
        useQuery([GET_REFERRAL_INSIGHTS], async () => {
            try {
                const response = await API({
                    requireAuth: true,
                    token: user.jwt,
                }).get<any, any>(`insights/referral/me`);

                return flattenObj(response.data);
            } catch (e) {
                return e;
            }
        });

    return {
        useGetReferralInsights,
        useGetUserInsights,
        useGetRequestInsights,
    };
};
