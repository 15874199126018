import { Box, Button, Card, Group, Modal, Stack, Text, Badge, Flex, Image, useMantineTheme, Checkbox } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useUserAPI } from 'api/userAPI';
import { useProfileController } from 'hooks/useProfile';
import React, { useCallback, useState } from 'react';

const RequestTutorialModal = (props: any) => {
    const { useAgentUpdateRequestTutorialComplete } = useUserAPI()
    const agentUpdateRequestTutorialComplete = useAgentUpdateRequestTutorialComplete()

    const theme = useMantineTheme()
    const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const [checked, setChecked] = useState(false)

    const [step, setStep] = useState(1)

    const Step1 = () => {
        return (
            <Stack>
                <Text align="center" sx={{ color: "#417256" }} fz={32} fw="600">How do <Text sx={{ color: "#417256" }} fw="800" component='span'>REQUESTS</Text> work?</Text>
                <Text fz={'xl'} fw="500" align="center">Propmoth runs ads and creates content to bring in people that are interested in property to list their requirements here for you to view.</Text>
                <Text fz={'xl'} fw="500" align="center">We then verify these requests by calling the number attached to them to try to ensure that they are not other agents looking to poach your listings.</Text>
                <Text fz={'xl'} fw="500" align="center">We understand that some of these requests might be unrealistic but it's up to you to help them better understand the market! Just because they have a specific budget doesn't mean they can't be convinced to buy/rent somewhere else!</Text>
                <Flex
                    sx={(theme) => ({
                        alignItems: 'center',
                        gap: 8,
                        [theme.fn.smallerThan('md')]: {
                            flexDirection: 'column'
                        }
                    })}
                >
                    <Box sx={{ border: "2px solid #2C5314" }}>
                        <Image src="/request-tutorial/step1-1.png" />
                    </Box>
                    <Text fz={'xl'} color="red" >VS</Text>
                    <Box sx={{ border: "2px solid #2C5314" }}>
                        <Image src="/request-tutorial/step1-2.png" />
                    </Box>
                </Flex>
            </Stack >
        )
    }

    const Step2 = () => {
        return (
            <Stack>
                <Text align="center" sx={{ color: "#417256" }} fz={32} fw="600">How do I know which request is good?</Text>
                <Group spacing={4} position='center' sx={{ width: '100%' }}>
                    <Text fz={'xl'} fw="500" align="center">If a quick look at the request is not good enough, you can click
                        <Box component='span' px={4}>

                            <Button
                                variant="outline"
                                size={isTablet ? "xs" : 'md'}
                            >
                                View Details
                            </Button>
                        </Box>
                        to see more info about who made the request! These additional points of information are filled out by the requester and can help you understand them better before you make an offer.
                    </Text>
                </Group>
                <Group spacing={4} position='center' sx={{ width: '100%' }}>
                    <Text fz={'xl'} fw="500" align="center">IF a request is labeled</Text>
                    <Badge color="red">⚠️ Urgent</Badge>
                    <Text fz={'xl'} fw="500" align="center">their phone number is available for every agent to see!</Text>
                </Group>
                <Box sx={{ border: "2px solid #2C5314" }}>
                    <Image src="/request-tutorial/step2-1.png" />
                </Box>
            </Stack >
        )
    }

    const Step3 = () => {
        return (
            <Stack>
                <Text align="center" sx={{ color: "#417256" }} fz={32} fw="600">How do i make an <Text sx={{ color: "#417256" }} fw="800" component='span'>OFFER?</Text></Text>
                <Group spacing={4} position={isTablet ? "left" : 'center'} sx={{ width: '100%' }}>
                    <Text fz={'xl'} fw="500" align="center">You can make an offer by click the
                        <Box component='span' px={4}>
                            <Button size={isTablet ? "xs" : 'md'}>
                                Make Offer
                            </Button>
                        </Box>
                        button or click on
                        <Box component='span' px={4}>
                            <Button
                                variant="outline"
                                size={isTablet ? "xs" : 'md'}
                            >
                                View Details
                            </Button>
                        </Box>
                        to find out more about the who the request was made by.
                    </Text>
                </Group>
                <Text fz={'xl'} fw="500" align="center">Clicking on “Make Offer” will bring you to a page for you to input the property details you wish to offer!</Text>
                <Text fz={'xl'} fw="500" align="center">To save time, you can also copy and paste a link from propertyguru or iproperty!</Text>
                <Flex
                    sx={(theme) => ({
                        alignItems: 'center',
                        gap: 8,
                        [theme.fn.smallerThan('md')]: {
                            flexDirection: 'column'
                        }
                    })}
                >
                    <Box sx={{ border: "2px solid #2C5314" }}>
                        <Image src="/request-tutorial/step3-1.png" />
                    </Box>
                    <Box sx={{ border: "2px solid #2C5314" }}>
                        <Image src="/request-tutorial/step3-2.png" />
                    </Box>
                </Flex>
            </Stack>
        )
    }

    const Step4 = () => {
        return (
            <Stack>
                <Text align="center" sx={{ color: "#417256" }} fz={32} fw="600">How do I know if they have seen my offer?</Text>
                <Text fz={'xl'} fw="500" align="center" color="red">When you make an offer, the requester will be notified by us via WhatsApp!</Text>
                <Text fz={'xl'} fw="500" align="center">Make sure you send a convincing message with your offer!</Text>
                <Text fz={'xl'} fw="500" align="center">To check whether they have seen the offer, you can look at the icon on your offer in your dashboard to see if they have looked at your offer!</Text>
                <Text fz={'xl'} fw="500" align="center">If they decide to learn more about your offer, they will send you a message!</Text>
                <Flex
                    sx={(theme) => ({
                        alignItems: 'center',
                        gap: 8,
                        [theme.fn.smallerThan('md')]: {
                            flexDirection: 'column'
                        }
                    })}
                >
                    <Box sx={{ border: "2px solid #2C5314" }}>
                        <Image src="/request-tutorial/step4-1.png" />
                    </Box>
                    <Box sx={{ border: "2px solid #2C5314" }}>
                        <Image src="/request-tutorial/step4-2.png" />
                    </Box>
                </Flex>
            </Stack >
        )
    }

    const Step5 = () => {
        return (
            <Stack>
                <Text align="center" sx={{ color: "#417256" }} fz={32} fw="600">What happens if the requester never responds to ANY offer?</Text>
                <Text fz={'xl'} fw="500" align="center">We at Propmoth want to make sure that we create the best possible environment for serious agents and serious clients so we have implemented a few security measures to protect you!</Text>
                <Text fz={'xl'} fw="500" align="center">Each <Text sx={{ color: "#417256" }} fw="800" component='span'>REQUEST</Text> only lasts for a <Text color="red" fw="800" component='span'>MAXIMUM OF 3 MONTHS</Text></Text>
                <Text fz={'xl'} fw="500" align="center">after 3 months, the request will be taken down.</Text>
                <Text fz={'xl'} fw="500" align="center">
                    IF the client is <Text color="red" fw="800" component='span'>NOT ACTIVE</Text> and 3 months have passed, every agent thathas offered gets a <Text sx={{ color: "#417256" }} fw="800" component='span'>FULL REFUND</Text> of (1) TOKEN
                </Text>
                <Text fz={'xl'} fw="500" align="center">IF the client <Text sx={{ color: "#417256" }} fw="800" component='span'>ACCEPTS</Text> an offer by another agent, then the request is <Text color="red" fw="800" component='span'>CLOSED</Text> </Text>
                <Text fz={'xl'} fw="500" align="center">This will consume your token as the client is responsive and has decided on another agent/property.</Text>
                <Text sx={{ color: "#417256" }} fw="800" align="center">OUR GOAL IS TO MAINTAIN A 100% CLOSING RATE ON PROPMOTH</Text>
            </Stack >
        )
    }

    const Step6 = () => {
        return (
            <Stack>
                <Text align="center" sx={{ color: "#417256" }} fz={32} fw="600">Are you ready to beat your competition?</Text>
                <Text fz={'xl'} fw="500"><Text component='span' fw={'bold'}>1.</Text> Check in everyday to see someone has requested for property you can fulfill!</Text>
                <Stack spacing={2} align='left'>
                    <Text fz={'xl'} fw="500"><Text component='span' fw={'bold'}>2.</Text> Refer Propmoth to your colleagues and claim your free tokens! </Text>
                    <Text fz={'sm'} fw="500">(They will find us eventually so might as well get something out of it!)</Text>
                    <Box>
                        <Image src="/request-tutorial/step6-1.png" sx={(theme) => ({
                            width: '70%',
                            [theme.fn.smallerThan('md')]: {
                                width: '100%',
                            }
                        })} />
                    </Box>
                </Stack>
                <Text fz={'xl'} fw="500"><Text component='span' fw={'bold'}>3.</Text> Be proactive! It's only RM5 per lead and if the request is fake, you get your money back anyways!</Text>
                <Text fz={'xl'} fw="500"><Text component='span' fw={'bold'}>4.</Text> Be creative with your offers! It's not a listing so make sure you attach videos and brochures too! These private offers could even contain unique deals only you have!</Text>
            </Stack >
        )
    }

    const handleCloseAndSubmit = useCallback(async () => {
        if (!!checked && !!props.profileData) {
            await agentUpdateRequestTutorialComplete.mutateAsync({
                id: props.profileData.id,
                requestTutorialComplete: true
            })

            props.onClose()
        }
    }, [props.profileData, checked])

    const handleSteps = useCallback(() => {
        switch (step) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            case 4:
                return <Step4 />;
            case 5:
                return <Step5 />;
            case 6:
                return <Step6 />;
        }
    }, [step])

    return (
        <Modal
            centered
            opened={props.opened}
            onClose={props.onClose}
            size={isTablet ? "90%" : "70%"}
            styles={{
                content: {
                    border: "2px solid #2C5314"
                }
            }}
            closeOnClickOutside={props.isHelp}
            withCloseButton={props.isHelp}
        >
            <Stack>
                {handleSteps()}
                <Text color="red" fz="sm" align='center'>Please read carefully</Text>
                {step === 6 && !props.isHelp && (
                    <Group position='center'>
                        <Checkbox
                            label="I’ve read an understood the “Request and Offer” process "
                            checked={!!checked}
                            onChange={(event) => setChecked(event.currentTarget.checked)}
                        />
                    </Group>
                )}
                <Group sx={{ width: '100%' }} position='center'>
                    {step > 1 && (
                        <Button onClick={() => setStep(step - 1)} variant='outline'>Back</Button>
                    )}
                    {step < 6 && (
                        <Button onClick={() => setStep(step + 1)}>Next</Button>
                    )}
                    {step === 6 && !props.isHelp && (
                        <Button onClick={() => handleCloseAndSubmit()} disabled={!checked}>Let's Go!</Button>
                    )}
                    {step === 6 && !!props.isHelp && (
                        <Button onClick={() => props.onClose()} >Let's Go!</Button>
                    )}
                </Group>
                <Group sx={{ width: '100%' }} position='center'>
                    {[1, 2, 3, 4, 5, 6].map(i => (
                        <Box
                            key={`counter-${i}`}
                            sx={{
                                background: step === i ? "#4E7A4D" : "#D9D9D9",
                                height: 16,
                                width: 16,
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                        />
                    ))}
                </Group>
            </Stack>
        </Modal>
    );
};

export default RequestTutorialModal;