import React, { useState, useEffect, useContext, useMemo } from "react";
import { Permission, useAuth } from "hooks/useAuth";
import { useMutation, useQuery } from "react-query";
import { accountTypes } from "types/accountTypes";
import { AgentDetail, BaseUser, ListerDetail } from "types/userTypes";
import {
    UserDetailData,
    updateUser,
    updateUserDetailPayload,
    updateUserPayload,
} from "api/updateUserAPI";
import API from "api/API";
import { useUserAPI } from "api/userAPI";

export const useProfileController = () => {
    const qs = require("qs");
    const { user, userDispatch } = useAuth(Permission.USER_ONLY);
    const { useGetSubscription } = useUserAPI();
    const { data: subData, refetch: refetchSubscription } =
        useGetSubscription();

    const agentQuery = qs.stringify(
        {
            filters: {
                agent: {
                    username: {
                        $eq: user.username,
                    },
                },
            },
            populate: ["renImg", "agent", "agent.avatar"],
        },
        { encodeValuesOnly: true }
    );
    const listerQuery = qs.stringify(
        {
            filters: {
                user: {
                    username: {
                        $eq: user.username,
                    },
                },
            },
            populate: ["renImg", "user", "user.avatar"],
        },
        { encodeValuesOnly: true }
    );
    const accountType = user.userType;

    const {
        data: agentData,
        error: agentError,
        isLoading: agentIsLoading,
        refetch: refetchAgent,
    } = useQuery<AgentDetail[]>({
        queryKey: [`/agent-details?${agentQuery}`, user.jwt],
        enabled: accountType === accountTypes.AGENT,
    });

    const {
        data: listerData,
        error: listerError,
        isLoading: listerIsLoading,
        refetch: refetchLister,
    } = useQuery<ListerDetail[]>({
        queryKey: [`/user-details?${listerQuery}`, user.jwt],
        enabled: accountType === accountTypes.LISTER,
    });

    const refetchProfile = () => {
        if (accountType === accountTypes.AGENT) {
            refetchAgent();
        } else {
            refetchLister();
            refetchSubscription();
        }
    };

    // useEffect(() => {
    //     console.log('agent data', agentData)
    // },[agentData])
    // useEffect(() => {
    //     console.log('lister data', listerData)
    // },[listerData])

    const profile = useMemo(() => {
        if (accountType === accountTypes.AGENT && agentData) {
            return agentData[0];
        } else if (listerData) {
            return listerData[0];
        }
        return null;
    }, [accountType, agentData, listerData]);

    const updateUserMutation = useMutation({
        mutationFn: (data: updateUserPayload) => updateUser(data),
        onSuccess: (data) => {
            // console.log('update mutation succ', data)
        },
    });

    const updateAgentDetailMutation = useMutation({
        mutationFn: (data: any) => {
            const payload: any = { ...data };
            return API({ requireAuth: true }).put(
                `/agent-details/${data.detailId}`,
                { data: payload }
            );
        },
        onSuccess: (data) => {
            console.log("agent detail on suc", data);
        },
    });

    const updateUserDetailMutation = useMutation({
        mutationFn: (data: updateUserDetailPayload) => {
            const payload: any = { ...data };
            if (
                payload.noOfChildren === "" ||
                payload.noOfChildren === undefined
            ) {
                payload.noOfChildren = null;
            }
            return API({ requireAuth: true }).put(
                `/user-details/${data.detailId}`,
                { data: payload }
            );
        },
        onSuccess: (data) => {
            console.log("usdetail on suc", data);
        },
    });

    let profileData =
        !!user && user?.userType === accountTypes.AGENT
            ? (profile as AgentDetail)
            : (profile as ListerDetail);

    const currentActiveSubscription = useMemo(() => {
        if (!subData) {
            return false;
        }

        return subData;
    }, [subData]);

    const isPro = useMemo(() => {
        return (
            !!currentActiveSubscription &&
            currentActiveSubscription?.package === "PRO"
        );
    }, [subData]);
    const isBasic = useMemo(() => {
        return (
            !!currentActiveSubscription &&
            currentActiveSubscription?.package === "BASIC"
        );
    }, [subData]);
    const isUnsubscribed = useMemo(() => {
        return (
            !!currentActiveSubscription &&
            currentActiveSubscription?.package === "UNSUBBED"
        );
    }, [subData]);

    return {
        view: accountType,
        profileData: profileData as any,
        profileIsLoading: agentIsLoading || listerIsLoading,
        refetchProfile,
        updateUserMutation,
        updateUserDetailMutation,
        user,
        currentActiveSubscription,
        isPro,
        isBasic,
        isUnsubscribed,
        updateAgentDetailMutation
    };
};
