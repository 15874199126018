import { MantineProvider, MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
    // colorScheme: "dark",
    colors: {
        buyColor: [
            "#e1f1ff",
            "#b6d1fc",
            "#8ab2f5",
            "#5c93ee",
            "#3074e8",
            "#175acf",
            "#0e46a2",
            "#063275",
            "#011e49",
            "#000a1e",
        ],
        buyBackground: [
            "#e5f4ff",
            "#b9ddfa",
            "#8bc6f7",
            "#60b0f6",
            "#3f9af5",
            "#3181dc",
            "#2564ab",
            "#1a4879",
            "#0c2b49",
            "#000e1a",
        ],
        rentColor: [
            "#efe8ff",
            "#cdbcf9",
            "#ab91ef",
            "#8a65e7",
            "#6839df",
            "#4f20c6",
            "#3d189b",
            "#2c1170",
            "#190a45",
            "#0a021c",
        ],
        rentBackground: [
            "#e8e5ff",
            "#bab4fa",
            "#8c81f8",
            "#5f4ff7",
            "#371ff7",
            "#240ade",
            "#1b06ac",
            "#13037b",
            "#0a014a",
            "#02001a",
        ],
        investColor: [
            "#ffe5f7",
            "#fbb8de",
            "#f48bc6",
            "#ee5dad",
            "#e83096",
            "#cf177c",
            "#a20f61",
            "#750845",
            "#48032a",
            "#1d0011",
        ],
        investBackground: [
            "#fce9f6",
            "#f1bfe3",
            "#e993d1",
            "#e169c0",
            "#da43ae",
            "#c12e96",
            "#962374",
            "#6b1953",
            "#3f0e32",
            "#160311",
        ],
        primaryGreen: [
            "#e8f8ef",
            "#cce4d6",
            "#add1bc",
            "#8ebfa2",
            "#6fab87",
            "#56936e",
            "#417256",
            "#2e523c",
            "#1a3123",
            "#001208",
        ],
        bgYellow: [
            '#FBFAF7'
        ],
        grey: [
            '#EAEDEB',
            '#A9B6AF',
            '#ced4da',
        ]
    },
    primaryColor: "primaryGreen",
    headings: {
        sizes: {
            h1: { fontSize: '36px', fontWeight: 600 }
        }
    },
    fontFamily: "Public Sans, sans-serif"
};

interface ThemeProviderProps {
    children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
    return (
        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
            {children}
        </MantineProvider>
    );
}
